<div class="dialog" *ngIf="!addOtherOption">
    <div><mat-toolbar class="tool"><span>Confirmation</span></mat-toolbar></div>
    <div class="message">
        <span>One or more mandatory fields are empty, are you sure you wish to exit?</span>
    </div>
    <div class="b-middle">
        <button (click)="exit()" class="deletebutton fix" mat-button>Exit</button>
        <button (click)="closeDialog()" class="fix" mat-button>Cancel</button>
    </div>   
</div>
<div class="dialog" *ngIf="addOtherOption">
    <div><mat-toolbar class="tool"><span>Confirmation</span></mat-toolbar></div>
    <div class="message">
        <span>Are you sure you wish to add "{{searchTerm}}" to "{{active_field.label}}"?</span>
    </div>
    <div class="b-middle">
        <button (click)="confirm()" class="deletebutton fix" mat-button>Yes</button>
        <button (click)="closeDialog()" class="fix" mat-button>No</button>
    </div>   
</div>