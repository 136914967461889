<div style="background-color: #FBFBFB; height: 100vh;">
    <header>
        <img src="assets/city_page/est-logo.svg" alt="">
        <a class="logout" (click)="logout()">
            <img src="../../../assets/city_page/turn-off 1.svg" alt="logout">
            <span>Logout</span>
        </a>
    </header>
<ng-container>
    <div class="city-selection">
        <div class="title-head">
            <div class="select-city">
                <h3>Select City</h3>
                <p>Select one city you want to start with</p>
            </div>
            <span style="position: relative;">
                <input type="text" class="form-control" [formControl]="searchFilter" placeholder="Search">
                <img class="search" src="../../../assets/city_page/search.svg" alt="">
            </span>
        </div>
        <div class="city-overflow">
            <div class="city-list">
                <ng-container *ngFor="let cities of citieslist | searchFilter: 'search_param': search_param : citieslist">
                    <div class="city-box" (click)="selectCity(cities)" [ngClass]="{active: (cities.place_id==selectedCity?.place_id)}">
                        <div class="city-icon-box">
                            <div class="country-name" *ngIf="cities.parent_name">
                                {{cities.parent_name}}
                            </div>
                            <img class="country-cons" src="{{cities.image}}" onerror="this.src='assets/city_page/Others.png'">
                        </div>
                        <div matTooltip={{cities.place_name}} class="city-more">
                            <span class="check-icon">{{cities.place_name}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="next-btn" *ngIf="mobile">
            <button (click)="submitCity()">Next</button>
        </div>
    </div>
</ng-container>
</div>
