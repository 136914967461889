import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { ShareService } from "../../services/share.service";
import { MainBreadcrumbService } from "../main-breadcrumb/main-breadcrumb.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { NotificationService } from "../../../../src/app/services/notification.service";
// import { NotificationService } from '../../services/notification.service';
@Component({
  selector: "app-main-property",
  templateUrl: "./main-property.component.html",
  styleUrls: ["./main-property.component.css"],
})
export class MainPropertyComponent implements OnInit {
  watcher: Subscription;
  mobileview: boolean = false;
  gLoader: boolean = false;
  selected_property = {
    property_id: null,
    address: null,
  };
  property_access: any = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };

  public loader: boolean = true;
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private _crumbs: MainBreadcrumbService,
    private share: ShareService,
    private route: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private fnc: CommonfunctionService,
    private media: MediaObserver,
    private changeDetectorRef: ChangeDetectorRef,
    private clipboard: Clipboard,
    private notify: NotificationService
  ) {
    this.share.main_property_obj = this;

    iconRegistry
      .addSvgIcon(
        "add_property",
        sanitizer.bypassSecurityTrustResourceUrl("assets/icon/add_property.svg")
      )
      .addSvgIcon(
        "filer",
        sanitizer.bypassSecurityTrustResourceUrl("assets/icon/Filter.svg")
      )
      .addSvgIcon(
        "pin",
        sanitizer.bypassSecurityTrustResourceUrl("assets/icon/Map.svg")
      );
  }

  // isHandset$ = this.media
  // .asObservable()
  // .subscribe((change) => {
  //   change.forEach((item) => {
  //     if (item.mqAlias === 'xs' || item.mqAlias === 'sm') {
  //       this.mobileview = true;
  //     }
  //   });
  // });

  ngOnInit() {
    this.getAllowResource();
    //   this.isHandset$.subscribe(isHandset =>{
    //     //console.log(isHandset);
    //     this.mobileview = isHandset;

    //  })
    this.share.loader.subscribe((data: boolean) => {
      this.loader = data;
    });
  }
  getAllowResource() {
    this.property_access = this.fnc.checkResourceAccess("properties", false);
  }

  getCrumbs() {
    return this._crumbs.crumbs;
  }

  getMobileLastCrumb() {
    let totalcrumb = this.getCrumbs().length;
    return this._crumbs.crumbs[totalcrumb - 1];
  }

  getCount() {
    return this._crumbs.count;
  }
  save() {
    if (this.share.dynamic_obj.form) {
      this.share.block3_obj.submitForm();
    }
  }
  redirect() {
    if (this.share.block0_obj.for_parcel) {
      this.route.navigateByUrl("/parcel");
    } else if (this.property_access.PATCH) {
      this.route.navigateByUrl("/map");
    }
  }

  setMapMarker() {
    this.share.block1_obj.setMapMarker();
  }
  isPropertySection() {
    if (this.share.block0_obj?.entity_type == "property") {
      return true;
    }
    return false;
  }

  public copyToClipboard(id): void {
    const successful = this.clipboard.copy(id);
    if (successful) {
      this.notify.notify(
        `Property uid ${id} copied`,
        "success"
      );
    } else {
      this.notify.notify(`Not copied, try again`, "warn");
    }
  }
}
