import { DatePipe, Location, PlatformLocation } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { debounceTime } from 'rxjs';
import { ApiserviceService } from '../../../services/apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { NotificationService } from '../../../services/notification.service';
import { ShareService } from '../../../services/share.service';
// import { IMultiSelectOption, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatIconRegistry } from '@angular/material/icon';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { YEAR_MODE_FORMATS } from '../../quick-edit/quick-edit.component';


  /**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_entity_masters_master_id_values_get</li>
 * </ol>
 *
 */

  @Component({
    selector: 'master-multiselect',
    templateUrl: './master-multiselect.html',
    styleUrls: ['../../master-multiselect.css']
  })
  export class MasterMultiselect {
    editable: boolean = false;
    mobile: boolean = false;
    incoming: any;
    searchterm: string = '';
    list: any = [];
    filteredList: any = [];
    selectedValues: any = [];
    filteredSelectedValues: any = [];
    form_id: any;
    mt_error: boolean = false;
    selectedMaster: string = '';
    searchForm: UntypedFormControl;
    data_type: any;
    singleList: any = [];
    selectedItem: any = null;
    filteredSingleList: any = [];
    newList: any = [];
    tempVals: any = [];
    mainLoader: boolean = false;
    showAddButton: boolean = false;
    active_field: any = {
      id: null,
      label: null
    };
  
    constructor(public dialogRef: MatDialogRef<MasterMultiselect>, @Inject(MAT_DIALOG_DATA) public data: any,
      public iconRegistry: MatIconRegistry,
      public sanitizer: DomSanitizer,
      private api: ApiserviceService,
      private share: ShareService,
      private fnc: CommonfunctionService,
      private notify: NotificationService) {
      this.editable = data.attrib.is_editable;
      this.mobile = this.share.mobile;
      dialogRef.disableClose = true;
      this.incoming = data.attrib;
      this.data_type = this.incoming.data_type;
      this.list = this.data.source.getList(this.incoming, '');
      this.form_id = 'input_' + data.attrib.id;
      this.getSelectedValues(this.data.selected);
      this.searchForm = new UntypedFormControl('');
    }
  
    getSelectedValues(selected) {
      if (this.data_type == 'multi-select') {
        selected.forEach(element => {
          if (element.attr_id == this.data.attrib.id) {
            this.selectedValues = [];
            element.selected.forEach(id => {
              let val = this.fnc.getArrayValue('id', id, this.list);
              this.selectedValues.push(val);
            });
            this.filteredSelectedValues = this.selectedValues;
            this.tempVals = this.selectedValues;
          }
        });
      } else {
        this.singleList = this.list;
        this.filteredSingleList = this.singleList;
        if (this.data.source.propertyForm.get(this.form_id).value) {
          let id = this.data.source.propertyForm.get(this.form_id).value;
          this.selectedItem = this.fnc.getArrayValue('id', id, this.filteredSingleList);
          this.filteredSingleList = this.fnc.array_move(this.filteredSingleList, this.fnc.getArrayindex('id', id, this.filteredSingleList), 0);
  
        }
      }
      this.getList();
    }
  
    getList() {
      if (this.data_type == 'multi-select') {
        if (this.searchterm) {
          this.searchterm = this.searchterm.trim().toLowerCase();
          this.mainLoader = true;
          this.searchMasterApi(this.searchterm, this.incoming);        
          this.filteredList = [];
          this.filteredSelectedValues = [];
          //filtering original values using searchterm
          this.list.forEach(e => {
            if (this.selectedValues.indexOf(e) == -1) {
              if (e.label.toLowerCase().includes(this.searchterm)) {
                if (e.master_name.toLowerCase() == this.searchterm) {
                  this.showAddButton = false;
                }
                this.filteredList.push(e);
              } else if (e.master_name.toLowerCase().includes(this.searchterm)) {
                if (e.master_name.toLowerCase() == this.searchterm) {
                  this.showAddButton = false;
                }
                if (this.selectedValues.length) {
                  this.filteredList.push(e);
                }
              }
              if (!e.master_name.toLowerCase().includes(this.searchterm) && !e.label.toLowerCase().includes(this.searchterm)) {
                this.showAddButton = true;
              }
            }
          })
          //filtering selected values using searchterm
          this.selectedValues.forEach(val => {
            if (val.label.toLowerCase().includes(this.searchterm)) {
              if (val.label.toLowerCase() == this.searchterm) {
                this.showAddButton = false;
              }
              this.filteredSelectedValues.push(val);
            } else if (val.master_name.toLowerCase().includes(this.searchterm)) {
              if (val.master_name.toLowerCase() == this.searchterm) {
                this.showAddButton = false;
              }
              this.filteredSelectedValues.push(val);
            }
          });
        } else {
          this.mt_error = false;
          this.showAddButton = false;
          this.list.forEach(e => {
            if (this.selectedValues.indexOf(e) == -1) {
              this.filteredList.push(e);
            }
            this.filteredSelectedValues = this.selectedValues;
          })
        }
      } else {
        //filtering single select values using searchterm
        if (this.searchterm) {
          this.searchterm = this.searchterm.trim().toLowerCase();
          this.mainLoader = true;
          this.searchMasterApi(this.searchterm, this.incoming);
          this.filteredSingleList = [];
          this.singleList.forEach((slist) => {
            if (slist.label.toLowerCase().includes(this.searchterm)) {
              if (slist.label.toLowerCase() == this.searchterm) {
                this.showAddButton = false;
              }
              this.filteredSingleList.push(slist);
            } else if (slist.master_name.toLowerCase().includes(this.searchterm)) {
              if (slist.master_name.toLowerCase() == this.searchterm) {
                this.showAddButton = false;
              }
              if (this.selectedValues.length) {
                this.filteredSingleList.push(slist);
              }
            }
            if (!slist.master_name.toLowerCase().includes(this.searchterm) && !slist.label.toLowerCase().includes(this.searchterm)) {
              this.showAddButton = true;
            }
          });
        } else {
          this.showAddButton = false;
          this.filteredSingleList = this.singleList;
        }
      }
    }
  
    clear() {
      this.searchForm.setValue('');
      this.getList();
      this.filteredSelectedValues = this.tempVals;
    }
  
    add() {
      if (this.searchterm && this.data.attrib)
        this.data.source.searchTerm = this.searchterm;
      this.data.source.active_field = this.data.attrib;
      this.data.source.addNewMasterValue(this.searchterm, this.data.attrib, this.data.parent, this.selectedMaster);
      this.dialogRef.close();
    }
  
    save() {
      // if (this.searchterm.trim().toLowerCase()) {
      //   this.mt_error = true;
      //   return;
      // }
  
      this.selectedValues = this.tempVals;
      let key = this.form_id;
      let ids = [], values = {};
      this.selectedValues.forEach((id) => {
        ids.push(id.id);
      })
      values = {
        [this.form_id]: ids
      };
     if(this.data.attrib.is_mandatory && !this.selectedValues.length){
      this.notify.notify('Field is required and cannot be empty, Select at least 1 value', 'warn');
       return
     }
      this.data.source.propertyForm.patchValue(values);
  
      if(this.data.parent === false){
        let parent_selected_values = values[this.form_id];
        if(parent_selected_values.length === 0){
          if(this.data.attrib && this.data.attrib.child && this.data.attrib.child.length){
            this.data.attrib.child.forEach(ch => {
              let ch_form_id = 'input_' + ch.id;
              if(ch.data_type == 'single-select'){
                this.data.source.propertyForm.patchValue({
                  [ch_form_id]: null
                })
              }else if (ch.data_type == 'multi-select'){
                this.data.source.propertyForm.patchValue({
                  [ch_form_id]: []
                })
              }
  
            });
          }
        }
      }
  
      this.data.source.changeMultiChild(ids, this.incoming);
      this.data.source.submitForm();
      this.dialogRef.close();
    }
    saveSingle() {
    if(this.selectedItem == null && this.data.attrib.is_mandatory){
      this.notify.notify('Field is required and cannot be empty, Select at least 1 value', 'warn');
       return
   }
   else if(this.selectedItem == null && !this.data.attrib.is_mandatory){
    this.data.source.submitForm();
    this.dialogRef.close();
   }
   else{
   this.searchterm = '';
    let key = this.form_id;
    let values = {
      [this.form_id]: this.selectedItem.id
    };
    this.data.source.changeChild(this.selectedItem.id, this.incoming);
    this.data.source.propertyForm.patchValue(values);
  
    // if(this.data.parent === false){
    //   let parent_selected_values = values[this.form_id];
    //   if(!parent_selected_values){
    //     if(this.data.attrib && this.data.attrib.child && this.data.attrib.child.length){
    //       this.data.attrib.child.forEach(ch => {
    //         let ch_form_id = 'input_' + ch.id;
    //         if(ch.data_type == 'single-select'){
    //           this.data.source.propertyForm.patchValue({
    //             [ch_form_id]: null
    //           })
    //         }else if (ch.data_type == 'multi-select'){
    //           this.data.source.propertyForm.patchValue({
    //             [ch_form_id]: []
    //           })
    //         }
    //       });
    //     }
    //   }
    // }
    this.data.source.submitForm();
    this.dialogRef.close();
  }
    }
  
    drop(event: CdkDragDrop<string[]>, direction) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
        if (!this.searchterm) {
          if (direction == 'right') {
            if (event.container.data.length) {
              this.tempVals = event.container.data;
            }
          } else if (direction == 'left') {
            if (event.container.data.length) {
              event.container.data.forEach((val: any) => {
                this.tempVals = this.fnc.getDeletedArray('id', val.id, this.tempVals);
              })
            }
          }
        } else if (this.searchterm) {
          if (direction == 'right') {
            if (event.container.data.length) {
              event.container.data.forEach((val: any) => {
                if (this.fnc.getArrayindex('id', val.id, this.tempVals) == null) {
                  this.tempVals.push(val);
                };
              })
            }
          } else if (direction == 'left') {
            if (event.container.data) {
              event.container.data.forEach((val: any) => {
                if (val) this.tempVals = this.fnc.getDeletedArray('id', val.id, this.tempVals);
  
              })
            }
          }
        }
      }
    }
  
    selectItem(i) {
      this.selectedItem = i;
    }
  
    cancel() {
      this.dialogRef.close();
      this.clear();
      this.data.source.active_field = null;
    }
  
  
    searchMasterApi(str, attrib) {
      str = str.toLowerCase();
      let url = this.share.block0_obj.entity_type + "/masters/" + attrib.master_id + "/values?sort_by=sort_order&limit=100&offset=0&status=1&master_value_name=" + str;
      if (this.share.block0_obj.property_type_id && this.share.block0_obj.entity_type == "property_unit") {
        url += "&property_type_ids=" + this.share.block0_obj.property_type_id;
      }
      let curMaster: any;
      this.api.getEmsData(url)
        .subscribe({
          next:(data: any) => {
          this.mainLoader = false;
          this.newList = [];
          let label = '';
          data.forEach((element, k) => {
            label = element.master_value_name;
            if (element.master_value_label[this.data.source.form_language]) {
              label = element.master_value_label[this.data.source.form_language];
            }
            let val = {
              label: label,
              labels: element.master_value_label,
              master_id: attrib.master_id,
              master_name: element.master_value_name,
              id: element.master_value_id,
              status: element.status,
              property_type_id: element.property_type_id,
              master_value_id: element.parent_master_value_id,
              parent_master_value_ids: element.parent_master_value_ids,
              name: label, // adding this for drop down
              image_url:element.image_url
            };
            if (this.data_type == 'multi-select') {
              curMaster = this.fnc.getArrayValue('id', val.id, this.list);
              if (curMaster == null) {
                this.data.source.master_val.push(val);
                this.filteredList.push(val);
              }
            } else {
              curMaster = this.fnc.getArrayValue('id', val.id, this.filteredSingleList);
              if (curMaster == null) {
                this.data.source.master_val.push(val);
                this.filteredSingleList.push(val);
              }
            }
  
            this.newList.push(val)
          });
          
        }, error:() => {
          this.mainLoader = false;
        }
      });
    }
  
  
  
    ngOnInit() {
      this.searchForm.valueChanges
        .pipe(debounceTime(500))
        .subscribe((val) => {
          this.searchterm = val;
          this.filteredList = [];
          this.getList();
        })
        if(!this.editable){
          // this.searchForm.disable();
        }
    }
  }
  