<div *ngIf="!mobile">
    <div class="block" id="DynamicBlock">
        <div class="button-block-m">
        <ng-container *ngFor="let value of sub_group">
            <div *ngIf="value.status" class="big-button animated fadeIn delay-2s" [ngClass]="{'active':isActive(value.id)}" (click)="openOptions(value)">
                <div>{{value.group_name}}</div>
            </div>
        </ng-container>
        </div>
    </div>
</div>

<div *ngIf="mobile">
    <app-mobile-toolbar [back]="true" [nav_link]="'/groups'" [title]="'Property Info | '+selected_group.group_name"
        [property_uid]="selected_property.property_id"></app-mobile-toolbar>


    <div class="block-m" id="pList">
        <div class="mobile-block">
            <ng-container *ngFor="let value of sub_group">
                <div *ngIf="value.status" (click)="openOptions(value,true)" class="big-button animated fadeIn delay-2s" [ngClass]="{'active':isActive(value.id)}">
                    <div>
                        {{value.group_name}}
                    </div>
                    <div class="led">
                        <!-- <div id="indicator"></div> -->
                        <mat-icon class="indicator" [ngClass]="{'filled':isFilled(value.id)}" svgIcon="check"></mat-icon>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>