<div *ngIf="!mobileview">
    <div class="block">
        <div class="search_web">
            <div class="searchBtn_web">
                <div>
                    <input type="search" class="search-input" placeholder="Enter Property UID" [formControl]="searchField">
                    <!--<input (input)="getProperties(false)" [(ngModel)]="search_input" class="search-input">-->
                </div>
                <div id="mag" title="search">
                    <mat-icon class="magnify hvr-grow">search</mat-icon>
                </div>
                <div class="searchFilter" [ngClass]="{'addFilter': getfilter()}" title="Filter" (click)="openFilter()">
                    <mat-icon class="hvr-grow" svgIcon="filter"></mat-icon>
                </div>
            </div>
        </div>
        <div class="pList-W pro-list" id="webPList" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll()">
            <div *ngFor="let property of property_list_custom" class="pCard-W animated fadeIn delay-2s" [ngClass]="{active: isActive(property.id),  'reworkpro' : property.status == 4, 'reworkstatus':property.status == 6}"
                (click)="openOptions(property)">
                <div class="property-image">
                    <img id="i-web" src="{{property.img_url}}" alt="Apartment">
                    <!-- <div id="i-web" [ngStyle]="{'background': 'linear-gradient(to top, black , transparent), url('+property.img_url+')', 'background-repeat':'no-repeat', 'background-size': 'cover'}"></div> -->
                    <div class="subtitle-W">
                        <span>{{property.property_id}}</span>
                    </div>
                </div>
                <div class="details">
                    <div class="property-name">
                        <div>
                            <p class="clmp fontp cp" title="{{property.property_name}}">{{property.property_name?property.property_name:'Property Name'}}</p>
                        </div>
                    </div>
                    <div class="parts">
                        <div class="year">
                            <span>Build year:
                               <br *ngIf="property.build_status.length > 4"> <b>{{property.build_status}}</b>
                            </span>
                        </div>
                        <div class="catagory">
                            <p class="clmp99 catg">{{property.property_category}}</p>
                            <span *ngIf="property.property_category.length > 8" class="tooltiptext">{{property.property_category}}</span>
                        </div>
                    </div>
                    <div id="ad">
                        <div class="">
                            <p class="clmp3 address">{{(property.address.length > 33) ? (property.address | slice:0:33)+'...': property.address}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="loader" class='loader-div'>
                <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
            </div>
            <!-- quickedit loader start-->
            <div *ngIf="loading">
                <ngx-loading [show]="loading" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
            </div>
            <!-- quickedit loader end -->

        </div>
    </div>
</div>



<!-- Mobile view of Property List -->
<div *ngIf="mobileview" (window:resize)="onResize($event)">
    <div>
        <app-mobile-toolbar
        [title]="assignment_name"
        [username]="selectedUserName()"></app-mobile-toolbar>
    </div>
    <div class="search">
        <div class="searchBtn">
            <div class="box">
                <div>
                    <input type="search" class="search-input" placeholder="Enter Property UID" [formControl]="searchField">
                    <!--<input (input)="getProperties(false)" [(ngModel)]="search_input" class="search-input">-->
                </div>
                <div id="mag" title="search">
                    <mat-icon class="magnify">search</mat-icon>
                </div>
            </div>
            <div class="searchFilter" [ngClass]="{'addFilter': getfilter()}" title="Filter" (click)="openFilter()">
              <mat-icon class="hvr-grow" svgIcon="filter"></mat-icon>
          </div>
        </div>
    </div>

    <div class="list-container-mobile" #viewWidth>
        <div class="pList pro-list" style="overflow: auto;" id="pList" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
            [scrollWindow]="false" (scrolled)="onScroll()">
            <div *ngFor="let property of property_list_custom" [ngClass]="{active: isActive(property.id), 'reworkpro' : property.status == 4, 'reworkstatus':property.status == 6}"
                (click)="openOptions(property,true)" class="pCard animated fadeIn delay-2s">
                <div class="card-text">
                    <div class="title">
                        <p class="clmp">
                            {{property.property_name}}
                        </p>
                    </div>
                    <div class="subtitle">
                        {{property.property_id}}
                    </div>
                </div>
                <div class="pImage" [ngStyle]="{'background': 'linear-gradient(to top, black , transparent), url('+property.img_url+')', 'background-repeat':'no-repeat', 'background-size': 'cover'}">
                    <!-- <img id="i-mob" src="{{property.img_url}}" alt=""> -->
                </div>
                <div class="pOver">
                    <div class="pricing">
                        <div class="">
                            <p class="bs-text">{{property.build_status}}</p>
                        </div>
                        <div class="">
                            <p class="s-text">{{property.property_category}}</p>
                        </div>
                    </div>
                    <div class="addr">
                        <p class="comment clmp2">
                            <span class="field">Address: </span>{{property.address}}</p>
                    </div>
                </div>
            </div>
            <!-- loader start -->
            <div *ngIf="loader" class='loader-div'>
                <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
            </div>
            <!-- loader end -->
            <!-- quickedit loader start-->
            <div *ngIf="loading">
                    <ngx-loading [show]="loading" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
            </div>
            <!-- quickedit loader end -->
        </div>
    </div>
    <div>
        <app-add-f-button *ngIf="property_access.PATCH && !for_parcel && assignment_detail" [routerLink]="['/map']"></app-add-f-button>
        <app-add-f-button *ngIf="for_parcel" [routerLink]="['/parcel']"></app-add-f-button>
    </div>
</div>
