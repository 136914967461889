<div *ngIf="!mobile">
    <div *ngIf="plist" class="list-container">
            <app-block0 *ngIf="proList" (notify)="onOptionsRequested($event)"></app-block0>
            <app-block1 *ngIf="groups" (notify)="onOptionsRequested($event)"></app-block1>
            <app-block2 *ngIf="subGroups" (notify)="onOptionsRequested($event)"></app-block2>
            <app-floor *ngIf="floor" (notify)="onOptionsRequested($event)"></app-floor>
            <app-floor-group *ngIf="fGroup" (notify)="onOptionsRequested($event)"></app-floor-group>
            <app-floor-subgroup *ngIf="floorSubgroup" (notify)="onOptionsRequested($event)"></app-floor-subgroup>
            <app-unit *ngIf="unit" (notify)="onOptionsRequested($event)"></app-unit>
            <app-unit-group *ngIf="unitGroup" (notify)="onOptionsRequested($event)"></app-unit-group>
            <app-unit-subgroup *ngIf="unitSubgroup" (notify)="onOptionsRequested($event)"></app-unit-subgroup>
            <app-photo-block *ngIf="uploadMedia"></app-photo-block>
            <!-- <app-photo-block *ngIf="upload_Media_unit"></app-photo-block> -->
            <app-block3 *ngIf="form" (notif)="onOptionsRequested($event)"></app-block3>
    </div>
    <div *ngIf="map">
        <app-map (notify)="onOptionsRequested($event)"></app-map>
    </div>
</div>

<div *ngIf="mobile">
    <app-block0></app-block0>
</div>