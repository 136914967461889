import { Component, OnInit, Inject, ElementRef, EventEmitter, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import { ShareService } from '../../../services/share.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { ApiserviceService } from '../../../services/apiservice.service';
import { NotificationService } from '../../../services/notification.service';

export interface subgrouplist {
  group_name : any,
  id : number,
  name : string,
  property_id : any,
  labels : any,
  description : any,
  status : boolean,
  parent_id : number,
  attributes_count: number
};
export interface grouplist {
  group_name : any,
  childrens : Array<subgrouplist>,
  id : number,
  name : string,
  property_id : any,
  labels : string,
  description : any,
  status : boolean,
  parent_id : number,
  attributes_count: number,
  sub_group_count : number
};

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_get</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-floor-group',
  templateUrl: './floor-group.component.html',
  styleUrls: ['./floor-group.component.css']
})
export class FloorGroupComponent implements OnInit {
  mobile : boolean = false;
  floor : boolean = false;
  hasRejection: boolean = false;
  assignment_detail:any;
  dHeight:number;
  group_custom_list_floor:any;
  selected_group: any;
  selected_floor:any;
  selected_property: any;
  selected_group_id:number;
  floor_access:any;
  selected_fg_id: Array<number>=[];
  reworkAttributes: any = [];

  @Output() notify: EventEmitter<string> = new EventEmitter<string>();

  constructor(private elementRef: ElementRef, private location: Location,
    public dialog: MatDialog, private share:ShareService, iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer, private route:Router, private fnc: CommonfunctionService, private api: ApiserviceService) {
    this.assignment_detail = localStorage.getItem('assignment_detail');
    this.share.floor_group_obj = this;
    this.mobile = this.share.mobile;
    if(!this.share.block0_obj){
      this.route.navigateByUrl('/');
      return;
    }
    this.api.form_language = null; //reset form language to default
    this.floor_access = this.share.block0_obj.floor_access;
    this.selected_floor = this.share.floor_obj.selected_floor;
    iconRegistry.addSvgIcon('floor',sanitizer.bypassSecurityTrustResourceUrl('assets/icon/bottom/Floor.svg'))
                .addSvgIcon('delete',sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/delete.svg'))
                .addSvgIcon('shuffle',sanitizer.bypassSecurityTrustResourceUrl('assets/images/Shuffle_arrow.svg'))
                .addSvgIcon('tick',sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Check.svg'));

    if(this.share.block0_obj.entity_type != 'property_floor'){ // In case of back button.
      this.share.block0_obj.entity_type = 'property_floor';
      this.share.block0_obj.getAttributes();
    }
    this.share.block0_obj.getAllowResource();
    setTimeout(()=>{
      this.floor_access = this.share.block0_obj.floor_access;
    },800);
  }
  onCheckStatus(e){
    if(e.checked){
      this.selected_fg_id.push(+e.source.value);
    }else{
      this.selected_fg_id.splice(this.selected_fg_id.indexOf(+e.source.value),1);
    }
  }
  backClicked(){
    //this.location.back();
    this.route.navigateByUrl('floor');
  }

  routing(data){
    this.selected_group = data;
    if(data.childrens.length > 0){
      this.route.navigateByUrl('/floorSubgroup');
    }
  }
  openOptions(group,mobile:boolean=false):void{
    if(mobile == false){
      if(this.share.dynamic_obj.form){
        this.share.block3_obj.submitForm();
      }
    }
    this.selected_group_id = group.id;
    this.selected_group = group;
    this.share.block2_obj = null;
    if(this.selected_group_id == 0){
     if(mobile){
      this.route.navigateByUrl('unit');
      //  if(this.share.floor_obj.floor_map){
      //   this.route.navigateByUrl('/floor/map');
      //  }else{
      //   this.route.navigateByUrl('unit');
      //  }
     }
     this.notify.emit('unit');
     return;
    }
    let child = this.fnc.getArrayValue('id',this.selected_group_id,this.group_custom_list_floor);
    child = child.childrens;
    if(child.length > 0){
      this.notify.emit('floorSubgroup');
      if(mobile){
        this.route.navigateByUrl('floorSubgroup');
      }
    }else{
      this.share.floor_subgroup_obj = null;
      if(this.share.dynamic_obj){
        this.share.dynamic_obj.floorSubgroup = false;
      }
      this.share.unit_obj = null; //to prevent opening of incorrect component
      this.notify.emit('form');
      if(mobile){
        this.route.navigateByUrl('form');
      }
    }

}

checkRejected(){
  this.assignment_detail = JSON.parse(this.assignment_detail);
  let url =  "deadmin/"+this.assignment_detail.assignment_name+"/properties/"+this.share.block0_obj.selected_property.id+'/floors/'+this.share.floor_obj.selected_floor_id+'/units?status=4,6';
  url += `&get_latest_property=Y`;
  this.api.getEpsData(url).subscribe({next:(data:any) =>{
    if(data && data.status == undefined && data.status !=404){
      this.hasRejection = true;
    }else{
      this.hasRejection  = false;
    }
  },error:()=>{
    this.hasRejection  = false;
  }})
}
geometry_loader:boolean;
  ngOnInit() {
    this.share.geometry_loader.subscribe((ele:boolean)=>{
      this.geometry_loader = ele;
    })

    setTimeout(()=>{
      this.dHeight = this.share.heightService;
      let e = $(this.elementRef.nativeElement);
      e.find('#pList').css('height',this.dHeight);
      e.find('#DynamicBlock').css('height',(this.dHeight-50));
    }, 10);

    this.selected_property = this.share.block0_obj.selected_property;
    this.checkRejected();
    this.group_custom_list_floor = this.share.block0_obj.group_custom_list_floor;
    if(this.group_custom_list_floor && this.share.block0_obj.unit_access.GET){
      if(this.fnc.getArrayindex('id',0,this.group_custom_list_floor) === null){
        let floor = {
          group_name : 'Unit',
          childrens : [],
          id : 0,
          name : 'unit',
          property_id : null,
          labels : null,
          description : null,
          status : true,
          parent_id : null,
          attributes_count: null,
          sub_group_count : null
        }
        if(this.group_custom_list_floor.length > 2){
          setTimeout(()=>{
            this.group_custom_list_floor.splice(2,0,floor);
          },100);
        }else{
          this.group_custom_list_floor.push(floor);
        }
      }
    }
  }
  isActive(v){
    if(v == this.selected_group_id) return true;
  }

  openDialog(type : string, info:any = {}): void {
    let dialogview;
    info.pObj = this;
    if(type == 'floorPaste') {
      dialogview = FloorPasteComponent
    }
    if(dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: '350px',
        panelClass : 'create-master-panel',
        data: info
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  showFloor(){
    if(this.floor == false){
      this.floor = true;
    }
    else{
      this.floor = false;
    }
  }

  isRerorkFloorGroup(group){

    let id = group.id;
    let status = false,child;
    let reworkrecord = [];
    if(this.share.floor_obj){
      reworkrecord = this.share.floor_obj.rejectedFloorAttributeList;
    }

    let attributes = this.fnc.getChildArray('group_id',id,this.share.block0_obj.attribute_custom_list_floor);
   child = group.childrens;
    if(child.length){
      let sub_attrib=[];
      child.forEach((v) => {
        sub_attrib = this.fnc.getChildArray('group_id', v.id, this.share.block0_obj.attribute_custom_list_floor);
        if (sub_attrib.length && reworkrecord.length > 0) {

          for (let k = 0; k < reworkrecord.length; k++) {
            for (let j = 0; j < sub_attrib.length; j++) {
              if (reworkrecord[k]) {
                if (reworkrecord[k].keyId == sub_attrib[j].id) {
                  status = true;
                  break;
                }
              }
            }
          }
        }
      });
    }
    if(reworkrecord){
      for (let k = 0; k < reworkrecord.length; k++) {
        for (let j = 0; j < attributes.length; j++) {
          if (reworkrecord[k]) {
            if (reworkrecord[k].keyId == attributes[j].id) {
              status = true;
              break;
            }
          }
        }
      }
    }
     return status;
  }
}

 /**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_get</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_copy_post</li>
 * </ol>
 *
 */

@Component({
  selector: 'floorPaste',
  templateUrl: './floorPaste.component.html',
  styleUrls: ['./floor-group.component.css']
})
export class FloorPasteComponent {
  mobile : boolean = false;
  count = 1;
  loader = false;
  selected_group_ids:any;
  enableConfirm: boolean = false;
  selected_floor_ids = [];
  floorData:Array<{}> = [
    {
      serial : 1,
      type : 'Brick Floor',
      units : 4,
      parking : 5
    }
   ];
  assignment_detail: any;
  confirmation:boolean = false;
  constructor(private share:ShareService, public dialogRef: MatDialogRef<FloorPasteComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private api:ApiserviceService, private notify:NotificationService){
    this.mobile = this.share.mobile;
     this.selected_group_ids = this.data.pObj.selected_fg_id;
     this.assignment_detail = JSON.parse(localStorage.getItem('assignment_detail'));
  }

  ngOnInit(){
    this.getFloorData();
  }

  saveDialog(): void {
    //save information here
    this.dialogRef.close();
  }
  close(){
    this.dialogRef.close();
  }

  selectFloor(id){
    if(this.selected_floor_ids.indexOf(id) == -1){
      this.selected_floor_ids.push(id);
    }else{
      this.selected_floor_ids.splice(this.selected_floor_ids.indexOf(id),1);
    }
    if(this.selected_floor_ids.length>0){
      this.enableConfirm = true;
    }else{
      this.enableConfirm = false;
    }
  }

  isSelected(id){
    if(this.selected_floor_ids.indexOf(id) != -1){
      return true;
    }
  }

   getFloorData(is_scroll = false) {
      this.floorData = [];
      this.loader = true;
      let parking = 0;
    this.api.getEpsData("deadmin/"+this.assignment_detail.assignment_name+'/properties/' + this.share.block0_obj.selected_property.id + '/floors?get_latest_property=Y')
      .subscribe({
          next: (data:any) => {
          data.forEach(e => {
            parking = 0;
            if (e.data) {
              if (e.data.parking){
                let num = 0;
                  e.data.parking.forEach(ele => {
                      num += ele.parking_value;
                  });
                  parking = num;
              }
            }
            let fdata: any = {
              serial: this.count,
              sort_order: e.sort_order,
              uid: e.floor_uid,
              type: e.floor_name,
              units: e.no_of_units,
              parking: parking,
              id: e.floor_id
            }
            if(e.floor_id != this.share.floor_obj.selected_floor_id){ // exclude current floor from floor list
              this.floorData.push(fdata);
            }
            this.count++;
          });
          this.loader = false;
        },
        error:() => {
          this.floorData = [];
          this.loader = false;
        }}
      )
  }


  confirm(flag:string){
    if(flag == 'YES'){
      this.copyFloor()
    }else{
      this.confirmation = false
      this.dialogRef.close();
      return;
    }
  }
  copyFloor(){
    if(this.selected_group_ids.length && this.selected_floor_ids.length){
      let unit = false;
      if(this.selected_group_ids.indexOf(0) != -1){
        unit = true;
        //this.selected_group_ids.splice(this.selected_group_ids.indexOf(0),1);
      }
      if(this.selected_group_ids.length == 0) this.selected_group_ids.push(0);
      let body = {
        "attribute_group_ids": this.selected_group_ids,
        "copy_units": unit,
        "overwrite_existing": true,
        "target_floor_ids": this.selected_floor_ids,
        "updated_by": this.api.user_id
      }
      let url = "deadmin/"+this.assignment_detail.assignment_name+'/properties/' + this.share.block0_obj.selected_property.id + '/floors/'+this.share.floor_obj.selected_floor_id+'/copy';
      this.api.postEpsData(url,body)
      .subscribe({next:(data:any) =>{
        this.notify.notify('Unit copied','success');
        this.share.floor_obj.getFloorData(false)
      },error:()=>{
        this.notify.notify('Unit not copied','error');
      }});
      this.dialogRef.close();
    }
  }
}
