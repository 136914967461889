import { Component, OnInit, Input, ViewChildren, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MainMenuService } from '../../main-menu.service';
import {DomSanitizer} from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatListItem } from '@angular/material/list';
import { MediaObserver} from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { ApiserviceService } from '../../services/apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { Router } from '@angular/router';
import { ShareService } from '../../services/share.service';
import { MainBreadcrumbService } from '../main-breadcrumb/main-breadcrumb.service';
import { map, tap } from 'rxjs';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ProfileComponent } from '../profile.component';

interface MainMenu {
  key : string,
  title? : string,
  icon? : string,
  subMenu : Array<any>,
  url : string
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_assignments_get</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-main-sidenav',
  templateUrl: './main-sidenav.component.html',
  styleUrls: ['./main-sidenav.component.css']
})
export class MainSidenavComponent implements OnInit {
  watcher: Subscription;
  mobile : boolean = false;
  selected_submenu:any;
  assignment_detail:any = null;
  selected_submenu_title: any = null;

  @Input() size : any;
  @Input() mode :string;
  mainMenu : MainMenu[] =  [
    {
      key : 'home',
      title : "Home",
      icon : 'home',
      url : 'home',
      subMenu: []
    },
    {
      key : 'city',
      title : "City",
      icon : 'property',
      url : 'country',
      subMenu: []
    },
    {
      key : 'my_assignments',
      title : "My Assignments",
      icon : 'my_listing',
      url : '',
      subMenu : [
        {
          key : 'assignments',
          title : "Loading...",
          url : 'assignment'
        }
      ]
    }
  ]


  filterTerm:string='';
    constructor(private media: MediaObserver,private menu : MainMenuService,iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,private _crumbs: MainBreadcrumbService,
    private api: ApiserviceService,private fnc:CommonfunctionService,private router: Router, private share: ShareService, private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog) {
    iconRegistry.addSvgIcon('my_listing', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/my_listing.svg'))
                .addSvgIcon('home', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/home.svg'))
                .addSvgIcon('led', sanitizer.bypassSecurityTrustResourceUrl('assets/images/indicator.svg'));

    this.share.main_side_nav = this;

   }

  //  isHandset$ = this.media
  //  .asObservable()
  //  .subscribe((change) => {
  //    change.forEach((item) => {
  //      if (item.mqAlias === 'xs' || item.mqAlias === 'sm') {
  //        this.mobile = true;
  //      }
  //    });
  //  });

   logout(){
    this.api.logout(false);
   }
  //  openProfile(){
  //   this.dialog.open(ProfileComponent,{
  //     width: '450px',
  //     panelClass: 'create-master-panel',
  //     data: {}
  //   })
  //   this.closeNav();
  //  }
   closeNav(){
     this.share.sideNav_obj.sidenavClose();
   }
   getAssignment(){
    if(this.mode == 'small') return;
     let assignment = [], title,pro_count = '...';

    this.api.getEmsData('assignments?sort_by=assignment_name&status=1&assigned_to='+this.api.user_id+'&property_count=true&property_status=1,4,6')
    .subscribe(
      {next: (data:any) => {
        assignment = [];
        data.forEach((v,k)=>{
          title = v.assignment_name;
          if(v.assignment_label[this.api.language]){
            title = v.assignment_label[this.api.language]
          }
        //   if(v.assigned_to[this.api.user_id]){
        //     pro_count = v.assigned_to[this.api.user_id];
        //     pro_count  = pro_count[1] + pro_count[4] + pro_count[6];
        //  }
        pro_count = v.property_count;
        let val = {
          key : v.assignment_id,
          id : v.assignment_id,
          title : title,
          url : 'property',
          count : pro_count,
          year: v?.year?v?.year:'',
          search_param: v.assignment_name +' '+ title
        };
          assignment.push(val);

        });

        this.mainMenu.map(ele=>{
          if(ele.key == 'my_assignments'){
            ele.subMenu = assignment;
          }
        })
      },
      error:(err) => {
      }}
    );
}


  // openProfile(){}

  ngOnInit() {
    if(this.api.user_id != null && this.api.user_id != undefined) this.getAssignment();
    this.mobile = this.api.ismobile;
   }

  isSmall() {
    return this.size === 'small';
  }

  isNormal() {
    return this.size === 'normal';
  }

  setActive(menu) {
    if(this.share.floor_obj){
      this.share.floor_obj= null;
    }
   this.menu.setActive(menu.key);
   if(menu.key == 'city'){
    this.router.navigateByUrl('/country');
   }else{
    this.navigate(menu);
   }
  }

  select(menu){
    this.selected_submenu = menu.key;
    this.selected_submenu_title = menu.title
    this.share.main_side_nav = this;
    localStorage.setItem('selected_user',null);
    this.share.loader.next(true);
    this.navigate(menu);
  }
  navigate(menu){
    if(menu.url){
      if(menu.id){
        if(this.share.dynamic_obj){
          this._crumbs.count = 0;
          if(this.share.main_property_obj){
            this.share.main_property_obj.selected_property = {
              'property_id':null,
              'address':null
            };
          }
          this.share.dynamic_obj.onOptionsRequested('proList');
        }
        this.router.navigate([menu.url,menu.id]);
      }else{
        this.router.navigateByUrl('/home');
      }
   }else{
    this.share.sideNav_obj.openMenu();
   }
  }

  isActive(menu){
    if(this.share?.block0_obj && this.share.block0_obj?.assignment_id == menu.key){
        return true;
    }
    return false;
  }

  getActive(menu) {
    return this.menu.getIsActive(menu.key);
  }

}
