<div class="main-div">
  <div class="top-section">
      <img class="close" src="assets/icon/Cross_purple.svg" alt="close" (click)="closeReset()">
      <img src="assets/icon/reset_password.svg" alt="reset password">
      <p>Reset Password</p>
  </div>
  <ng-container  *ngIf="visible_section == 'email_input'">

  <form [formGroup]="forgotForm" (ngSubmit)="onSubmit(forgotForm.value)">
      <div class="bottom-section">
          <div class="form-group">
              <div class="input-group">
                <img class="email-icon" src="assets/icon/email-icon.svg" alt="">
                  <input  type="email" name="email" class="form-control input_direction reset_input" formControlName="email"
                  placeholder="Enter your email">
              </div>
              <mat-error class="text-left input-group" *ngIf="email.invalid && (email.dirty || email.touched)">
                  <span *ngIf="!email.errors?.required && email.errors?.pattern && email?.errors != null">
                    Please enter valid email</span>
                  <span class="text-center" *ngIf="email.errors?.required && !email.errors?.pattern && email?.errors != null">
                    Email is required</span>
              </mat-error>
          </div>
          <button *ngIf="visible_OTP_section != 'fill_opt'" type="submit" [disabled]="submited || forgotForm.invalid" class="btn save_button">Send OTP
            <div class="button_loader" *ngIf="submited"></div></button>
      </div>
  </form>

  <form [formGroup]="OTPForm" (ngSubmit)="SubmitOTP(OTPForm.value)" *ngIf="visible_OTP_section == 'fill_opt'">
    <div class="bottom-section">
      <div class="otp-input-wrapper">
      <app-otp-verification formControlName="mail_otp" (submitevent) = "SubmitOTP(OTPForm.value)" ></app-otp-verification>
      </div>
      <div class="resend">
        <button (click)="resendOTP()" class="onSubmit" type="button" [disabled]="button_state">
          <span class="animation_fade link"  *ngIf="couterdown == '0:00'">Resend OTP </span>
          <ng-container *ngIf="couterdown != '0:00'">
            <span class="animation_fade" *ngIf="couterdown != '0:00'">Resend OTP in </span>
            <span class="animation_fade">{{ (couterdown != '0:00') ? couterdown : ''}}</span>
          </ng-container>
          <div class="button_loader" *ngIf="button_state && couterdown == '0:00'"></div>
        </button>

      </div>
      <button type="submit" [disabled]="loading || OTPForm.invalid" class="btn save_button">Verify
        <div class="button_loader" *ngIf="loading"></div>
        </button>
    </div>
  </form>

</ng-container>

  <form [formGroup]="newPasswordForm" (ngSubmit)="resetPassword(newPasswordForm.value)"  *ngIf="visible_section == 'new_password'" autocomplete="off">
    <div class="bottom-section">
        <div class="form-group">
            <div class="input-group">
                <span class="icon_pass input_icon_rtl"></span>
                <input type="password" class="form-control input_direction reset_input" formControlName="password"
                placeholder="Password" autocomplete="new-password">
            </div>
            <mat-error *ngIf="newPasswordForm.controls['password'].hasError('minlength'); else hasNumber">
              Must be at least 8 characters, one number, one Letter in Capital Case, one Letter in Small Case, one special Character!
            </mat-error>
            <ng-template #hasNumber>
              <mat-error *ngIf="newPasswordForm.controls['password'].hasError('hasNumber'); else hasCapitalCase">
                Must contain at least one number!
              </mat-error>
            </ng-template>
            <ng-template #hasCapitalCase>
              <mat-error *ngIf="newPasswordForm.controls['password'].hasError('hasCapitalCase'); else hasSmallCase">
                Must contain at least one Letter in Capital Case!
              </mat-error>
            </ng-template>
            <ng-template #hasSmallCase>
              <mat-error *ngIf="newPasswordForm.controls['password'].hasError('hasSmallCase'); else hasSpecialCharacters">
                Must contain at least one Letter in Small Case!
              </mat-error>
            </ng-template>
            <ng-template #hasSpecialCharacters>
              <mat-error *ngIf="newPasswordForm.controls['password'].hasError('hasSpecialCharacters')">
                Must contain at least one Special Character!
              </mat-error>
            </ng-template>
        </div>
        <div class="form-group">
          <div class="input-group">
            <span class="icon_pass input_icon_rtl"></span>
              <input  type="password" class="form-control input_direction reset_input" formControlName="confirmPassword"
              placeholder="Confirm password" autocomplete="new-password">
          </div>
          <mat-error *ngIf="newPasswordForm.controls['confirmPassword'].hasError('NoPassswordMatch') && newPasswordForm.value.confirmPassword.length > 0">
            Password do not match
          </mat-error>
        </div>
        <button type="submit" [disabled]="resetLoader || newPasswordForm.invalid" class="btn save_button">Reset
          <div class="button_loader" *ngIf="resetLoader"></div></button>
    </div>
</form>

</div>
