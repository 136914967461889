<mat-toolbar class="fixed-top">
    <div fxLayout="row" class="w-100" fxFlex fxLayoutAlign="start center">
        <button mat-mini-fab class="est-blue" *ngIf="back" (click)="jumpToUrl(nav_link)">
            <mat-icon id="back-arrow" svgIcon="back-arrow"></mat-icon>
        </button>
        <div claback-arrowss="titleBar ml-1" *ngIf="caption">{{caption}}<strong class="ml-1">{{title}}</strong></div>
        <div class="titleBar" *ngIf="!caption"><span>{{title}} <span *ngIf="totalCount > 0">({{totalCount}})</span><span *ngIf="selected_entity"> - </span> {{selected_entity ? selected_entity.uid : ''}}</span>
           <span *ngIf="unit_uid" class="uid_details" (click)="copyToClipboard(unit_uid)"> <br> {{unit_uid}}  <mat-icon  title="Copy Unit ID" svgIcon="copyfm" class="icon copy-icon-titel"></mat-icon></span>
           <span class="uid_details" *ngIf="floor_uid" (click)="copyToClipboard(floor_uid)"> <br> {{floor_uid}}  <mat-icon  title="Copy Property ID" svgIcon="copyfm" class="icon copy-icon-titel"></mat-icon></span>
            <div *ngIf="refreshView" class="ml-2 refdiv center" (click)="reset()">
                <mat-icon class="ref">refresh</mat-icon>
            </div>
        </div>
    </div>
    <div fxLayout="row" class="id" fxFlex fxLayoutAlign="end center" *ngIf="username || property_uid || floor_name">
        <span class="v-bar"></span>
        <div *ngIf="username" class="fnt-11">{{username}}</div>
        <div class="multiline">
            <ng-container *ngIf="property_uid">
              <span (click)="copyToClipboard(property_uid)">
                Property UID <br>
                {{property_uid}}
                <mat-icon  title="Copy Property ID" svgIcon="copyfm" class="icon copy-icon"></mat-icon></span>
              </ng-container>
            <ng-container *ngIf="floor_name"><br />{{floor_name}}</ng-container>
            <!-- <ng-container *ngIf="unit_uid"><br />{{unit_uid}}

            </ng-container> -->
        </div>
    </div>
</mat-toolbar>
