<div *ngIf="!mobile">
    <div id="border">
        <div id="add-btn">
            <button *ngIf="floor_access.POST && select_import.length" mat-button
                (click)="openDialog('addFloor',{'gis':true,'bulk':true})" class="redbutton">
                <mat-icon id="import">add_box</mat-icon>
            </button>
            <button *ngIf="floor_access.POST" (click)="openDialog('addFloor')" mat-button class="greenbutton">
                Add Floors<mat-icon id="add">add</mat-icon>
            </button>
        </div>
        <div class="search">
            <div class="searchBtn">
                <div class="box">
                    <div>
                        <input type="search" class="search-input" [formControl]="searchField"
                            placeholder="Enter Floor UID">
                    </div>
                    <div id="mag" title="search">
                        <mat-icon class="magnify">search</mat-icon>
                    </div>
                </div>
            </div>
            <ng-container>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon class="applied" svgIcon="filter">
                        <div class="fixed-badge">
                            <i *ngIf="filterObj && filterObj.class"
                                class="material-icons {{filterObj.class}}">fiber_manual_record</i>
                            <img *ngIf="filterObj && filterObj.icon" class="all-select mr-1" [src]="filterObj.icon"
                                alt="All">
                        </div>
                    </mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item *ngFor="let st of statusFilters" class="align-c"
                        (click)="filterFloorList(st)">
                        <i *ngIf="st && st.class" class="material-icons {{st.class}}">fiber_manual_record</i>
                        <img *ngIf="st && st.icon" class="all-select mr-1" [src]="st.icon" alt="All">
                        <span>{{st.name}}</span>
                    </button>
                </mat-menu>
            </ng-container>
        </div>
     <!-- <mat-tab-group>
            <mat-tab> -->
                <div class="block" infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll()">
                <div cdkDropList (cdkDropListDropped)="sortWebFloor($event)">
                    <div cdkDrag *ngFor="let data of floorData">
                        <div class="cdkDrop-area" *cdkDragPlaceholder></div>
                        <div  class="animated fadeIn delay-2s big-button"
                            [ngClass]="{'active': isActive(data.id),'floor-style':data.type == 'Ground'}">
                            <div id="part1" (click)="openOptions(data, false)"
                                [ngClass]="{'reworkpro': data.status == 4, 'reworkstatus' : data.status == 6}">
                                <div>
                                    <span id="serial">{{data.sort_order}}</span>
                                </div>
                            </div>
                            <div id="part2">
                                <div id="part2_1_1">
                                    <div (click)="openOptions(data, false)">
                                        <span matTooltip="Edit Floor Type" [class.floor-type]="data.type!='Ground'" [class.fx]="data.type=='Ground'"
                                            (click)="selectFloorType($event,data)"
                                            [matMenuTriggerFor]="floorMenu">
                                            <span class="material-icons" style="font-size:18px !important;">
                                                edit
                                                </span>{{data.name}}
                                        </span> - <span class="fx">{{data.uid}}</span> <span *ngIf="!data.id"
                                            class="fx">
                                            - GIS</span>
                                    </div>
                                </div>
                                <div id="part2_1_2" (click)="openOptions(data, false)">
                                    <div id="part2-2">
                                        <div id="boxes">
                                            <div id="unit">Units-{{data.units}}</div>
                                            <div id="parking">Parking-{{data.parking}}</div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div id="del1">
                                        <mat-icon id="delete" svgIcon="deletef"></mat-icon>
                                    </div>
                                </div>
                            </div>
                            <mat-menu #floorMenu="matMenu" class="mat-menu-user">
                                <ng-container *ngIf="data.type!='Ground'">
                                    <ng-template matMenuContent>
                                        <ng-container *ngIf="floor_type_list && floor_type_list.length">
                                            <ng-container *ngFor="let type of floor_type_list">
                                                <a *ngIf="type.name != 'Ground' && type.name!= 'Dummy' && !data.name.includes(type.label)" mat-menu-item
                                                class="floor-options d-flex align-items-center"
                                                (click)="changeFloorType(data,type)">
                                                   {{type.label}}
                                            </a>
                                            </ng-container>

                                        </ng-container>
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngIf="data.type.toLowerCase() == 'ground'">
                                    <ng-template matMenuContent class="h-100">
                                        <div class="h-100 d-flex align-items-center">
                                            <span class="text-danger" style="padding: 5%;"> Floor Change not Allowed on Ground</span>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </mat-menu>
                        </div>
                    </div>
                  </div>
                    <div *ngIf="loader || loading" class='loader-div'>
                        <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                        </ngx-loading>
                        <ngx-loading [show]="loading" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                        </ngx-loading>
                    </div>
                </div>
            <!-- </mat-tab>
        </mat-tab-group> -->

    </div>
</div>
    <!-- <div [sortablejs]="floorData" [sortablejsOptions]="flooroptions" class="button-block"> -->
                    <!-- <ngx-sortable [items]="floorData" (listSorted)="sortWebFloor($event)" listStyle='{
                        width:100%, //width of the list defaults to 300,
                        height: 100%, //height of the list defaults to 250
                        }' showHeader={{showListHeader}}>

                    </ngx-sortable> -->
                    <!-- </div> -->
<div *ngIf="mobile">
    <app-mobile-toolbar [back]="true" [title]="'Floors'" [totalCount]="noOfFloor" [nav_link]="'backClicked'"
        [property_uid]="selected_property.property_id" [customClickAction]="true" [customObj]="this">
    </app-mobile-toolbar>

    <!-- <mat-toolbar class="toptoolbar new">
        <div fxLayout="row" class="w-100" fxFlex fxLayoutAlign="start center">
            <div class="backBtn" (click)="backClicked()">
                <mat-icon id="back-arrow" svgIcon="back-arrow"></mat-icon>
            </div>
            <div class="titleBar">Floors</div>
        </div>

        <div fxLayout="row" class="id" fxFlex fxLayoutAlign="end center">
            <span class="v-bar"></span>
            <div>{{selected_property.property_id}}</div>
        </div>
    </mat-toolbar> -->

    <div id="add-btn-m">
        <div class="left-btn" *ngIf="floor_access.PATCH">
            <button mat-button (click)="sortOrder('up')" [disabled]="!selected_floor_id" class="arrow-btn">
                <mat-icon id="arrow" class="icon-up" svgIcon="upf"></mat-icon>
            </button>
            <button mat-button (click)="sortOrder('down')" [disabled]="!selected_floor_id" class="arrow-btn">
                <mat-icon id="arrow" class="icon-down" svgIcon="downf"></mat-icon>
            </button>
        </div>
        <button *ngIf="floor_access.POST && select_import.length" mat-button
            (click)="openDialog('addFloor',{'gis':true,'bulk':true})" class="redbutton">
            <mat-icon id="import">add_box</mat-icon>
        </button>
        <button *ngIf="floor_access.POST" (click)="openDialog('addFloor')" mat-button class="greenbutton">
            <div>Add Floors
                <mat-icon id="add">add</mat-icon>
            </div>
        </button>
    </div>
    <div class="search">
        <div class="searchBtn">
            <div class="box">
                <div>
                    <input type="search" class="search-input" [formControl]="searchField"
                        placeholder="Enter Floor UID">
                </div>
                <div id="mag" title="search">
                    <mat-icon class="magnify">search</mat-icon>
                </div>
            </div>
        </div>
        <ng-container>
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon class="applied" svgIcon="filter">
                    <div class="fixed-badge">
                        <i *ngIf="filterObj && filterObj.class"
                            class="material-icons {{filterObj.class}}">fiber_manual_record</i>
                        <img *ngIf="filterObj && filterObj.icon" class="all-select mr-1" [src]="filterObj.icon"
                            alt="All">
                    </div>
                </mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let st of statusFilters" class="align-c" (click)="filterFloorList(st)">
                    <i *ngIf="st && st.class" class="material-icons {{st.class}}">fiber_manual_record</i>
                    <img *ngIf="st && st.icon" class="all-select mr-1" [src]="st.icon" alt="All">
                    <span>{{st.name}}</span>
                </button>
            </mat-menu>
        </ng-container>
    </div>
    <!-- <mat-tab-group>
        <mat-tab label="Estater Floors [{{noOfFloor}}]"> -->
            <div class="button-block-m" >
                <div class="mobile-block" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                    [scrollWindow]="false" (scrolled)="onScroll()">

                    <!-- <div > -->

                    <div *ngFor="let data of floorData" class="big-button-m animated fadeIn delay-2s"
                        id="floor_{{data.id}}"
                        [ngClass]="{'active':isActive(data.id),'floor-style':data.type == 'Ground'}">
                        <div id="part1" [ngClass]="{'reworkpro': data.status == 4, 'reworkstatus' : data.status == 6}">
                            <div>
                                <span id="serial">{{data.sort_order}}</span>
                            </div>
                            <div id="icon-move">
                                <mat-checkbox [ngClass]="{'cc-w': data.status == 4}" (change)="onCheckStatus($event)"
                                    [checked]="selected_floor_id == data.id" value="{{data.id}}" class="checkB">
                                </mat-checkbox>
                            </div>
                        </div>

                        <div id="part2">
                            <div id="part2_1_1" (click)="openOptions(data, false)">
                                <div>
                                    <span matTooltip="Edit Floor Type" [class.floor-type]="data.type!='Ground'" [class.fx]="data.type=='Ground'"
                                        (click)="selectFloorType($event,data)"
                                        [matMenuTriggerFor]="floorMenu"><span class="material-icons" style="font-size:18px !important;">
                                            edit
                                            </span>{{data.name}}
                                    </span> - <span class="fx">{{data.uid}}</span> <span *ngIf="!data.id" class="fx">
                                        - GIS</span>
                                </div>

                                <!-- <div id="part2-1">
                                    <div></div>
                                </div> -->
                            </div>
                            <div id="part2_1_2">
                                <div id="part2-2" (click)="openOptions(data, true)">
                                    <div id="boxes">
                                        <div id="unit">Units-{{data.units}}</div>
                                        <div id="parking">Parking-{{data.parking}}</div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                                <div id="del1">
                                    <mat-icon id="delete" svgIcon="deletef"></mat-icon>
                                </div>
                            </div>
                        </div>
                        <mat-menu #floorMenu="matMenu" class="mat-menu-user">
                            <ng-container *ngIf="data.type!='Ground'">
                                <ng-template matMenuContent>
                                    <!-- <ng-container *ngIf="floor_type_list && floor_type_list.length">
                                        <a *ngFor="let type of floor_type_list" mat-menu-item
                                            class="floor-options d-flex align-items-center"
                                            (click)="changeFloorType(data,type)">
                                            <ng-container *ngIf="type.name != 'Ground' || type.name !='Dummy'">
                                             {{type.name}}
                                            </ng-container>

                                        </a>
                                    </ng-container> -->
                                    <ng-container *ngFor="let type of floor_type_list">
                                      <a *ngIf="type.name != 'Ground' && type.name!= 'Dummy' && !data.name.includes(type.label)" mat-menu-item
                                      class="floor-options d-flex align-items-center"
                                      (click)="changeFloorType(data,type)">
                                      {{type.label}}
                                  </a>
                                  </ng-container>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngIf="data.type.toLowerCase() == 'ground'">
                                <ng-template matMenuContent class="h-100">
                                    <div class="h-100 d-flex align-items-center">
                                        <span class="text-danger" style="padding: 5%;"> Floor Change not Allowed on
                                            Ground</span>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </mat-menu>
                    </div>
                    <!-- <div class="remove" id="remove_{{data.id}}">
                    <div id="options">
                        <div id="sm-box">
                            <mat-icon id="delete" svgIcon="delete"></mat-icon>
                        </div>
                        <div (click)="openDialog('addFloor')" id="sm-box">
                            <mat-icon id="delete" svgIcon="edit"></mat-icon>
                        </div>
                    </div>
                  </div> -->
                    <!-- </div> -->
                    <div *ngIf="loader || loading" class='loader-div'>
                        <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                        </ngx-loading>
                        <ngx-loading [show]="loading" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                        </ngx-loading>
                    </div>
                </div>
            </div>
        <!-- </mat-tab>
    </mat-tab-group> -->
</div>
