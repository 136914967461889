
    <div class="app">
        <mat-sidenav-container class="sidenav-container" autosize>
            <mat-sidenav #sidenav class="sidenav" *ngIf="!showside" (closedStart)="sidenavClose()" mode="over">
                <app-main-sidenav size="normal" [mode]="'normal'"></app-main-sidenav>
            </mat-sidenav>
            <mat-sidenav #sidenavsmall *ngIf="showside && !mobileview" class="sidenavsmall" mode="side">
                <app-main-sidenav size="small" [mode]="'small'"></app-main-sidenav>
            </mat-sidenav>
            <mat-sidenav-content>
                <!-- Web Toptoolbar========================================================================================================== -->
                <div fxFlex *ngIf="!mobileview">
                    <mat-toolbar *ngIf="!mobileview && !countryView" class="toptoolbar">

                        <div fxLayout="row" class="w-100" fxFlex fxLayoutAlign="start center">
                            <button mat-icon-button (click)="openMenu()">
                                <mat-icon svgIcon="menu" class="hvr-grow ctrans"></mat-icon>
                            </button>
                            <app-main-header></app-main-header>
                        </div>

                        <div fxLayout="row" class="toptoolbar" fxFlex fxLayoutAlign="end center">
                          <button mat-button [routerLink]="['/country']">
                              <mat-icon class="cit">location_city</mat-icon>
                            {{getCity()}}
                          </button>
                            <!-- <button mat-icon-button class="notifybutton">
                                <mat-icon aria-label="Toggle menu" svgIcon="bell-icon" class="bell ctrans"></mat-icon>
                                <b class="badge bg-back up">3</b>
                            </button> -->

                            <span class="v-bar"></span>

                            <button mat-button class="user-button" [matMenuTriggerFor]="UserMenu">
                                <div fxLayout="row" class="w-100" fxLayoutAlign="space-around center">
                                    <img *ngIf="picture_URL && picture_URL != 'null'" alt="user-image" class="rounded mat-elevation-z6 img-profile" src="{{picture_URL}}"/>
                                    <span *ngIf="!picture_URL || picture_URL == 'null'" class="avatar-t cp">{{getInitials(username)}}</span>
                                    <span class="usr" fxHide="true" fxShow.gt-sm="true" class="user_name">{{username}}</span>
                                    <mat-icon fxHide.xs="true" aria-label="Toggle menu" class="down-a">keyboard_arrow_down</mat-icon>
                                </div>
                            </button>

                            <mat-menu #UserMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="above" class="mat-menu-user">
                                <ng-template matMenuContent>
                                    <a (click)="openDialog('main-dialog')" class="noDecor inl" mat-menu-item>
                                        <mat-icon>settings</mat-icon>Profile
                                    </a>
                                    <div class="divider"></div>
                                    <a class="noDecor inl" mat-menu-item (click)="logout(false)">
                                        <mat-icon>power_settings_new</mat-icon>
                                        Log out
                                    </a>
                                    <div class="divider"></div>
                                    <a class="noDecor inl" mat-menu-item (click)="logout(true)">
                                        <mat-icon>power_settings_new</mat-icon>
                                        Log out from all devices
                                    </a>
                                </ng-template>
                            </mat-menu>
                        </div>
                    </mat-toolbar>
                    <mat-toolbar *ngIf="countryView" class="cameo">

                    </mat-toolbar>
                </div>
                <!-- End of Web Toptoolbar================================================================================================== -->

                <div *ngIf="!mobileview" class="middle-content-web" #viewHeightDiv>
                    <router-outlet></router-outlet>
                </div>

                <div *ngIf="mobileview" class="middle-content" #viewHeightDiv (window:resize)="onResize($event)">
                    <div class="bgcolor">
                        <router-outlet></router-outlet>
                    </div>
                    <mat-toolbar *ngIf="showBar" class="b-bar">
                        <button mat-icon-button (click)="openMenu()">
                            <mat-icon class="add" svgIcon="menu"></mat-icon>
                        </button>
                        <app-mobile-footer fxLayout="row" class="w-100" fxLayoutAlign="space-around start"></app-mobile-footer>
                    </mat-toolbar>
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
