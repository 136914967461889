<div *ngIf="!mobile">
  <div class="block" id="DynamicBlock">
    <ng-container *ngFor="let value of sub_group">
      <div *ngIf="value.status" (click)="openOptions(value,false)" class="big-button" [ngClass]="{'active':isActive(value.id)}">
          <div>
              {{value.group_name}}
          </div>
          <div class="led">
            <mat-icon class="indicator" [ngClass]="{'filled':isFilled(value.id)}" svgIcon="check"></mat-icon>
          </div>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="mobile">
    <app-mobile-toolbar [back]="true" [nav_link]="'/unitGroup'" [title]="'Unit | '+selected_group.group_name" [property_uid]="selected_property.property_id"
    [floor_name]="selected_floor.name" [unit_uid]="selected_unit.uid"></app-mobile-toolbar>

  <div class="block-m" id="pList">
      <div class="mobile-block">
            <ng-container *ngFor="let value of sub_group">
          <div *ngIf="value.status" (click)="openOptions(value,true)" class="big-button" [ngClass]="{'active':isActive(value.id)}">
              <div>
                  {{value.group_name}}
              </div>
              <div class="led">
                <mat-icon class="indicator" [ngClass]="{'filled':isFilled(value.id)}" svgIcon="check"></mat-icon>
              </div>
          </div>
        </ng-container>
      </div>
  </div>
</div>