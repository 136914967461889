import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiserviceService } from '../services/apiservice.service';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class InterceptorService {

  constructor(private router:Router,  private notify: NotificationService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if ([403].includes(event?.body?.status) && localStorage.getItem('user_token')) {
                    localStorage.clear();
                    this.router.navigateByUrl('login')
                }
            }
            return event;
        }),
        catchError((error: HttpErrorResponse) => {
            if([403].includes(error.status) && localStorage.getItem('user_token')){
                localStorage.clear();
                this.router.navigateByUrl('login')
            }else if([401].includes(error.status)){
              this.notify.notify(error.error.message, "error");
            }else if([404].includes(error.status)){
              this.notify.notify(error.error.message, "error");
            }
            return throwError(() => error)
        })
    );
}
}
