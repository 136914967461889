import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiserviceService } from '../services/apiservice.service';


export class LayerDataSource {
  format: string;
  layerName: string;
  cityCode: string;
  featureId: string;
  filter: object;
  http: HttpClient;
  gisUrl: string; // = 'https://estapi.dev10.in/gis/api/'
  apiKey: string;
  languageId: number = 1;
  titleField: string;
  descField: string;
  baseHeightField: string;
  topHeightField: string;
  iconUrlField: string;
  idField: string;
  latField: string = 'lat';
  lonField: string = 'lon';
  sortByField: string;
  api: ApiserviceService;

  constructor(options) {
    this.api = options.api;
    // this.gisUrl = this.api.gisUrl;
    this.format = options.format;
    this.layerName = options.layerName;
    this.cityCode = options.cityCode;
    this.apiKey = options.apiKey;
    this.http = options.http;
    this.filter = options.filter;
    this.featureId = options.featureId;
    this.titleField = options.titleField;
    this.descField = options.descField;
    this.topHeightField = options.topHeightField;
    this.baseHeightField = options.baseHeightField;
    this.iconUrlField = options.iconUrlField;
    this.sortByField = options.sortByField;
    this.idField = options.idField;
  }

  destroy() {

  }

  get(onSuccess, onError) {
    this.getByFilter(this.filter, onSuccess, onError);
  }

  getByFilter(filter, onSuccess, onError) {
    this.api.getEpsData(`/deadmin/${filter.assignment_name}/properties/${filter.property_id}/floors/${filter.floor_id}/units-geojson?get_latest_property=Y`)
    .subscribe(
      {
        next : (data:any)=>{      
          onSuccess(data.data)
        },
        error: ()=> {},
        complete() {
            
        },
      })
      
// return;
//     let url = this.gisUrl + this.layerName + '/';
//     if (typeof this.featureId != 'undefined') url += this.featureId + '/';
//     url += this.format;
//     if (typeof filter == 'undefined') filter = {};
//     if (typeof filter.limit == 'undefined') filter.limit = 10000;
//     url += '?limit=' + filter.limit;
//     if (typeof this.sortByField != 'undefined') url += '&sort_by=' + this.sortByField;
//     Object.keys(filter).forEach(key => {
//       if (key != 'limit' && typeof filter[key] != 'undefined') {
//         url += '&' + key + '=' + filter[key];
//       }
//     });
//     if (typeof this.cityCode == 'undefined') {
//       if (onError) onError('No cityCode defined in source');
//       return;
//     }
//     if (typeof this.apiKey == 'undefined') {
//       if (onError) onError('No apiKey defined in source');
//       return;
//     }
//     let headers = new HttpHeaders({
//       'Content-Type': 'application/json',
//       'USER-CITY': this.cityCode,
//       'API-KEY': this.apiKey,
//       'USER-LANGUAGE': '' + this.languageId + '',
//       'USER-TOKEN': this.api.user_token,
//     });
//     this.http.get(url, { 'headers': headers }).subscribe(
//       (data:any) =>{ 
//         //console.log(data);
        
//         onSuccess(data)
//       },
//       error => onError(error)
//     );
  }
}