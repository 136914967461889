<!-- <mat-card appearance="outlined" *ngIf="load">
  <div><mat-card-title class="edit-btn-wrapper"><button (click)="openProfile()" class="edit-btn">Edit <img src="../../../assets/icon/Edit.svg" alt=""></button></mat-card-title></div>
  <figure class="avatar">
    <img src="{{user_detail.imageURL}}" alt="Profile-picture">
  </figure>
  <div class="p-0 mt-2"><h3>{{user_detail.fname}} {{user_detail.lname}}</h3></div>
  <div class="p-0"><p>{{user_detail.contact}}</p></div>
  <div>
    <p class="language">{{user_detail.language}}</p>
    <p class="country m-0">{{user_detail.country}}</p>
  </div>
</mat-card> -->

<mat-card>
  <div class="user-details-wrapper">
    <div class="avatar-container">
      <img src="{{user_detail.imageURL}}" alt="Profile-picture">
    </div>
    <div class="user-detail">
      <h3>{{user_detail.fname}} {{user_detail.lname}}</h3>
       <p>{{user_detail.contact}}</p>
       <p><span>{{user_detail.language}}</span> <span *ngIf="user_detail.country != null">/{{user_detail.country}}</span></p>
    </div>
    <button class="edit-btn" (click)="openProfile()">
      <img src="../../../assets/icon/edit-org.svg" alt=""> Edit
    </button>
  </div>
</mat-card>
