<div *ngIf="show_property">
    <div class="dialog1" *ngIf="!invalidArea">
        <div class="toolbar_div"><mat-toolbar class="tool">Add Own Address</mat-toolbar></div>
        <form [formGroup] = "addressForm" (ngSubmit)="saveDialog(addressForm)">
        <div class="form">
            <div class="boxDiv">
                <div>
                    <span>Building Name</span>
                </div>
                <div>
                    <input class="form-input" formControlName="building_name" type="text">
                </div>
            </div>
            <div class="boxDiv">
                <div>
                    <span>Parcel No</span>
                </div>
                <div>
                    <input class="form-input" formControlName="parcel_no" type="text">
                </div>
            </div>
            
            <div class="boxDiv">
                <div>
                    <span>House No</span>
                </div>
                <div>
                    <input class="form-input" formControlName="house_no" type="text">
                </div>
            </div>
            <div class="boxDiv">
                <div>
                    <span>Lane Name</span>
                </div>
                <div>
                    <input class="form-input" formControlName="lane_name" [matAutocomplete]="auto2" type="text">
                    <mat-autocomplete #auto2="matAutocomplete">
                        <mat-option class="laneOp" *ngFor="let data of lanedata | async" [value]="data.name">
                            <div class="lanes">
                                <span>{{ data.name }}</span>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="boxDiv">
                <div>
                    <span>Street Name</span>
                </div>
                <div>
                    <input class="form-input" formControlName="street_name" [matAutocomplete]="auto1" 
                     type="text">
                    <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option class="laneOp" *ngFor="let data of streetdata | async" [value]="data.name">
                            <div class="lanes">
                                <span>{{ data.name }}</span>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="boxDiv">
                <div>
                    <span>Block</span>
                </div>
                <div>
                    <input class="form-input" formControlName="block" type="text">
                </div>
            </div>
            <div class="boxDiv">
                <div>
                    <span>Neighbourhood</span>
                </div>
                <div>
                    <input class="form-input" formControlName="neighbourhood" type="text">
                </div>
            </div>
        </div>
        <div class="action">
            <div>
                <button [disabled]="addressForm.invalid" class="act" mat-button>Save</button>
            </div>
            <div>
                <button (click)="closeDialog()" type="button" class="act" mat-button>Cancel</button>
            </div>
        </div>
        </form>
    </div>
    <div *ngIf="invalidArea">
        <div class="toolbar_div"><mat-toolbar class="tool">Invalid Area</mat-toolbar></div>
        <div class="alert-div">
            <span class="fnt-alert">This location is unsupported. Please choose a different location.</span>
        </div>
    </div>
</div>
<div *ngIf="show_address">
    <div class="dialog1">
        <div><mat-toolbar class="tool">Available address</mat-toolbar></div>
        <br>
        <div class="form1">
            <div (click)="selectAddr(data)" class="boxDiv1" *ngFor="let data of addresses">
                <div class="bx1">
                    <span>Address</span>
                </div>
                <div class="border-left">
                    <span class="addr">{{data.address}}</span>
                </div>
            </div>
        </div>
        <!--<div class="cent">
            <button (click)="show_property = true" (click)="addOwn(data)" mat-button class="bluebutton">Add own address</button>
        </div>-->
    </div>
</div>