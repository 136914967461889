<div fxLayout="row" fxShow.gt-sm="true" fxHide="true" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <ng-container>
        <div *ngIf="getuserName()">
            <span class="selection" *ngIf="getuserName()" title="Showing properies for {{getuserName()}}">{{getuserName()}}</span>
        </div>
        <div class="master-title" id="master-title">
          <span *ngIf="getassignName()"> {{getassignName()}}</span>
          <span *ngIf="getCount()"> No of {{getTitle()}} : {{getCount()}} </span>
          <span *ngIf="getFlooreName()">{{getFlooreName()}}</span>
          <span *ngIf="selectedFloorId && !isPropertyLevel"> {{selectedFloorId}} <mat-icon title="Copy Floor ID"  (click)="copyToClipboard(selectedFloorId)" svgIcon="copyfm" class="icon copy-icon"></mat-icon></span>
        </div>
      </ng-container>
    </div>
</div>
