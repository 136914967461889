import { Component, OnInit, OnDestroy, Output, EventEmitter, ElementRef, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ApiserviceService } from '../../../services/apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { ShareService } from '../../../services/share.service';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';

 /**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_get</li>
 * <li>umsapi_user_user_id_get</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-block1',
  templateUrl: './block1.component.html',
  styleUrls: ['./block1.component.css']
})
export class Block1Component implements OnInit {
  dHeight: number;
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  mobile : boolean = false;
  hasRejection:boolean = false;
  selected_group_id : number;
  selected_group:any;
  selected_property:any;
  group_custom_list:any;
  selected_user_id: any;
  assignment_detail:any;
  reworkAttributes: any = [];
  user_detail:any = {
    user_name:'',
    user_email:'',
    assignment_name:''
  };
  constructor(private elementRef: ElementRef, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private share:ShareService, private route:Router,private api:ApiserviceService,private info:NotificationService,
    private fnc: CommonfunctionService, public dialog: MatDialog) {
    this.assignment_detail = localStorage.getItem('assignment_detail');
    this.api.form_language = null; //reset form language to default
    iconRegistry.addSvgIcon("pin", sanitizer.bypassSecurityTrustResourceUrl("assets/icon/Map.svg"))
    .addSvgIcon('check',sanitizer.bypassSecurityTrustResourceUrl('assets/icon/GreenCheck.svg'));
    this.mobile = share.mobile;
    share.block1_obj = this;
    if(!this.share.block0_obj){
      route.navigateByUrl('/');
      return;
    }
    this.share.block0_obj.getAllowResource();
    this.selected_property = this.share.block0_obj.selected_property;
    if(this.share.block0_obj.entity_type != 'property'){ // In case of back button.
      this.share.block0_obj.entity_type = 'property';
      this.share.block0_obj.getAttributes();
    }
    this.getUserDetail(this.selected_property.property_id);
    if (localStorage.getItem('selected_user') != 'null') {
      this.selected_user_id = +localStorage.getItem('selected_user');
    }
   }

   openDialog() {
    let property_resource = this.share.block0_obj.property_access;

    if(!property_resource.PATCH){
      this.info.notify('You are not authorized to submit property', 'warn');
      return;
    }
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: { parent: this }//optional
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

   openOptions(param:any,group,mobile:boolean=false):void{
      this.selected_group_id = group.id;
      this.selected_group = group;
      this.share.block2_obj = null;
      if(mobile == false){
        if(this.share.dynamic_obj.form){
          if(!this.share.block3_obj.compLoader){
            this.share.block3_obj.submitForm();
          }else{
            this.info.notify("Please wait",'info');
          }
          
        }
      }
      if(this.selected_group_id == 0){
       if(mobile) this.route.navigateByUrl('floor');
       this.notify.emit('floor');
       return;
      }
      let child = this.fnc.getArrayValue('id',this.selected_group_id,this.group_custom_list);
      child = child.childrens;
      if(child.length > 0){
        this.notify.emit(param);
        if(mobile){
          this.route.navigateByUrl('subGroups');
        }
      }else{
        this.share.block2_obj = null;
        this.share.unit_obj = null;
        this.share.floor_obj = null;
        this.notify.emit('groups'); // to hide sub group
        this.notify.emit('form');
        if(mobile){
          this.route.navigateByUrl('form');
        }
      }
    }

  isActive(v){
    if(v == this.selected_group_id) return true;
  }

  isFilled(group){
    let id = group.id;
    let status = false,child;
    let record = {};
    if(this.share.block0_obj.property_detail){
      record = this.share.block0_obj.property_detail.data;
    }
    let attributes = this.fnc.getChildArray('group_id',id,this.share.block0_obj.attribute_custom_list);
    child = group.childrens;
    if(child.length){
      let sub_attrib=[];
      child.forEach((v)=>{
        sub_attrib = this.fnc.getChildArray('group_id',v.id,this.share.block0_obj.attribute_custom_list);
        if(sub_attrib.length && record){
          sub_attrib.forEach((v)=>{
            if(record[v.id]){
              status = true;
            }
          })
        }
      });
    }
    if(attributes && record){
      attributes.forEach((v)=>{
        if(record[v.id]){
          status = true;
        }
      })
    }
    return status;
  }

  isRerorkGroup(group){
    let id = group.id;
    let status = false, child;
    let reworkrecord = [];
    if(this.share.block0_obj.property_detail){
      reworkrecord = this.share.block0_obj.rejectedAttributeList;
    }

    let attributes = this.fnc.getChildArray('group_id',id,this.share.block0_obj.attribute_custom_list);
   child = group.childrens;
  
   
    if(child.length){
      let sub_attrib=[];
      child.forEach((v) => {
        sub_attrib = this.fnc.getChildArray('group_id', v.id, this.share.block0_obj.attribute_custom_list);
        if (sub_attrib.length && reworkrecord.length) {

          for (let k = 0; k < reworkrecord.length; k++) {
            for (let j = 0; j < sub_attrib.length; j++) {
              if (reworkrecord[k]) {
                if (reworkrecord[k].keyId == sub_attrib[j].id) {
                  status = true;
                  break;
                }
              }
            }
          }
        }
      });
    }
      for (let k = 0; k < reworkrecord.length; k++) {
        for (let j = 0; j < attributes.length; j++) {
          if (reworkrecord[k]) {
            if (reworkrecord[k].keyId == attributes[j].id) {
              status = true;
              break;
            }
          }
        }
      }
     return status;
  }
  checkRejected(){
    this.assignment_detail = JSON.parse(this.assignment_detail);
    let url =  "deadmin/"+this.assignment_detail.assignment_name+"/properties/"+this.share.block0_obj.selected_property.id+'/floors?status=4,6';
    url += `&get_latest_property=Y`;
    this.api.getEpsData(url).subscribe(
     {next: (data:any) =>{     
      if(data && data.status == undefined && data.status != 404){        
        this.hasRejection = true;
      }else{
        this.hasRejection  = false;
      }
    },error:() => {
      this.hasRejection = false
    }})
  }

  ngOnInit() {
    this.group_custom_list=[];
    setTimeout(()=>{
      this.dHeight = this.share.heightService;
      let e = $(this.elementRef.nativeElement);
      e.find('#pList').css('height',this.dHeight);
      e.find('#DynamicBlock').css('height',(this.dHeight));
    }, 10);
    this.group_custom_list = JSON.stringify(this.share.block0_obj.group_custom_list);
    this.group_custom_list = JSON.parse(this.group_custom_list);
    this.checkRejected();
    if(!this.fnc.getChildArray('id',0,this.group_custom_list).length && this.share.block0_obj.floor_access.GET){
      let floor = {
        id:0,
        group_name:"Floor",
        childrens:[],
        parent_id:0,
        status:true
      }
      if(this.group_custom_list.length){
        setTimeout(()=>{
          this.group_custom_list.splice(2,0,floor);
        },500);
      }else{
        this.group_custom_list.splice(2,0,floor);
      }
    }
    if(this.share.block2_obj){
      this.selected_group_id = this.share.block2_obj.selected_group.id;
    }
    if(this.share.block3_obj){
      this.selected_group_id = this.share.block3_obj.selected_group.id;
    }
  }
  getUserDetail(pid: any){
    let proplist = this.share.block0_obj.property_list;
    let temuser = this.fnc.getArrayValue('property_uid', pid, proplist);
    let body = `user?user_id=${this.api.user_id}`    
    if(temuser){
      this.api.getUmsData(body)
      .subscribe((data:any) => {
        if(data.data){
          let userdata = data.data;
          let first_name = (userdata.user_details[this.api.language].first_name)? userdata.user_details[this.api.language].first_name: userdata.user_details['1'].first_name;
          let  last_name = (userdata.user_details[this.api.language].last_name)?userdata.user_details[this.api.language].last_name:userdata.user_details['1'].last_name;
        this.user_detail = {
          user_email : userdata.primary_email_id,
          user_name : first_name +' '+ last_name,
          assignment_name : this.share.block0_obj.assignment_name
        }
      }
      });
    }
  }
  setMapMarker(){
    if(!this.share.block0_obj.property_access.PATCH){
      this.info.notify("You are not authorized to update property",'warn');
      //return;
    }
    this.share.block0_obj.enablePatch = true;
    this.route.navigateByUrl('map');
  }
  ngOnDestroy(){
    //this.sub.unsubscribe();
  }

}

 /**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_assignment_name_properties_property_id_patch</li>
 * </ol>
 *
 */
@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.html',
  styleUrls: ['./block1.component.css']
})

export class ConfirmDialog{
  assignment_detail:any;
  selected_property_id:any;
  constructor(public dialogRef: MatDialogRef<ConfirmDialog>, private route: Router, private share: ShareService,
        @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiserviceService, private notify: NotificationService, private fnc: CommonfunctionService) {
          this.assignment_detail = JSON.parse(localStorage.getItem('assignment_detail'));
          this.selected_property_id = this.share.block0_obj.selected_property.property_id;
        }

  ngOninit(){}

  submit(){
    let property = this.data.parent.selected_property;
    // if(property.attribute_info != null){
    //   for(let key in property.attribute_info){
    //    if (property.attribute_info[key].status == -1){
    //     property.attribute_info[key].status = 3; // attribute info with status 3 indicate reworked attribute
    //    }
    //   }
    // }
    let url = "deadmin/"+this.assignment_detail.assignment_name+"/properties/" + property.id;
    let status = 2;
    if(property.status == 6 || property.status == 4) status = 7;
    let body = {
      "status": status,
      "updated_by": this.api.user_id
      // "attribute_info": {
      //   "all": {
      //     "comment": "",
      //     "status": "1"
      //     },
      //  "all_floors": {
      //     "comment": "",
      //     "status": "1"
      //     },
      //   "all_units": {
      //     "comment": "",
      //     "status": "1"
      //     }
      // }
    }
    this.api.patchEpsData(url, body).subscribe((data:any) =>{
      this.notify.notify('Information Updated','success');
      this.dialogRef.close();
      this.route.navigateByUrl('property/'+this.data.parent.selected_property.assignment_id);
    }, err=> {
      this.notify.notify(this.fnc.getErrorMessage(err.error),'error');
    })
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
