import { Component } from '@angular/core';
import { LayerDataSource } from './layerdatasource';
import { IndoorMapDataSource } from './indoormapdatasource';
import { Style } from './style';
import { Map } from './map';
import { Layer } from './layer';
import { ApiserviceService } from '../services/apiservice.service';
import { ShareService } from '../services/share.service';

export class IndoorMap extends Map {
  styleMap: any;
  onError: any;
  onSuccess: any;
  source: IndoorMapDataSource;
  floorSwitcherElement: any;
  // floorLayer: Layer;
  unitLayer: Layer;
  floorList: any;
  constructor(options, private api_service: ApiserviceService, private share:ShareService) {
    super(options);
    this.styleMap = options.styleMap;
    this.source = options.source;
    this.onSuccess = options.onSuccess;
    this.onError = options.onError;
    this.floorSwitcherElement = options.floorSwitcherElement;
    // console.log('Showing received options', options);
    if (typeof this.source == 'undefined') {
      if (this.onError) this.onError('No source defined');
      return;
    }
    else if (typeof this.source.buildingId != 'undefined') {
      // this.getFloorList((floorList) => {
      //   this.floorList = floorList;
      //   console.log('floor list >> ', this.floorList);
        
      //   if (typeof this.source.floorId == 'undefined') {
      //     this.source.floorId = this.getLowestPositiveFloorId();
      //   }
      //   this.displayFloorSwitcher();
      //   this.addIndoorMapLayersForFloor();
      // });
      this.addIndoorMapLayersForFloor();
    }
    else {
      throw 'Either provide source.buildingId or provide source.buildingUid';
    }
  };

  destroy() {
    // this.floorLayer.destroy();
    // this.floorLayer = undefined;
    this.unitLayer.destroy();
    this.unitLayer = undefined;
    this.source = undefined;
    this.floorSwitcherElement = undefined;
    this.floorList = [];
    this.onSuccess = undefined;
    this.onError = undefined;
    this.styleMap = undefined;
    super.destroy();
  };

  getBuildingInfo(onSuccess) {
    var buildingsDataSource = new LayerDataSource({
      'format': 'json',
      'layerName': 'buildings',
      'cityCode': this.source.cityCode,
      'filter': {
        'building_uid': this.source.buildingId
      },
      'apiKey': this.source.apiKey,
      'http': this.source.http,
      'api': this.api_service
    })
    buildingsDataSource.get(
      (data) => {
        if (data == null || data.length == 0) {
          if (this.onError) this.onError('Invalid buildingUid provided in source');
          return;
        }
        onSuccess(data[0].building_id);
      },
      (error) => {
        if (this.onError) this.onError(error);
      }
    );
  };

  getFloorList(onSuccess) {
    var floorDataSource = new LayerDataSource({
      'format': 'json',
      'layerName': 'floors',
      'cityCode': this.source.cityCode,
      'filter': {
        'building_uid': this.source.buildingId,
        'assignment_name' : this.source.assignment_name,
        'property_id' : this.source.property_id,
        'floor_id' : this.source.floor_id
      },
      'apiKey': this.source.apiKey,
      'http': this.source.http,
      'api': this.api_service,
    });
    floorDataSource.get(
      (data) => {
        let floorList = [];
        if(data){
          data.forEach((floor) => {
            floor['sub_category'] = floor['sub_category'] ? floor['sub_category'] : '';
            floorList.push(floor);
          });
        }
        onSuccess(floorList);
      },
      (error) => {
        if (this.onError) this.onError(error);
      }
    );
  };

  addIndoorMapLayersForFloor() {
   
     // this.floorLayer = this.addIndoorMapLayerForFloor('floors', 'floor_id', 'floor_name');
     this.unitLayer = this.addIndoorMapLayerForFloor('units', 'unit_id', 'unit_name', null, 'unit_url', 'unit_id');
    // console.log(this.unitLayer);
    
  };

  getUnitLayer(): Layer {
    // console.log(this.unitLayer);
    return this.unitLayer;
  };

  getFloorLayer(): Layer {
    return null;//this.floorLayer;
  };

  addIndoorMapLayerForFloor(layerName: string, idField: string, titleField: string, descField?: string, iconUrlField?: string, sortByField?: string): Layer {
    if (typeof this.source.floorId == 'undefined') {
      if (this.onError) this.onError('floorId is not defined in source');
      return null;
    }
    
    let layerOptions = {
      'name': layerName,
      'map': this,
      'zoomToLayer': true,
      'style': this.styleMap[layerName],
      'onError': this.onError,
      'onSuccess': () => {
        // if (this.floorLayer.isDataLoaded && this.unitLayer.isDataLoaded) {
        //   if (this.onSuccess) this.onSuccess();
        // }
      },
      'source': new LayerDataSource({
        'format': 'geojson',
        'layerName': layerName,
        'cityCode': this.source.cityCode,
        'idField': idField,
        'titleField': titleField,
        'descField': descField,
        'apiKey': this.source.apiKey,
        'http': this.source.http,
        'iconUrlField': iconUrlField,
        'sortByField': sortByField,
        'api': this.api_service,
        'filter': {
          'building_uid': this.source.buildingId,
          'assignment_name' : this.source.assignment_name,
          'property_id' : this.source.property_id,
          'floor_id' : this.source.floor_id
        },
      })
    };
    // if (layerName == 'floors') {
    //   layerOptions.source['featureId'] = this.source.floorId;
    // }
    // else {
    //   layerOptions.source['filter'] = {
    //     'floor_uid': this.source.floorId
    //   };
    // }
    return new Layer(layerOptions, this.share);
  };

  getLowestPositiveFloorId(): string {
    let lowestPositiveFloorNo: string = '1000';
    let lowestPositiveFloorId: string;
    for (var i = 0; i < this.floorList.length; i++) {
      let floorInfo = this.floorList[i];
      if (floorInfo.floor_index >= 0 && floorInfo.floor_index < lowestPositiveFloorNo) {
        lowestPositiveFloorNo = floorInfo.floor_no;
        lowestPositiveFloorId = floorInfo.floor_uid;
      }
    }
    return lowestPositiveFloorId;
  };

  // displayFloorSwitcher() {
  //   if (typeof this.floorSwitcherElement == 'undefined') {
  //     return;
  //   }
  //   this.floorSwitcherElement.innerHTML = '';
  //   let floorSwitcher = document.createElement('select');
  //   floorSwitcher.id = 'mapFloorSelector';
  //   for (var i = 0; i < this.floorList.length; i++) {
  //     let floorInfo = this.floorList[i];
  //     let floorOption = document.createElement('option');
  //     let opt_txt = document.createTextNode(floorInfo.floor_name);
  //     floorOption.appendChild(opt_txt);
  //     floorOption.setAttribute('value', floorInfo.floor_uid);
  //     if (floorInfo.floor_uid == this.source.floorId) {
  //       floorOption.setAttribute('selected', 'selected');
  //     }
  //     floorSwitcher.appendChild(floorOption);
  //   }
  //   floorSwitcher.addEventListener("change", (e: Event) => {
  //     let floorSwitcher = <HTMLSelectElement>e.target;
  //     this.switchFloor(floorSwitcher.options[floorSwitcher.selectedIndex].value);
  //   });
  //   this.floorSwitcherElement.appendChild(floorSwitcher);
  // };

  switchFloor(floorId: string) {    
    if (typeof floorId == 'undefined') {
      if (this.onError) this.onError('floorId is not provided');
      return;
    }
    // this.floorLayer.clear();
    this.unitLayer.clear();
    this.source.floorId = floorId;
    // this.floorLayer.source['featureId'] = floorId;
    // this.floorLayer.loadData();
    // this.unitLayer.source.filter['floor_uid'] = floorId;
    this.unitLayer.source.filter['assignment_name'] = this.source.assignment_name,
    this.unitLayer.source.filter['property_id'] = this.source.property_id,
    this.unitLayer.source.filter['floor_id'] = floorId
    this.unitLayer.loadData();
  };

}