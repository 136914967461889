<div *ngIf="!mobile">
    <div class="block" id="DynamicBlock">
        <div class="userDiv" *ngIf="selected_user_id == 0">
            <div *ngIf="!user_detail.assignment_name">
                <ngx-loading [show]="!user_detail.assignment_name"
                    [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                </ngx-loading>
            </div>
            <ng-container *ngIf="user_detail.assignment_name">
                <div class="userDetail animated fadeIn delay-2s">
                    <p class="sp1">Assignment Name: </p>
                    <p class="sp clmp">{{user_detail.assignment_name}}</p>
                </div>
                <div class="userDetail animated fadeIn delay-2s">
                    <p class="sp1">User Name: </p>
                    <p class="sp clmp">{{user_detail.user_name}}</p>
                </div>
                <div class="userDetail animated fadeIn delay-2s">
                    <p class="sp1">User Email: </p>
                    <p class="sp clmp">{{user_detail.user_email}}</p>
                </div>
            </ng-container>
        </div>
        <div class="button-block">
            <ng-container *ngFor="let group of group_custom_list">
                <div *ngIf="group.status" class="big-button trans animated fadeIn delay-2s"
                    [ngClass]="{'active':isActive(group.id), 'floor-style':group.id == 0, 'remark-style':(isRerorkGroup(group)) || (group.id == 0 && hasRejection)}"
                    (click)="openOptions('subGroups',group)">
                    <div>{{group.group_name}}</div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div *ngIf="mobile" class="rel">
    <a class="floating animated bounceIn delay-2s" (click)="openDialog()">
        <mat-icon>backup</mat-icon>
    </a>
    <app-mobile-toolbar [back]="true" [nav_link]="'/property/'+share.block0_obj.assignment_id"
        [title]="'Property Information'" [property_uid]="selected_property.property_id"></app-mobile-toolbar>

    <div class="list-container-mobile" id="pList">
        <div class="block">
            <div class="block" id="DynamicBlock">
                <div class="userDiv-m" *ngIf="selected_user_id == 0">
                    <div *ngIf="!user_detail.assignment_name">
                        <ngx-loading [show]="!user_detail.assignment_name"
                            [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                    </div>
                    <ng-container *ngIf="user_detail.assignment_name">
                        <div class="userDetail animated fadeIn delay-2s">
                            <p class="sp1-m">Assignment Name: </p>
                            <p class="sp-m clmp">{{user_detail.assignment_name}}</p>
                        </div>
                        <div class="userDetail animated fadeIn delay-2s">
                            <p class="sp1-m">User Name: </p>
                            <p class="sp-m clmp">{{user_detail.user_name}}</p>
                        </div>
                        <div class="userDetail animated fadeIn delay-2s">
                            <p class="sp1-m">User Email: </p>
                            <p class="sp-m clmp">{{user_detail.user_email}}</p>
                        </div>
                    </ng-container>
                </div>
                <div class="test-block">
                    <div class="location">
                        <div class="address-line">
                            <p class="p-text">{{selected_property.address}}</p>
                        </div>
                        <div (click)="setMapMarker()" class="circle">
                            <div>
                                <mat-icon class="map" svgIcon="pin"></mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button-block">
                    <ng-container *ngFor="let group of group_custom_list">
                        <div *ngIf="group.status" (click)="openOptions('subGroups',group,true)"
                            class="big-button-m animated fadeIn delay-2s"
                            [ngClass]="{'active':isActive(group.id),'floor-style':group.id == 0, 'remark-style':(isRerorkGroup(group)) || (group.id == 0 && hasRejection)}">
                            <div>
                                {{group.group_name}}
                            </div>
                            <div class="led" *ngIf="group.id">
                                <mat-icon class="indicator" [ngClass]="{'filled':isFilled(group)}" svgIcon="check">
                                </mat-icon>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>