import { Component, OnInit, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import {MainBreadcrumbService} from '../../main-breadcrumb/main-breadcrumb.service';
import { ApiserviceService } from '../../../services/apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { ShareService } from '../../../services/share.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NotificationService } from '../../../../../src/app/services/notification.service';
import { Clipboard } from "@angular/cdk/clipboard";
@Component({
  selector: 'app-unit-group',
  templateUrl: './unit-group.component.html',
  styleUrls: ['./unit-group.component.css']
})
export class UnitGroupComponent implements OnInit {
  dHeight: number;
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  mobile : boolean = false;
  selected_group_id : number;
  selected_group:any;
  selected_floor:any;
  selected_unit:any;
  selected_property:Array<any>;
  group_custom_list_unit:any=[];
  loader: boolean = false;
  reworkAttributes: any = [];
  constructor(private elementRef: ElementRef, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private share:ShareService, private route:Router,private api:ApiserviceService,
  private fnc: CommonfunctionService, private _crumbs : MainBreadcrumbService,
  private clipboard: Clipboard,
  private notifyService: NotificationService) {
    iconRegistry.addSvgIcon("pin", sanitizer.bypassSecurityTrustResourceUrl("assets/icon/Map.svg"))
    .addSvgIcon('check',sanitizer.bypassSecurityTrustResourceUrl('assets/icon/GreenCheck.svg'));

    this.mobile = this.share.mobile;
    this.api.form_language = null; //reset form language to default
    //this.share.unit_group_obj = this;
    if(!this.share.block0_obj){
      route.navigateByUrl('/');
      return;
    }
    if(this.share.floor_obj.selected_floor) this.selected_floor = this.share.floor_obj.selected_floor;
    if(this.share.unit_obj.selected_unit) this.selected_unit = this.share.unit_obj.selected_unit;
    if(this.share.block0_obj.selected_property) this.selected_property = this.share.block0_obj.selected_property;
    this.share.block0_obj.getAllowResource();
}

openOptions(param:any,group,mobile:boolean=false):void{
  if(mobile == false){
    if(this.share.dynamic_obj.form){
      this.share.block3_obj.submitForm();
    }
  }
  this.selected_group_id = group.id;
   this.selected_group = group;
  this.share.unit_group_obj = this;
   this.share.block2_obj = null;
   let child = this.fnc.getArrayValue('id',this.selected_group_id,this.group_custom_list_unit);
   child = child.childrens;
   if(child.length > 0){
     this.notify.emit(param);
     if(mobile){
       this.route.navigateByUrl('unitSubgroup');
     }else {
       this.notify.emit('unitSubgroup');
    }
   }else{
     if(mobile){
       this.route.navigateByUrl('form');
     }else{
      this.share.unit_subgroup_obj = null;
      if(this.share.dynamic_obj){
         this.share.dynamic_obj.unitSubgroup = false;
         if( this.share.dynamic_obj.uploadMedia)  this.share.dynamic_obj.uploadMedia = false;
      }
      this.notify.emit('form');
    }
   }


}

isActive(v){
 if(v == this.selected_group_id) return true;
}
isFilled(group){
  let id = group.id;
  let status = false,child;
  let record = {};
  if(this.share.unit_obj.unit_detail){
    record = this.share.unit_obj.unit_detail.data;
  }
  let attributes = this.fnc.getChildArray('group_id',id,this.share.block0_obj.attribute_custom_list_unit);
  child = group.childrens;
  if(child.length){
    let sub_attrib=[];
    child.forEach((v)=>{
      sub_attrib = this.fnc.getChildArray('group_id',v.id,this.share.block0_obj.attribute_custom_list_unit);
      if(sub_attrib.length && record){
        sub_attrib.forEach((v)=>{
          if(record[v.id]){
            status = true;
          }
        })
      }
    });
  }
  if(attributes && record){
    attributes.forEach((v)=>{
      if(record[v.id]){
        status = true;
      }
    })
  }
  return status;
}

isRerorkFloorGroup(group){

  let id = group.id;
  let status = false,child;
  let reworkrecord = [];
  if(this.share.floor_obj){
    reworkrecord = this.share.unit_obj.rejectedUnitAttributeList;
  }

  let attributes = this.fnc.getChildArray('group_id',id,this.share.block0_obj.attribute_custom_list_unit);
 child = group.childrens;
  if(child.length){
    let sub_attrib=[];
    child.forEach((v) => {
      sub_attrib = this.fnc.getChildArray('group_id', v.id, this.share.block0_obj.attribute_custom_list_unit);
      if (sub_attrib.length && reworkrecord.length > 0) {

        for (let k = 0; k < reworkrecord.length; k++) {
          for (let j = 0; j < sub_attrib.length; j++) {
            if (reworkrecord[k]) {
              if (reworkrecord[k].keyId == sub_attrib[j].id) {
                status = true;
              }
            }
          }
        }
      }
    });
  }
  if(reworkrecord){
    for (let k = 0; k < reworkrecord.length; k++) {
      for (let j = 0; j < attributes.length; j++) {
        if (reworkrecord[k]) {
          if (reworkrecord[k].keyId == attributes[j].id) {
            status = true;
          }
        }
      }
    }
  }
   return status;
}



  ngOnInit() {
    setTimeout(()=>{
      this.dHeight = this.share.heightService;
      let e = $(this.elementRef.nativeElement);
      e.find('#pList').css('height',this.dHeight);
      e.find('#DynamicBlock').css('height',(this.dHeight-50));
    }, 10);
    this.loader = true;
     if(!this.group_custom_list_unit.length){
      this.group_custom_list_unit = this.share.block0_obj.group_custom_list_unit;
      if(!this.fnc.getArrayValue('id', -1, this.group_custom_list_unit)){
        let other = {
          id: -1,
          group_name:"Rework Required !",
          childrens:[],
          parent_id: null,
          status:true
        }
        setTimeout(() => {
          this.reworkAttributes =  this.share.block0_obj.reworkAttributes;
          if(this.reworkAttributes.length){
            this.group_custom_list_unit.splice(0,0,other);
          }
        }, 500);
      }
      this.loader = false;
    }
    if(this.share.unit_subgroup_obj){
      //this.selected_group_id = this.share.unit_subgroup_obj.selected_group.id;
    }
  }
  public copyToClipboard(id): void {
    const successful = this.clipboard.copy(id);
    if (successful) {
      this.notifyService.notify(
        `Unit uid ${id} copied`,
        "success"
      );
    } else {
      this.notifyService.notify(`Not copied, try again`, "warn");
    }
  }
}
