import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiserviceService } from '../services/apiservice.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private api: ApiserviceService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem('user_id') && localStorage.getItem('city_id')) {
      return true;
    }else if (localStorage.getItem('user_id') && !localStorage.getItem('city_id')) {
      this.router.navigateByUrl('/country');
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}
