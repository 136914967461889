import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'; 
import { LoginComponent } from './main/login/login.component';
import { CountryComponent } from './main/country/country.component';
import { AuthGuard } from './services/auth.guard';

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {path : 'login', component : LoginComponent},
      { path: 'country', component: CountryComponent},

    ],{ enableTracing: false , initialNavigation: 'enabledBlocking'})
  ],
  declarations: [],
  exports : [RouterModule]
})
export class AppRoutingModule { }
