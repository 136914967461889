<mat-toolbar class="center bg">
    <span>Create Unit </span>
</mat-toolbar>
<div class="pad10 col1">
    <form class="vgrid create-Unit-Form" [formGroup]="createUnitForm" (ngSubmit)="saveUnit(createUnitForm)">
        <div class="customform">
            <strong for="name" class="colr">Unit type</strong>
            <div class="ms1">
              <!-- <mat-label>Unit type</mat-label> -->
                <mat-select formControlName="property_type" class="form-control" (selectionChange)="getSubUnit($event)">
                    <mat-option *ngFor="let data of propertyTypes" class="ddList" value="{{data.id}}">{{data.label}}</mat-option>
                </mat-select>
                <mat-hint>Select unit type here.</mat-hint>
            </div>
        </div>
        <div class="customform">
            <strong for="name" class="colr">Unit sub category</strong>
            <div class="ms1">
              <!-- <mat-label>Unit sub category</mat-label> -->
                <mat-select formControlName="unit_sub_category" class="form-control">
                    <mat-option *ngFor="let data of unit_type_list" class="ddList" value="{{data.name}}">{{data.label}}</mat-option>
                </mat-select>
                <mat-hint>Select unit sub category here.</mat-hint>
            </div>
        </div>
        <div class="customform">
            <strong for="name" class="colr">Unit Area</strong>
            <div class="cform1 center">
              <div>
                <input type="number" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57" class="form-control" id="name" required formControlName="unit_area">
                <mat-hint>Enter unit area here.</mat-hint>
              </div>
                <div class="ms2">
                    <mat-select formControlName="unit_areaunit" class="form-control">
                        <mat-option selected class="ddList" value="Sq M">Sq M</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
        <div class="customform">
          <strong for="name" class="colr">Unit Number</strong>
          <div style="width: 100%; min-width: 300px;">
            <input type="text" class="form-control" id="name" required formControlName="unit_number">
            <mat-hint>Enter unit number here.</mat-hint>
          </div>
        </div>
        <div class="action">
            <!-- <div>
                <button type="submit" [disabled]="loader || !createUnitForm.valid" class="act" mat-button>
                    <span *ngIf="!loader">Save</span>
                    <span *ngIf="loader">Saving...</span>
                </button>
            </div>
            <div>
                <button type="button" [disabled]="loader" (click)="closeDialog()" class="act" mat-button>Cancel</button>
            </div> -->
            <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
              <button mat-raised-button class="cancel-btn" type="button" (click)="closeDialog()">Cancel</button>
              <button mat-raised-button class="bluebtn save-btn" type="submit">Save</button>
          </mat-dialog-actions>
        </div>
    </form>
</div>
