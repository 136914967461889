import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareService } from '../../services/share.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiserviceService } from '../../services/apiservice.service';
import { NotificationService } from '../../services/notification.service';
import { CommonfunctionService } from '../../services/commonfunction.service';

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_assignment_name_properties_property_id_patch</li>
 * </ol>
 *
 */


@Component({
  selector: 'app-submit-property-dialog',
  templateUrl: './submit-property-dialog.component.html',
  styleUrls: ['./submit-property-dialog.component.css']
})
export class SubmitPropertyDialogComponent {
  mobile: boolean;
  assignment_detail: any;
  selected_property_id: any
  constructor(public dialogRef: MatDialogRef<SubmitPropertyDialogComponent>, private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiserviceService,
    private notify: NotificationService, private fnc: CommonfunctionService,
    private share: ShareService) {
    this.mobile = this.share.mobile;
    this.assignment_detail = JSON.parse(localStorage.getItem('assignment_detail'));
    if (this.share.block0_obj.selected_property) {

      this.selected_property_id = this.share.block0_obj.selected_property.property_id;
    }
  }

  ngOninit() { }

  submit() {
    if (!this.share.block0_obj.selected_property) {
      this.notify.notify('No properties selected, please check again!', 'warn');
      return;
    }
    let url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id;
    let status = 2;
    if (this.share.block0_obj.selected_property.status == 6 || this.share.block0_obj.selected_property.status == 4) status = 7;
    let body = {
      "status": status,
      "updated_by": this.api.user_id
    }

    this.api.patchEpsData(url, body).subscribe(
      {
       next: () => {
          this.data.parent.finalize = false;
          this.notify.notify('Information Updated', 'success');
          if(this.mobile){
            this.data.parent.closeDialog();
          }
          this.dialogRef.close();
          // if (this.mobile) {
          //   this.route.navigateByUrl('property/' + this.share.block0_obj.selected_property.assignment_id);
          // }
          this.share.main_property_obj.selected_property = {
            'property_id':null,
            'address':null
          };
          this.share.block0_obj.getProperties();
        },
        error:(err:any) => {
          // this.notify.notify(err.error.message, 'error');
        },
        complete() {},
      }
    )
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
