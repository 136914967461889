import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { ApiserviceService } from '../../services/apiservice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ShareService } from '../../services/share.service';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_user_notification_post</li>
 * <li>umsapi_user_login_post</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, AfterViewInit {
  hide = true;
  error: string;
  loginForm: UntypedFormGroup;
  showForgot: boolean = false;
  //verify : boolean = false;
  forget_email: string;
  forget_message = '';
  f_error = false;
  enable_btn = true;
  login_action: boolean = false;
  isLocalServe =  environment.isLocalServe;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  isMobile: boolean;
  constructor(private _fb: UntypedFormBuilder,
    private api: ApiserviceService,
    private route: Router,
    private share: ShareService,
    private recap: ReCaptchaV3Service,
    public dialog: MatDialog) {
    this.dialog.closeAll();
    this.share.loginObj = this;
    this.isMobile = this.api.ismobile;
    if(environment.isLocalServe || this.api.ismobile){
      if (localStorage.getItem('user_id') && localStorage.getItem('city_id')) {
        this.route.navigateByUrl('/home');
      }

      if (localStorage.getItem('user_id') && !localStorage.getItem('city_id')) {
          this.route.navigateByUrl('/country');
      }
    }else{
      if (!localStorage.getItem('user_id') && !localStorage.getItem('user_token')) {
        let newwindow = window.open(`${environment.adminHost}/login?continue=${environment.baseURL}`, '_self');
        if (window.focus) {newwindow.focus()}
      }else{
        if (localStorage.getItem('user_id') && localStorage.getItem('city_id')) {
          this.route.navigateByUrl('/home');
        }

        if (localStorage.getItem('user_id') && !localStorage.getItem('city_id')) {
            this.route.navigateByUrl('/country');
        }

      }
    }

    this.loginForm = this._fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });
    // if(window.location.host == 'localhost:4200' || window.location.host == 'deapp.dev10.in'){
    //   this.verify = true;
    // }

  }
  ngAfterViewInit() {

    //this.tabGroup.selectedIndex = 1;
  }

  jumpRegister() {
    this.tabGroup.selectedIndex = 1;
  }
  jumpLogin() {
    this.tabGroup.selectedIndex = 0;
  }

  jumpForgot() {
    this.showForgot = !this.showForgot;
    //   let dialogRef = this.dialog.open(ConfirmRedirect, {
    //     width: '350px',
    //     panelClass: 'create-master-panel',
    //     data: this
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //   });
  }
  forgetPassword() {

    this.enable_btn = false;
    this.forget_message = '';
    let body = {
      "email": this.forget_email,
      "notification_type": "forgot_password"
    }
    // let body = "email=" + this.forget_email + "&notification_type=forgot_password";
    this.api.postUmsData('notification', body)
      .subscribe({
        next: (data: any) => {
        if (data && data.status == 201) {
          this.enable_btn = true;
          this.f_error = false;
          this.forget_message = "Reset password link has been sent to your email";
        }
        else {
          this.f_error = true;
          this.enable_btn = true;
          this.forget_message = data.message;
        }
      },
        error:(err)=> {
          this.f_error = true;
          this.enable_btn = true;
          this.forget_message = err.error.message;
        }}
      );
  }
  // forgetPassword(){
  //     this.enable_btn = false;
  //   this.forget_message = '';
  //   let body = "email="+this.forget_email+"&notification_type=forgot_password";
  //   this.api.postUmsData('user/notification',body)
  //   .subscribe((data:any) =>{
  //       this.enable_btn = true;
  //       this.f_error = false;
  //       this.forget_message = "Reset password link has been sent to your email";
  //   },
  //   err=>{
  //       this.f_error = true;
  //       this.enable_btn = true;
  //       this.forget_message = "This email id is not register yet."
  //   }
  //   );
  // }

  doLogin(val) {
    this.recap.execute(null).subscribe((token) => {
      this.login(val);
    })
  }

  login(val: any) {
    this.login_action = true;
    let record: any;
    let name: string;
    this.error = null;
    let userInfo = {
      "user_name": val.email,
      "user_password": val.password,
      "keep_log_in": false
    }
    this.api.postUmsData('login', userInfo)
      .subscribe(
        {next:(res: any) => {
		  this.login_action = false;
          if (res.status == 200) {
            record = res.data;
            this.share.appComp.logIn();
            localStorage.setItem('user_token', record.token);
            localStorage.setItem('session_id', record.session_id);
            localStorage.setItem('user_id', record.user_id);
            localStorage.setItem('profile_image', record.profile_image);
            this.api.profile_image.next(record.profile_image);
            if (record.user_details) {
              name = record.user_details['1'].first_name;
            }
            if (record.user_details) {
              name += " " + record.user_details['1'].last_name;
            }
            localStorage.setItem('user_name', name);
            this.api.user_id = record.user_id;
            this.api.user_name.next(name);
            this.api.user_token = record.token;
            this.api.session_id = record.session_id;
            let image = 'assets/images/user.png';
            if (record.profile_image) {
              image = record.profile_image;
            }
            let user_info = {
              "fname": record.user_details['1'].first_name,
              "lname": record.user_details['1'].last_name,
              "contact": record.phone_number,
              "country": record.nationality,
              "email": record.primary_email_id,
              "language": 'en',
              "imageURL": image
            }
            localStorage.setItem('user_info', JSON.stringify(user_info));
            this.api.user_info.next(user_info)
            this.route.navigateByUrl('/country');
          }else{
            this.error = res.message
          }
        },
        error:(err) => {
          this.login_action = false;
          this.error = err.error;
        }}
      )
  }
  ngOnInit() {

  }
  public resolved(captchaResponse: string) {
    // this.verify = true;
  }
  closingStatus(status) {
    this.showForgot = status;
  }
}
