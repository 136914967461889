import { Component, Inject } from '@angular/core';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { ShareService } from '../../../services/share.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainBreadcrumbService } from '../../main-breadcrumb/main-breadcrumb.service';


@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./block0.component.css']
})
export class Filter {
  selected_user_id: number;
  is_allow = true;
  user_list = [];
  user_id: number;
  mobile: boolean;
  public statusList: any = [
    { status: '1' },
    { status: '4' },
    { status: '6' }
  ];

  public statusfilter;

  // blockList: any = [];
  streetList: any = [];

  neigh_uid: any;
  block_uid: any;
  is_filtered: boolean = false;
  filteredBlockList: any = [];
  // filteredStreetList: any = [];

  neighbourhoodList: any = [];
  // filteredNeighList: any = [];

  searchTerm: string = '';
  blocksearchTerm :string='';
  filter_data = null;
  sort_by_list = ['updated_date', 'property_uid'];
  sort_by: any;
  constructor(private fnc: CommonfunctionService, public dialogRef: MatDialogRef<Filter>, @Inject(MAT_DIALOG_DATA) public data, private share: ShareService, private _crumbs: MainBreadcrumbService) {
    this.selected_user_id = this.data.selected_user_id;
    this.mobile = this.data.mobileview;
    if (!this.data.show_all.GET) {
      this.is_allow = false;
    }
    this.user_id = this.data.api.user_id;
    if (this.data.assignment_detail) {
      this.user_list = this.data.assignment_detail.assigned_user_details;
      // console.log(this.user_list);

      if(this.user_list){
        this.user_list.forEach(element => {
          let num = element.no_of_properties[1] + element.no_of_properties[4] + element.no_of_properties[6];
          element.no_of_properties = num;
        });
      }
    }
    if (this.data) {
      this.statusfilter = this.data.statusfilter;
      this.neighbourhoodList = this.data.neighbourhoodList;
      if (this.data.neigh_uid) {
        this.neigh_uid = this.data.neigh_uid;
        this.getBlock(this.data.neigh_uid);
        if (this.data.block_uid) this.block_uid = this.data.block_uid
      }
      // this.filteredNeighList = this.data.filteredNeighList;
      this.sort_by = this.data.sort_by;
    }

  }
  ngOnInit() {

  }
  getValue(e) {
    this.selected_user_id = e.source.value;
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  apply(): void {
    if ((this.neigh_uid == null || this.neigh_uid == undefined) && (this.block_uid == null || this.block_uid == undefined)
      && (this.statusfilter == '1,4,6') && (this.selected_user_id == this.data.api.user_id)
      && (this.sort_by == 'updated_date' || this.sort_by == null)) {
      this.is_filtered = false;
    } else {
      this.is_filtered = true;
    }

    this.data.neigh_uid = this.neigh_uid;
    this.data.block_uid = this.block_uid;
    this.data.statusfilter = this.statusfilter;

    this.data.is_filtered = this.is_filtered;
    this.data.sort_by = this.sort_by;

    if (this.share.dynamic_obj) {
      this.share.dynamic_obj.onOptionsRequested('proList');
    }
    //this.share.dynamic_obj.onOptionsRequested('proList');
    this._crumbs.count = 0;
    this.share.block0_obj.property_list_custom = [];
    this.data.selected_user_id = this.selected_user_id;
    if (!this.is_allow) {
      this.data.selected_user_id = this.user_id;
    }
    localStorage.setItem('selected_user', this.selected_user_id.toString());
    this.filter_data = {
      'selected_user': this.selected_user_id.toString(),
      'status_filter': this.statusfilter,
      'neighbourhood_list': this.neighbourhoodList,
      // 'filteredNeigh_list': this.filteredNeighList,
      // 'filteredBlock_list': this.filteredBlockList,
      'neigh_uid': this.neigh_uid,
      'block_uid': this.block_uid,
      'is_filtered': this.is_filtered,
      'sort_by': this.sort_by
    }
    localStorage.setItem('filter_data', JSON.stringify(this.filter_data));

    this.data.getProperties();
    this.dialogRef.close();
    //this.data.getAssignmentsDetail(this.data.assignment_id);
  }

  // filter by color
  public getTitlefilter(val) {
    if (val == '1') {
      return 'Created'
    } else if (val == '4') {
      return 'Rework'
    } else if (val == '6') {
      return 'Editing'
    }
  }

  // filter with status
  public filterProperty(val) {
    this.statusfilter = val;
  }

  // get Blocks By Neigh uid
  getBlocksByNeigh(val) {
    this.neigh_uid = val.value;
    this.block_uid = null;
    if (val.value == 'all') {
      // this.blockList = [];
      this.filteredBlockList = [];
      // this.neigh_uid = null;
    }

    if (this.neigh_uid) this.getBlock(this.neigh_uid);
  }

  // find(type) {
  //   let tempArr = [], lower, term;
  //   if (type == 'neigh') {
  //     term = this.searchTerm.trim().toLowerCase();
  //     this.neighbourhoodList.forEach(v => {
  //       let val = v['neigh_name'];
  //       if (val) {
  //         lower = val.toLowerCase();
  //         if (lower.includes(term)) {
  //           tempArr.push(v);
  //         }
  //       }
  //     });
  //     this.filteredNeighList = tempArr;
  //   }
  //   if (type == 'block') {
  //     term = this.blocksearchTerm.trim().toLowerCase();
  //     this.blockList.forEach(v => {
  //       let val = v['block_name'];
  //       if (val) {
  //         lower = val.toLowerCase();
  //         if (lower.includes(term)) {
  //           tempArr.push(v);
  //         }
  //       }
  //     });
  //     this.filteredBlockList = tempArr;
  //   }
  // }

  getBlock(id) {
    let blockDetail = this.neighbourhoodList.find(e=> e.neigh_uid == id);
    if (blockDetail && blockDetail.block && blockDetail.block.length) {
      this.filteredBlockList = blockDetail.block;
      // this.blockList = blockDetail.block;
    }
  }

  getStreetsByBlock(val) {
    this.block_uid = val.value;
  }


  setSortBy(val) {
    this.sort_by = val.value;
  }
}
