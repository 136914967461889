import { Component, OnInit} from '@angular/core';
import { Block1Component } from '../dynamic/block1/block1.component';
import { ApiserviceService } from '../../services/apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ShareService } from '../../services/share.service';
import * as $ from 'jquery';
import { MainBreadcrumbService } from '../main-breadcrumb/main-breadcrumb.service';
@Component({
  selector: 'app-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.css']
})
export class DynamicComponent implements OnInit {
  mobile : boolean = false;
  groups : boolean = false;
  subGroups : boolean = false;
  form : boolean = false;
  fGroup : boolean = false;
  plist:boolean = true;
  map : boolean = false;
  floor: boolean = false;
  proList: boolean = false;
  unit: boolean = false
  assignment_id:number;
  floorSubgroup: boolean = false;
  uploadMedia : boolean = false;
  unitGroup: boolean = false;
  unitSubgroup: boolean =  false;
  constructor(private share:ShareService,private fnc: CommonfunctionService, private api: ApiserviceService,
    private _crumbs : MainBreadcrumbService) {
    this.mobile = share.mobile;
    this.share.dynamic_obj = this;
  }

  onOptionsRequested(message:string):void{
    switch(message){
      case 'proList':
      this.proList        = true;
      this.groups         = false;
      this.subGroups      = false;
      this.form           = false;
      this.fGroup         = false;
      this.floor          = false;
      this.unit           = false;
      this.floorSubgroup  = false;
      this.uploadMedia    = false;
      this.unitGroup      = false;
      this.unitSubgroup   = false;
      break;

      case 'groups':
      this.groups         = true;
      this.subGroups      = false;
      this.form           = false;
      this.fGroup         = false;
      this.floor          = false;
      this.unit           = false;
      this.floorSubgroup  = false;
      this.uploadMedia    = false;
      this.unitGroup      = false;
      this.unitSubgroup   = false;
      break;

      case 'subGroups':
      this.subGroups      = true;
      this.groups         = true;
      this.form           = false;
      this.fGroup         = false;
      this.floor          = false;
      this.unit           = false;
      this.floorSubgroup  = false;
      this.uploadMedia    = false;
      this.unitGroup      = false;
      this.unitSubgroup   = false;
      break;

      case 'form':
        this.form = false;
        this.uploadMedia = false;
        setTimeout(() => {
          this.form = true;
        }, 300);

      break;

      case 'floor':
      this.floor          = true;
      this.subGroups      = false;
      this.groups         = false;
      this.form           = false;
      this.fGroup         = false;
      this.proList        = false;
      this.unit           = false;
      this.floorSubgroup  = false;
      this.uploadMedia    = false;
      this.unitGroup      = false;
      this.unitSubgroup   = false;
      break;

      case 'fGroup':
      this.fGroup         = false;
      setTimeout(() => {
        this.fGroup         = true;
      }, 200);
      this.floor          = true;
      this.groups         = false;
      this.subGroups      = false;
      this.form           = false;
      this.unit           = false;
      this.floorSubgroup  = false;
      this.uploadMedia    = false;
      this.unitGroup      = false;
      this.unitSubgroup   = false;
      break;

      case 'floorSubgroup':
      this.floor          = true;
      this.fGroup         = true;
      this.floorSubgroup  = true;
      this.groups         = false;
      this.subGroups      = false;
      this.form           = false;
      this.unit           = false;
      this.uploadMedia    = false;
      this.unitGroup      = false;
      this.unitSubgroup   = false;
      break;

      case 'unit':
      this.unit           = true;
      this.subGroups      = false;
      this.groups         = false;
      this.form           = false;
      this.proList        = false;
      this.fGroup         = false;
      this.floor          = false;
      this.floorSubgroup  = false;
      this.uploadMedia    = false;
      this.unitGroup      = false;
      this.unitSubgroup   = false;
      break;

      case 'unitGroup':
      this.unit           = true;
      this.unitGroup      = false;
        setTimeout(() => {
          this.unitGroup      = true;
        }, 200);

      this.unitSubgroup   = false;
      this.subGroups      = false;
      this.groups         = false;
      this.form           = false;
      this.fGroup         = false;
      this.floor          = false;
      this.floorSubgroup  = false;
      this.uploadMedia    = false;
      break;

      case 'unitSubgroup':
      this.unit           = true;
      this.unitGroup      = true;
      this.unitSubgroup   = true;
      this.subGroups      = false;
      this.groups         = false;
      this.form           = false;
      this.fGroup         = false;
      this.floor          = false;
      this.floorSubgroup  = false;
      this.uploadMedia    = false;

      break;

      case 'uploadMedia':
      this.uploadMedia    = true;
      this.unit           = false;
      this.unitGroup      = false;
      this.unitSubgroup   = false;
      this.subGroups      = false;
      this.groups         = true;
      this.form           = false;
      this.fGroup         = false;
      this.floor          = false;
      this.floorSubgroup  = false;
      break;

      case 'upload_Media_unit':
      this.uploadMedia    = true;
      this.unit           = true;
      this.unitGroup      = true;
      this.unitSubgroup   = false;
      this.subGroups      = false;
      this.groups         = false;
      this.form           = false;
      this.fGroup         = false;
      this.floor          = false;
      this.floorSubgroup  = false;
      break;

      case 'upload_Media_floor':
      this.uploadMedia    = true;
      this.unit           = false;
      this.unitGroup      = false;
      this.unitSubgroup   = false;
      this.subGroups      = false;
      this.groups         = false;
      this.form           = false;
      this.fGroup         = true;
      this.floor          = true;
      this.floorSubgroup  = false;
      break;
    }
  }

  ngOnInit() {
    setTimeout(()=>{
      this.onOptionsRequested('proList');
    },200);
  }
}
