<div *ngIf="!mobile">
    <div class="container1-W">
        <div id="pList" class="profile-W">
            <div class="section1-W">
                <div class="picture-W">
                    <img class="pPic-W" src="{{userInfo.pictureURL}}" alt="User Image">
                </div>
                <div class="userdetails-W">
                    <div class="cell-W">
                        <div><strong>Name </strong></div>
                        <div>:</div>
                        <div class="p05-W" matTooltip="{{userInfo.username}}"><span>{{userInfo.username}}</span></div>
                    </div>
                    <div class="cell-W">
                        <div><strong>Contact </strong></div>
                        <div>:</div>
                        <div class="p05-W"><span>{{userInfo.contact}}</span></div>
                    </div>
                    <div class="cell-W">
                        <div><strong>Country </strong></div>
                        <div>:</div>
                        <div class="p05-W"><span>{{userInfo.country}}</span></div>
                    </div>
                    <div class="cell-W">
                        <div><strong>Email </strong></div>
                        <div>:</div>
                        <div class="p05-W" matTooltip="{{userInfo.email}}"><span>{{userInfo.email}}</span></div>
                    </div>
                </div>
            </div>
            <div class="section2-W">
                <div class="border-W list-W" (click)="openMenu()">
                    <div class="item-W ctrans">
                        <mat-icon class="listing-W" svgIcon="my_listing"></mat-icon><span>Allocated Assignments </span>
                    </div>
                    <div class="counter-W">
                        <strong>{{allocatedAssignment}}</strong>
                    </div>
                </div>
                <div class="border list-W" (click)="openMenu()">
                    <div class="item-W ctrans">
                        <mat-icon class="listing-W" svgIcon="property"></mat-icon><span>Property Collected </span>
                    </div>
                    <div class="counter-W">
                        <strong>{{propertyCollected}}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="mobile">
        <div class="container1">
                <!-- <mat-toolbar class="toptoolbar new">
                    <div fxLayout="row" class="w-100" fxFlex fxLayoutAlign="start center">
                            <div class="titleBar">Welcome <strong>{{userInfo.username}}</strong></div>
                    </div>
                </mat-toolbar> -->
                <app-mobile-toolbar [caption]="'Welcome'" [title]="userInfo.username" [back]="false"></app-mobile-toolbar>
                <div id="pList" class="profile">
                    <div class="section1">
                        <div class="picture">
                            <img *ngIf="userInfo.pictureURL" class="pPic" src="{{userInfo.pictureURL}}" alt="User Image">
                        </div>
                        <div class="userdetails">
                            <div class="cell">
                                <div><strong>Name </strong></div>
                                <div>{{userInfo?.username}}</div>
                            </div>
                            <div class="cell">
                                <div><strong>Contact </strong></div>
                                <div>{{userInfo?.contact}}</div>
                            </div>
                            <div class="cell">
                                <div><strong>Country </strong></div>
                                <div>{{userInfo?.country}}</div>
                            </div>
                            <div class="cell">
                                <div><strong>Email </strong></div>
                                <div>{{userInfo?.email}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="section2">
                        <div class="border list" (click)="openMenu()">
                            <div class="item">
                                <mat-icon class="listing" svgIcon="my_listing"></mat-icon><span>Allocated Assignments </span>
                            </div>
                            <div class="counter">
                                <strong>{{allocatedAssignment}}</strong>
                            </div>
                        </div>
                        <div class="border list" (click)="openMenu()">
                            <div class="item">
                                <mat-icon class="listing" svgIcon="property"></mat-icon><span>Property Collected </span>
                            </div>
                            <div class="counter">
                                <strong>{{propertyCollected}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</div>