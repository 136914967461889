<div class="dialog">
        <div><mat-toolbar class="tool"><span>Confirmation</span></mat-toolbar></div>
        <div class="message">
            <span class="msg1">Are you sure you wish to submit your collected properties  <strong>{{selected_property_id}}</strong> for Review?</span>
        </div>
        <div class="message">
            <span class="note">NOTE: Please make sure that the Unit and Floor information is complete before sending.</span>
        </div>
        <div class="b-middle">
            <button (click)="submit()" class="deletebutton fix" mat-button>Submit</button>
            <button (click)="closeDialog()" class="fix" mat-button>Cancel</button>
        </div>
    </div>