<mat-toolbar class="tool">Select from available polygons</mat-toolbar>
<mat-dialog-content>
    <div fxLayout="row">
        <mat-select class="form-select-md" [(value)]="selectedPolygon" (selectionChange)="getSelectedPoly($event)">
            <mat-option *ngFor="let poly of polygonsList" [value]="poly.name">{{poly.name}}</mat-option>
        </mat-select>
    </div>
</mat-dialog-content>
<!-- <mat-dialog-actions>
    <div class="action">
        <div>
            <button class="act" mat-button>Save</button>
        </div>
        <div>
            <button (click)="closeDialog()" type="button" class="act" mat-button>Cancel</button>
        </div>
    </div>
</mat-dialog-actions> -->