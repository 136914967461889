<div class="dialog">
    <div><mat-toolbar class="tool"><span>Apply Filter</span></mat-toolbar></div>
    <div class="filter_head"><label>Other Filter</label></div>
    <div class="search_web_filter">
      <div>
        <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxLayoutAlign="start center" style="width: 50%;">
              <span class="all-btn1 lable_filter" (click)="filterProperty('1,4,6')"
                [ngClass]="('1,4,6' == statusfilter)?'lable_filter_sel':''"  title="Select all">
                  <img class="all-select" src="./assets/images/selectAll.png" alt="All">
                  <div>Select_All</div>
              </span>
            </div>
            <div class="center" *ngFor="let sts of statusList"  fxLayout="column" fxLayoutAlign="start center"
            [ngClass]="(sts.status == statusfilter)?'lable_filter_sel':''" [title]="getTitlefilter(sts.status)" (click)="filterProperty(sts.status)">
              <div><i class="material-icons" [ngClass]="{'red': sts.status == 1, 'blue': sts.status == '4' , 'green': sts.status == '6'}" >fiber_manual_record</i></div>
              <div class="lable_filter" [ngClass]="(sts.status == statusfilter)?'lable_filter_sel':'lable_filter'" >{{getTitlefilter(sts.status)}}</div>
            </div>
          </div>
          <div>
            <mat-select [(ngModel)]="neigh_uid" (selectionChange)="getBlocksByNeigh($event)"
              (openedChange)="searchTerm = ''" placeholder="Neighbourhood"
              class="form-select-l pt-1 ovrd100 Neighbourhood-drop">
              <div class="rel w-100 cen">
                <input [ngModelOptions]="{standalone: true}" class="form-input-l" type="text" [(ngModel)]="searchTerm"
                  placeholder="Search" (keydown)="$event.stopPropagation()" />
                <mat-option [value]="'all'">All</mat-option>
                <mat-option *ngFor="let data of neighbourhoodList | searchFilter : 'neigh_name': searchTerm : neighbourhoodList"
                [value]="data.neigh_uid">{{data.neigh_name}}
                </mat-option>
              </div>
            </mat-select>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            <mat-select [(ngModel)]="block_uid" (selectionChange)="getStreetsByBlock($event)"
            (openedChange)="blocksearchTerm = ''"
              placeholder="Block"
              class="form-select-l pt-1 ovrd100">
              <div class="rel w-100 cen">
                <input [ngModelOptions]="{standalone: true}" class="form-input-l" type="text" [(ngModel)]="blocksearchTerm"
                  placeholder="Search"  (keydown)="$event.stopPropagation()" />
                <mat-option *ngIf="filteredBlockList.length>0" [value]="'all'">All</mat-option>
                <mat-option *ngFor="let data of filteredBlockList | searchFilter : 'block_name': blocksearchTerm : filteredBlockList"
                 [value]="data.block_uid">{{data.block_name}}
                </mat-option>
              </div>
            </mat-select>
          </div>
          <div>
            <mat-select [(ngModel)]="sort_by" (selectionChange)="setSortBy($event)"
              placeholder="Sort by" class="form-select-l pt-1 ovrd100">
              <div class="rel w-100 cen">
                <mat-option *ngFor="let data of sort_by_list" [value]="data">{{data}}</mat-option>
              </div>
            </mat-select>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="is_allow">
      <div class="filter_head"><label>User Filter</label></div>
      <div class="filter-dialog">
          <div class="filter-row">
              <mat-radio-button (change)="getValue($event)" [checked]="0"  value="" name="user"></mat-radio-button>
              <span>All Properties</span>
          </div>
          <div *ngFor="let user of user_list" class="filter-row">
              <mat-radio-button (change)="getValue($event)" [checked]="user.user_id == selected_user_id"  value="{{user.user_id}}" name="user"></mat-radio-button>
              <span [style.color]="user.user_id == user_id ? '#23cb6b':'#212529'">{{user.user_name}} - {{user.no_of_properties}}</span>
          </div>
      </div>
      <!-- <div *ngIf="!is_allow" class="filter-dialog">
          <div class="filter-row">
              <span>Nothing to show</span>
          </div>
      </div> -->
    </div>
    <div class="b-middle">
      <button (click)="apply()" class="applybutton" mat-button>Apply</button>
      <button (click)="closeDialog()" class="cancelbutton" mat-button>Cancel</button>
    </div>

  </div>
