import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareService } from '../../services/share.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiserviceService } from '../../services/apiservice.service';
import { NotificationService } from '../../services/notification.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { SubmitPropertyDialogComponent } from '../submit-property-dialog/submit-property-dialog.component';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit {
  disableFloo: boolean = false;
  disableUnit: boolean = false;
  finalize: boolean = false;
  mobile: boolean;

  constructor(private share: ShareService, private route: Router, public dialog: MatDialog, private routeAct: ActivatedRoute,
    private fnc: CommonfunctionService,  private notify: NotificationService) {
    this.share.header_obj = this;
    this.mobile = this.share.mobile;
    
  }

  openDialog() {
    
    let property_resource = this.share.block0_obj.property_access;
    if(!property_resource.PATCH){
      this.notify.notify('You are not authorized to submit property', 'warn');
      return;
    }

    if (this.share.dynamic_obj.form) {
      this.share.block3_obj.submitForm();
    }

    let dialogRef = this.dialog.open(SubmitPropertyDialogComponent, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: { parent: this }//optional
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  getFloor() {
    if (this.share.main_property_obj) {
      if (this.share.main_property_obj.selected_property.property_id) {
        this.disableFloo = false;
      }
    } else {
      this.disableFloo = true;
    }
    return this.disableFloo;
  }
  getunit() {
    if (this.share.floor_obj) {
      if (this.share.floor_obj.selected_floor) {
        this.disableUnit = false;
      }
    } else {
      this.disableUnit = true;
    }
    return this.disableUnit;
  }
  ngOnInit() {
  }

  navigate(path) {

    if (this.share.block0_obj && this.share.block0_obj.assignment_id) {
      if (path == 'property') {
        if (this.share.block0_obj) {
          this.finalize = false;
          this.share.block0_obj.selected_property = null;
          this.share.block0_obj.selected = null;
        }
        this.share.main_property_obj.selected_property.property_id = null
        if (this.share.floor_obj) {
          this.share.floor_obj.selected_floor = null;
        }
        this.share.loader.next(true);
        this.route.navigateByUrl('property/' + this.share.block0_obj.assignment_id);
        setTimeout(() => {
          this.share.dynamic_obj.onOptionsRequested('proList');
        }, 200);
      }
      if (path == 'floor') {
        if (this.share.main_property_obj.selected_property.property_id) {
          this.share.floor_obj.selected_floor = null
          this.share.dynamic_obj.onOptionsRequested('floor');
        }
      }
      if (path == 'unit' && this.share.floor_obj) {
        if (this.share.floor_obj.selected_floor) {
          this.share.dynamic_obj.onOptionsRequested('unit');
        }
      }
    }

  }

}
