import { Injectable } from '@angular/core';

@Injectable()
export class EncryptDecryptService {
    
    private rand_array = ['s', 'e', '4', 'z', 'x', 'f', 'a', 'k', 'l', 'y', 'n','6','1'];
    keys:string = 'QhdfV7@186lkgrf';

    public EncryptDecrypt(encrypt_key){
        this.keys = encrypt_key;
    }

    public mixKey(str:string) {
        this.keys += this.keys + str;
    }

    public encryptKey(text:string){
        var rand_index= Math.floor(Math.random()*10);
        var  rand_value = this.rand_array[rand_index];
         
         this.mixKey(text);
         var  str_final_char = rand_value;
         rand_index++; // one increment to avoid zero index
         for (var i = 0; i < text.length; i++) {
             var str       = text.substr(i,1);
             var str_value = str.charCodeAt(0) - rand_index;
             var str_chr   = String.fromCharCode(str_value);
             str_final_char += str_chr;
             
             var keychar      = this.keys.substr(i, 1);
             var keychr_value = keychar.charCodeAt(0) - rand_index;
             var key_chr      = String.fromCharCode(keychr_value);
                 str_final_char += key_chr;
        }
         return window.btoa(str_final_char); //Buffer.from(str_final_char, 'binary').toString('base64');
    }
    public decryptKey(text:string){

        var original_key = this.keys;
        var result = '';
    //    var decoed_text = Buffer.from(text, 'base64').toString('binary')
       var decoed_text = window.atob(text);
        var str_final_char = '';
        var key_final_char = '';
        // one increment to avoid zero index
        
        for (let i = 0; i < decoed_text.length; i++) {
            var str = decoed_text.substr(i,1);
            if (i == 0) {
                var rand_index =this.rand_array.indexOf(str);
                rand_index++;
                continue;
            }
            
            if (i % 2 != 0) {
              var str_value = str.charCodeAt(0) + rand_index;
              var str_chr   = String.fromCharCode(str_value);
               str_final_char += str_chr;
            } else {
              var keychr_value = str.charCodeAt(0) + rand_index;
              var key_chr      = String.fromCharCode(keychr_value);
                key_final_char += key_chr;
            }
        }
        var keyOriginalLen = original_key.length; //10
        var keyleng = key_final_char.length; //5
	  	if(keyOriginalLen < keyleng) {
            for(let k=0; k < original_key.length; k++) {
                if(original_key.substr(k,1) != key_final_char.substr(k,1)) {
                  console.log('Key not matched');
                  
                    return null;
                }
            }
        }else {
            for(let k=0; k < key_final_char.length; k++) {
              if(original_key.substr(k,1) != key_final_char.substr(k,1)) {
                console.log('Key not matched');
                    return null;
                }
            }
        }
        return str_final_char;
    }
  
}