<div class="dialog">
    <div><mat-toolbar class="tool"><span>Add description</span></mat-toolbar></div>
    <div class="m-container">
      <div id="img-container">
        <img id="i-def" src="{{imageURL}}" alt="">
      </div>
      <div><textarea class="newDesc" [(ngModel)]="description" placeholder="Type description">{{description}}</textarea></div>
      <div id="message">
          <mat-checkbox id="default" [(ngModel)]="isDefault" [checked]="isDefault"></mat-checkbox><span>Set this image as default</span>
      </div>
      <div class="b-middle">
          <button class="yes-btn" (click)="saveDialog()" mat-button>Save</button>
          <button class="no-btn" (click)="closeDialog()" mat-button>Cancel</button>
      </div>
    </div>
</div>
