<div *ngIf="!mobile">
    <div class="block" id="DynamicBlock">
        <div class="userDiv-m">
            <ng-container>
                <div class="userDetail">
                    <p class="sp1-m">Current Unit: </p>
                    <p *ngIf="selected_unit && selected_unit.uid" class="sp-m clmp">{{selected_unit.uid}}  <mat-icon  (click)="copyToClipboard(selected_unit.uid)" title="Copy Unit ID" svgIcon="copyfm" class="icon copy-icon"></mat-icon></p>
                </div>
            </ng-container>
        </div>
        <div class="button-block">
            <ng-container *ngFor="let group of group_custom_list_unit">
                <div *ngIf="group.status" (click)="openOptions('subGroups',group,false)" class="big-button-m" [ngClass]="{'active':isActive(group.id),'floor-style':group.id == 0, 'remark-style':isRerorkFloorGroup(group)}">
                    <div>
                        {{group.group_name}}
                    </div>
                    <div class="led">
                        <mat-icon class="indicator" [ngClass]="{'filled':isFilled(group)}" svgIcon="check"></mat-icon>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div *ngIf="mobile">
        <app-mobile-toolbar [back]="true" [nav_link]="'/unit'" [title]="'Unit groups'" [property_uid]="selected_property.property_id"
        [floor_name]="selected_floor.name" [unit_uid]="(selected_unit && selected_unit.uid)? selected_unit.uid : ''"></app-mobile-toolbar>

    <div class="list-container-mobile" id="pList">
        <div *ngIf="loader" class='loader-div'>
            <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
        </div>
        <div class="block">
            <div class="button-block">
                <ng-container *ngFor="let group of group_custom_list_unit">
                    <div *ngIf="group.status" (click)="openOptions('subGroups',group,true)" class="big-button-m" [ngClass]="{'active':isActive(group.id),'floor-style':group.id == 0, 'remark-style':isRerorkFloorGroup(group)}">
                        <div>
                            {{group.group_name}}
                        </div>
                        <div class="led">
                            <mat-icon class="indicator" [ngClass]="{'filled':isFilled(group)}" svgIcon="check"></mat-icon>
                        </div>
                    </div>
                </ng-container>
                <!-- <div [routerLink]="['/floor']" class="big-button-m floor-style">Floor</div>-->
            </div>
        </div>
    </div>
</div>
