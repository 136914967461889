import { Component, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ShareService } from '../../../services/share.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { CommonfunctionService } from '../../../services/commonfunction.service';

@Component({
  selector: 'app-floor-subgroup',
  templateUrl: './floor-subgroup.component.html',
  styleUrls: ['./floor-subgroup.component.css']
})
export class FloorSubgroupComponent implements OnInit {
mobile: boolean = false;
selected_property: string;
selected_group: any;
selected_floor:any;
selected_sub_group:any;
child_group_list: any;
dHeight:number;
@Output() notify: EventEmitter<string> = new EventEmitter<string>();
  constructor(private location: Location, private share:ShareService, private route: Router, private elementRef: ElementRef, private fnc: CommonfunctionService) {
    this.mobile = this.share.mobile;
    if(!this.share.block0_obj){
      this.route.navigateByUrl('/');
      return;
    }
    this.share.floor_subgroup_obj = this;
    this.selected_floor = this.share.floor_obj.selected_floor;
   }
  ngOnInit() {
    setTimeout(()=>{
      this.dHeight = this.share.heightService;
      let e = $(this.elementRef.nativeElement);
      e.find('#pList').css('height',this.dHeight);
    }, 10);

    this.selected_property = this.share.block0_obj.selected_property;
    this.selected_group = this.share.floor_group_obj.selected_group;
    this.child_group_list = this.selected_group.childrens;
  }
  backClicked(){
    this.location.back();
  }
  openOptions(v,mobile:boolean=false):void{
    if(mobile == false){
      if(this.share.dynamic_obj.form){
        this.share.block3_obj.submitForm();
      }
    }
    this.selected_sub_group = v;
    if(mobile){
      this.route.navigateByUrl('form');
    }else{
      this.notify.emit('form');
    }
   
  }
  isRerorkFloorGroup(group){
    
    let id = group.id;
    let status = false
    let reworkrecord = [];
    if(this.share.floor_obj){
      reworkrecord = this.share.floor_obj.rejectedFloorAttributeList;
    }
    
    let attributes = this.fnc.getChildArray('group_id',id,this.share.block0_obj.attribute_custom_list_floor);

    if(reworkrecord){
      for (let k = 0; k < reworkrecord.length; k++) {
        for (let j = 0; j < attributes.length; j++) {
          if (reworkrecord[k]) {
            if (reworkrecord[k].keyId == attributes[j].id) {
              status = true;
            }
          }
        }
      }
    }
     return status;
  }
}
