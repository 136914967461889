import { Injectable } from '@angular/core';

interface breadcrumbs {
  title : string,
  url? : string
}

@Injectable()
export class MainBreadcrumbService {
  public links : Array<breadcrumbs>  = [];
  private _title : string = '';
  private _count : number = 0;
  constructor() { 
  }

  clear() {
    this.links = [];
  }

  set addcrumb(crumb : breadcrumbs) {
    this.links.push(crumb);
  }

  set count(number : number) {
    this._count = number;
  }

  set title(title : string) {
    this._title = title;
  }

  get title() : string {
    return this._title;
  }

  get count() : number {
    return this._count;
  }

  get crumbs(): Array<breadcrumbs> {
    return this.links;
  }

  set mobiletitle(title : string) {
    this._title = title;
  }

  get mobiletitle() : string {
    return this._title;
  }

}
