<div class="dialog">
    <div>
        <mat-toolbar class="tool">
            <span>Add new value</span>
        </mat-toolbar>
    </div>
    <div class="center h-100p">
        <input type="text" class="form-input-l fx200" (input)="cleanError()" [(ngModel)]="searchTerm" />
    </div>
    <div class="message" *ngIf="err_msg">
        <span class="dang">{{err_msg}}</span>
    </div>
    <div class="p-3" *ngIf="part2 && searchTerm">
        <span>Associate "{{searchTerm}}" with:</span>
    </div>
    <div class="center p-3" *ngIf="part2">
        <mat-select class="form-select-md fx200" [(ngModel)]="selectedMaster">
            <mat-option [value]="data" *ngFor="let data of masterVals">{{getMasterLabel(data)}}</mat-option>
        </mat-select>
    </div>
    <div class="b-middle">
        <button (click)="confirm()" class="deletebutton fix" mat-button [disabled]="!selectedMaster && part2">Add</button>
        <button (click)="exit()" class="fix" mat-button>Cancel</button>
    </div>
</div>