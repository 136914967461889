import { Component, HostBinding , ChangeDetectorRef, HostListener} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { ShareService } from './services/share.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
// import { map, tap } from 'rxjs';
import * as fromRoot from './state/reducers';
import * as ApplicationActions from './state/application/actions';
import { ApiserviceService } from './services/apiservice.service';
import { EncryptDecryptService } from './encryptdecrypt.service';
import { NotificationService } from './services/notification.service';
import { CommonfunctionService } from './services/commonfunction.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  // @HostListener('window:message', ['$event'])
  // onMessage(e:any){
  //   if(e.origin  == environment.adminHost){
  //     let record = e.data;
  //     this.setUserDataSSO(record);
  //   }
  // }

  // mobile: boolean;
  watcher: Subscription;
  proceed: boolean = true;
  country_selected: boolean = false;
  mobile: boolean = false;
  $isLoggedIn: Observable<boolean>;
  @HostBinding('class.application') class = 'application';

  constructor(iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private media:  MediaObserver,
    private share: ShareService,
    private store: Store<fromRoot.State>, private api: ApiserviceService, private EncryptDecrypt: EncryptDecryptService,
    private notify: NotificationService,  private fnc: CommonfunctionService, private changeDetectorRef: ChangeDetectorRef,
    private route: Router) {
    iconRegistry.addSvgIcon("menu", sanitizer.bypassSecurityTrustResourceUrl("assets/icon/bottom/Menu.svg"))
      .addSvgIcon("property", sanitizer.bypassSecurityTrustResourceUrl("assets/icon/bottom/Property.svg"))
      .addSvgIcon("floor", sanitizer.bypassSecurityTrustResourceUrl("assets/icon/bottom/Floor.svg"))
      .addSvgIcon("unit", sanitizer.bypassSecurityTrustResourceUrl("assets/icon/bottom/unit.svg"))
      .addSvgIcon("poly", sanitizer.bypassSecurityTrustResourceUrl("assets/icon/line-path.svg"))
      .addSvgIcon("save", sanitizer.bypassSecurityTrustResourceUrl("assets/icon/bottom/save.svg"))
      .addSvgIcon("edit", sanitizer.bypassSecurityTrustResourceUrl("assets/icon/Edit.svg"))
      .addSvgIcon("Dubai", sanitizer.bypassSecurityTrustResourceUrl("assets/images/svgicons/Dubai.svg"))
      .addSvgIcon("Bahrain", sanitizer.bypassSecurityTrustResourceUrl("assets/images/svgicons/Bahrain.svg"))
      .addSvgIcon("Kuwait", sanitizer.bypassSecurityTrustResourceUrl("assets/images/svgicons/Kuwait.svg"))
      .addSvgIcon("Sharjah", sanitizer.bypassSecurityTrustResourceUrl("assets/images/svgicons/Sharjah.svg"))
      .addSvgIcon("Riyadh", sanitizer.bypassSecurityTrustResourceUrl("assets/images/svgicons/Riyadh.svg"))
      .addSvgIcon("Delhi", sanitizer.bypassSecurityTrustResourceUrl("assets/images/svgicons/Delhi.svg"))
      .addSvgIcon('copyfm', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/toolbox/copy.svg'))
      .addSvgIcon('pastefm', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/toolbox/paste.svg'))
      .addSvgIcon('movefm', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/move.svg'))
      .addSvgIcon('movef', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/move.svg'))
      .addSvgIcon('deletef', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/delete.svg'))
      .addSvgIcon('checkf', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/GreenCheck.svg'))
      .addSvgIcon('upf', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/up_arrow.svg'))
      .addSvgIcon('downf', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/down-arrow.svg'))
      .addSvgIcon('shuffle', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Shuffle_arrow.svg'))
      .addSvgIcon('editf', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Edit.svg'));

    this.share.appComp = this;
    if(environment.isLocalServe || this.api.ismobile){
      // if (localStorage.getItem('user_id') && localStorage.getItem('city_id')) {
      //   this.route.navigateByUrl('/home');
      // }
      // if (localStorage.getItem('user_id') && !localStorage.getItem('city_id')) {
      //     this.route.navigateByUrl('/country');
      // }
    }else{
      if (!localStorage.getItem('user_id') && !localStorage.getItem('user_token')) {
        let newwindow = window.open(`${environment.adminHost}/login?continue=${environment.baseURL}`, '_self');
        if (window.focus) {newwindow.focus()}

      }
      // else{
      //   if (localStorage.getItem('user_id') && localStorage.getItem('city_id')) {
      //     this.route.navigateByUrl('/home');
      //   }
      //   if (localStorage.getItem('user_id') && !localStorage.getItem('city_id')) {
      //       this.route.navigateByUrl('/country');
      //   }
      // }
    }
  }

  // isHandset$ = this.media
  // .asObservable()
  // .subscribe((change) => {
  //   change.forEach((item) => {
  //     if (item.mqAlias === 'xs' || item.mqAlias === 'sm') {
  //       this.mobile = true;
  //     }
  //   });
  // });

  ngOnInit(){
    // this.getGoogleMapKey();
    // this.$isLoggedIn = this.store.select(fromRoot.selectIsLoggedIn);
    // this.$isLoggedIn.subscribe((state:any)=>{
    //   if(!state){
    //     this.api.logout(false);
    //   }
    // })

    // this.mobile = this.api.ismobile;

    // document.body.addEventListener('click', () => this.extendLogout());
  }
  logIn(){
    this.store.dispatch(new ApplicationActions.LogIn());
  }
  // extendLogout(){
  //   this.store.dispatch(new ApplicationActions.ExtendLogoutTimer());
  // }


}
