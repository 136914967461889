import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, Validators, UntypedFormGroup, FormControl, UntypedFormBuilder, FormArray } from '@angular/forms';
// import { MatChipInputEvent } from '@angular/material/chips';
import { ShareService } from '../../../services/share.service';
import { NotificationService } from '../../../services/notification.service';
import { ApiserviceService } from '../../../services/apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { ImageEditorComponent } from '../../profile/image-editor/image-editor.component';
// import { EXIF } from '../../../../assets/exif.js'


/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_module_type_images_post</li>
* <li>estapi_module_type_assignment_name_properties_property_id_patch</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_unit_id_patch</li>
* <li>estapi_module_type_videos_post</li>
* </ol>
*
*/
const EXIF = window['EXIF'];
@Component({
  selector: 'app-photo-block',
  templateUrl: './photo-block.component.html',
  styleUrls: ['./photo-block.component.css']
})
export class PhotoBlockComponent implements OnInit {
  mobile: boolean = false;
  dHeight: number;
  imageForm: UntypedFormGroup;
  wait: boolean = false;
  selected_property: any;
  image_attr: any;
  video_attr: any;
  photoList: Array<any> = [];
  assignment_detail: any;
  property_detail: any = null;
  videoList: Array<any> = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  uploading: boolean = false;
  selected_group: any;
  group_id: any;
  attribute_list: any;
  all_attribute_list: any;
  rejected_attribute: any;



  @ViewChild('imageInput') imageInput;
  show: boolean;
  videoSource = "";
  isImageMandatory: boolean = false;
  isVideoMandatory: boolean = false;isHiddenImg: any;
  isHiddenVideo: any;
;
  constructor(private route: Router, public dialog: MatDialog, private share: ShareService, private elementRef: ElementRef, private _location: Location,
    private fb: UntypedFormBuilder, private fnc: CommonfunctionService, private api: ApiserviceService, private notify: NotificationService) {
    if (!this.share.block0_obj) {
      this.route.navigateByUrl('/');
      return;
    }
    this.property_detail = localStorage.getItem('property_detail') ? JSON.parse(localStorage.getItem('property_detail')) : '';
    // console.log(EXIF);
    if (this.share.block0_obj.entity_type == "property") {
      this.selected_group = this.share.block1_obj?.selected_group;
      this.group_id = this.selected_group?.id;
      this.all_attribute_list= this.share.block0_obj.attribute_custom_list;
      this.attribute_list = this.fnc.getChildArray('group_id', this.group_id, this.share.block0_obj.attribute_custom_list);
      this.rejected_attribute = this.share.block0_obj.rejectedAttributeList;
    }
    if (this.share.block0_obj.entity_type == "property_floor") {
      this.selected_group = this.share.floor_group_obj?.selected_group;
      this.group_id = this.selected_group?.id;
      this.all_attribute_list= this.share.block0_obj.attribute_custom_list_floor;
      this.attribute_list = this.fnc.getChildArray('group_id', this.group_id, this.share.block0_obj.attribute_custom_list_floor);
      this.rejected_attribute = this.share.floor_obj.rejectedFloorAttributeList;
    }
    if (this.share.block0_obj.entity_type == "property_unit") {
      this.selected_group = this.share.unit_group_obj?.selected_group;
      this.group_id = this.selected_group?.id;
      this.all_attribute_list= this.share.block0_obj.attribute_custom_list_unit;
      this.attribute_list = this.fnc.getChildArray('group_id', this.group_id, this.share.block0_obj.attribute_custom_list_unit);
      this.rejected_attribute = this.share.unit_obj.rejectedUnitAttributeList;
    }
    this.assignment_detail = JSON.parse(localStorage.getItem('assignment_detail'));
    this.mobile = this.share.mobile;
    this.selected_property = this.share.block0_obj.selected_property;
    // this.image_attr = this.fnc.getArrayValue('attribute_name', 'images', this.share.block0_obj.attribute_list);
    // this.video_attr = this.fnc.getArrayValue('attribute_name', 'videos', this.share.block0_obj.attribute_list);
    this.imageForm = this.fb.group({
      'image': [],
      'video': [],
      'description': []
    });
    this.freezAttribute();
    this.getImageList();
    this.getVideoList();
  }

  freezAttribute() {
    let is_old = false;
    if (this.property_detail) {
      if (this.property_detail.status != 1) is_old = true;
    }
    if ((this.selected_property.status == 4 || this.selected_property.status == 6) && is_old) {
      this.attribute_list.forEach((e, k) => {
        e.is_editable = false;
        if (this.fnc.getArrayValue('keyId', e.id, this.rejected_attribute)) {
          e.is_editable = true;
        }
      });
    }else{
      this.attribute_list.forEach((e, k) => {
        e.is_editable = true;
      });
    }
    this.checkIfImageVideoAttr();
  }

  checkIfImageVideoAttr(){
    if(this.group_id){
      this.attribute_list.forEach((e, k) => {
        if (e.data_type == 'images') {
          console.log(e);
          this.isImageMandatory =e.is_mandatory
          this.isHiddenImg = e.is_hidden
          this.image_attr=e
        }
        if(e.data_type == 'videos'){
          this.video_attr=e
          this.isVideoMandatory =e.is_mandatory;
          this.isHiddenVideo = e.is_hidden

        }
      });
    }else{
      this.all_attribute_list.forEach((e, k) => {
        if (e.name == 'images') {
          this.image_attr=e
        }
        if(e.name == 'videos'){
          this.video_attr=e
        }
      });
    }

  }

  getImageList() {
    if (this.image_attr) {
      let val = null;
      if (this.share.block0_obj.entity_type == 'property') {
        if (this.share.block0_obj.property_detail) {
          val = this.share.block0_obj.property_detail.data;
        }
      }
      if (this.share.block0_obj.entity_type == 'property_floor') {
        if (this.share.floor_obj.floor_detail) {
          val = this.share.floor_obj.floor_detail.data;
        }
      }
      if (this.share.block0_obj.entity_type == 'property_unit') {
        if (this.share.unit_obj.unit_detail) {
          val = this.share.unit_obj.unit_detail.data;
        }
      }
      if (val) {
        let img_data = val[this.image_attr.id];
        if (img_data) {
          let list = {};
          img_data.forEach((v) => {
            list = {
              imageName: 'ex Image',
              imageURL: v[0],
              id: Math.floor((Math.random() * 10000) + 100),
              loader: false,
              description: v[1],
              isDefault: v[2],
              lat: (v.geotag && (v.geotag.lat == undefined || v.geotag.lat == null)) ? null : v.geotag.lat,
              lon: (v.geotag && (v.geotag.lon == undefined || v.geotag.lon == null)) ? null : v.geotag.lon,
              make: (v.geotag && (v.geotag.make == undefined || v.geotag.make == null)) ? null : v.geotag.make,
              model: (v.geotag && (v.geotag.model == undefined || v.geotag.model == null)) ? null : v.geotag.model,
              dateTime: (v.geotag && (v.geotag.dateTime == undefined || v.geotag.dateTime == null)) ? null : v.geotag.dateTime,
            }
            this.photoList.push(list);
          });
        }
      }
    }
  }

  getVideoList() {
    if (this.video_attr) {
      let val = null;
      if (this.share.block0_obj.entity_type == 'property') {
        if (this.share.block0_obj.property_detail) {
          val = this.share.block0_obj.property_detail.data;
        }
      }
      if (this.share.block0_obj.entity_type == 'property_floor') {
        if (this.share.floor_obj.floor_detail) {
          val = this.share.floor_obj.floor_detail.data;
        }
      }
      if (this.share.block0_obj.entity_type == 'property_unit') {
        if (this.share.unit_obj.unit_detail) {
          val = this.share.unit_obj.unit_detail.data;
        }
      }
      if (val) {
        let vid_data = val[this.video_attr.id];
        if (vid_data) {
          let list = {};
          vid_data.forEach((v) => {
            list = {
              videoName: 'estVideo',
              videoThumbURL: '../../../assets/images/vid.png',
              videoURL: v[0],
              id: Math.floor((Math.random() * 10000) + 100),
              loader: false,
              description: v[2],
              isDefault: v[3]
            }
            this.videoList.push(list);
          });
        }
      }
    }

  }

  openDialog(type: string, info: any = {}): void {
    let dialogview;
    info.pObj = this;
    if (type == 'app-description' && !this.image_attr?.is_editable) {
      return;
    }
    if (type == 'app-description') {
      dialogview = DescriptionComponent
    }
    if (type == 'confirm-delete') {
      dialogview = ConfirmDelete
    }
    if (dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: '400px',
        panelClass: 'create-master-panel',
        data: info
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

/**
   * This is a funtion that use to attach a file and open crop option to edit image

   * @param event event is parameter
   * @returns return void
   */

 uploadExplorImage(event){
  if(this.photoList.length >= 10){
    this.notify.notify('Image cannot be uploaded more than 10', 'warn');
    return;
  }
  const dialogRef = this.dialog.open( ImageEditorComponent, {
    width:'800px',
    panelClass:'image-croper-dialog',
    data: {info: event, ratio: 6 / 4, size: 1080,  type: 'property'},
    disableClose:true
  })
  dialogRef.afterClosed().subscribe((result: FormData)=>{
    if(result){
      this.uploadImages(result)
    }
    this.imageInput.nativeElement.value = '';
  })
}
  uploadImages(formData: FormData) {
    // let fileList: FileList = event.target.files;
    // let formData: FormData = new FormData();
    if (formData) {
      // let file: File = fileList[0];
      // let pattern = /image-*/;
      // if (!file.type.match(pattern)) {
      //   return;
      // }
      let img_id = Math.floor((Math.random() * 10000) + 100);
      // adding image from list
      let img = {
        imageName: 'a',
        imageURL: 'assets/images/no-img.png',
        id: img_id,
        loader: true,
        description: 'Enter image description here',
        isDefault: false,
        lat: null,
        lon: null,
        make: null,
        model: null,
        dateTime: null
      };
      // let reader = new FileReader();
      // reader.onload = (event: any) => {
      //   img.imageURL = 'assets/images/prop_img.jpg';
      // }
      // reader.readAsDataURL(file);
      if (this.photoList.length > 0) {
        this.photoList.splice(0, 0, img);
      } else {
        this.photoList.push(img);
      }

      let myData: any;

      let latDegree;
      let latMinute;
      let latSecond;
      let latDirection;
      let latFinal;

      // Calculate longitude decimal
      let lonDegree;
      let lonMinute;
      let lonSecond;
      let lonDirection;
      let lonFinal;
      let imgid: any;
      let thisObj = this;

      // setTimeout(() => {
      //   this.photoList.forEach((e: any) => {
      //     if (e.lat == null || e.lon == null) {
      //       imgid = document.getElementById(e.id);
      //       if (imgid != null) {
      //         EXIF.getData(imgid, function () {
      //           myData = imgid;
      //           if (myData.exifdata == undefined || myData.exifdata == '{}') {
      //             e.lat = thisObj.selected_property.lat;
      //             e.lon = thisObj.selected_property.lon;
      //             e.model = null;
      //             e.make = null;
      //             e.dateTime = thisObj.fnc.formatDateTime(new Date());
      //           } else {
      //             // Calculate latitude decimal
      //             if (myData.exifdata.GPSLatitude == undefined && myData.exifdata.GPSLongitude == undefined) {
      //               e.lat = thisObj.selected_property.lat;
      //               e.lon = thisObj.selected_property.lon;
      //               e.model = null;
      //               e.make = null;
      //               e.dateTime = (myData.exifdata.DateTime == undefined) ? thisObj.fnc.formatDateTime(new Date()) : myData.exifdata.DateTime;
      //             } else {


      //               latDegree = myData.exifdata.GPSLatitude[0].numerator / myData.exifdata.GPSLatitude[0].denominator;
      //               latMinute = myData.exifdata.GPSLatitude[1].numerator / myData.exifdata.GPSLatitude[1].denominator;
      //               latSecond = myData.exifdata.GPSLatitude[2].numerator / myData.exifdata.GPSLatitude[2].denominator;
      //               latDirection = myData.exifdata.GPSLatitudeRef;
      //               latFinal = thisObj.convertDMSToDD(latDegree, latMinute, latSecond, latDirection);

      //               // Calculate longitude decimal
      //               lonDegree = myData.exifdata.GPSLongitude[0].numerator / myData.exifdata.GPSLongitude[0].denominator;
      //               lonMinute = myData.exifdata.GPSLongitude[1].numerator / myData.exifdata.GPSLongitude[1].denominator;
      //               lonSecond = myData.exifdata.GPSLongitude[2].numerator / myData.exifdata.GPSLongitude[2].denominator;
      //               lonDirection = myData.exifdata.GPSLongitudeRef;
      //               lonFinal = thisObj.convertDMSToDD(lonDegree, lonMinute, lonSecond, lonDirection);

      //               e.lat = latFinal;
      //               e.lon = lonFinal;
      //               e.model = myData.exifdata.Model;
      //               e.make = myData.exifdata.Make;
      //               e.dateTime = myData.exifdata.DateTime

      //             }
      //           }
      //         });
      //       }
      //     }
      //   })
      // }, 1000);

      // formData.append('file', file, file.name.replace(/["'\(\)]/g, ""));
      this.uploading = true;
      let index = this.fnc.getArrayindex('id', img.id, this.photoList);
      this.api.postFileUms('uploads/images?folder_name=property_image&city-code=' + this.api.city_code, formData)
        .subscribe(
          {next: (res: any) => {
          this.uploading = false;
          if (res.status == 201 && res.data) {
            if (index > -1) {
              this.photoList[index].imageURL = res.data;
              setTimeout(() => {
                this.photoList[index].loader = false;
                this.updateImageList();
              }, 10);
            } else {
              this.photoList[index].loader = false;
              this.notify.notify('Something went wrong!', 'warn');
            }
          }

        },
          error:(err) => {
            this.photoList[index].loader = false;
            // this.notify.notify(err.error.message, 'error');
            this.uploading = false;
          }}
        );
    }
  }
  public convertDMSToDD(degrees, minutes, seconds, direction) {

    let dd = degrees + (minutes / 60) + (seconds / 3600);
    if (direction == "S" || direction == "W") {
      dd = dd * -1;
    }

    return dd;
  }
  updateImageList() {
    let body = {}, record = {}, url;
    let img_data = {};
    let image_list = [];
    this.photoList.forEach((v) => {
      if (v.imageURL.length < 200) image_list.push({ '0': v.imageURL, '1': v.description, '2': v.isDefault, 'geotag': { "lat": v.lat, "lon": v.lon, "model": v.model, "make": v.make, "dateTime": v.dateTime } });
    })
    img_data[this.image_attr.id] = image_list;
    record["0"] = img_data;
    record[this.api.language] = {};
    body["data"] = record;
    body["updated_by"] = this.api.user_id;
    body["assignment_id"] = this.share.block0_obj.assignment_id;
    //url = 'deadmin/properties/'+this.share.block0_obj.selected_property.id;
    if (this.share.block0_obj.entity_type == 'property') {
      url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id;
    }
    if (this.share.block0_obj.entity_type == 'property_floor') {
      url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + "/floors/" + this.share.floor_obj.selected_floor_id;
    }
    if (this.share.block0_obj.entity_type == 'property_unit') {
      url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + "/floors/" + this.share.floor_obj.selected_floor_id + "/units/" + this.share.unit_obj.selected_unit.id;
    }
    this.api.patchEpsData(url, body)
      .subscribe({
        next: (d: any) => {
        this.notify.notify('Image Information Updated', 'success');
        if (this.share.block0_obj.entity_type == 'property') {
          this.share.block0_obj.getPropertyDetail();
        }
        if (this.share.block0_obj.entity_type == 'property_floor') {
          this.share.floor_obj.getFloorDetail();
        }
        if (this.share.block0_obj.entity_type == 'property_unit') {
          this.share.unit_obj.getUnitDetail();
        }
      },
      error:() => {
        this.notify.notify('Something went wrong', 'warn');
      }}
      );
  }

  uploadVideos(event) {
    if (this.videoList.length >= 5) {
      this.notify.notify('Video cannot be uploaded more than 5', 'warn');
      return;
    }
    let body = {}, record = {};
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /video-*/;
      if (!file.type.match(pattern)) {
        ('Not Video');
        return;
      }
      // adding image from list
      let vid = {
        videoName: file.name,
        videoThumbURL: '../../../assets/images/vid.png',
        videoURL: '../../../assets/images/vid.png',
        id: Math.floor((Math.random() * 10000) + 100),
        loader: true,
        description: 'Enter image description here',
        isDefault: false
      }

      if (this.videoList.length > 0) {
        this.videoList.splice(0, 0, vid);
      } else {
        this.videoList.push(vid);
      }

      formData.append('file', file, file.name.replace(/["'\(\)]/g, ""));
      this.uploading = true;
      this.api.postFileUms('uploads/videos?folder_name=property_video&city-code=' + this.api.city_code, formData, 'application/json')
        .subscribe({
          next:(data: any) => {
           let result =  JSON.parse(data)
          this.uploading = false;
          if (result.status &&  result.data) {
            let index = this.fnc.getArrayindex('id', vid.id, this.videoList);
            if (index !== null) {
              this.videoList[index].videoURL = result.data;
              setTimeout(() => {
                this.videoList[index].loader = false;
                this.updateVideoList();
              }, 10);
            } else {
              this.notify.notify('Something went wrong', 'warn');
            }
          }
        },
          error:() => {
            this.notify.notify('Something went wrong', 'error');
            this.uploading = false;
          }}
        );
    }
  }

  uploadVideoByUrl() {
    if (this.videoList.length >= 5) {
      this.notify.notify('Video cannot be uploaded more than 5', 'warn');
      return;
    }
    let e = $(this.elementRef.nativeElement);
    let vid_url = e.find("#video_url").val();
    vid_url = vid_url.replace(/["'\(\)]/g, "");
    if (vid_url) {
      let vid = {
        videoName: 'estVideo',
        videoThumbURL: '../../../assets/images/vid.png',
        videoURL: vid_url,
        id: Math.floor((Math.random() * 10000) + 100),
        loader: false,
        description: 'Enter image description here',
        isDefault: false
      }

      if (this.videoList.length > 0) {
        this.videoList.splice(0, 0, vid);
      } else {
        this.videoList.push(vid);
      }
      e.find("#video_url").val('');
      this.updateVideoList();
    }
  }

  updateVideoList() {
    let body = {}, record = {}, url;
    let vid_data = {};
    let video_list = [];
    this.videoList.forEach((v) => {
      if (v.videoURL.length < 200) video_list.push({ '0': v.videoURL, '1': v.description, '2': v.isDefault });
    })
    vid_data[this.video_attr.id] = video_list;
    // //console.log(data);
    record["0"] = vid_data;
    record[this.api.language] = {};
    body["data"] = record;
    body["updated_by"] = this.api.user_id;
    body["assignment_id"] = this.share.block0_obj.assignment_id;
    //url = 'deadmin/properties/'+this.share.block0_obj.selected_property.id;
    if (this.share.block0_obj.entity_type == 'property') {
      url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id;
    }
    if (this.share.block0_obj.entity_type == 'property_floor') {
      url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + "/floors/" + this.share.floor_obj.selected_floor_id;
    }
    if (this.share.block0_obj.entity_type == 'property_unit') {
      url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + "/floors/" + this.share.floor_obj.selected_floor_id + "/units/" + this.share.unit_obj.selected_unit.id;
    }
    this.api.patchEpsData(url, body)
      .subscribe({next: (d: any) => {
        this.notify.notify('Video Information Updated', 'success');
        if (this.share.block0_obj.entity_type == 'property') {
          this.share.block0_obj.getPropertyDetail();
        }
        if (this.share.block0_obj.entity_type == 'property_floor') {
          this.share.floor_obj.getFloorDetail();
        }
        if (this.share.block0_obj.entity_type == 'property_unit') {
          this.share.unit_obj.getUnitDetail();
        }
      },
        error:() => {
          this.notify.notify('Something went wrong', 'error');
        }});
  }
  @ViewChild('videoPlayer') videoplayer: any;
  pauseVideo(videoplayer)
{

  videoplayer.nativeElement.play();
  // this.startedPlay = true;
  // if(this.startedPlay == true)
  // {
     setTimeout(() =>
     {
      videoplayer.nativeElement.pause();
       if(videoplayer.nativeElement.paused)
      {
        this.show = !this.show;
      }
     }, 5000);
  // }
}
  remove(type, data, event): void {
    event.stopPropagation();
    if (!this.image_attr?.is_editable || !this.video_attr?.is_editable) {
      return;
    }
    if (type == 'vid') {
      const index = this.videoList.indexOf(data);
      if (index >= 0) {
        this.openDialog('confirm-delete', { video: data });
      }
    } else {
      const index = this.photoList.indexOf(data);
      if (index >= 0) {
        this.openDialog('confirm-delete', { image: data });
      }
    }

  }

  removeImage(id) {
    let imglist = this.fnc.getDeletedArray('id', id, this.photoList);
    this.photoList = imglist;
    this.updateImageList();
  }
  removeVideo(id) {
    let videolist = this.fnc.getDeletedArray('id', id, this.videoList);
    this.videoList = videolist;
    this.updateVideoList();
  }
  openExplorer(type) {
    if (type == 'image') {
      if(this.photoList.length >= 10){
        this.notify.notify('Image cannot be uploaded more than 10', 'warn');
        return;
      }
      let e = $(this.elementRef.nativeElement);
      e.find("#image_input").click();
    }
    if (type == 'video') {
      if (this.videoList.length >= 5) {
        this.notify.notify('Video cannot be uploaded more than 5', 'warn');
        return;
      }
      let e = $(this.elementRef.nativeElement);
      e.find("#video_input").click();
    }
  }

  backClicked() {
    if (this.share.block0_obj.entity_type == 'property') {
      this.route.navigateByUrl('groups');
    } else if (this.share.block0_obj.entity_type == 'property_floor') {
      this.route.navigateByUrl('floorGroup');
    } else {
      this.route.navigateByUrl('unitGroup');
    }
  }




  ngOnInit() {
    setTimeout(() => {
      this.dHeight = (this.share.heightService - 45);
      let e = $(this.elementRef.nativeElement);
      e.find('#pList').css('height', this.dHeight);
    }, 10);
  }


}
@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent {
  imageURL: string;
  description: string;
  isDefault: boolean;
  imageList: any;
  constructor(public dialogRef: MatDialogRef<DescriptionComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fnc: CommonfunctionService) {

  }
  ngOnInit() {
    this.imageURL = this.data.image.imageURL;
    this.description = this.data.image.description;
    this.isDefault = this.data.image.isDefault;
    this.imageList = this.data.pObj.photoList;
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  saveDialog(): void {
    //save information here
    this.data.image.description = this.description;
    this.data.image.isDefault = this.isDefault;
    if (this.isDefault) {
      let index = this.fnc.getArrayindex('id', this.data.image.id, this.imageList);
      this.imageList.forEach((v, k) => {
        if (k != index) v.isDefault = false;
      });
    }
    this.data.pObj.updateImageList();
    this.dialogRef.close();
  }

}

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDelete {
  mediaId: number;
  constructor(public dialogRef: MatDialogRef<ConfirmDelete>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit() {
    if (this.data.image) {
      this.mediaId = this.data.image.id;
    } else if (this.data.video) {
      this.mediaId = this.data.video.id;
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  delete(id): void {
    if (this.data.image) {
      this.data.pObj.removeImage(id);
    } else if (this.data.video) {
      this.data.pObj.removeVideo(id);
    }
    this.dialogRef.close();
  }

}
