import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as $ from 'jquery';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-2';
import { ApiserviceService } from '../services/apiservice.service';
import { CommonfunctionService } from '../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { CustomValidator } from './main-property/customValidator';
import { ImageEditorComponent } from './profile/image-editor/image-editor.component';
/**
*
* <strong>List of API using</strong>
* <ol>
* <li>umsapi_language_get</li>
* <li>umsapi_user_user_id_post</li>
* <li>umsapi_place_get</li>
* <li>estapi_module_type_images_post</li>
* <li>umsapi_user_user_id_patch</li>
* </ol>
*
*/

@Component({
  selector: 'profile-dialog',
  templateUrl: './profile.component.html',
  styleUrls: ['./main.component.css']
})
export class ProfileComponent {
  user_detail: any;
  language_list = [];
  city_list = [];
  loader: boolean = false;
  loader1: boolean = false;
  change: boolean = false;

  profileForm: FormGroup;
  changePasswordForm:FormGroup;

  @ViewChild('imageInput') imageInput;
  profile_image: string;

  SearchCountryField = SearchCountryField;
  cuntry: string = 'us';
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Bahrain,
    CountryISO.India,
    CountryISO.Kuwait,
    CountryISO.SaudiArabia,
    CountryISO.Switzerland,
    CountryISO.UnitedKingdom,
    CountryISO.UnitedStates,
  ];
  @Output() close = new EventEmitter
  constructor(private api: ApiserviceService, private fnc: CommonfunctionService,
    private elementRef: ElementRef, private notify: NotificationService, private fb: FormBuilder,
    public dialogRef: MatDialogRef<ProfileComponent>,
    private dialog: MatDialog
    ) {
    this.getLanguageList();
    this.user_detail = JSON.parse(localStorage.getItem('user_info'));
    let phone_number = (this.user_detail?.contact) ? this.user_detail.contact.split('-')[1] : '';
    this.api.profile_image.subscribe((img:string)=>{
      if(img){
         this.profile_image = img;
      }
    })
    this.profileForm = this.fb.group({
      'fname': [this.user_detail.fname, Validators.compose([Validators.required, Validators.maxLength(45), Validators.minLength(2), Validators.pattern('^[a-zA-Z ]*$')])],
      'lname': [this.user_detail.lname, Validators.compose([Validators.required, Validators.maxLength(45), Validators.minLength(2), Validators.pattern('^[a-zA-Z ]*$')])],
      'email': [{ value: this.user_detail.email, disabled: true }],
      'contact': [this.user_detail.contact, Validators.required],
      'city': [this.user_detail.country, Validators.required],
      'language': [this.user_detail.language, Validators.required],
      'image': [this.profile_image],

    }
    );
    this.changePasswordForm = this.fb.group({
      'oldPassword':['', Validators.required],
      'password': [null, Validators.compose([
        Validators.required,
        CustomValidator.patternValidator(/\d/, { hasNumber: true }),
        CustomValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidator.patternValidator(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/, { hasSpecialCharacters: true }),
        Validators.minLength(8), Validators.maxLength(20)
        ])
      ],
      'confirmPassword':['', Validators.required],
    },{
      validator: CustomValidator.passwordMatchValidator
    })
    setTimeout(() => { this.profileForm.get('contact')?.setValue(phone_number) }, 150);
  }

  getLanguageList() {
    this.api.getUmsData('language')
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            res.data.forEach(element => {
              if (element.status == 1) {
                this.language_list.push(element);
              }
            });
          }
        }
      );
  }

  changePass() {
    this.change = !this.change;
  }

  /**
   * This is a function for change password
   * @returns
   */
  changePassword() {
    var body = {
      "user_id": this.api.user_id,
      "new_password": this.changePasswordForm.value.password,
      "old_password": this.changePasswordForm.value.oldPassword
    }
    this.api.postUmsData('change-password', body).subscribe(
     {next: (res: any) => {
      if (res && res.status == 201) {
        this.notify.notify(res.message, "success", 5000);
        this.dialogRef.close();
      }
      else {
        this.notify.notify(res.message, "warn", 5000);
      }
    }, error:(err) => {
      // this.notify.notify(err.error.message, 'error', 5000)
    }})
  }


  ngOnInit() {
    this.getCityList();
  }

  getCityList() {
    this.api.getUmsData('place')
      .subscribe(
          {
            next: (res: any) => {
            if(res.status == 200){
              res.data.forEach(element => {
                if (element.status == 1) {
                  this.city_list.push(element);
                }
              });
            }

          }, error:(err)=>{

          }
        }
      );
  }

 /**
   * This is a funtion that use to attach a file and open crop option to edit image

   * @param event event is parameter
   * @returns return void
   */

  uploadExplorImage(event){
    const dialogRef = this.dialog.open( ImageEditorComponent, {
      width:'800px',
      panelClass:'image-croper-dialog',
      data: {info: event, ratio: 1 / 1, size: 512, type: 'profile'},
      disableClose:true
    })
    dialogRef.afterClosed().subscribe((result: FormData)=>{
      if(result){
        this.uploadImage(result)
      }
      this.imageInput.nativeElement.value = '';
    })
  }

  /**
 * This is a funtion that use to update profile image

 * @param {FormData} formData event is parameter
 * @returns return void
 */
  uploadImage(formData:FormData) {
    this.loader = true;

    if (formData) {
      this.api.postFileUms(`profile-image?user_id=${this.api.user_id}`, formData)
        .subscribe({next: (res: any) => {
          this.loader = false;
         if(res && res.status == 201 && res.data){
          this.profileForm.patchValue({
            image: res.data
          })
          this.notify.notify(res.message, 'success',5000)
          localStorage.setItem('profile_image', res.data);
          this.api.profile_image.next(res.data);
          this.api.getUserDetail(this.api.user_id);
         }

        },
          error: (err) => {
            this.loader = false;
          }}
        );
    }
  }
  openExplorer(type) {
    if (type == 'image') {
      let e = $(this.elementRef.nativeElement);
      e.find("#image_input").click();
    }
  }
  saveUserdetail(e): void {
    let user_name ;
    this.loader1 = true;
    let lang_code = e.value.language;
    let lang_detail = this.fnc.getArrayValue('language_code', lang_code, this.api.language_list);

   let attribute   ={};
   attribute[1]={"first_name": e.value.fname, "last_name": e.value.lname }
  let number = e.value.contact.e164Number.split(e.value.contact.dialCode)[1]
    let body = {
      "attributes": attribute,
      "phone_number": e.value.contact.dialCode+'-'+number,
      "gender": e.value.gender,
      "nationality": e.value.city,
      "profile_image": this.profile_image
    }
    this.api.patchUmsData(`user?updated_by=${this.api.user_id}&id=${this.api.user_id}`, body)
      .subscribe(
     {  next: (res: any) => {
        this.loader1 = false;
        if (res && res.status == 201) {
          this.api.getUserDetail(this.api.user_id);
          this.notify.notify(res.message, 'success',5000);
          this.close.emit()
        }   else {
          this.notify.notify(res.message, 'warn', 5000);

        }
      },
      error:(err) => {
        this.loader1 = false;
      }
      })
  }
  closeDialog() {
    this.dialogRef.close();
  }

}
