<div *ngIf="!mobile" class="rel">
  <ngx-loading [show]="mainLoader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
  <div class="button-block" id="DynamicBlock">
    <!-- <popover-content #myPopover placement="bottom" [animation]="true" [closeOnClickOutside]="true">
      <div class="rejectmessage">{{rejectmessage}}</div>
    </popover-content> -->

    <!---select language-->
    <div class="top">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon class="lang-icon">translate</mat-icon>
        <b class="badge bg-back up">{{selected_langauge}}</b>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let lang of language_list" (click)="changeFormLanguage(lang.language_id)">
          <span>{{lang.name}}</span>
        </button>
      </mat-menu>
    </div>

    <form id="property_form" [formGroup]="propertyForm" (ngSubmit)="createProperty(propertyForm.getRawValue())">
      <!-- form web template start -->
      <ng-template #formTemplate let-attrib="attrib" let-parent="parent">
        <input type="radio" id="radio_{{attrib.id}}_1" value="on" name="radio_{{attrib.id}}"
          *ngIf="attrib.mutual.length > 0" (change)="showChild(attrib,$event,1)" />

        <span class="df" id="span_{{attrib.id}}"><span class="attr-label" title="{{attrib.label}}">{{attrib.label}}</span>
          <!-- information tooltip start -->
          <span *ngIf="isRejected(attrib.id)" (click)="setRejectedMessage(attrib.id)"
            class="error"  matTooltip={{rejectmessage}}><i class="material-icons">error</i></span>
          <!-- information tooltip end -->
        </span>

        <input class="form-control input-sm" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
          type="number"
          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
          *ngIf="attrib.data_type == 'decimal-input' || attrib.data_type == 'number-input'">

        <input class="form-control input-sm" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}" type="text"
          *ngIf="attrib.data_type == 'string-input'">
        <!-- <input class="form-control input-sm" id="input_{{attrib.id}}" [min]="min_date"  [max]="max_date" formControlName="input_{{attrib.id}}" type="date"
          *ngIf="attrib.data_type == 'date'"> -->
          <div class="ml-10 d-flex align-items-center flex-column"  *ngIf="attrib.data_type == 'date'">
            <!-- <mat-form-field> -->
            <span class="d-flex align-items-center position-relative " (click)="focusPicker(picker1)">
                <input matInput maxlength="10"  [min]="min_date" #datePickerValue
                formControlName="input_{{attrib.id}}" readonly
                placeholder="dd-mm-yyyy" [max]="max_date"  class="ng-trim-ignore form-select-l"
                id="input_{{attrib.id}}"
                style="padding :8px 8px !important;width: 100%;" [matDatepicker]="picker1"
                >
                <span class="position-absolute righ-0">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1 disabled="false"></mat-datepicker>
              </span>
            </span>
            <mat-hint style="width: 100%;padding-left: 10px;">DD-MM-YYYY</mat-hint>
        </div>
        <mat-select class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
          *ngIf="attrib.data_type == 'year'">
          <mat-option *ngFor="let year of years" value={{year}}>{{year}}</mat-option>
        </mat-select>
        <mat-select class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
          *ngIf="attrib.data_type == 'month'">
          <mat-option *ngFor="let month of month" value="{{month.id}}">{{month.name}}</mat-option>
        </mat-select>

        <ng-container *ngIf="!parent">
          <div *ngIf="attrib.data_type == 'single-select'" (click)="openMultiSelect(attrib,parent)"
          [ngClass]="{'disabled-div':!attrib.is_editable}" class="lnk minw fixed-in-1">
            <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
             [value]="getSelectedValues(attrib, true)">
            </mat-select>
            <output  disabled="disabled" class="form-control input-sm minw lnk select-danger-color"
              id="input_custom_{{attrib.id}}"></output>
          </div>

          <div *ngIf="attrib.data_type == 'multi-select'" (click)="openMultiSelect(attrib,parent)"
          [ngClass]="{'disabled-div':!attrib.is_editable}" class="lnk minw fixed-in-1">
            <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
            [value]="getSelectedValues(attrib)">
            </mat-select>
            <output type="text" disabled="disabled" class="form-control input-sm minw lnk select-danger-color"
              id="input_custom_{{attrib.id}}"></output>
          </div>
        </ng-container>

        <ng-container *ngIf="parent">
          <!-- <mat-select (click)="getMasterValues(attrib.master_id)" (selectionChange)="changeChild($event.value,attrib)"
            class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
            *ngIf="attrib.data_type == 'single-select'">
            <div class="pad5 rel custom">
              <div class="fixed-in">
                <input formControlName="search_{{attrib.id}}" class="form-control input-sm minw fixed-in" type="text"
                  placeholder="Search" (keyup)="find($event,attrib)" (keydown)="$event.stopPropagation()" />
              </div>
              <mat-option *ngIf="newValue(attrib, searchTerm)" (click)="addNewMasterValue(searchTerm,attrib,parent)"
                value="addNewOption">Add "{{searchTerm}}"</mat-option>
              <mat-option *ngFor="let master of getList(attrib,searchTerm,parent)" value="{{master.id}}">
                {{master.label}}</mat-option>
            </div>
          </mat-select> -->
          <div *ngIf="attrib.data_type == 'single-select'" (click)="openMultiSelect(attrib,parent)"
            [ngClass]="{'disabled-div':!attrib.is_editable}" class="lnk minw fixed-in-1">
              <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
                disabled="disabled" [value]="getSelectedValues(attrib, true)">
              </mat-select>
              <output disabled="disabled" class="form-control input-sm minw lnk select-danger-color"
                id="input_custom_{{attrib.id}}"></output>
            </div>



          <div *ngIf="attrib.data_type == 'multi-select'" (click)="openMultiSelect(attrib,parent)"
          [ngClass]="{'disabled-div':!attrib.is_editable}" class="lnk minw fixed-in-1">
            <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
             [value]="getSelectedValues(attrib)">
            </mat-select>
            <output disabled="disabled" class="form-control input-sm minw lnk select-danger-color"
              id="input_custom_{{attrib.id}}"></output>
          </div>

        </ng-container>

        <ng-container *ngIf="attrib.data_type == 'toggle'">
          <label [ngClass]="{'disabled-div':!attrib.is_editable}" class="switch" id="div_{{attrib.id}}_1">
            <input type="checkbox" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}" class="chkBox"
              (change)="checkChild(attrib,$event,'checkbox')">
            <span class="slider round" id="toggle_{{attrib.id}}">
              <div class="toggle-container">
                <span class="text">Yes</span>
                <span class="text">No</span>
              </div>
            </span>
          </label>
        </ng-container>
        <mat-select class="form-select-md" id="unit_{{attrib.id}}" formControlName="unit_{{attrib.id}}"
          *ngIf="attrib.measure_unit">
          <mat-option *ngFor="let unit of getMeasureUnit(attrib.measure_type)" value="{{unit}}">{{unit}}</mat-option>
        </mat-select>
      </ng-template>

      <ng-template #childElement let-dep="dep" , let-parent="parent">
        <div [ngClass]="[dep.mutual.length > 0 ? 'field-dep-mutual' : 'field-dep']">
          <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:dep,parent:parent}"></ng-container>
        </div>
        <!-- mutual under child start -->
        <div *ngIf="dep.mutual.length > 0" class="mutual-child border">
          <input type="radio" id="radio_{{dep.id}}_2" name="radio_{{dep.id}}" (change)="showChild(dep,$event,2)" />
          <div>
            <div id="div_{{dep.id}}_2" class="disabled-div">
              <div *ngFor="let mt of dep.mutual" class="field-dep">
                <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:mt,parent:false}"></ng-container>
              </div>
            </div>
          </div>
        </div>

      </ng-template>


      <div *ngFor="let attrib of customize_attribute">
        <ng-container *ngIf="attrib.status && !attrib.is_hidden && attrib.data_type != 'table'">
          <div class="form-group box1">
            <div [ngClass]="[attrib.mutual.length ? 'field-mutual' : 'field']">
              <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:attrib,parent:false}"></ng-container>
            </div>
            <!-- Child attributes start -->
            <div *ngIf="attrib.child.length >= 1" id="child_{{attrib.id}}">
              <div *ngFor="let dep of attrib.child">
                <ng-container *ngTemplateOutlet="childElement;context:{dep:dep,parent:attrib}"></ng-container>
              </div>
            </div>
            <!-- Child attributes end -->

            <!-- Mutual attributes start -->
            <div *ngIf="attrib.mutual.length >= 1" class="mutual-div">
              <input type="radio" value="off" id="radio_{{attrib.id}}_2" name="radio_{{attrib.id}}"
                (change)="showChild(attrib,$event,2)" />
              <div id="div_{{attrib.id}}_2" class="disabled-div">
                <div *ngFor="let dep of attrib.mutual" class="field">
                  <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:dep,parent:false}"></ng-container>
                </div>
              </div>
            </div>
            <!-- Mutual attributes end -->
          </div>

          <!-- attributtes without child and dependent attribute end  -->

        </ng-container>
        <ng-container *ngIf="attrib.status && !attrib.is_hidden && attrib.data_type == 'table'">
          <div class="form-group box1">
            <div>
              <label class="label-text">{{attrib.label}}</label>
              <!-- information tooltip start -->
              <span *ngIf="isRejected(attrib.id)" (click)="setRejectedMessage(attrib.id)"
                class="error"  matTooltip={{rejectmessage}}><i class="material-icons">error</i></span>
              <!-- information tooltip end -->
            </div>
            <div class="master-container" *ngFor="let masterval of getMasterById(attrib.master_id)">
              <div class="master-field" *ngIf="masterval">
                <label>{{masterval.label}}</label>
                <ng-container *ngFor="let child of attrib.child">
                  <ng-container *ngIf="child.status && !child.is_hidden">
                    <div class="animat"  *ngIf="child.data_type == 'decimal-input' || child.data_type == 'number-input'">
                      <label class="animat_label" for="input_{{masterval.id}}_{{child.id}}">{{child.label}}</label>
                      <input class="form-control input-sm animat_input" type="number" placeholder=" "
                        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
                        id="input_{{masterval.id}}_{{child.id}}" formControlName="input_{{masterval.id}}_{{child.id}}"
                       >
                    </div>
                   <div class="animat" *ngIf="child.data_type == 'string-input'">
                    <label class="animat_label" for="input_{{masterval.id}}_{{child.id}}">{{child.label}}</label>
                    <input class="form-control input-sm animat_input" type="text" id="input_{{masterval.id}}_{{child.id}}" placeholder=" "
                    formControlName="input_{{masterval.id}}_{{child.id}}" >
                   </div>
                   <div class="animat" *ngIf="child.data_type == 'single-select'">
                    <label class="animat_label" for="input_{{masterval.id}}_{{child.id}}">{{child.label}}</label>
                    <mat-select class="form-select-md animat_input" id="input_{{masterval.id}}_{{child.id}}" placeholder=" "
                      formControlName="input_{{masterval.id}}_{{child.id}}">
                      <mat-option *ngFor="let master of getMasterById(child.master_id)" value="{{master.id}}">
                        {{master.label}}</mat-option>
                    </mat-select>
                   </div>
                  <div class="animat" *ngIf="child.data_type == 'multi-select'">
                    <label class="animat_label" for="input_{{masterval.id}}_{{child.id}}">{{child.label}}</label>
                    <mat-select class="form-select-md animat_input" id="input_{{masterval.id}}_{{child.id}}" placeholder=""
                    formControlName="input_{{masterval.id}}_{{child.id}}" multiple>
                    <mat-option *ngFor="let master of getMasterById(child.master_id)" value="{{master.id}}">
                      {{master.label}}</mat-option>
                  </mat-select>
                  </div>

                    <label class="switch" *ngIf="child.data_type == 'toggle'">
                      <input type="checkbox" id="input_{{masterval.id}}_{{child.id}}"
                        formControlName="input_{{masterval.id}}_{{child.id}}" class="chkBox" checked>
                      <span class="slider round">
                        <div class="toggle-container">
                          <span class="text">Yes</span>
                          <span class="text">No</span>
                        </div>
                      </span>
                    </label>
                    <!-- <input class="form-control input-sm" type="date" [min]="min_date" [max]="max_date" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'date'"> -->
                      <div class="ml-10 d-flex align-items-center flex-column"  *ngIf="child.data_type == 'date'">
                        <!-- <mat-form-field> -->
                        <span class="d-flex align-items-center mx-2 position-relative " (click)="focusPicker(picker1)">
                            <input matInput maxlength="10"  [min]="min_date" #datePickerValue
                            formControlName="input_{{masterval.id}}_{{child.id}}" readonly
                            placeholder="dd-mm-yyyy" [max]="max_date"  class="ng-trim-ignore form-select-l"
                            id="input_{{masterval.id}}_{{child.id}}"
                            style="padding :8px 8px !important;width: 100%;" [matDatepicker]="picker1"
                            >
                            <span class="position-absolute righ-0">
                              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                              <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                          </span>
                        </span>
                        <mat-hint style="width: 100%;padding-left: 10px;">DD-MM-YYYY</mat-hint>
                    </div>
                    <mat-select placeholder="{{child.label}}" class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'year'">
                      <mat-option *ngFor="let year of years" value={{year}}>{{year}}</mat-option>
                    </mat-select>
                    <mat-select placeholder="{{child.label}}" class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                      formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'month'">
                      <mat-option *ngFor="let month of month" value="{{month.id}}">{{month.name}}</mat-option>
                    </mat-select>
                    <mat-select class="form-select-md" id="unit_{{masterval.id}}_{{child.id}}"
                      formControlName="unit_{{masterval.id}}_{{child.id}}" *ngIf="child.measure_unit">
                      <mat-option *ngFor="let unit of getMeasureUnit(child.measure_type)" value="{{unit}}">{{unit}}
                      </mat-option>
                    </mat-select>
                  </ng-container>
                </ng-container>

              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- using hidden button to submit the form hidden button  -->
      <input style="display: none" id="property_form_btn" type="submit" name="submit" />
    </form>


  </div>
</div>


<div *ngIf="mobile" class="rel">
  <ngx-loading [show]="mainLoader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
  <app-mobile-toolbar [back]="true" [title]="group_name" [property_uid]="selected_property.property_id"
    [customClickAction]="true" [customObj]="this"></app-mobile-toolbar>
  <div class="list-container-mobile" id="pList">

    <!---select language-->
    <div class="top">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon class="lang-icon">translate</mat-icon>
        <b class="badge bg-back up">{{selected_langauge}}</b>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let lang of language_list" (click)="changeFormLanguage(lang.language_id)">
          <span>{{lang.name}}</span>
        </button>
      </mat-menu>
    </div>


    <div class="button-block">
      <!-- <popover-content #myPopover placement="bottom" [animation]="true" [closeOnClickOutside]="true">
        <div class="rejectmessage">{{rejectmessage}}</div>
      </popover-content> -->
      <div class="v-space"></div>
      <form id="property_form" [formGroup]="propertyForm" (ngSubmit)="createProperty(propertyForm.getRawValue())">

        <!-- form web template start -->
        <ng-template #formTemplate let-attrib="attrib" let-parent="parent">
          <input type="radio" id="radio_{{attrib.id}}_1" value="on" name="radio_{{attrib.id}}"
            *ngIf="attrib.mutual.length > 0" (change)="showChild(attrib,$event,1)" />

          <span class="df" id="span_{{attrib.id}}"><span class="attr-label" title="{{attrib.label}}">{{attrib.label}}</span>
            <!-- information tooltip start -->
            <span *ngIf="isRejected(attrib.id)" (click)="setRejectedMessage(attrib.id)"
              class="error"  matTooltip={{rejectmessage}}><i class="material-icons">error</i></span>
            <!-- information tooltip end -->
          </span>

          <input class="form-control input-sm" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
            type="number"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
            *ngIf="attrib.data_type == 'decimal-input' || attrib.data_type == 'number-input'">

          <input class="form-control input-sm" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
            type="text" *ngIf="attrib.data_type == 'string-input'">
          <!-- <input class="form-control input-sm" id="input_{{attrib.id}}" [min]="min_date" [max]="max_date" formControlName="input_{{attrib.id}}"
            type="date" *ngIf="attrib.data_type == 'date'"> -->
            <div class="ml-10 d-flex align-items-center flex-column"  *ngIf="attrib.data_type == 'date'">
              <!-- <mat-form-field> -->
              <span class="d-flex align-items-center mx-2 position-relative " (click)="focusPicker(picker1)">
                  <input matInput maxlength="10" [min]="min_date" #datePickerValue
                  formControlName="input_{{attrib.id}}" readonly
                  placeholder="dd-mm-yyyy" [max]="max_date"  class="ng-trim-ignore form-select-l"
                  id="input_{{attrib.id}}"
                  style="padding :8px 8px !important;width: 100%;" [matDatepicker]="picker1"
                  >
                  <span class="position-absolute righ-0">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                </span>
              </span>
              <mat-hint style="width: 100%;padding-left: 10px;">DD-MM-YYYY</mat-hint>
          </div>
          <mat-select class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
            *ngIf="attrib.data_type == 'year'">
            <mat-option *ngFor="let year of years" value={{year}}>{{year}}</mat-option>
          </mat-select>
          <mat-select class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
            *ngIf="attrib.data_type == 'month'">
            <mat-option *ngFor="let month of month" value="{{month.id}}">{{month.name}}</mat-option>
          </mat-select>

          <ng-container *ngIf="!parent">


            <div *ngIf="attrib.data_type == 'single-select'" (click)="openMultiSelect(attrib,parent)"
            [ngClass]="{'disabled-div':!attrib.is_editable}" class="lnk minw fixed-in-1">
              <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
                disabled="disabled" [value]="getSelectedValues(attrib, true)">
              </mat-select>
              <output disabled="disabled" class="form-control input-sm minw lnk select-danger-color"
                id="input_custom_{{attrib.id}}"></output>
            </div>


            <div *ngIf="attrib.data_type == 'multi-select'" (click)="openMultiSelect(attrib,parent)"
            [ngClass]="{'disabled-div':!attrib.is_editable}" class="lnk minw fixed-in-1">
              <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
                disabled="disabled" [value]="getSelectedValues(attrib)">
              </mat-select>
              <output disabled="disabled" class="form-control input-sm minw lnk select-danger-color"
                id="input_custom_{{attrib.id}}"></output>
            </div>

          </ng-container>

          <ng-container *ngIf="parent">
          <!-- <mat-select (click)="getMasterValues(attrib.master_id)" (selectionChange)="changeChild($event.value,attrib)"
            class="form-select-md" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}"
            *ngIf="attrib.data_type == 'single-select'">
            <div class="pad5 rel custom">
              <div class="fixed-in">
                <input formControlName="search_{{attrib.id}}" class="form-control input-sm minw fixed-in" type="text"
                  placeholder="Search" (keyup)="find($event,attrib)" (keydown)="$event.stopPropagation()" />
              </div>
              <mat-option *ngIf="newValue(attrib, searchTerm)" (click)="addNewMasterValue(searchTerm,attrib,parent)"
                value="addNewOption">Add "{{searchTerm}}"</mat-option>
              <mat-option *ngFor="let master of getList(attrib,searchTerm,parent)" value="{{master.id}}">
                {{master.label}}</mat-option>
            </div>
          </mat-select> -->
          <div *ngIf="attrib.data_type == 'single-select'" (click)="openMultiSelect(attrib,parent)"
            [ngClass]="{'disabled-div':!attrib.is_editable}" class="lnk minw fixed-in-1">
              <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
                disabled="disabled" [value]="getSelectedValues(attrib, true)">
              </mat-select>
              <output disabled="disabled" class="form-control input-sm minw lnk select-danger-color"
                id="input_custom_{{attrib.id}}"></output>
            </div>


          <div *ngIf="attrib.data_type == 'multi-select'" (click)="openMultiSelect(attrib,parent)"
          [ngClass]="{'disabled-div':!attrib.is_editable}" class="lnk minw fixed-in-1">
            <mat-select formControlName="input_{{attrib.id}}" id="input_{{attrib.id}}" class="hidden lnk"
              disabled="disabled" [value]="getSelectedValues(attrib)">
            </mat-select>
            <output disabled="disabled" class="form-control input-sm minw lnk select-danger-color"
              id="input_custom_{{attrib.id}}"></output>
          </div>

        </ng-container>

          <ng-container *ngIf="attrib.data_type == 'toggle'">
            <label [ngClass]="{'disabled-div':!attrib.is_editable}" class="switch" id="div_{{attrib.id}}_1">
              <input type="checkbox" id="input_{{attrib.id}}" formControlName="input_{{attrib.id}}" class="chkBox"
                (change)="checkChild(attrib,$event,'checkbox')">
              <span class="slider round" id="toggle_{{attrib.id}}">
                <div class="toggle-container">
                  <span class="text">Yes</span>
                  <span class="text">No</span>
                </div>
              </span>
            </label>
          </ng-container>
          <mat-select class="form-select-md" id="unit_{{attrib.id}}" formControlName="unit_{{attrib.id}}"
            *ngIf="attrib.measure_unit">
            <mat-option *ngFor="let unit of getMeasureUnit(attrib.measure_type)" value="{{unit}}">{{unit}}</mat-option>
          </mat-select>
        </ng-template>

        <ng-template #childElement let-dep="dep" , let-parent="parent">
          <div [ngClass]="[dep.mutual.length > 0 ? 'field-dep-mutual' : 'field-dep']">
            <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:dep,parent:parent}"></ng-container>
          </div>
          <!-- mutual under child start -->
          <div *ngIf="dep.mutual.length > 0" class="mutual-child border">
            <input type="radio" id="radio_{{dep.id}}_2" name="radio_{{dep.id}}" (change)="showChild(dep,$event,2)" />
            <div>
              <div id="div_{{dep.id}}_2" class="disabled-div">
                <div *ngFor="let mt of dep.mutual" class="field-dep">
                  <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:mt,parent:false}"></ng-container>
                </div>
              </div>
            </div>
          </div>
          <!-- mutual under child end -->

        </ng-template>


        <div *ngFor="let attrib of customize_attribute">
          <ng-container *ngIf="attrib.status && !attrib.is_hidden && attrib.data_type != 'table'">
            <div class="form-group box1">
              <div [ngClass]="[attrib.mutual.length ? 'field-mutual' : 'field']">
                <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:attrib,parent:false}"></ng-container>
              </div>
              <!-- Child attributes start -->
              <div *ngIf="attrib.child.length >= 1" id="child_{{attrib.id}}">
                <div *ngFor="let dep of attrib.child">
                  <ng-container *ngTemplateOutlet="childElement;context:{dep:dep,parent:attrib}"></ng-container>
                </div>
              </div>
              <!-- Child attributes end -->

              <!-- Mutual attributes start -->
              <div *ngIf="attrib.mutual.length >= 1" class="mutual-div">
                <input type="radio" value="off" id="radio_{{attrib.id}}_2" name="radio_{{attrib.id}}"
                  (change)="showChild(attrib,$event,2)" />
                <div id="div_{{attrib.id}}_2" class="disabled-div">
                  <div *ngFor="let dep of attrib.mutual" class="field">
                    <ng-container *ngTemplateOutlet="formTemplate;context:{attrib:dep,parent:false}"></ng-container>
                  </div>
                </div>
              </div>
              <!-- Mutual attributes end -->
            </div>

            <!-- attributtes without child and dependent attribute end  -->

          </ng-container>
          <ng-container *ngIf="attrib.status && !attrib.is_hidden && attrib.data_type == 'table'">
            <div class="form-group box1">
              <div>
                <label class="label-text">{{attrib.label}}</label>
                <!-- information tooltip start -->
                <span *ngIf="isRejected(attrib.id)" (click)="setRejectedMessage(attrib.id)"
                  class="error"  matTooltip={{rejectmessage}}><i class="material-icons">error</i></span>
                <!-- information tooltip end -->
              </div>
              <div class="master-container" *ngFor="let masterval of getMasterById(attrib.master_id)">
                <div class="master-field" *ngIf="masterval">
                  <label>{{masterval.label}}</label>
                  <ng-container *ngFor="let child of attrib.child">
                    <ng-container *ngIf="child.status && !child.is_hidden">
                      <div class="animat" *ngIf="child.data_type == 'decimal-input' || child.data_type == 'number-input'">
                        <label class="animat_label" for="input_{{masterval.id}}_{{child.id}}">{{child.label}}</label>
                        <input class="form-control input-sm" type="number" placeholder=""
                        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57"
                        id="input_{{masterval.id}}_{{child.id}}" formControlName="input_{{masterval.id}}_{{child.id}}">
                      </div>

                      <div class="animat" *ngIf="child.data_type == 'string-input'">
                        <label class="animat_label" for="input_{{masterval.id}}_{{child.id}}">{{child.label}}</label>
                        <input class="form-control input-sm" type="text" id="input_{{masterval.id}}_{{child.id}}" placeholder=""
                        formControlName="input_{{masterval.id}}_{{child.id}}">
                      </div>

                      <div class="animat" *ngIf="child.data_type == 'single-select'">
                        <label class="animat_label" for="input_{{masterval.id}}_{{child.id}}">{{child.label}}</label>
                        <mat-select class="form-select-md" id="input_{{masterval.id}}_{{child.id}}" placeholder=""
                          formControlName="input_{{masterval.id}}_{{child.id}}">
                          <mat-option *ngFor="let master of getMasterById(child.master_id)" value="{{master.id}}">
                            {{master.label}}</mat-option>
                        </mat-select>
                      </div>

                      <div class="animat" *ngIf="child.data_type == 'multi-select'">
                        <label class="animat_label" for="input_{{masterval.id}}_{{child.id}}">{{child.label}}</label>
                        <mat-select class="form-select-md" id="input_{{masterval.id}}_{{child.id}}" placeholder=""
                            formControlName="input_{{masterval.id}}_{{child.id}}" multiple>
                            <mat-option *ngFor="let master of getMasterById(child.master_id)" value="{{master.id}}">
                              {{master.label}}</mat-option>
                          </mat-select>
                      </div>

                      <label class="switch" *ngIf="child.data_type == 'toggle'">
                        <input type="checkbox" id="input_{{masterval.id}}_{{child.id}}"
                          formControlName="input_{{masterval.id}}_{{child.id}}" class="chkBox" checked>
                        <span class="slider round">
                          <div class="toggle-container">
                            <span class="text">Yes</span>
                            <span class="text">No</span>
                          </div>
                        </span>
                      </label>
                      <!-- <input class="form-control input-sm" type="date" [min]="min_date" [max]="max_date" id="input_{{masterval.id}}_{{child.id}}"
                        formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'date'"> -->
                        <div class="ml-10 d-flex align-items-center flex-column"  *ngIf="child.data_type == 'date'">
                          <!-- <mat-form-field> -->
                          <span class="d-flex align-items-center mx-2 position-relative " (click)="focusPicker(picker1)">
                              <input matInput maxlength="10"  [min]="min_date" #datePickerValue
                              formControlName="input_{{masterval.id}}_{{child.id}}" readonly
                              placeholder="dd-mm-yyyy" [max]="max_date"  class="ng-trim-ignore form-select-l"
                              id="input_{{masterval.id}}_{{child.id}}"
                              style="padding :8px 8px !important;width: 100%;" [matDatepicker]="picker1"
                              >
                              <span class="position-absolute righ-0">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                            </span>
                          </span>
                          <mat-hint style="width: 100%;padding-left: 10px;">DD-MM-YYYY</mat-hint>
                      </div>
                      <mat-select placeholder="{{child.label}}"  class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                        formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'year'">
                        <mat-option *ngFor="let year of years" value={{year}}>{{year}}</mat-option>
                      </mat-select>
                      <mat-select placeholder="{{child.label}}"  class="form-select-md" id="input_{{masterval.id}}_{{child.id}}"
                        formControlName="input_{{masterval.id}}_{{child.id}}" *ngIf="child.data_type == 'month'">
                        <mat-option *ngFor="let month of month" value="{{month.id}}">{{month.name}}</mat-option>
                      </mat-select>
                      <mat-select class="form-select-md" id="unit_{{masterval.id}}_{{child.id}}"
                        formControlName="unit_{{masterval.id}}_{{child.id}}" *ngIf="child.measure_unit">
                        <mat-option *ngFor="let unit of getMeasureUnit(child.measure_type)" value="{{unit}}">{{unit}}
                        </mat-option>
                      </mat-select>
                    </ng-container>
                  </ng-container>

                </div>
              </div>
            </div>

          </ng-container>
        </div>
        <!-- using hidden button to submit the form hidden button  -->
        <input style="display: none" id="property_form_btn" type="submit" name="submit" />
      </form>
      <div class="space-bottom"></div>
    </div>

  </div>
</div>
