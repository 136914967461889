<div *ngIf="!mobile">
    <div class="list-container">
        <div class="block">
            <div class="fit">
                <div class="box-web">
                    <div class="">
                        <div class="" style="position: relative;">
                            <input class="search-input-web" [formControl]="searchField" [(ngModel)]="searchData"
                                placeholder="search location">
                            <mat-icon class="magnify hvr-grow">search</mat-icon>
                            <div *ngIf="showSearchPane" class="result_container">
                                <div class="content-box">
                                    <div class="inner_container">
                                        <div *ngIf="loader1 == false && notFound == true" class="addr_container">
                                            <span id="notFound">No results found...</span>
                                        </div>
                                        <div *ngIf="loader1" class='loader-div'>
                                            <ngx-loading [show]="loader1"
                                                [config]="{backdropBackgroundColour: 'rgba(0,0,0,0.6)'}"></ngx-loading>
                                        </div>
                                        <div (click)="selectedLocation(data)" class="addr_container"
                                            *ngFor="let data of address_list">
                                            <div class="addr_line">
                                                <p id="p1">{{data.address_text}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <a (click)="openEdit()" class="floating-web"><mat-icon class="icon-edit" svgIcon="edit"></mat-icon></a> -->
            <div class="addressBox-web h-aut">
                <span>Address:</span>
                <span class="addrLine" (click)="openEdit()">{{address}}</span>
            </div>

            <div *ngIf="is_Address()" class="nav-btn_div">
                <div class="navDiv">
                    <button type="button" mat-button class="cancel" (click)="cancelProperty()">
                        Cancel
                        <mat-icon class="cross">close</mat-icon>
                    </button>
                    <button type="button" mat-button class="nxtBtn"
                        (click)="createProperty()">
                        {{getSavetext()}}
                        <mat-icon class="save">save</mat-icon>
                    </button>
                </div>

            </div>
        </div>
        <div class="block">
            <div #gmap class="gmapWeb animated fadeIn delay-2s"></div>
            <div class="cancel-web">
                <button
                [disabled]="!isGeomAvailable()"
                class="ml-1" mat-raised-button
                (click)="selectPolygon()">
                Area
                </button>
                <button mat-raised-button
                (click)="gotoPreviousLocation()"
                title="Go to previous location"
                class="ml-1">
                <i class="material-icons">history</i>
                </button>
                <button mat-raised-button
                title="Mark location"
                (click)="toggleNavigation()"
                [ngClass]="{'applybutton': showNavigation && !dropped }">
                <i class="material-icons">
                    navigation
                </i>
                </button>
                <button class="ml-1"
                mat-raised-button
                (click)="backToProperty()">
                Cancel
                </button>
                <button mat-raised-button
                (click)="markerDrop()"
                title="Drop marker"
                class="ml-1"
                [ngClass]="{'applybutton': !showNavigation && dropped }">
                <i class="material-icons">place</i>
                </button>
                <button mat-raised-button (click)="getCurrentLocation()" title="drop marker" class="ml-1">
                    <i class="material-icons">gps_fixed</i>
                </button>
            </div>

        </div>

    </div>
    <div *ngIf="loader" class="loader-pos">
        <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(0,0,0,0.6)'}"></ngx-loading>
    </div>
</div>


<div *ngIf="mobile">
    <mat-toolbar class="toptoolbar new">
        <div class="button-block">
            <div class="searchBtn">
                <div class="box">
                    <input class="search-input" [formControl]="searchField" [(ngModel)]="searchData">
                    <mat-icon class="magnify">search</mat-icon>
                    <div *ngIf="showSearchPane" class="res-con">
                        <div class="content-box">
                            <div class="inner_container">
                                <div *ngIf="!loader1 && notFound" class="addr_container">
                                    <span id="notFound">No records found...</span>
                                </div>
                                <div *ngIf="loader1" class='loader-div'>
                                    <ngx-loading [show]="loader1"
                                        [config]="{backdropBackgroundColour: 'rgba(0,0,0,0.6)'}"></ngx-loading>
                                </div>
                                <div (click)="selectedLocation(data)" class="addr_container"
                                    *ngFor="let data of address_list">
                                    <div class="addr_line">
                                        <p id="p1">{{data.address_text}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div #gmap class="gmap D-height animated fadeIn delay-2s" (click)="showSearchPane = false">

                </div>
                <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(0,0,0,0.6)'}"></ngx-loading>
            </div>



            <div class="btn-next">
                <div class="addressBox" fxLayout="row" fxLayoutAlign="start center">
                    <div>
                        <button mat-raised-button [disabled]="!isGeomAvailable()" (click)="selectPolygon()"
                            title="drop marker" class="ml-1 mob-btn">
                            Area
                        </button>
                        <button mat-raised-button (click)="gotoPreviousLocation()" title="Go to previous location"
                            class="ml-1 mob-btn">
                            <i class="material-icons">history</i>
                        </button>
                        <button mat-raised-button title="Mark location" (click)="toggleNavigation()"
                            [ngClass]="{'applybutton': showNavigation && !dropped }" class="ml-1 mob-btn">
                            <i class="material-icons">
                                navigation
                            </i>
                        </button>
                        <button mat-raised-button (click)="markerDrop()" title="drop marker" class="ml-1 mob-btn"
                            [ngClass]="{'applybutton': !showNavigation && dropped }">
                            <i class="material-icons">place</i>
                        </button>

                        <button mat-raised-button (click)="getCurrentLocation()" title="drop marker"
                            class="ml-1 mob-btn">
                            <i class="material-icons">gps_fixed</i>
                        </button>
                    </div>
                </div>
                <div class="addressBox" fxLayout="row" fxLayoutAlign="start center">
                    <div class="colored">Address:</div>
                    <div class="fill">
                        <p id="colr" (click)="openEdit()">
                            <u>{{address}}</u>
                        </p>
                    </div>
                </div>
                <div id="nav-btn">
                    <button [routerLink]="['/property/'+assignment_id]" mat-button class="cancel">
                        Cancel
                        <mat-icon class="cross">close</mat-icon>
                    </button>
                    <button [disabled]="!neighbourhood || !block" (click)="createProperty()" mat-button class="nxtBtn">
                       Next
                        <mat-icon class="forward">arrow_forward</mat-icon>
                    </button>

                </div>
            </div>
        </div>
    </mat-toolbar>
</div>
