    <div class="dialog">
        <div><mat-toolbar class="tool">Add Floor</mat-toolbar></div>
        <form *ngIf="!is_gis" [formGroup]="floorForm" (ngSubmit)="addFloor(floorForm)">
        <div class="form">
            <div *ngFor = "let data of floor_type_list" class="boxDiv">
                <ng-container *ngIf="data.status == 1 && data.name.toLowerCase() != 'Ground'.toLowerCase() && restrictDummy(data.name)">
                <div>
                    <span>{{data.label}}</span>
                </div>
                <div>
                    <input class="form-input" formControlName="input_!{{data.name}}" type="number" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57">
                </div>
                </ng-container>
            </div>
        </div>
        <div class="action">
            <div>
                <button type="submit" class="act" mat-button>Create</button>
            </div>
            <div>
                <button (click)="closeDialog()" type="button" class="act" mat-button>Cancel</button>
            </div>
        </div>
        </form>
        <div *ngIf="is_gis">
            <div *ngIf="loader">
                <ngx-loading [show]="loader"
                    [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                </ngx-loading>
            </div>
            <div class="message">
                <span>Do you want to import selected the floors and its units in estater?</span>
            </div>
            <div class="action">
                <div>
                    <button (click)="createFloorOnGis()" class="act" mat-button>Create</button>
                </div>
                <div>
                    <button (click)="closeDialog()" type="button" class="act" mat-button>Cancel</button>
                </div>
            </div>
        </div>
    </div>
