import { Component, OnInit, ViewChild, AfterViewInit, QueryList, Inject, ViewChildren, ElementRef, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { map, tap } from 'rxjs';
import { Observable } from 'rxjs';
import { ShareService } from '../services/share.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiserviceService } from '../services/apiservice.service';
import { ProfileComponent } from './profile.component';
// import { MessagingService } from '../services/messaging.service';
// import { MainBreadcrumbService } from './main-breadcrumb/main-breadcrumb.service';

export interface UserInfo {
  fname: string;
  lname: string;
  contact: string;
  country: string;
  language: string;
  imageURL: string;
  email: string
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, AfterViewInit {
  watcher: Subscription;
  showside: boolean = false;
  mobileview: boolean = false;
  showBar: boolean = true;
  login: boolean = false;
  countryView: boolean = false;
  @ViewChild('sidenavsmall') sidenavsmall: MatSidenav;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChildren(MatMenuTrigger) triggers: QueryList<MatMenuTrigger>;
  
  @ViewChild('viewHeightDiv') elementView: ElementRef;
  viewheight: number;
  showFiller = false;
  username: any;
  picture_URL: any= 'assets/images/user.png';
  user_detail: UserInfo;
  message: any;
  city_name: string;
  constructor(private share: ShareService, private element: ElementRef,
    private location: Location, private router: Router,private media: MediaObserver,
    private activatedRoute: ActivatedRoute, iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer, public dialog: MatDialog, private api: ApiserviceService,
     private changeDetector: ChangeDetectorRef, private changeDetectorRef: ChangeDetectorRef) {
      if(!localStorage.getItem('city_id')){
        this.router.navigateByUrl('/country');
      }
      
      this.api.user_name.subscribe((data:any)=>{
        if(data){
          this.username = data;
        }
      })
    if (!this.api.user_id) {
      this.showside = false;
    } else {
      this.showside = true;
    }
    this.share.sideNav_obj = this;
    this.mobileview = this.api.ismobile;
    this.activatedRoute.url.subscribe(url => {
      if (this.sidenav) {
        setTimeout(function () {
          this.sidenav.close();
        }.bind(this), 100)
      }
    });

    iconRegistry.addSvgIcon('bell-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/bell.svg'))
      .addSvgIcon('add_property', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/add_property.svg'))
      .addSvgIcon('back-arrow', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/back_arrow.svg'))
      .addSvgIcon('filter', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Filter.svg'))
      .addSvgIcon('gallery', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Gallery.svg'))
      .addSvgIcon('camera', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/camera.svg'))
      .addSvgIcon('cross', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Cross.svg'))
  }

  // isHandset$ = this.media
  // .asObservable()
  // .subscribe((change) => {
  //   change.forEach((item) => {         
  //     if (item.mqAlias === 'xs' || item.mqAlias === 'sm') {
  //       this.mobileview = true;
  //     }
  //   });
  // });


  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
  getCity() {
    let cName;
    // if (localStorage.getItem('city_detail')) {
    //   let cityDetail = JSON.parse(localStorage.getItem('city_detail'));
    //   cName = cityDetail.city_name;
    // } else
     
      if (localStorage.getItem('city_name')) {
        cName = localStorage.getItem('city_name');
      
    }
    return cName;
  }
  getInitials(e) {
    let str = e;
    if (str) {
      let splited = str.split(' '), newStr: any = [], count = 0;
      if (splited.length >= 2) {
        splited.forEach((v) => {
          if (count < 2) {
            newStr.push(v.charAt(0));
            count++;
          }
        })
      } else {
        let char = splited[0];
        newStr.push(char.charAt(0))
        newStr.push(char.charAt(1))
      }
      let initials = newStr[0] + newStr[1];
      return initials;
    }
  }
  // ngOnDestroy() {
  //   this.watcher.unsubscribe();
  // }
  OpenUserMenu() { }

  ngAfterViewInit() {
  //   this.isHandset$.subscribe(isHandset =>{
  //     // console.log(isHandset);
  //     this.mobileview = isHandset;
  //  })
   this.viewheight = (this.elementView.nativeElement.offsetHeight);
    this.share.heightService = this.viewheight;
  }

  openMenu() {
    if (this.api.user_id) {
      this.openSideNav()
    }
  }

  openSideNav() {
    if (this.sidenavsmall) {
      this.sidenavsmall.close();
    }
    this.showside = false;
    setTimeout(function () {
      this.sidenav.open();
    }.bind(this), 100)
  }

  openSideNavSmall() {
    if (this.sidenav) {
      this.sidenav.close();
    }
    this.showside = true;
    setTimeout(function () {
      if (this.sidenavsmall) {
        this.sidenavsmall.open();
      }
    }.bind(this), 100)
  }

  loginNav() {
    if (this.sidenav) {
      this.sidenav.close();
    }
  }

  sidenavClose() {
    this.openSideNavSmall();
  }

  toolbarOff(action: string): void {
    if (action == 'off') {
      this.showBar = false;
    }
    else {
      this.showBar = true;
    }

  }
  onResize(event) {
    //this.viewheight = (this.elementView.nativeElement.offsetHeight);
    //this.share.heightService = this.viewheight;
  }

  logout(val) {
    this.api.logout(val);
  }

  ngOnInit() {
    this.api.profile_image.subscribe(res=>{
      this.picture_URL = res;
    })
    // this.login = true;
    if (this.api.user_id) {
      // this.messagingService.requestPermission(this.username + " - id: " + this.api.user_id);
      // this.messagingService.receiveMessage();
      // this.message = this.messagingService.currentMessage;
      this.openSideNavSmall();
    } else {
      this.showside = false;
    }
   
    this.router.events.subscribe((val) => {
      if (this.location.path() == '/map' || this.location.path() == '/profile' ||
        this.location.path() == '/login' || this.location.path() == '/country') {
        this.toolbarOff('off');
      }
      else {
        this.toolbarOff('on');
      }
    });
    if (this.location.path() == '/country') {
      this.countryView = true;
      this.showside = false;
    } else {
      this.countryView = false;
      this.showside = true;
    }
  }

  openDialog(type: string, info: any = {}): void {
    let dialogview;
    info.pObj = this;
    if (type == 'main-dialog') {
      dialogview = ProfileComponent
    }
    if (dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: '450px',
        panelClass: 'create-master-panel',
        data: info
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

  save() {
    if (this.share.block3_obj) {
      this.share.block3_obj.submitForm();
    }
  }

  navigate(path) {
    if (this.share.block0_obj && this.share.block0_obj.selected_property) {
      let url = path;
      if (path == 'property') {
        url = path + "/" + this.share.block0_obj.assignment_id;
        this.router.navigateByUrl(url);
      }
      if (path == 'floor') {
        this.router.navigateByUrl(url);
      }
      if (path == 'unit' && this.share.floor_obj) {
        if (this.share.floor_obj.selected_floor) {
          this.router.navigateByUrl(url);
        }
      }
    }
  }

}
