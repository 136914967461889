import { Component, OnInit } from '@angular/core';
import { ShareService } from '../../services/share.service';
import { Router} from '@angular/router';
import { Location } from '@angular/common';
import { ApiserviceService } from '../../services/apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';

@Component({
  selector: 'app-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.css']
})
export class MobileFooterComponent implements OnInit {
  form_language:any;
  language_list:any = [];
  path:any;
  selected_lang:any;
  constructor(private share:ShareService, 
    private route:Router, 
    private location: Location, 
    private api: ApiserviceService,
    private fnc: CommonfunctionService) { 
    this.getLangs();
  }
  getLangs(){
      this.api.getUmsData('language')
      .subscribe(
        (data:any) => {
          if(data.length > 0){
           this.language_list = data;
           this.selected_lang = this.fnc.getArrayValue('language_id', this.api.language, this.language_list);
          }
        }
      );
  }
  changeLanguage(e){
    this.form_language = e;
      // this.api.language = this.form_language;
      this.selected_lang = this.fnc.getArrayValue('language_id', this.api.language, this.language_list);
  }

  ngOnInit() {
    this.route.events.subscribe((val) => {
        this.path = this.location.path();
    });
  }

  save(){
    if(this.share.block3_obj){
      this.share.block3_obj.submitForm();
    } 
  }

  navigate(path){
    if(this.share.block0_obj && this.share.block0_obj.selected_property){
      let url = path;
      if(path == 'property'){
        url = path +"/"+this.share.block0_obj.assignment_id;
        this.route.navigateByUrl(url);
      }
      if(path == 'floor'){
        this.route.navigateByUrl(url);
      }
      if(path == 'unit' && this.share.floor_obj){
        if(this.share.floor_obj.selected_floor){
          this.route.navigateByUrl(url);
        }
      }
    }
  }
}
