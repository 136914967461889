import { NgModule } from '@angular/core';
import { FilterPipe } from './filterPipe';

const PIPES = [
    FilterPipe
];

@NgModule({
    declarations: [...PIPES],
    exports: [...PIPES],
})
export class PipesModule {}
