import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CustomValidator } from '../../../../../../src/app/main/main-property/customValidator';
import { NotificationService } from '../../../../../../src/app/services/notification.service';
import { ApiserviceService } from '../../../../../../src/app/services/apiservice.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  @Output() childButtonEvent = new EventEmitter();
  @Output() gotologin = new EventEmitter()
  forgotForm: UntypedFormGroup;
  OTPForm: UntypedFormGroup;
  newPasswordForm:UntypedFormGroup;
  errormessege: string = '';
  visible_section = 'email_input';
  submited: boolean = false;
  visible_OTP_section='';

  couterdown:any;
  counter:any;
  timemer;
  loading: boolean = false;
  resetLoader: boolean=false;
  timemer_limit: number = 60;
  button_state: boolean = false;
  constructor(public dialog: MatDialog,
    private alertService: NotificationService,
    public fb: UntypedFormBuilder, public api: ApiserviceService, private el: ElementRef) {

   }

   startCounter(){
    this.timemer = this.timemer_limit;
    this.counter = setInterval(()=>{
        this.timemer--;
        const minutes = Math.floor(this.timemer / 60);
        const seconds = this.timemer - minutes * 60;
        this.couterdown = this.str_pad_left(minutes, '0', 2) + ':' + this.str_pad_left(seconds, '0', 2);
        if (this.timemer == 0) {
          clearInterval(this.counter);
          this.couterdown = '0:00';
          this.button_state = false;
        }
      }, 1000)
   }
  ngOnInit(): void {
    this.forgotForm = this.fb.group({
      email: ['', [
        Validators.email,
        Validators.required,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]
      ]
    });

    this.OTPForm = this.fb.group({
      mail_otp: ['', Validators.compose([Validators.required, Validators.maxLength(6), Validators.maxLength(6)])]
    });


    this.newPasswordForm = this.fb.group({
      password: [null, Validators.compose([
        Validators.required,
        CustomValidator.patternValidator(/\d/, { hasNumber: true }),
        CustomValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidator.patternValidator(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/, { hasSpecialCharacters: true }),
        Validators.minLength(8)])
      ],
      confirmPassword: ['', Validators.required],
    },
    {
      // check whether our password and confirm password match
      validator: CustomValidator.passwordMatchValidator
    })
  }

  str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }


  closeReset() {
    this.gotologin.emit(false)
  }

  get email() {
    return this.forgotForm.get('email')!;
  }


  onSubmit(formvalue: any) {
    this.button_state =true;
    this.OTPForm.reset();
    for (const key of Object.keys(this.forgotForm.controls)) {
      if (this.forgotForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
    if (this.forgotForm.invalid) return;
    let body = {
      "email": formvalue.email,
      "notification_type": "email_otp"
    }
    this.submited = true;

    this.api.postUmsData('/notification', body).subscribe({
      next: (res: any) => {
        if (res.status == 201) {
          this.startCounter();
          this.visible_OTP_section = 'fill_opt';
          this.forgotForm.controls['email'].disable();
          if(this.couterdown == '0:00'){
            this.alertService.notify("OTP sent to your Email address", "success");
          }else{
            this.alertService.notify("OTP sent successfully, Please check your Email address", "success");
          }
        } else {
          this.button_state = false;
          this.alertService.notify(res.message,'warn');
        }
        this.submited = false;
      },
      error: (err) => {
        // this.alertService.notify(err.error.message, "error");
        this.submited = false;
        this.button_state = false;
      }
    })
  }



  SubmitOTP(formvalue: any) {

    if (this.OTPForm.invalid) return;

    let body = {
      "email": this.forgotForm.get('email').value,
      "type": "email_otp",
      "token": formvalue.mail_otp
    }
    this.loading = true;
    this.api.postUmsData('/verify', body).subscribe({
      next: (res: any) => {
        if (res.status == 201) {
          this.visible_OTP_section ='';
          this.visible_section = 'new_password';
          this.alertService.notify(res.message,"success");
        } else {
          this.alertService.notify(res.message,"warn");
        }
        this.loading = false;
      },
      error: (err) => {
        // this.alertService.notify(err.error.message, "error");
        this.loading = false;
      }
    })
  }

  resendOTP(){
    this.onSubmit(this.forgotForm.value);
  }

  resetPassword(fvalue){
    if(this.newPasswordForm.invalid) {
      return;
    }
    if(fvalue.password !==  fvalue.confirmPassword){
        this.alertService.notify('Password Mismatch','warn');
        return;
    }
    let body={
      "email": this.forgotForm.get('email').value,
      "type": "email_otp",
      "token": this.OTPForm.get('mail_otp').value,
      "password": fvalue.password
    };
    this.resetLoader= true;
    this.api.patchUmsData('reset-password', body).subscribe({
      next: (res: any) => {
        this.newPasswordForm.reset();
        this.resetLoader= false;
        if (res && res.status == 201) {
          this.closeReset();
          this.alertService.notify(res.message,"success");
          this.gotologin.emit(false);
        }
        else {
          this.alertService.notify(res.message,"warn");
        }
      },
      error: (err) => {
        this.newPasswordForm.reset();
        this.resetLoader= false;
        // this.alertService.notify(err.error.message,"error");
      }
    })

  }

}
