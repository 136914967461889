import { Component, OnInit, ElementRef } from '@angular/core';
import { ShareService } from '../../services/share.service';
import { ApiserviceService } from '../../services/apiservice.service';
import { MatIconRegistry } from '@angular/material/icon';
import { MatListItem } from '@angular/material/list';
import {DomSanitizer} from '@angular/platform-browser';
import * as $ from 'jquery';
import { Router } from '@angular/router';

export interface UserInfo{
  fname: string;
  lname: string;
  contact: string;
  country:string;
  language: string;
  imageURL: string;
  email: string
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_assignments_get</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  dHeight:number;
  mobile: boolean = false;
  collectedProp: number;
  totalAssign: number;

  user_image:any;
  userInfo:any;
  assignment_list:any;
  allocatedAssignment:number;
  propertyCollected:number;

  constructor(private share: ShareService, private elementRef: ElementRef,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private api: ApiserviceService, private router: Router) {
      if((!this.api.user_id && !this.api.city_id)){
        this.router.navigateByUrl('/login');
      }

    this.mobile = this.share.mobile;

    iconRegistry.addSvgIcon('my_listing', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/my_listing.svg'))
                .addSvgIcon('others', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/others.svg'))
                .addSvgIcon("property", sanitizer.bypassSecurityTrustResourceUrl("assets/icon/bottom/Property.svg"));
                this.getAssignment();
    
    let user_detail = localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')) : null;
    if(user_detail){
      this.api.user_info.next(user_detail);
    }
    
    
   }
  ngOnInit() {
    this.api.user_info.subscribe(user=>{
      let user_detail = user;
      this.userInfo = {
        pictureURL: user_detail.imageURL? user_detail.imageURL :'',
        username: user_detail.fname + " " +  user_detail.lname,
        contact: user_detail.contact,
        country: user_detail.country,
        email: user_detail.email
      }
    })
    setTimeout(()=>{
      this.dHeight = (this.share.heightService);
      let e = $(this.elementRef.nativeElement);
      e.find('#pList').css('height',this.dHeight);
    }, 10);
  }
  openMenu(){
    this.share.sideNav_obj.openMenu();
  }

  getAssignment(){

   this.api.getEmsData('assignments?sort_by=assignment_name&status=1&assigned_to='+this.api.user_id+'&property_count=true')
   .subscribe(
     {next: (data:any) => {
       this.assignment_list = data;

      let count = 0;
      this.allocatedAssignment = data.length;
      data.forEach((v)=>{
        // if(v.assigned_to[this.api.user_id]){
        //    let num = v.assigned_to[this.api.user_id][1] + v.assigned_to[this.api.user_id][4] + v.assigned_to[this.api.user_id][6];
        //   count += num
        // }

        count += v.property_count;
      });
      this.propertyCollected = count;
     },
     error:(err) => {
     }}
   );
 }
}
