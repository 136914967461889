import { Component, OnInit } from "@angular/core";
import { ApiserviceService } from "../../services/apiservice.service";
import { ProfileComponent } from "../profile.component";
import { MatDialog } from "@angular/material/dialog";
import { ShareService } from "../../../../src/app/services/share.service";
@Component({
  selector: "app-mobile-card",
  templateUrl: "./mobile-card.component.html",
  styleUrls: ["./mobile-card.component.css"],
})
export class MobileCardComponent implements OnInit {
  //user_name = localStorage.getItem('username');
  user_name = "";
  load: boolean = false;
  user_detail: any = {
    contact: null,
    fname: "",
    lname: "",
    language: null,
    country: null,
  };
  constructor(
    private api: ApiserviceService,
    private dialog: MatDialog,
    private share: ShareService
  ) {
    this.user_detail = JSON.parse(localStorage.getItem("user_info"));
    if (this.api.user_id) {
      this.load = true;
    }
  }

  ngOnInit() {}
  openProfile() {
    this.dialog.open(ProfileComponent, {
      width: "450px",
      panelClass: "create-master-panel",
      data: {},
    });
    this.closeNav();
  }
  closeNav() {
    this.share.sideNav_obj.sidenavClose();
  }
}
