import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { AuthGuard } from '../../services/auth.guard';
import { MainPropertyComponent } from '../main-property/main-property.component';
import { Block1Component } from '../dynamic/block1/block1.component';
import { Block2Component } from '../dynamic/block2/block2.component';
import { Block3Component } from '../dynamic/block3/block3.component';
import { PhotoBlockComponent } from '../dynamic/photo-block/photo-block.component';
import { ParcelComponent } from '../parcel/parcel.component';
import { MapComponent } from '../map/map.component';
import { FloorComponent } from '../dynamic/floor/floor.component';
import { FloorGroupComponent } from '../dynamic/floor-group/floor-group.component';
import { UnitComponent } from '../dynamic/unit/unit.component';
import { FloorSubgroupComponent } from '../dynamic/floor-subgroup/floor-subgroup.component';
import { UnitGroupComponent } from '../dynamic/unit-group/unit-group.component';
import { UnitSubgroupComponent } from '../dynamic/unit-subgroup/unit-subgroup.component';
import { FloorMapComponent } from '../floor-map/floor-map.component';
import { MainComponent } from '../main.component';
import { ProfileComponent } from '../profile.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: '',  component : MainComponent, canActivate: [AuthGuard],
      children : [
        { path: 'home',  component : LandingPageComponent, canActivate: [AuthGuard]},
        { path: 'property/:id', component: MainPropertyComponent, canActivate: [AuthGuard]},
        { path: 'groups', component: Block1Component, canActivate: [AuthGuard]},
        { path: 'subGroups', component: Block2Component, canActivate: [AuthGuard]},
        { path: 'form', component: Block3Component, canActivate: [AuthGuard]},
        { path: 'uploadMedia', component: PhotoBlockComponent, canActivate: [AuthGuard]},
        { path: 'parcel', component: ParcelComponent , canActivate: [AuthGuard]},
        { path: 'map', component: MapComponent , canActivate: [AuthGuard]},
        { path: 'floor', component: FloorComponent , canActivate: [AuthGuard]},
        { path: 'floorGroup', component: FloorGroupComponent, canActivate: [AuthGuard]},
        { path: 'unit', component: UnitComponent, canActivate: [AuthGuard]},
        { path: 'floorSubgroup', component: FloorSubgroupComponent, canActivate: [AuthGuard]},
        { path: 'unitGroup', component: UnitGroupComponent, canActivate: [AuthGuard]},
        { path: 'unitSubgroup', component: UnitSubgroupComponent, canActivate: [AuthGuard]},
        { path: 'floor/map', component: FloorMapComponent, canActivate: [AuthGuard]}
      ]},
    ])
  ],
  declarations: [],
  exports : [RouterModule]
})
export class MainRoutingModule { }
