import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { ShareService } from './share.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiserviceService {
  public httpOptions;
  public status;
  public device: any;
  public ip: any = '::';
  public user_id: any;
  public user_name = new BehaviorSubject<any>(localStorage.getItem('user_name'));
  public user_info = new BehaviorSubject<any>(localStorage.getItem('user_info'));

  public profile_image = new BehaviorSubject<any>(localStorage.getItem('profile_image'));
  public user_token: string = '';
  public city_id: any = 'null';
  public city_code = '';
  public session_id: string = 'null';
  public language: any;
  public form_language: number;
  public language_list = [];
  public property_type_list = [];
  public entity_type_list = [];
  public allowResources = [];
  // public gisUrl = null;
  public wms_url = null;
  public estUrl = null;
  public epsUrl = null;
  public emsUrl = null;
  public umsUrl = null;
  public user_location = null;
  public api_key = '';


  ismobile:boolean;

  gesUrl: string =null;
  gmsUrl: string =null;
  gpsUrl: string =null;
  gmusUrl: string =null;

  constructor(private http: HttpClient,
    private deviceInfo: DeviceDetectorService,
    private route: Router,
    private share: ShareService) {

    this.ismobile = this.deviceInfo.isMobile();
    this.share.mobile = this.ismobile;
    this.setApiUrl();
    this.language = '1';
    this.getIpAddress();
    this.getLanguageList();

    if (localStorage.getItem('device')) {
      this.device = JSON.parse(localStorage.getItem('device'));
    } else {
      this.device = this.getDevice();
      localStorage.setItem('device', JSON.stringify(this.device));
    }

    this.user_id = localStorage.getItem('user_id');
    if (localStorage.getItem('user_token') != undefined && localStorage.getItem('user_token') != null) {
      this.user_token = localStorage.getItem('user_token');
    }

    // this.user_name = localStorage.getItem('user_name');
    if (localStorage.getItem('city_id')) this.city_id = localStorage.getItem('city_id');
    if (localStorage.getItem('city_code')) this.city_code = localStorage.getItem('city_code');
    if (localStorage.getItem('ip') === null || localStorage.getItem('ip') == undefined) {
      this.getIpAddress();
    } else {
      this.ip = localStorage.getItem('ip');
    }
    if(this.user_id && this.city_id){
      // this.getUserDetail(this.user_id);
      this.getGoogleMapKey();
    }

    if (localStorage.getItem('session_id') != undefined && localStorage.getItem('session_id') != null) {
      this.session_id = localStorage.getItem('session_id');
    }
    if (this.user_id && this.city_id) {
      this.getLanguageList();
    }

  }

   // set url of api
   setApiUrl() {
    this.estUrl = ''//environment.estUrl;
    this.emsUrl = environment.emsUrl ;
    this.epsUrl = environment.epsUrl ;
    this.umsUrl = environment.umsUrl;
    // this.gisUrl = environment.gisUrl;

    this.gesUrl =environment.ges;
    this.gmsUrl =environment.gms;
    this.gpsUrl =environment.gps;
    this.gmusUrl =environment.gmus;

    this.wms_url = environment.wms_url + this.city_code.toUpperCase() + "_gisdb/wms?";
    this.user_token = (this.user_token == '') ? environment.user_token : this.user_token
    this.api_key = environment.api_key;
  }

  public getGoogleMapKey(){
    this.getUmsData("setting?key=google_map_key&status=1").subscribe(
      {
        next: (res:any) => {
          if (res && res.data) {
            res = res.data;
            let goole_key = res[0].value;
            // this.EncryptDecrypt.EncryptDecrypt('HqfDb@2389xDYaM');
            // goole_key = this.EncryptDecrypt.decryptKey(goole_key);
            const url = 'https://maps.googleapis.com/maps/api/js?key=' + goole_key + '&libraries=drawing,marker,label';
            const script = document.createElement('script');
            script.setAttribute('src', url);
            script.setAttribute('async', '');
            script.setAttribute('defer', 'defer');
            document.head.appendChild(script);
          }else{
            console.log('google_map_key not available');

          }
        }, error: (err) =>{
          console.log(err.error.message)
        }
      }
    )
  }

  //SETTING HEADERS HERE--------------------------------------------------------------------------------------------------------
  //UMS
  setUmsHeader(content_type = 'application/json') {
    //let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', content_type);
    headers = headers.append('app-id', "7");
    //headers = headers.append('User-Device', dInfo);
    headers = headers.append('user-lang', 'en');
    headers = headers.append('city-id', String(this.city_id))
    if (localStorage.getItem('ip')) {
      headers = headers.append('user-ip', this.ip);
    } else {
      headers = headers.append('user-ip', '::');
    }
    headers = headers.append('user-token', String(this.user_token));


    this.httpOptions = { headers: headers };
  }


  //GIS
  setGisHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('API-KEY', '7kjghhjj');
    headers = headers.append('USER-LANGUAGE', '1');
    headers = headers.append('USER-CITY', String(this.city_id));
    headers = headers.append('user-token', String(this.user_token));

    this.httpOptions = { headers: headers };
  }

  setNewUploadHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('API-KEY', '7kjghhjj');
    headers = headers.append('USER-LANGUAGE', '1');
    headers = headers.append('USER-CITY', String(this.city_id));
    headers = headers.append('user-token', String(this.user_token));
    this.httpOptions = { headers: headers };
  }

  //ESTATER
  setHeader(content_type = 'application/json', response_type = null) {
    // let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    let headers = new HttpHeaders();
    let language = this.language;
    if (this.form_language) language = this.form_language;
    if (content_type != 'no-content') {
      headers = headers.append('Content-Type', content_type);
    }
    else {
      // headers = headers.append('Content-Type', 'multipart/form-data');
    }
    this.user_location = localStorage.getItem('user_location') ? localStorage.getItem('user_location') : '';
    headers = headers.append('USER-CITY', String(this.city_id));
    headers = headers.append('USER-TOKEN', String(this.user_token));
    headers = headers.append('USER-LANGUAGE', '' + language + '');
    headers = headers.append('location', this.user_location);
    headers = headers.append('API-KEY', this.api_key?this.api_key:'');
    this.httpOptions = { headers: headers };
    if (response_type) {
      this.httpOptions['responseType'] = response_type;
      //headers = headers.append('responseType', response_type);
    }

  }

  setUploadHeaderUms(content_type = 'multipart/form-data', response_type) {
    let headers = new HttpHeaders();
    // headers.append('Content-Type', content_type);
    // let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    headers = headers.append('city-id', String(this.city_id));
    headers = headers.append('USER-CITY', String(this.city_id));
    headers = headers.append('CITY-CODE', this.city_code ? String(this.city_code):'');

    headers = headers.append('user-token', this.user_token);
    headers = headers.append('app-id', '7');
    headers = headers.append('user-lang', 'en-us');
    if (localStorage.getItem('ip')) {
      headers = headers.append('user-ip', String(this.ip));
    } else {
      headers = headers.append('user-ip', '::');
    }
    console.log(headers);

    this.httpOptions = { headers: headers };

    if (response_type) {
      this.httpOptions['responseType'] = response_type;
      //headers = headers.append('responseType', response_type);
    }
  }
  //SETTING HEADERS COMPLETE----------------------------------------------------------------------------------------------------


  //UMS CALLS STARTS HERE -----------------------------------------------------------------------------------------------------
  getUmsData(url) {
    this.setUmsHeader();
    return this.http.get(this.umsUrl + url, this.httpOptions);
    // .map(data => data.json());
  }
  // send a POST request to the API to create a data object
  postUmsData(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUmsHeader();
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }
  // send a PATCH request to the API to update a data object
  patchUmsData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUmsHeader();
    return this.http.patch(this.umsUrl + urlWithId, body, this.httpOptions);
  }
  // send a DELETE request to the API to delete a data object
  deleteUmsData(urlWithId) {
    this.setUmsHeader();
    return this.http.delete(this.umsUrl + urlWithId, this.httpOptions);
  }

  //UMS CALLS ENDS HERE -----------------------------------------------------------------------------------------------------






  //GIS CALLS STARTS HERE -----------------------------------------------------------------------------------------------------
  // getGisData(url) {
  //   this.setGisHeader();
  //   return this.http.get(this.gisUrl + url, this.httpOptions);
  //   // .map(data => data.json());
  // }
  // postGisData(url, body) {
  //   if (body === null || body === undefined) {
  //     throw new Error('Required parameter body was null or undefined when calling Api.');
  //   }
  //   this.setGisHeader();
  //   return this.http.post(this.gisUrl + url, body, this.httpOptions);
  // }
  // putGisData(urlWithId, body) {
  //   if (body === null || body === undefined) {
  //     throw new Error('Required parameter body was null or undefined when calling Api.');
  //   }
  //   this.setGisHeader();
  //   return this.http.put(this.gisUrl + urlWithId, body, this.httpOptions);
  // }
  //GIS CALLS ENDS HERE -----------------------------------------------------------------------------------------------------

// Gis new Header and fuction start

getGmusData(url) {
  this.setGisHeader();
  return this.http.get(this.gmusUrl + url, this.httpOptions)
  // .map(data => data.json());
}
getGmsData(url) {
  this.setGisHeader();
  return this.http.get(this.gmsUrl + url, this.httpOptions)
  // .map(data => data.json());
}
getGpsData(url) {
  this.setGisHeader();
  return this.http.get(this.gpsUrl + url, this.httpOptions)
  // .map(data => data.json());
}
getGesData(url) {
  this.setGisHeader();
  return this.http.get(this.gesUrl + url, this.httpOptions)
  // .map(data => data.json());
}

// post record of gis
// postGisData(url, body) {
//   if (body === null || body === undefined) {
//     throw new Error('Required parameter body was null or undefined when calling Api.');
//   }
//   this.setGisHeader();
//   return this.http.post(this.gisUrl + url, body, this.httpOptions);
// }
postGmusData(url, body) {
  if (body === null || body === undefined) {
    throw new Error('Required parameter body was null or undefined when calling Api.');
  }
  this.setGisHeader();
  return this.http.post(this.gmusUrl + url, body, this.httpOptions);
}
postGmsData(url, body) {
  if (body === null || body === undefined) {
    throw new Error('Required parameter body was null or undefined when calling Api.');
  }
  this.setGisHeader();
  return this.http.post(this.gmsUrl + url, body, this.httpOptions);
}
postGpsData(url, body) {
  if (body === null || body === undefined) {
    throw new Error('Required parameter body was null or undefined when calling Api.');
  }
  this.setGisHeader();
  return this.http.post(this.gpsUrl + url, body, this.httpOptions);
}
postGesData(url, body) {
  if (body === null || body === undefined) {
    throw new Error('Required parameter body was null or undefined when calling Api.');
  }
  this.setGisHeader();
  return this.http.post(this.gesUrl + url, body, this.httpOptions);
}

// delete record of gis
// deleteGisData(url) {
//   this.setGisHeader();
//   return this.http.delete(this.gisUrl + url, this.httpOptions);
// }
deleteGmusData(url) {
  this.setGisHeader();
  return this.http.delete(this.gmusUrl + url, this.httpOptions);
}
deleteGmsData(url) {
  this.setGisHeader();
  return this.http.delete(this.gmsUrl + url, this.httpOptions);
}
deleteGpsData(url) {
  this.setGisHeader();
  return this.http.delete(this.gpsUrl + url, this.httpOptions);
}
deleteGesData(url) {
  this.setGisHeader();
  return this.http.delete(this.gesUrl + url, this.httpOptions);
}
//Uploaded File action
postUserAction(url, body) {
  this.setGisHeader();
  return this.http.post(this.gmusUrl + url, body, this.httpOptions);
}

postFile(url, body) {
  if (body === null || body === undefined) {
    throw new Error('Required parameter body was null or undefined when calling Api.');
  }
  this.setNewUploadHeader();
  return this.http.post(this.gmusUrl + url, body, this.httpOptions);
}

//put GIS data
// putGisData(url, body) {
//   this.setGisHeader();
//   return this.http.put(this.gisUrl + url, body, this.httpOptions);
// }
putGmusData(url, body) {
  this.setGisHeader();
  return this.http.put(this.gmusUrl + url, body, this.httpOptions);
}
putGmsData(url, body) {
  this.setGisHeader();
  return this.http.put(this.gmsUrl + url, body, this.httpOptions);
}
putGpsData(url, body) {
  this.setGisHeader();
  return this.http.put(this.gpsUrl + url, body, this.httpOptions);
}
putGesData(url, body) {
  this.setGisHeader();
  return this.http.put(this.gesUrl + url, body, this.httpOptions);
}


// Gis new Header and fuction end





  //ESTATER API CALLS STARTS HERE--------------------------------------------------------------------------------------------------
  getData(url) {
    this.setHeader();
    return this.http.get(this.estUrl + url, this.httpOptions)
  }

  postData(url, body, response_type = null) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader('application/json', response_type);
    return this.http.post(this.estUrl + url, body, this.httpOptions);
  }

  postMedia(url, body, response_type = null) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader('no-content', response_type);
    return this.http.post(this.estUrl + url, body, this.httpOptions);
  }

  postFileUms(url, body, response_type = null) {
    // if (body === null || body === undefined) {
    //   throw new Error('Required parameter body was null or undefined when calling Api.');
    // }
    // this.setUploadHeaderUms('application/json', response_type);
    // return this.http.post(this.estUrl + url, body, this.httpOptions);
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUploadHeaderUms('application/json', response_type);
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }

  patchData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.patch(this.estUrl + urlWithId, body, this.httpOptions);
  }

  deleteData(urlWithId) {
    this.setHeader();
    return this.http.delete(this.estUrl + urlWithId, this.httpOptions);
  }
  //ESTATER API CALLS ENDS HERE--------------------------------------------------------------------------------------------------




  //APP SPECIFIC CALLS-----------------------------------------------------------------------------------------------------------
  getAllowResources() {
    let body = `user/resource?place_id=${this.city_id}&user_id=${this.user_id}`
    this.getUmsData(body)
      // .map(data => data.json())
      .subscribe(
        {
          next: (res: any) => {
          this.allowResources = res.data;
        },
        error: (err) => {
        },
        complete() {}
      }
      )
  }

  // this function for Eps start
  getEpsData(url) {
    this.setHeader();
    return this.http.get(this.epsUrl + url, this.httpOptions)
  }
  postEpsData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.post(this.epsUrl + url, body, this.httpOptions);
  }
  patchEpsData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.patch(this.epsUrl + url, body, this.httpOptions);
  }
  // end
  // this function for EMS start
  getEmsData(url) {
    this.setHeader();
    return this.http.get(this.emsUrl + url, this.httpOptions)
  }
  postEmsData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.post(this.emsUrl + url, body, this.httpOptions);
  }
  patchEmsData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.patch(this.emsUrl + url, body, this.httpOptions);
  }
// end

  getLanguageList() {
    this.getUmsData('language')
      .subscribe(
       {
        next :  (res: any) => {
          if (res && res.data) {
            this.language_list=[];
            res.data.forEach(element => {
              if (element.status == 1) {
                this.language_list.push(element);
              }
            });
          }
        },
        error:()=>{},
        complete(){}
       }
      );
  }

  logout(val) {
    if(environment.isLocalServe || this.ismobile){
      this.getUmsData('logout?all_device='+val)
      .subscribe({
        next: (data: any) => {
          localStorage.clear();
          this.user_id = 'null';
          this.city_id = 'null';
          this.user_token = 'null';
          this.session_id = 'null';
          this.route.navigateByUrl('/login');
        },
        error: () =>{
          localStorage.clear();
          this.user_id = 'null';
          this.city_id = 'null';
          this.user_token = 'null';
          this.session_id = 'null';
          this.route.navigateByUrl('/login');
        },
        complete() {}
      });

    }else{
      localStorage.clear();
      this.user_id = 'null';
      this.city_id = 'null';
      this.user_token = 'null';
      this.session_id = 'null';
      let newwindow = window.open(`${environment.adminHost}/logout?continue=${environment.baseURL}&all_device=${val}`, '_self');
      if (window.focus) {newwindow.focus()}
    }

  }
  // my external ip
  getIpAddress() {
    this.http.get('https://api.ipify.org/?format=json')
      .subscribe({
        next: (data: any) => {
          let ip_details: any = data;
          if (ip_details.ip) {
            this.ip = ip_details.ip;
            localStorage.setItem('ip', ip_details.ip);
          } else {
            this.ip = '::';
            localStorage.setItem('ip', this.ip);
          }

        },
        error:()=>{},
        complete(){}
      });
  }

  checkRole(role) {
    let status = false;
    let roles = localStorage.getItem('roles');
    if (roles) {
      let roles_arr = [];
      roles_arr = roles.split(',');
      if (roles_arr.includes(role)) status = true;
    }
    return status;
  }
  getUserRole() {
    let url = `users?user_ids=${this.user_id}`;
    this.getUmsData(url)
      .subscribe(
        {
          next: (res:any)=>{
            if (res) {
              let val = res.data;
              let roles = val[0].role_names;
              localStorage.setItem('roles', roles);
            }
          },
          error:()=>{},
          complete(){}
        }
      )
  }

  // get device info
  getDevice() {
    let device = this.deviceInfo.getDeviceInfo();
    return device;
  }
  //APP SPECIFIC CALLS END------------------------------------------------------------------------------------------------------

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      // console.log('Geolocation is not supported by this browser');
    }
  }

  showPosition(position) {
    // console.log(position);
    let Latitude = position.coords.latitude;
    let Longitude = position.coords.longitude;
    let user_location = { lat: Latitude, lon: Longitude };
    localStorage.setItem('user_location', JSON.stringify(user_location));
  }


  getUserDetail(id) {
    this.getUmsData(`user?user_id=${id}`).subscribe(
      {
        next: (res:any) => {
          if (res.data) {
            this.setUser_info(res.data);
          }
        },
        error: (e) => console.error(e),
        complete: () => console.info('complete')
      }
    )
  }

  setUser_info(userdata) {
    let image = 'assets/images/user.png';
      if (userdata.profile_image) {
        image = userdata.profile_image;
      }
      localStorage.setItem('profile_image', image);
      this.profile_image.next(image);
      let name='';
      if (userdata.user_details['1'].first_name) {
          name = userdata.user_details['1'].first_name;
      }
      if (userdata.user_details['1'].last_name) {
          name += " " +  userdata.user_details['1'].last_name;
      }
      localStorage.setItem('user_name', name);
      this.user_name.next(name);
      let user_info = {
          "fname": userdata.user_details['1'].first_name,
          "lname": userdata.user_details['1'].last_name,
          "contact": userdata.phone_number,
          "country": userdata.nationality,
          "email": userdata.primary_email_id,
          "language": 'en',
          "imageURL": userdata.profile_image
      }
      localStorage.setItem('user_info', JSON.stringify(user_info));
      this.user_info.next(user_info)

  }
}
