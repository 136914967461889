/// <reference types="@types/googlemaps" />
import { Component } from '@angular/core';
// import { } from '@types/googlemaps';
import { LayerDataSource } from './layerdatasource';
import { Style } from './style';
import { Feature } from './feature';
import { Map } from './map';
import { ShareService } from '../services/share.service';

export class Layer {
    name: string;
    source: LayerDataSource;
    style: Style;
    map: Map;
    zoomToLayer: boolean;
    isDataLoaded: boolean = false;
    isLoadedFirstTime: boolean = true;
    onError: any;
    onSuccess: any;
    dataSource: any;
    __dataSource : any;

    dataForStyle:any;

    markers=[];
    showTanent='true';
    constructor(options, private share:ShareService){
      this.map = options.map;
      this.name = options.name;
      this.source = options.source;
      this.style = options.style;
      this.zoomToLayer = options.zoomToLayer;
      this.onError = options.onError;
      this.onSuccess = options.onSuccess;
      if (typeof this.source != 'undefined'){
        this.loadData();
      }

      this.share.showTanent.subscribe(res=>{
        this.showTanent = res;
        this.shoplable(res);
      })
    }

    loadData() {
      this.isDataLoaded = false;
      this.source.get(
        (data) => {
          this.onDataLoad(data);
        },
        (error) => {
          if (this.onError) this.onError('Error code:' + error.statusCode + 'message:' + error.statusText);
        }
      );
    }

    getFeaturesByAttributes(filter, options?): Feature[]  {
      if (!filter) filter = this.source.filter;
      let features = [];
      if (this.map.api == 'googlemap'){
        for (let i = 0; i < this.__dataSource.length; i++) {
          let googleFeature = this.__dataSource[i];
          let keys = Object.keys(filter);
          let filterMatches = false;
          for (let i = 0; i < keys.length; i++) {
            if (googleFeature.getProperty(keys[i]) == filter[keys[i]]){
              filterMatches = true;
            }
            else {
              filterMatches = false;
              break;
            }
          }
          if (filterMatches){
            let properties = [];
            googleFeature.forEachProperty(function(value,property) {
                properties[property] = value;
            });
            let feature = new Feature({
              'attributes': properties
            });
            feature.id = googleFeature.getId();
            features.push(feature);
            googleFeature = null;
          }
        }
      }
      // else if (this.map.api == 'cesium'){
      //   let entities = this.__dataSource.entities.values;
      //   for (let i = 0; i < entities.length; i++) {
      //     let entity = entities[i];
      //     let properties = entity.properties.getValue(new Cesium.JulianDate());
      //     let keys = Object.keys(filter);
      //     let filterMatches = false;
      //     for (let i = 0; i < keys.length; i++) {
      //       if (properties[keys[i]] == filter[keys[i]]){
      //         filterMatches = true;
      //       }
      //       else {
      //         filterMatches = false;
      //         break;
      //       }
      //     }
      //     if (filterMatches){
      //       let feature = new Feature({
      //         'attributes': properties
      //       });
      //       feature.id = entity.id;
      //       features.push(feature);
      //     }
      //     entity = null;
      //   }
      //   entities = null;
      // }
      return features;
    }

    getFeaturesByLocation(lat, lon, radius, options): Feature[]  {
      return null;
    }

    getFeaturesByGeometry(geometry, radius, options): Feature[]  {
      return null;
    }

    getFeatureById(id): Feature  {
      if (this.map.api == 'googlemap'){
        let googleFeature = this.map.map.data.getFeatureById(id);
        let properties = [];
        googleFeature.forEachProperty(function(value,property) {
            properties[property] = value;
        });
        let feature = new Feature({
          'attributes': properties
        });
        feature.id = googleFeature.getId();
        return feature;
      }
      // else if (this.map.api == 'cesium'){
      //   let entity = this.__dataSource.entities.getById(id);
      //   let feature = new Feature({
      //     'attributes': entity.properties.getValue(new Cesium.JulianDate())
      //   });
      //   feature.id = entity.getId();
      //   return feature;
      // }
    }

    addFeature(feature, pesist): boolean {
      //add feature to map.
      //if persist = true, then add it to the layer-datasource
      return true;
    }

    destroy(){
      this.clear();
      if (this.map.api == 'googlemap'){
        this.__dataSource.length = 0;
      }
      this.__dataSource = null;
      this.onSuccess = undefined;
      this.onError = undefined;
      this.map = undefined;
      this.source = undefined;
    }

    clear(){
      if(this.__dataSource) {
        // if (this.map.api == 'cesium'){
        //   this.__dataSource.entities.removeAll();
        //   this.map.map.dataSources.remove(this.__dataSource, true);
        // }
          this.map.map.data.forEach((googleFeature) => {
              this.map.map.data.remove(googleFeature);
          });
        }
      
      this.isDataLoaded = false;
    }

    revertToDefaultStyle() {
      if (this.map.api == 'googlemap'){
        this.map.map.data.revertStyle();
      }
      // else if (this.map.api == 'cesium'){

      // }
    }

    setStyle(feature: Feature, style: Style) {
      if (this.map.api == 'googlemap'){
        let googleFeature = this.map.map.data.getFeatureById(feature.id);
        this.map.map.data.overrideStyle(googleFeature, {
          fillColor: style.fillColor,
          fillOpacity: style.fillOpacity,
          strokeColor: style.outlineColor,
          strokeWeight: style.outlineWidth,
          strokeOpacity: style.outlineOpacity,
          icon: style.iconUrl
        });
      }
      // else if (this.map.api == 'cesium'){
      //   let entity = this.__dataSource.entities.getById(feature.id);
      //   if (typeof entity != 'undefined'){
      //     entity.polygon.fill = (typeof style.fillColor != 'undefined');
      //     entity.polygon.outline = (typeof style.outlineColor != 'undefined');
      //     entity.polygon.closeTop = (typeof style.closeTop != 'undefined')? style.closeTop: true;
      //     entity.polygon.closeBottom = (typeof style.closeBottom != 'undefined')? style.closeBottom: true;
      //     if (typeof style.fillColor != 'undefined'){
      //       entity.polygon.material = Cesium.Color.fromCssColorString(style.fillColor);
      //     }
      //     if (typeof style.outlineColor != 'undefined'){
      //       entity.polygon.outlineColor = Cesium.Color.fromCssColorString(style.outlineColor);
      //     }
      //     if (typeof style.outlineWidth != 'undefined'){
      //       entity.polygon.outlineWidth = style.outlineWidth;
      //     }
      //     entity = undefined;
      //   }
      // }
    }


    onDataLoad(data: any) {     
      if (this.map && this.map.api == 'googlemap'){
        this.dataForStyle = data;
        this.__dataSource = this.map.map.data.addGeoJson(data, {
            //idPropertyName: "unit_id"
            idPropertyName : this.source.idField
        });
        let googleBounds = new google.maps.LatLngBounds();
        let grey :Boolean= false
        for (let i = 0; i < this.__dataSource.length; i++) {
          let googleFeature = this.__dataSource[i];
          ; //because toLowerCase was giving error
          const sub_category = googleFeature.getProperty('sub_category') ? googleFeature.getProperty('sub_category')?.toLowerCase() : '';
          if(sub_category == 'not_retail' || sub_category == 'path'){
            grey =true;
          }else{
            grey =false;
          }
          googleFeature.setProperty('__fillColor', grey ? '#999999' : this.style.fillColor);
          googleFeature.setProperty('__fillOpacity', this.style.fillOpacity);
          googleFeature.setProperty('__outlineColor', grey ? '#64706c' : this.style.outlineColor);
          googleFeature.setProperty('__outlineWidth', this.style.outlineWidth);
          googleFeature.setProperty('__outlineOpacity', this.style.outlineOpacity);
          googleFeature.setProperty('__iconUrl', this.style.iconUrl);
          googleFeature.setProperty('__iconHeight', this.style.iconHeight);
          googleFeature.setProperty('__iconWidth', this.style.iconWidth);
          googleFeature.setProperty('__zIndex', this.style.zIndex);
          googleFeature.setProperty('__clickable', this.style.clickable);
          if (this.isLoadedFirstTime && this.zoomToLayer){
            let geometry = googleFeature.geometry;
            /*if (geometry instanceof google.maps.LatLng) {
              googleBounds.extend(geometry);
            } else if (geometry instanceof google.maps.Data.Point) {
              googleBounds.extend(geometry.get());
            } else {
              geometry.getArray().forEach(function(point) {
                if (point instanceof google.maps.LatLng) {
                  googleBounds.extend(point);
                } else if (geometry instanceof google.maps.Data.Point) {
                  googleBounds.extend(point.get());
                }
              });
            }*/
            if(googleFeature.getGeometry()){
              googleFeature.getGeometry().forEachLatLng(function(latlng){
                googleBounds.extend(latlng);
             });
            }
         
          }
          
          let tenetName = "";
          
          if(this.share.floor_map_obj && this.share.floor_map_obj?.unit_list?.length > 0){            
            let unit = this.share.floor_map_obj.unit_list.filter(e=> googleFeature.getProperty('unit_uid') == e.gid);                  
            if(unit && unit[0]){
              tenetName = unit[0].shop_name;
            }else{
              tenetName="";
            }
          }          
          // add label tanent name
          if(tenetName){
            let marker = new google.maps.Marker({
              position: new google.maps.LatLng(googleFeature.getProperty('lat'), googleFeature.getProperty('lon')),
              map: this.map.map,
              title: "",
              icon: {
                url: 'assets/icon/dot.svg',
              },
              label: {
                text: tenetName,
                color: '#000',
                fontSize: '8px',
              }
            });
         
            this.markers.push(marker);
          }
          this.addMarker(null)
          googleFeature = null;
        }
        let mmm = this;
        google.maps.event.addListener(this.map.map,'zoom_changed', function() {
          if(mmm.showTanent == 'true'  && mmm.map.map.getZoom() > 21){
            mmm.addMarker(mmm.map.map);
          }else{
            //hide marker
            mmm.addMarker(null)
          }         
        })
     
        this.map.map.data.setStyle(function(googleFeature) {
          return /** @type {!google.maps.Data.StyleOptions} */({
            fillColor: googleFeature.getProperty('__fillColor'),
            fillOpacity: googleFeature.getProperty('__fillOpacity'),
            strokeColor: googleFeature.getProperty('__outlineColor'),
            strokeWeight: googleFeature.getProperty('__outlineWidth'),
            strokeOpacity: googleFeature.getProperty('__outlineOpacity'),
            icon: googleFeature.getProperty('__iconUrl'),
            zIndex: googleFeature.getProperty('__zIndex'),
            clickable: googleFeature.getProperty('__clickable')
          });
        });

        if (this.isLoadedFirstTime){
          if (this.zoomToLayer){
            this.map.map.fitBounds(googleBounds);
          }
          this.isLoadedFirstTime = false;
        }
        this.isDataLoaded = true;
        if (this.onSuccess) this.onSuccess();
      }

     

      // else if (this.map && this.map.api == 'cesium'){
      //   Cesium.GeoJsonDataSource
      //     .load(data)
      //     .then((dataSource) => {
      //       try {
      //         let entities = dataSource.entities.values;
      //         for (let i = 0; i < entities.length; i++) {
      //           let entity = entities[i];
      //           if (entity.polygon){
      //             //Height
      //             if (typeof this.style.baseHeight != 'undefined'){
      //               entity.polygon.height = this.style.baseHeight;
      //             }
      //             else if (typeof this.source.baseHeightField != 'undefined'){
      //               entity.polygon.height = entity.properties[this.source.baseHeightField];
      //             }
      //             else {
      //               entity.polygon.height = 0;
      //             }
      //             if (typeof this.style.topHeight != 'undefined'){
      //               entity.polygon.extrudedHeight = this.style.topHeight;
      //             }
      //             else if (typeof this.source.topHeightField != 'undefined'){
      //               entity.polygon.extrudedHeight = entity.properties[this.source.topHeightField];
      //             }
      //             else {
      //               entity.polygon.extrudedHeight = 0;
      //             }

      //             //Polygon color
      //             entity.polygon.fill = (typeof this.style.fillColor != 'undefined');
      //             entity.polygon.outline = (typeof this.style.outlineColor != 'undefined');
      //             entity.polygon.closeTop = (typeof this.style.closeTop != 'undefined')? this.style.closeTop: true;
      //             entity.polygon.closeBottom = (typeof this.style.closeBottom != 'undefined')? this.style.closeBottom: true;
      //             if (typeof this.style.fillColor != 'undefined'){
      //               entity.polygon.material = Cesium.Color.fromCssColorString(this.style.fillColor);
      //             }
      //             if (typeof this.style.outlineColor != 'undefined'){
      //               entity.polygon.outlineColor = Cesium.Color.fromCssColorString(this.style.outlineColor);
      //             }
      //             if (typeof this.style.outlineWidth != 'undefined'){
      //               entity.polygon.outlineWidth = this.style.outlineWidth;
      //             }
      //             //Icon
      //             if (typeof this.style.iconUrl != 'undefined'){
      //               let lat = entity.properties.getValue(new Cesium.JulianDate())[this.source.latField];
      //               let lon = entity.properties.getValue(new Cesium.JulianDate())[this.source.lonField];
      //               dataSource.entities.add({
      //                   rectangle : {
      //                       coordinates : Cesium.Rectangle.fromDegrees(lon - 0.0000075, lat - 0.0000075, lon + 0.0000075, lat + 0.0000075),
      //                       material : this.style.iconUrl,
      //                       height: entity.polygon.extrudedHeight + 0.1
      //                   },
      //                   properties: {}
      //               });
      //             }
      //             else if (typeof this.source.iconUrlField != 'undefined' && entity.properties.getValue(new Cesium.JulianDate())[this.source.iconUrlField] != null){
      //               let lat = entity.properties.getValue(new Cesium.JulianDate())[this.source.latField];
      //               let lon = entity.properties.getValue(new Cesium.JulianDate())[this.source.lonField];
      //               dataSource.entities.add({
      //                   rectangle : {
      //                       coordinates : Cesium.Rectangle.fromDegrees(lon - 0.0000075, lat - 0.0000075, lon + 0.0000075, lat + 0.0000075),
      //                       material : entity.properties.getValue(new Cesium.JulianDate())[this.source.iconUrlField],
      //                       height: entity.polygon.extrudedHeight + 0.1
      //                   },
      //                   properties: {}
      //               });
      //             }
      //           }

      //           if (typeof this.source.titleField != 'undefined'){
      //             entity.name = entity.properties[this.source.titleField];
      //           }
      //           if (typeof this.source.descField != 'undefined'){
      //             entity.description = entity.properties[this.source.descField];
      //           }
      //           else {
      //             entity.description = null;
      //           }
      //         }
      //         this.map.map.dataSources.add(dataSource);
      //         this.__dataSource = dataSource;
      //         if (this.isLoadedFirstTime){
      //           if (this.zoomToLayer){
      //             this.map.map.zoomTo(dataSource);
      //           }
      //           this.isLoadedFirstTime = false;
      //         }
      //         this.isDataLoaded = true;
      //         if (this.onSuccess) this.onSuccess();
      //       }
      //       catch(e){
      //         console.error(e);
      //         if (this.onError) this.onError('Error in fetching layer-data from source: ' + e.message);

      //       }
      //     });
      // }
    }

    addMarker(map){
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(map);
      }
    }

    shoplable(val){
      if(val == 'true' && this.map.map.getZoom() > 21){
        this.addMarker(this.map.map);
      }else{
        this.addMarker(null);
      }
    }
}
