<div *ngIf="!mobile">
    <div *ngIf="selected_fg_id.length>0">
        <div *ngIf="floor" class="switch3">
            <span matTooltip="Paste in Floor">
                <div (click)="openDialog('floorPaste')" class="icon-container">
                    <mat-icon id="floor-icon" svgIcon="floor"></mat-icon>
                </div>
            </span>
        </div>
        <div class="floating3 animated bounceIn delay-2s">
            <div (click)="showFloor()" class="round mat-elevation-z6">
                <div>
                    <mat-icon id="shuffle" svgIcon="shuffle"></mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div id="DynamicBlock" class="block1">
        <div class="button-block1">
            <ng-container  *ngFor="let data of group_custom_list_floor">
                <button class="big-button" [ngClass]="{'active':isActive(data.id),'floor-style':data.id == 0,'remark-style':(isRerorkFloorGroup(data)) || (data.id == 0 && hasRejection)}">
                    <div class="cent">
                        <mat-checkbox (change)="onCheckStatus($event)" value="{{data.id}}"></mat-checkbox>
                    </div>
                   <div style="position: absolute;
                   padding: 0;
                   left: 30px;
                   width: 90%;
                   height: 56px;">
                    <span style="padding-left: 10px ;" class="cent-start" (click)="openOptions(data)" *ngIf="data.name == 'unit' && !geometry_loader">
                        {{data.group_name}}
                    </span>
                    <span style="padding-left: 10px ;" class="cent-start" (click)="openOptions(data)" *ngIf="data.name != 'unit'">
                        {{data.group_name}}
                    </span>
                    <span style="padding-left: 10px ;" class="cent-start" *ngIf="data.name == 'unit' && geometry_loader">
                        Loading...
                    </span>
                   </div>


                </button>
            </ng-container>

        </div>
    </div>
</div>

<div *ngIf="mobile">
    <app-mobile-toolbar [back]="true" [title]="'Floor Information'" [property_uid]="selected_property.property_id"
        [customClickAction]="true" [customObj]="this" [floor_name]="selected_floor.name" [floor_uid]="selected_floor.uid"></app-mobile-toolbar>

    <div *ngIf="floor && (selected_fg_id.length > 0) && floor_access.POST" class="textBox">
        <span id="pasteInFloor">Paste in Floor</span>
    </div>
    <div *ngIf="selected_fg_id.length>0 && floor_access.POST">
        <div *ngIf="floor" class="switch">
            <div (click)="openDialog('floorPaste')" class="icon-container">
                <mat-icon id="floor-icon" svgIcon="floor"></mat-icon>
            </div>
        </div>
        <div class="floating animated bounceIn delay-2s">
            <div (click)="showFloor()" class="round">
                <div>
                    <mat-icon id="shuffle" svgIcon="shuffle"></mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="list-container-mobile" id="pList">
        <div class="block">
            <div class="button-block">
                <ng-container *ngFor="let data of group_custom_list_floor">
                    <div *ngIf="data.status" class="big-button-m"
                        [ngClass]="{'active':isActive(data.id),'floor-style':data.id == 0,'remark-style':(isRerorkFloorGroup(data)) || (data.id == 0 && hasRejection)}">
                        <div>
                            <span class="m-3 cent-start" (click)="openOptions(data,true)" *ngIf="data.name == 'unit' && !geometry_loader">
                                {{data.group_name}}
                            </span>
                            <span class="m-3 cent-start" (click)="openOptions(data,true)" *ngIf="data.name != 'unit'">
                                {{data.group_name}}
                            </span>
                            <span class="m-3 cent-start" *ngIf="data.name == 'unit' && geometry_loader">
                                Loading...
                            </span>
                           </div>
                        <div class="cent" id="check-box">
                            <mat-checkbox (change)="onCheckStatus($event)" value="{{data.id}}"></mat-checkbox>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
