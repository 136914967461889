<div class="inv" *ngIf="isLocalServe || isMobile">
  <div class="login-wrapper">
    <div class="login-middle" [class.forgotpassword]="showForgot">
      <div class="logo-contain">
        <div fxLayout="row" class="w-100" fxLayoutAlign="center center">
          <img class="hvr-grow cl" routerLink="/" src="assets/images/logo.svg"  width="220"/>
      </div>
      </div>
      <div class="login-container" *ngIf="!showForgot">
        <mat-card appearance="outlined" class="cen">
          <mat-card-content>

              <div>
                <div class="top-label">Login</div>
                <form [formGroup]="loginForm" (ngSubmit)="doLogin(loginForm.value)">
                  <div class="inside-content">

                    <mat-form-field class="full-width">
                      <mat-icon matPrefix class="mat-icons">email</mat-icon>
                      <input matInput type="email" formControlName="email" placeholder="Enter your email address" value="" required>

                      <mat-error>Email is required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width">
                      <mat-icon matPrefix class="mat-icons">lock_outline</mat-icon>
                      <input type="password" formControlName="password" matInput placeholder="Enter your password" value="" required [type]="hide ? 'password' : 'text'">
                      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </mat-form-field>

                    <div *ngIf="error" class="login-err">{{error}}</div>
                    <div class="button-row">
                      <button [disabled]="loginForm.invalid || login_action" class="login-btn">
                        <span *ngIf="!login_action">Login</span>
                        <span *ngIf="login_action">Logging in...</span>
                        <mat-icon *ngIf="!login_action">arrow_forward</mat-icon>
                      </button>
                    </div>
                  </div>
                </form>
                <div class="bottom-div"  (click)="jumpForgot()">
                  Forgot Password?
                </div>
              </div>

          </mat-card-content>
        </mat-card>
      </div>

      <div class="forgot-container" *ngIf="showForgot">
        <app-reset-password (gotologin)="closingStatus($event)"></app-reset-password>
        <!-- <div class="inside-content">
          <p class="text">Enter your email. We'll email instructions on how to reset your password</p>
          <mat-form-field class="full-width">
            <mat-icon matPrefix class="mat-icons">email</mat-icon>
            <input [(ngModel)]="forget_email" matInput placeholder="Enter your email address" type="email" >
          </mat-form-field>
          <span *ngIf="f_error" class="forget-err">{{forget_message}}</span>
          <span *ngIf="!f_error" class="forget-success">{{forget_message}}</span>
          <div class="button-row">
            <button type="button" (click)="jumpForgot()" class="back-btn">
              <mat-icon>arrow_back</mat-icon>
              <span>Back</span>
            </button>
            <button [disabled]="!forget_email ||!enable_btn" (click)="forgetPassword()" type="button" class="login-btn">
              <span *ngIf="enable_btn">Submit</span>
              <span *ngIf="!enable_btn">Please wait...</span>
              <mat-icon *ngIf="enable_btn">arrow_forward</mat-icon>
            </button>

          </div>
        </div> -->
      </div>

    </div>
  </div>
</div>
