import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ShareService } from '../services/share.service';
import { Clipboard } from "@angular/cdk/clipboard";
import { NotificationService } from '../services/notification.service';
@Component({
  selector: 'app-mobile-toolbar',
  templateUrl: './mobile-toolbar.component.html',
  styleUrls: ['./mobile-toolbar.component.css']
})
export class MobileToolbarComponent implements OnInit {
  @Input() title = '';
  @Input() caption = '';
  @Input() property_uid = '';
  @Input() floor_name = '';
  @Input() unit_uid = '';
  @Input() username = '';
  @Input() nav_link = '';
  @Input() refreshView:boolean = false;
  @Input() back:boolean = false;
  @Input() customClickAction:boolean = false;
  @Input() customObj:any;
  @Input() selected_entity;
  @Input() totalCount;
  @Input() floor_uid = '';

  constructor(private route: Router, private share: ShareService,private clipboard: Clipboard,
    private notify: NotificationService) { }

  jumpToUrl(link){
    if(this.customClickAction){
      this.customObj.backClicked();
      return;
    }
    this.route.navigateByUrl(link);
  }
  reset(){
    this.customObj.repositionbox();
  }
  ngOnInit() {

  }
  public copyToClipboard(id): void {
    const successful = this.clipboard.copy(id);
    if (successful) {
      this.notify.notify(
        `Uid ${id} copied`,
        "success"
      );
    } else {
      this.notify.notify(`Not copied, try again`, "warn");
    }
  }
}
