import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShareService } from '../../services/share.service';
import { ApiserviceService } from '../../services/apiservice.service';
import { NotificationService } from '../../services/notification.service';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>umsapi_place_get</li>
* <li>gisapi_cities_json_patch</li>
* </ol>
*
*/

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {
  mobile: boolean = false;
  selectedcity;
  citieslist = [];
  enablesubmit: boolean;
  search_param='';
  searchFilter=new FormControl();

  protected _onDestroy = new Subject<void>();
  constructor(private share: ShareService, private api: ApiserviceService, private router: Router, private notify: NotificationService) {
    this.mobile = share.mobile;
    if (!localStorage.getItem('user_id')) {
      this.api.logout(false);
    }
    localStorage.removeItem("city_id");
    localStorage.removeItem('city_name');
    localStorage.removeItem('city_slug');
    localStorage.removeItem('city_code');
    localStorage.removeItem('city_detail');
    this.getCityList();
  }

  getCityList() {
    this.api.getUmsData('country-with-city')
      .subscribe({
        next: (res: any) => {
        if(res.status == 200){
          let tempList = [];
          res.data.forEach((element) => {
            if (element.status == 1) {
              if(element?.child_data && element?.child_data?.length > 0){
               element.child_data.map(e=>{
                   e['parent_name']=e?.country_name;
                   e['search_param']=e.place_name+' '+e?.country_name;
                   tempList.push(e);
               })                            
              }else{
               element['search_param']=element.place_name;
               tempList.push(element);
              }
               
           }
          });
          this.citieslist = tempList;
        }
    },error:()=>{

    }
  });
}

  selectCity(val) {

    localStorage.setItem('city_name', val.place_name);
    this.selectedcity = {
      city_id: val.place_id,
      city_name: val.place_name,
      place_code: val.place_code,
      lat: val.latitude,
      lon: val.longitude
    };
    this.api.city_code = this.selectedcity.place_code;
    this.enablesubmit = true;
    if (!this.mobile) this.submitCity();
    if (this.share.sideNav_obj) this.share.sideNav_obj.getCity();
  }

  submitCity() {
    if (this.selectedcity.city_id) {
      localStorage.setItem("city_id", this.selectedcity.city_id);
      if (this.selectedcity.place_code) localStorage.setItem('city_code', this.selectedcity.place_code);
      this.api.city_id = this.selectedcity.city_id;
      // this.getCityDetail();

      localStorage.setItem('city_detail', JSON.stringify(this.selectedcity));
      setTimeout(() => {
        this.api.getAllowResources();
      }, 100);
      this.api.getUserDetail(this.api.user_id);
      this.api.getGoogleMapKey();
      this.router.navigateByUrl('/home');
    }
  }
   
  //logout 
   logout(){
      this.api.logout(false);
  }
  ngOnInit() {
    this.api.getGoogleMapKey();
    this.searchFilter.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe((val) => {
      this.search_param = val;
    });
  }
  ngOnDestroy() {

  }
}
