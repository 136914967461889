<div style="border-right: 1px solid #ddd;">
    <div class="page-title"><span>Upload Photos and Videos</span></div>
    <form [formGroup]="imageForm">
        <div class="button-block" id="pList">
            <div class="box1" *ngIf="image_attr">
                <div class="image-upload" *ngIf="!isHiddenImg">
                    <label for="photoChips" class="label-text">Photos <span *ngIf="isImageMandatory" class="required">*</span></label>
                    <div class="row-fluid">
                        <div class="photoChips">
                            <div *ngFor="let name of photoList; let i = index;" class="pChip animated fadeIn delay-2s" (click)="openDialog('app-description',{image:name})">
                                <div [disabled]="image_attr.is_editable == false" id="selection" class="pointer">
                                    <ngx-loading [show]="name.loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                                    <img id="{{name.id}}" class="i-mob" src="{{name.imageURL}}" alt="Image">
                                </div>
                                <div id="chip" [ngClass]="{'active':name.isDefault}">
                                    <div class="iName"><span>estImage {{i+1}}</span></div>
                                    <mat-icon (click)="remove(null,name, $event)" id="delete">cancel</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="upload">
                        <input id="image_input" #imageInput (change)="uploadExplorImage($event)" accept="image/*" type="file" formControlName="image" />
                        <button [disabled]="uploading || image_attr.is_editable == false" class="upload-drag" (click)="openExplorer('image')">Drag File <br> Or <br> Upload</button> <span>&nbsp;( Max 10 )</span>
                    </div>
                </div>
            </div>
            <div class="box1" *ngIf="video_attr" >
                <div class="video-upload" *ngIf="!isHiddenVideo">
                    <label for="videoChips" class="label-text">Videos <span *ngIf="isVideoMandatory" class="required">*</span></label>
                    <div class="row-fluid">
                        <div class="videoChips">
                            <div *ngFor="let data of videoList" class="pChip animated fadeIn delay-2s">
                                <!-- <div id="selection">
                                    <ngx-loading [show]="data.loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                                    <img id="{{data.id}}" class="i-mob" src="{{data.videoThumbURL}}" alt="Image">
                                </div> -->
                                <div class="popup">
                                  <video controls (canplay)="pauseVideo(videoplayer)"
                                    #videoPlayer>
                                  <source src="{{data.videoURL}}" type="video/mp4">
                                  Your browser does not support the video tag.
                                  </video>
                                </div>
                                <div id="chip">
                                    <div class="iName"><span>{{data.videoName}}</span></div>
                                    <div>
                                       <mat-icon id="delete" (click)="remove('vid', data, $event)">cancel</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="upload">
                        <input id="video_input" (change)="uploadVideos($event)" accept="video/*"  type="file" />
                        <button [disabled]="uploading || video_attr.is_editable == false" class="upload-drag" (click)="openExplorer('video')">Drag File <br> Or <br> Upload </button>  <span>&nbsp;( Max 5 )</span>
                    </div>
                    <div class="url">
                        <div><span class="label1">Video URL </span></div>
                        <div><input id="video_url" [disabled]="video_attr.is_editable == false" class="url-input" type="text" name="url" placeholder="video url"></div>
                        <button matTooltip="Publish" [disabled]="video_attr.is_editable == false" (click)="uploadVideoByUrl()" id="resize" mat-button><mat-icon class="icon-upload">publish</mat-icon></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- <div *ngIf="mobile">
    <app-mobile-toolbar [back]="true" [title]="'Photos and Videos'" [property_uid]="selected_property.property_id" [customClickAction]="true" [customObj]="this"></app-mobile-toolbar>

    <div class="page-title"><span>Upload Photos and Videos</span></div>
    <form [formGroup]="imageForm">
        <div class="button-block" id="pList">
            <div class="box1" *ngIf="image_attr">
                <div class="image-upload">
                    <label for="photoChips" class="label-text">Photos</label>
                    <div class="row-fluid">
                        <div class="photoChips">
                            <mat-chip-option *ngFor="let name of photoList" class="pChip animated fadeIn delay-2s" [selectable]="selectable" [removable]="removable" (removed)="remove(null,name)">
                                <div (click)="openDialog('app-description',{image:name})" id="selection">

                                    <ngx-loading [show]="name.loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                                    <img id="{{name.id}}" class="i-mob" src="{{name.imageURL}}" alt="Image">
                                </div>
                                <div id="chip" [ngClass]="{'active':name.isDefault}">
                                    <div class="iName"><span>estImage</span></div>
                                    <div>
                                    <mat-icon (click)="remove(null,name, $event)" id="delete" matChipRemove>cancel</mat-icon>
                                    </div>
                                </div>
                            </mat-chip-option>
                        </div>
                    </div>
                    <div class="upload">
                        <input id="image_input"#imageInput (change)="uploadExplorImage($event)" accept="image/*" type="file" formControlName="image" />
                        <button [disabled]="image_attr.is_editable == false" class="upload-drag upload-drag-m" (click)="openExplorer('image')">Upload Photo</button> <span>&nbsp;( Max 10 )</span>
                    </div>
                </div>
            </div>
            <div class="box1" *ngIf="video_attr">
                <div class="video-upload">
                    <label for="videoChips" class="label-text">Videos</label>
                    <div class="row-fluid">
                        <div class="videoChips">
                            <mat-chip-option *ngFor="let data of videoList" class="pChip animated fadeIn delay-2s" [selectable]="selectable" [removable]="removable" (removed)="remove('vid', data)">
                                <div id="selection">
                              g [show]="data.loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                                    <img id="{{data.id}}" class="i-mob" src="{{data.videoThumbURL}}" alt="Image">
                                </div>
                                <div id="chip">
                                    <div class="iName"><span>{{data.videoName}}</span></div>
                                    <div>
                                        <mat-icon id="delete" matChipRemove>cancel</mat-icon>
                                    </div>
                                </div>
                            </mat-chip-option>
                        </div>
                    </div>
                    <div class="upload">
                        <input id="video_input" (change)="uploadVideos($event)" accept="video/*" type="file" />
                        <button [disabled]="video_attr.is_editable == false" class="upload-drag upload-drag-m" (click)="openExplorer('video')">Upload Video</button>  <span>&nbsp;( Max 5 )</span>
                    </div>
                    <div class="url">
                        <div><span class="label1">Video URL </span></div>
                        <div><input [disabled]="video_attr.is_editable == false" id="video_url" class="url-input" type="text" name="url" placeholder="video url"></div>
                        <button [disabled]="video_attr.is_editable == false" (click)="uploadVideoByUrl()" id="resize" mat-button><mat-icon class="icon-upload">publish</mat-icon></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div> -->
