// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC58On0h_c59jMtJ4vqfgFnptcfIFKngnU",
    authDomain: "notify-43ece.firebaseapp.com",
    databaseURL: "https://notify-43ece.firebaseio.com",
    projectId: "notify-43ece",
    storageBucket: "notify-43ece.appspot.com",
    messagingSenderId: "610400916616"
  },

  estUrl : "https://estapi.dev10.in/estater/api/",
  emsUrl: "https://ems.dev10.in/",
  epsUrl: "https://eps.dev10.in/",
  umsUrl : "https://umsapinode.dev10.in/",
  // gisUrl : "https://gisapi.dev10.in/gis/api/",
  ges : "https://ges.dev10.in/",
  gms : "https://gms.dev10.in/",
  gps : "https://gps.dev10.in/",
  gmus : "https://gmus.dev10.in/",

  wms_url : "https://admin.dev10.in/geoserver/",
  geoestater : 'https://ged.gisworld.in/',
  user_token : 'TmpNPU0yTTJaVEJpT0dFNVl6RTFNakkwWVRneU1qaGlPV0U1T0dOaE1UVXpNV1E9TlRjNE1HVXdPRE00Wm1KaFpHSTRNMlF3WXpjeFpUQm1aV1ZqTmpSbU5EZz1NMk0yWlRCaU9HRTVZekUxTWpJMFlUZ3lNamhpT1dFNU9HTmhNVFV6TVdRPTE1Njk0NzgyNjE=',
  api_key: '31107fe38cd8e7034b80096c0d93fd3d',

  baseURL : 'https://de2.dev10.in',
  adminHost : "https://umsnew.dev10.in",
  //  adminHost : "http://localhost:4200",
  //  baseURL : 'http://localhost:6600'
  isLocalServe: false,
}
