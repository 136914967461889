<div *ngIf="!mobile">
    <div class="list-container">
        <div class="block">
            <div class="fit">
                
            </div>
           
            <!-- <div class="addressBox" (click)="openEdit()">
                <span>Address:</span>
                <span class="addrLine">{{address}}</span>
            </div>

            <div *ngIf="is_Address()" class="nav-btn_div">
                <div class="navDiv">
                    <button type="button" mat-button class="cancel_" (click)="cancelProperty()">
                        Cancel
                        <mat-icon class="cross">close</mat-icon>
                    </button>
                    <button *ngIf="property_access.POST" type="button" mat-button class="bluebutton1" (click)="createProperty()">
                        {{getSavetext()}}
                        <mat-icon class="save">save</mat-icon>
                    </button>
                </div>

            </div> -->
        </div>
        <div class="block">
            <div #gmap class="gmapWeb"></div>
            <div class="gps-web">
                <mat-icon (click)="getCurrentLocation()" id="gps" class="hvr-grow">gps_fixed</mat-icon>
            </div>
            <div class="cancel-web">
                <button mat-raised-button (click)="backToProperty()"> Cancel</button>
            </div>

            <!-- <div class="zoom">
                <div class="zoomBtn" (click)="zIn()">
                   <h1>+</h1>
                </div>
                <div class="zoomBtn" (click)="zOut()">
                    <h1>-</h1>
                </div>
            </div> -->

        </div>

    </div>
    <div *ngIf="loader" class="loader-pos">
        <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(0,0,0,0.6)'}"></ngx-loading>
    </div>
</div>


<div *ngIf="mobile">
    <mat-toolbar class="toptoolbar new">
        <div class="button-block">
            <div>
                <div #gmap class="gmap D-height" (click)="showSearchPane = false">

                </div>
                <div class="gps">
                    <mat-icon (click)="getCurrentLocation()" id="gps">gps_fixed</mat-icon>
                </div>
                <!-- <div *ngIf="loader" class="loader-pos"> -->
                    <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(0,0,0,0.6)'}"></ngx-loading>
                <!-- </div> -->
            </div>



            <div class="btn-next">
                <div class="addressBox">
                    <div class="colored">Address:</div>
                    <div>
                        <p id="colr" (click)="openEdit()">
                            <u>{{address}}</u>
                        </p>
                    </div>
                </div>
                <div id="nav-btn">
                    <button [routerLink]="['/property/'+assignment_id]" mat-button class="cancel">
                        Cancel
                        <mat-icon class="cross">close</mat-icon>
                    </button>
                    <button [disabled]="!neighbourhood || !block" (click)="createProperty()" mat-button class="nxtBtn">
                        Next
                        <mat-icon class="forward">arrow_forward</mat-icon>
                    </button>

                </div>
            </div>
        </div>
    </mat-toolbar>
</div>