<div>
    <div class="dialog1">
        <div class="toolbar_div"><mat-toolbar class="tool">Update Parcel - {{parcel_detail.parcel_uid}}</mat-toolbar></div>
        <div class="form">
            <div class="boxDiv">
                <div>
                    <span>Vacant status</span>
                </div>
                <div>
                    <label class="switch">
                        <input type="checkbox" class="chkBox" [(ngModel)]="is_vacant">
                        <span class="slider round">
                          <div class="toggle-container">
                            <span class="text">Yes</span>
                            <span class="text">No</span>
                          </div>
                        </span>
                      </label>
                </div>
            </div>
            <div class="boxDiv">
                <div>
                    <span>Comment</span>
                </div>
                <div>
                   <textarea class="form-input" style="max-height: 200px; min-height: 70px;" [(ngModel)]="comment"></textarea>
                </div>
            </div>
        </div>
        <div class="action">
            <div>
                <button (click)="save()" class="act" mat-button>Save</button>
            </div>
        </div>
    </div>
</div>
