import { Component, OnInit, ViewChild, Inject, ViewChildren, ElementRef, OnChanges, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, Validators, FormGroup, FormControl, UntypedFormBuilder, FormArray } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
// import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs';
// import { } from '@types/googlemaps';
import { Map } from '../../map_api/map';
import { LatLon } from '../../map_api/latlon';
// import { MainComponent } from '../main.component';
import { ShareService } from '../../services/share.service';
import { ApiserviceService } from '../../services/apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import * as $ from 'jquery';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { map, startWith } from 'rxjs';
import { environment } from '../../../environments/environment';
export class State {
  constructor(public name: string) { }
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>gisapi_parcels_json_get</li>
 * </ol>
 *
 */

@Component({
  selector: 'parcel-map',
  templateUrl: './parcel.component.html',
  styleUrls: ['./parcel.component.css']
})
export class ParcelComponent implements OnInit {
  map: Map;
  mobile: boolean = false;
  dHeight: number;
  gLat: number;
  gLon: number;
  assignment_id: number;
  assignment_detail: any;
  property_access: any;
  polygon:any;
  selected_parcel:any;
  loader:boolean = false;
  public city_lat_lon = {
    lat: '',
    lon: ''
  };
  @ViewChild('gmap') gmapElement: any;

  constructor(public dialog: MatDialog, private elementRef: ElementRef, private share: ShareService, private _zone: NgZone,
    private api: ApiserviceService, private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private fnc: CommonfunctionService, private notify: NotificationService) {
    iconRegistry.addSvgIcon('pin', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Pin.svg'));
    this.mobile = this.share.mobile;
    if (!this.share.block0_obj) {
      this.router.navigateByUrl('/');
      return;
    }
    this.property_access = this.share.block0_obj.property_access;
    this.assignment_id = this.share.block0_obj.assignment_id;
    this.assignment_detail = this.share.block0_obj.assignment_detail;

    this.api.setApiUrl();
    this.share.block0_obj.getAllowResource();
    setTimeout(() => {
      this.property_access = this.share.block0_obj.property_access;
    }, 800);
  }

  backToProperty(){
    this.router.navigateByUrl('property/' + this.share.block0_obj.assignment_id);
  }


  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        //this.map.panTo({ 'lat': position.coords.latitude, 'lng': position.coords.longitude }, false);
        this.map.panTo(new LatLon(position.coords.latitude, position.coords.longitude, 0));
      }.bind(this));
    }
  }
  getBound(polygon) {
    var bounds = new google.maps.LatLngBounds();
    var paths = polygon.getPaths();
    var path;
    for (var i = 0; i < paths.getLength(); i++) {
      path = paths.getAt(i);
      for (var ii = 0; ii < path.getLength(); ii++) {
        bounds.extend(path.getAt(ii));
      }
    }
    this.map.map.fitBounds(bounds);
  }
  renderMap(lt = null, ln = null) {
    if (lt == null) {
      if (localStorage.getItem('city_detail')) {
        let city_detail = JSON.parse(localStorage.getItem('city_detail'));
        if (city_detail.lat) {
          lt = city_detail.lat;
          ln = city_detail.lon;
        } else {

        }
      }

    }
    //Add map
    let geomdata = this.getgeom(this.assignment_detail.geom); // converting geom data to point
    //Add map

    let options = {

      center: new LatLon(lt, ln, 0),

      zoom: 13,

      showZoomControl: this.mobile ? false : true,

      showScaleControl: true,

      showMapTypeControl: true,

      showFullScreenControl: true,

      element: this.gmapElement.nativeElement,

      api: 'googlemap'

    };

    this.map = new Map(options);

    let start;
    if (geomdata) {
      // draw boundary
      let poly = new google.maps.Polyline({
        path: geomdata,
        strokeColor: '#FF0000',
        strokeOpacity: 1,
        strokeWeight: 3
      });
      poly.setMap(this.map.map);
      // center point of polygon
      this.polygon = new google.maps.Polygon({
        paths: geomdata
      });
      this.getBound(this.polygon);
      //this.map.panTo(ltln,false);
    }
    //Get long-press event to map by finding time-gap between mousedown and mouseup
    this.map.addEventListener('mousedown', (e) => {
      start = new Date().getTime();
    });

    this.map.addEventListener('mouseup', (e) => {
      var end = new Date().getTime();
      var tm = end - start;
      var longpress = (tm > 800) ? true : false;
      let p_name = 'Parcel';
      if (longpress) {
        this._zone.run(() => {
          this.gLat = e.latlon.getLat();
          this.gLon = e.latlon.getLon();

          //remove all markers
          this.map.removeAllMarkers();

          //add new marker with drag-end event
          this.map.addMarker(e.latlon, {
            'title': 'My property',
            'info': '<div id="content">' +
              p_name +
              '</div>',
            'iconUrl': '../assets/icon/Pin.svg',
            'iconWidth': 50,
            'iconHeight': 50,
            'dragend': (e) => {
              this._zone.run(() => {
                this.gLat = e.latlon.getLat();
                this.gLon = e.latlon.getLon();
                this.getLocationInfo();
              });
            }
          });

          this.getLocationInfo();
        });
      }
    });
  //WMS layer
  // this.map.addLayer({
  //   'format': 'wms',
  //   'layerName': 'parcels',
  //   'name': 'parcels',
  //   'url' : this.api.wms_url
  // });
  this.map.addGeoLayer({
    'format': 'wms',
    'layerName': 'parcels',
    'name': 'parcels',
    'url': environment.geoestater+'wms-layer',
    'city_code': this.api.city_code,
    'city_id': this.api.city_id,
    'user_token': environment.user_token
  });
  }


  getLocationInfo() {
    this.loader = true;
    let url = "parcels/json?lat=" + this.gLat + "&lon=" + this.gLon + "&return_geometry=true";
    this.api.getGpsData(url)
      .subscribe({next: (data:any) => {
        this.loader = false;
        if(data.length){
          this.selected_parcel = data[0];
          this.openDialog('update-parcel');
        }
      },
      error:(err) => {
        this.loader = false;
        this.notify.notify('invalid location','error')
        if (err['status'] == 401) {
          this.notify.notify('You are not authorize for GIS', 'error');
         }
      }})
  }


  cancelProperty() {
    this.router.navigateByUrl('property/' + this.assignment_id);
  }
  openDialog(type: string, info: any = {}): void {
    let dialogview;
    info.pObj = this;
    if (type == 'update-parcel') {
      dialogview = UpdateParcelComponent
    }
    if (dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: '350px',
        panelClass: 'create-master-panel',
        data: info,
        //disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }


  getgeom(data) {
    if (data != null) {
      let str = data;
      let result = str.match(/\(\((.*)\)\)/);
      let str2 = result[1];
      let latlon = str2.split(',');

      let temp = [];
      latlon.forEach(el => {
        let s = el.split(' ')
        temp.push({ "lat": +s[1], "lng": +s[0] });
      });
      return temp;
    }
  }
  ngOnInit() {
    setTimeout(() => {
      this.dHeight = (this.share.heightService + 9);
      let e = $(this.elementRef.nativeElement);
      e.find('.D-height').css('height', this.dHeight);
        this.renderMap();
    }, 10);
  }

  ngOnDestroy() {
    this.map = null;
  }
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>gisapi_parcels_put</li>
 * </ol>
 *
 */

@Component({
  selector: 'update-parcel',
  templateUrl: './update-parcel.component.html',
  styleUrls: ['./parcel.component.css']
})

export class UpdateParcelComponent {
  parcel_detail:any;
  comment:string='';
  is_vacant:boolean = false;
  parent:any;
  constructor(public dialogRef: MatDialogRef<UpdateParcelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder, private api: ApiserviceService,
    private fnc: CommonfunctionService, private share: ShareService) {
      this.parent = data.pObj;
      this.parcel_detail = this.parent.selected_parcel;
      if(this.parcel_detail.vacant){
        this.is_vacant = true;
      }else{
        this.is_vacant = false;
      }
    }

 save(){
   let body = {
    "comment": this.comment,
    "updated_by": this.api.user_id,
    "vacant": this.is_vacant
  };
  this.api.putGpsData('parcels/'+this.parcel_detail.parcel_uid,body)
  .subscribe(
    data=>{
      this.parent.notify.notify('Parcel information updated','success');
      this.data.pObj.map.refresh();
      this.dialogRef.close();
    },
    err=>{
      this.parent.notify.notify('Something went wrong','error');
      this.dialogRef.close();
    }
  );
 }
  ngOninit() {
    if(!this.parcel_detail){
      this.parent.notify.notify('Invalid parcel','warn');
      this.dialogRef.close();
    }
  }

}
