import { Injectable } from '@angular/core';
import { ApiserviceService } from './apiservice.service';

@Injectable()
export class CommonfunctionService {

  constructor(private api: ApiserviceService) {
    //this.checkResourceAccess('attributegroups','GET',false);

  }

  // extract value by key from object array, return object
  getArrayValue(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (obj[key] == val && flag) {
          res = obj;
          flag = false;
        }
      });
    }
    return res;
  }

  // return index of oblect from object array, return number
  getArrayindex(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach((obj, k) => {
        if (obj[key] == val && flag) {
          res = k;
          flag = false;
        }
      });
    }
    return res;
  }

  // get array of child element from parent
  getChildArray(key: any, val: any, arrayObj: any): any {
    let res: any = [];
    let i = 0;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (obj[key] == val) {
          res[i] = obj;
          i++;
        }
      });
    }
    return res;
  }

  // move array element by changing it's index
  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  // remove particular value from array of object
  getDeletedArray(key: any, val: any, arrayObj: any): any {
    if (Array.isArray(val)) { // accepting both array and single value
      val = val;
    } else {
      val = [val];
    }
    let res: any = [];
    let i = 0;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (val.indexOf(obj[key]) == -1) {
          res[i] = obj;
          i++;
        }
      });
    }
    return res;
  }

  // duplicate value from array

  removeDuplicateKey(arr: Array<any>) { // not in use
    let uniqueArray = [];
    arr.forEach(function (element) {
      if (uniqueArray.indexOf(element) === -1) {
        uniqueArray.push(element);
      }
    });
    return uniqueArray;
  }

  // copy json object without reference
  deepCopyJson(obj) {
    let copy = obj, k;

    if (obj && typeof obj === 'object') {
      copy = Object.prototype.toString.call(obj) === '[object Array]' ? [] : {};
      for (k in obj) {
        //copy[k] = this.deepCopyJson(obj[k]);
        copy[k] = obj[k];
      }
    }

    return copy;
  }

  jsonLength(obj) {
    return Object.keys(obj).length;
  }

  jsonCompare(obj1: any, obj2: any) {
    let flag = true;
    if (Object.keys(obj1).length == Object.keys(obj2).length) {
      for (let key in obj1) {
        if (obj1[key] == obj2[key]) {
          continue;
        }
        else {
          flag = false;
          break;
        }
      }
    }
    else {
      flag = false;
    }
    return flag;
  }

  getMatchPercent(a, b) {
    let equivalency = 0;
    let minLength = (a.length > b.length) ? b.length : a.length;
    let maxLength = (a.length < b.length) ? b.length : a.length;
    for (let i = 0; i < minLength; i++) {
      if (a[i] == b[i]) {
        equivalency++;
      }
    }
    let weight = equivalency / maxLength;
    return (weight * 100);
  }

  getErrorMessage(err) {
    let msg = JSON.parse(err);
    return msg.message;
  }

  getAddressText(address_obj: any) {
    let address = '';
    if (address_obj['neigh_name']) address += '' + address_obj.neigh_name + ", ";
    if (address_obj['block_name']) address += 'Block ' + address_obj.block_name + ", ";
    if (address_obj['street_name']) address += '' + address_obj.street_name + ", ";
    if (address_obj['lane_name']) address += '' + address_obj.lane_name + ", ";
    if (address_obj['parcel_no']) address += 'Parcel ' + address_obj.parcel_no + ", ";
    if (address_obj['house_no']) address += 'House No ' + address_obj.house_no + ", ";
    if (address_obj['building_name']) address += ' ' + address_obj.building_name;
    if (address) {
      return address;
    } else {
      return 'Invalid address';
    }
  }

  checkResourceAccess(name, flag = true) {
    let resource = {
      GET: false,
      PATCH: false,
      POST: false,
      DELETE: false
    }
    if (flag) {
      this.api.getAllowResources(); //call the api again
    }
    let resources = this.api.allowResources;
    resources.forEach(element => {
      if (element.resource_name == name) {
        if (element.methods.indexOf('GET') != -1) {
          resource.GET = true;
        }
        if (element.methods.indexOf('POST') != -1) {
          resource.POST = true;
        }
        if (element.methods.indexOf('PATCH') != -1) {
          resource.PATCH = true;
        }
        if (element.methods.indexOf('DELETE') != -1) {
          resource.DELETE = true;
        }
      }
    });
    return resource;
  }
  //date formate
  formatDate(value) {
    return value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getDate().toString().padStart(2, 0);
  }

  formatDateTime(value: any) {
    return value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getDate().toString().padStart(2, 0) + " " + value.getHours().toString().padStart(2, 0) + ":" + value.getMinutes().toString().padStart(2, 0) + ":" + value.getSeconds().toString().padStart(2, 0);
  }

  capitalize_each_word(str){
    if(str){
      return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    }else{
      return null;
    }

  }


    // get UTC date
    formatDateUTC(dt: any, getTime: boolean = false) {
      if(dt){
        dt = new Date(dt);
        let value;
        value = new Date(dt + ' UTC');
        if (getTime) {
          return  value.toLocaleDateString('en-GB', {day: 'numeric',month: 'short',year: 'numeric'}) + " " + value.getHours().toString().padStart(2, 0) + ":"
            + value.getMinutes().toString().padStart(2, 0) + ":" + value.getSeconds().toString().padStart(2, 0);
        } else {
          return (
            value.toLocaleDateString('en-GB', {day: 'numeric',month: 'short',year: 'numeric'})
          );
        }
      }
      return '';
    }


}
