import { Component, OnInit, Output, EventEmitter, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { transformMenu } from '@angular/material/menu';
import { MAT_SORT_HEADER_INTL_PROVIDER } from '@angular/material/sort';
import { NgForm, Validators, UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, FormArray } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from '../../../services/notification.service';
import { ShareService } from '../../../services/share.service';
import { ApiserviceService } from '../../../services/apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Map } from '../../../map_api/map';
import * as $ from 'jquery';
import { Location } from '@angular/common';
import { debounceTime } from 'rxjs';
import { Router } from '@angular/router';
import { MainBreadcrumbService } from '../../main-breadcrumb/main-breadcrumb.service';
import { QuickEditComponent } from '../../quick-edit/quick-edit.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
// import { Options } from '@dustfoundation/ngx-sortablejs';

export interface floor_list {
  serial: number,
  sort_order: number,
  uid: string,
  name: string,
  units: number,
  parking: number,
  id: number,
  attribute_info: any,
  status: number
}
export interface unit_list {
  direction: string,
  unit_type: string,
  currency: string,
  sale_price: number,
  lease_price: number,
  area: number,
  id: number,
  property_type_id: number,
  uid: string,
  sort_order: number,
  image: any,
  unit_number: any,
  is_vacant: boolean
}

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_propertytypes_get</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_get</li>
* <li>gisapi_floors_json_get</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_get</li>
* <li>gisapi_floors_floor_id_json_get</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_patch</li>
* <li>estapi_masters_get</li>
* <li>estapi_entity_masters__master_id_values_get</li>
* </ol>
*
*/

@Component({
  selector: 'app-floor',
  templateUrl: './floor.component.html',
  styleUrls: ['./floor.component.css']
})
export class FloorComponent implements OnInit {
  mobile: boolean = false;
  selected_property: string;
  dHeight: number;
  selected_floor_id: number;
  selected_floor: any;
  loader: boolean;
  floor_offset: number = 0;
  floor_limit: number = 10;
  gis_floor_limit: number = 1500;
  gis_floor_offset: number = 0;
  noOfGisFloor: number = 0;
  count: number = 1;
  // gcount: number = 1;
  entity_type = "floor";
  wait: boolean = false;
  capturedId: number;
  floorData: Array<floor_list> = [];
  floorDataGis: Array<floor_list> = [];
  floor_detail: any;
  floor_map: boolean;
  floor_type_list = [];
  floor_access: any;
  select_import = [];
  property_type_list = [];
  unit_list: boolean = false;
  mheight: number;
  mBlock: number;
  searchText: string;
  searchField: UntypedFormControl;
  noOfFloor: number = 0;
  floorlist: any[] = [];
  webBlock: any;
  assignment_detail: any;
  rejectedFloorAttributeList: any[] = [];
  loading: boolean = false;
  allEstFloorList = [];
  allGisFloorList = [];
  showListHeader: boolean;

  //floor filter declarations===================================================================================
  statusFilters: any = [
    { status: '1', name: 'Created', class: 'red', icon: '' },
    { status: '4', name: 'Rework', class: 'blue', icon: '' },
    { status: '6', name: 'Editing', class: 'green', icon: '' },
    { status: '2', name: 'Review', class: 'orange', icon: '' },
    { status: '1,2,3,4,5,6,7', name: 'All', class: '', icon: './assets/images/selectAll.png' }
  ];
  currentFilter: any = '1,2,3,4,5,6,7';
  filterObj: any = { status: '1,2,3,4,5,6,7', name: 'All', class: '', icon: './assets/images/selectAll.png' };
  //floor filter declarations===================================================================================


  @Output() notify: EventEmitter<string> = new EventEmitter<string>();

  constructor(private location: Location,
    private share: ShareService, public dialog: MatDialog, private element: ElementRef, private msg: NotificationService,
    private fnc: CommonfunctionService, private api: ApiserviceService, private route: Router,
    private _crumbs: MainBreadcrumbService) {
    if (!this.share.block0_obj) {
      this.route.navigateByUrl('/');
      return;
    }
    this.assignment_detail = JSON.parse(localStorage.getItem('assignment_detail'));
    //this.getAllFloor();
    this.floor_access = this.share.block0_obj.floor_access;
    this.getFloorGroup();
    this.mobile = this.share.mobile;
    this.share.floor_obj = this;
    this.floor_offset = 0;
    this.share.block0_obj.getAllowResource();
    setTimeout(() => {
      this.floor_access = this.share.block0_obj.floor_access;
      this.dHeight = this.share.heightService;

      this.webBlock = (this.dHeight - 198);
    }, 800);
  }

  filterFloorList(val) {
    this.currentFilter = val.status;
    this.filterObj = val;
    this.getAllFloor();
    this.getFloorData();
  }

  openFloorMap(v, info: any = {}) {
    this.selected_floor = v;
    let dialogview;
    info.pObj = this;
    dialogview = FloorMapWebComponent;
    if (dialogview) {
      const dialogRef = this.dialog.open(dialogview, {
        width: '98vw',
        height: '98vh',
        maxHeight: '98vh',
        panelClass: 'floor-map-view',
        data: info,
        disableClose:true
      });
      dialogRef.afterClosed().subscribe(result => {
        // if (result == 'full_view_map') {
        //   this.openFullViewMap();
        // }
      });
    }
  }

  // getting list of property type
  getPropertyType() {
    let url = "propertytypes";
    this.api.getEmsData(url)
      .subscribe(
        (data: any) => {
          let name, label = '';
          data.forEach((v) => {
            name = v.property_type_name;
            label = v.property_type_name;
            if (v.property_type_label[this.api.language]) {
              label = v.property_type_label[this.api.language];
            }
            this.property_type_list.push({
              name: name,
              label: label,
              id: v.property_type_id
            });
          })
        }
      );
  }
  getFloorGroup() {
    this.share.block0_obj.entity_type = "property_floor";
    this.share.block0_obj.getGroups();
    this.getFloorAttributes(); // get attributes
  }

  getFloorData(is_scroll = false) {

    let url;
    if (!this.share.block0_obj.floor_access.GET) {
      this.msg.notify('You are not authorized to access floors', 'warn');
      return;
    }

    this.loader = true;
    if (!is_scroll) {
      this.getAllFloor();
      this.floorData = [];
      this.floorDataGis = [];
      //this.count = 1;
      this.floor_offset = 0;
    }
    if (this.searchText) {
      url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + '/floors?floor_uid=' + this.searchText + "&attribute_value=" + this.searchText + '&limit=' + this.floor_limit + '&offset=' + this.floor_offset + '&sort_by=sort_order&show_total_of_table_values=true&status=' + this.currentFilter;
    } else {
      url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + '/floors?limit=' + this.floor_limit + '&offset=' + this.floor_offset + '&sort_by=sort_order&show_total_of_table_values=true&status=' + this.currentFilter;
    }
    url += `&get_latest_property=Y`;
    this.api.getEpsData(url)
      .subscribe({
        next: (data: any) => {
          this.loader = false;
          let units, parking;
          if(Array.isArray(data)){
            data.forEach(e => {
              units = 0; parking = 0;
              if (e.no_of_units) {
                units = e.no_of_units
              }
              if (e.data) {
                if (e.data.parking) parking = e.data.parking;
              }
              let fdata: any = {
                serial: this.count,
                sort_order: e.sort_order,
                uid: e.floor_uid,
                name: e.floor_name,
                units: units,
                parking: parking,
                id: e.floor_id,
                gid: e.floor_gis_uid,
                type: e.floor_type_name,
                attribute_info: e.attribute_info,
                status: e.status
              }
              this.floorData.push(fdata);
              //this.count++;
            });
          }
        }
        ,
        error:(err) => {
          this.loader = false;
        }}
      )
  }
  getGisFloor() {
    let gisurl = 'floors/json?building_uid=' + this.share.block0_obj.property_detail.building_gis_uid + '&limit=' + this.gis_floor_limit + '&offset=' + this.gis_floor_offset +'&sort_by=floor_index';
    this.api.getGesData(gisurl)
      .subscribe({
        next: (data: any) => {
        // this.noOfGisFloor = data.length;
        this.allGisFloorList = data;
        this.checkForExistingGisBuilding();
      }, error:(err) => {
        // console.log(err);
        if (err['status'] == 401) {
          this.msg.notify('You are not authorize for GIS', 'warn')
        }
        this.checkForExistingGisBuilding();
      }})
  }
  checkForExistingGisBuilding() {

    if (this.allGisFloorList.length) {
      let gdata: any, units = 0, parking = 0;
      this.allGisFloorList.forEach((e, k) => {
        // let res = this.fnc.getArrayValue('floor_gis_uid', e.floor_uid, this.allEstFloorList);
        let res :any=null;
        if(this.allEstFloorList.length){
          res = this.allEstFloorList.filter(ele=> e.floor_uid ==  ele.floor_gis_uid)
        }
        if(res) res=res[0];
        if (res == null || res == undefined) {
          units = 0, parking = 0;
          if (e.no_of_units) {
            units = e.no_of_units
          }
          gdata = {
            serial: e.floor_index,
            uid: null,
            name: (e.floor_name) ? e.floor_name : 'NA',
            units: units,
            parking: parking,
            id: e.floor_id,
            gid: e.floor_uid,
            type: e.category,
            status: e.status
          }
          this.floorDataGis.push(gdata);
        }
        if (this.floorDataGis.length && this.currentFilter == '1,2,3,4,5,6,7') {
          $(".mat-tab-header").css('display', 'flex');
          $(".mat-tab-label").css('font-size', '12px');
        } else {
          $(".mat-tab-header").css('display', 'none');
        }
      });
    }
  }

  getAllFloor() {
    let status='';
    if (this.currentFilter) status = this.currentFilter;
    // let url = 'deadmin/' + this.assignment_detail.assignment_name + '/count?sort_asc=false&action=floor_count&status='+status+'&property_id='+this.share.block0_obj.selected_property.id;
    let url = 'deadmin/' + this.assignment_detail.assignment_name + '/properties/' + this.share.block0_obj.selected_property.id + '/floors?status=' + status;
    url += `&get_latest_property=Y`;
    this.api.getEpsData(url)
      .subscribe({
        next : (data: any) => {
          this.allEstFloorList = data;
          this.noOfFloor = data.length;
          this._crumbs.count = this.noOfFloor;
          this._crumbs.title = 'Floor';
          // Remove Gis API calling for floor
          // setTimeout(() => {
          //   this.getGisFloor();
          // }, 1500);
        },
        error:(err) => {
          this.noOfFloor = 0;
          this._crumbs.count = 0;
          this._crumbs.title = 'Floor';
          // this.getGisFloor();  // Remove Gis API calling for floor
        }}
      )


  }
  getFloorAttributes() {
    this.share.block0_obj.entity_type = "property_floor";
    this.share.block0_obj.getAttributes();
  }
  getFloorDetail(openDialog = false) {
    this.loading = true;
    let url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + "/floors/" + this.selected_floor_id;
    this.api.getEpsData(url)
      .subscribe(
        {
          next: (data: any) => {
          this.loading = false;
          this.floor_detail = data;
          if (this.api.language == this.api.form_language || this.api.form_language == undefined) {
            localStorage.setItem('floor_detail', JSON.stringify(this.floor_detail));
          }
          if (openDialog) {
            if (this.fnc.getChildArray('quick_edit', true, this.share.block0_obj.attribute_custom_list_floor).length) {
              if (!this.mobile) { this.notify.emit('fGroup'); }
              this.selected_floor.pObj = this;
              let dialogRef = this.dialog.open(QuickEditComponent, {
                width: '450px',
                maxWidth: '95vw',
                maxHeight: '95vh',
                panelClass: 'create-master-panel',
                data: this.selected_floor
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            } else {
              this.notify.emit('fGroup');
              if (this.mobile) {
                this.route.navigate(['floorGroup']);
              }
            }
          }
        }, error: ()=> {
          this.loading = false;
        }}
        );

        // check the unit geom for open map
        if(this.mobile){
          this.getUnitJSON();
        }

    // checking for floor layout
    // this.share.geometry_loader.next(true);
    //  this.floor_map = true;
    // this.api.getGesData('floors/' + this.selected_floor.gid + '/json?return_geometry=true')
    //   .subscribe(
    //     (data: any) => {
    //       this.share.geometry_loader.next(false);
    //       if (data.geom) {
    //         if (data.geom.indexOf('POLYGON') > -1 || data.geom.indexOf('MULTIPOLYGON') > -1) this.floor_map = true;
    //       }
    //     }, error:() => {
    //       this.share.geometry_loader.next(false);
    //       // console.log(err);
    //       if (err['status'] == 401) {
    //         this.msg.notify('You are not authorize for GIS', 'warn');
    //       }
    //     }
    //   )
  }
  getUnitJSON(){
    this.api.getEpsData(`/deadmin/${this.assignment_detail.assignment_name}/properties/${this.share.block0_obj.selected_property.id}/floors/${this.share.floor_obj.selected_floor_id}/units-geojson?get_latest_property=Y`)
    .subscribe((data:any)=>{
      if(data && data.data){
        console.log(data.data);
        let geom_data:any= data.data;

        if(geom_data.hasOwnProperty("features") && geom_data.features.length > 0){

          for(let i=0; i<geom_data.features.length ; i++){
            if(geom_data.features[i].geometry){
              this.floor_map = true;
              break;
            }
          }
        }
      }
    }, error =>{

    }
    )
  }

  onScroll() {
    this.floor_offset += this.floor_limit;
    this.getFloorData(true);
  }

  openOptions(v: any, mobile: boolean = false): void {
    this.share.selected_obj_uid = '';
    this.rejectedFloorAttributeList = [];
    if (v.attribute_info != null) {
      for (let key in v.attribute_info) {
        if (v.attribute_info[key].status == -1 || (v.attribute_info[key].status == 3 && v.status == 6)) {
          let d = {
            "keyId": Number(key),
            "status": v.attribute_info[key].status,
            "comment": v.attribute_info[key].comment
          }
          this.rejectedFloorAttributeList.push(d);
        }
      }
    }
    if (!this.mobile) {
      if (this.share.dynamic_obj.form) {
        this.share.block3_obj.submitForm();
      }
    }
    this.selected_floor_id = v.id;
    this.selected_floor = v;
    if (!v.uid) {
      this.openDialog('addFloor', { 'gis': true });
      return;
    }
    this.getFloorDetail(true);

    this.share.selected_obj_uid = this.selected_floor.uid;

  }

  redirectToMap(v, mobile = true) {
    this.selected_floor = v;
    this.route.navigateByUrl('/floor/map');
  }


  // flooroptions: SortablejsOptions = {
  //   draggable: '.big-button',
  //   onUpdate: (event: any) => this.sortWebFloor(event)
  // };
  sortWebFloor(event) {
    // console.log(this.floorData);
    if(event.previousIndex == event.currentIndex){
      return;
    }
    moveItemInArray(this.floorData, event.previousIndex, event.currentIndex);
    let gIndex = this.fnc.getArrayindex('name', 'Ground', this.floorData);
    if (gIndex == null){
      gIndex = this.fnc.getArrayindex('name', 'ground', this.floorData);
    }


    // let oldIndex = e.oldDraggableIndex;
    // let newIndex = e.newDraggableIndex;
    // let newObj = this.floorData[newIndex];
    // let oldObj = this.floorData[oldIndex];
    // swapping the value
    // this.floorData[newIndex] = oldObj;
    //this.floorData[oldIndex] = newObj;
    // let min = 0, max = 0;
    //let index = 0;
    let up = 0;
    let down = gIndex - (gIndex * 2) - 1;
    let new_sort_order = [];
    // let floor_data:any;
    // if(oldIndex > newIndex){
    //   // up
    //   floor_data = this.floorData[oldIndex];
    //   new_sort_order.push({
    //     floor_id: floor_data.id,
    //     sort_order: newIndex
    //   });
    //   index = newIndex+1;
    //   for(index;index <= oldIndex; index++){
    //     console.log('up'+index);

    //     floor_data = this.floorData[index];
    //     if(floor_data){
    //       new_sort_order.push({
    //         floor_id: floor_data.id,
    //         sort_order: index
    //       });
    //     }
    //   }

    // }

    // if(newIndex > oldIndex){
    //   // down
    //   floor_data = this.floorData[oldIndex];
    //   new_sort_order.push({
    //     floor_id: floor_data.id,
    //     sort_order: newIndex+1
    //   });
    //   index = newIndex+1;
    //   while(index > oldIndex){
    //     floor_data = this.floorData[index];
    //     console.log('down'+index);
    //     if(floor_data){
    //       new_sort_order.push({
    //         floor_id: floor_data.id,
    //         sort_order: --index
    //       });
    //     }

    //   }
    // }


    this.floorData.forEach((v, k) => {
      if (k == gIndex) v.sort_order = 0;
      if (k < gIndex) {
        down++;
        v.sort_order = down;
      }
      if (k > gIndex) {
        up++;
        v.sort_order = up;
      }
      new_sort_order.push({
        floor_id: v.id,
        sort_order: v.sort_order
      })
    });
    let data = {
      "floor_sort_order_list": new_sort_order,
      "updated_by": this.api.user_id
    }
    this.api.patchEpsData("deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + '/floors', data)
      .subscribe({next : (data: any) => {
      },
        error:(err) => {
        }});

  }
  isActive(v) {
    if (v == this.selected_floor_id) return true;
  }
  sortOrder(action) {
    let secondFloor, is_valid = true;
    if (this.selected_floor_id) {
      let index = this.fnc.getArrayindex('id', this.selected_floor_id, this.floorData);
      let floorDetail = this.floorData[index];
      if (action == 'up' && index > 0) {
        secondFloor = this.floorData[index - 1];
        this.floorData[index] = secondFloor;
        this.floorData[index - 1] = floorDetail;
      }
      if (action == 'down' && index < this.floorData.length) {
        secondFloor = this.floorData[index + 1];
        this.floorData[index] = secondFloor;
        this.floorData[index + 1] = floorDetail;
      }
      let gIndex = this.fnc.getArrayindex('name', 'Ground', this.floorData);
      if (gIndex == null){
        gIndex = this.fnc.getArrayindex('name', 'ground', this.floorData);
      }
      let up = 0;
      let down = gIndex - (gIndex * 2) - 1;
      let new_sort_order = [];
      this.floorData.forEach((v, k) => {
        if (k == gIndex) v.sort_order = 0;
        if (k < gIndex) {
          down++;
          v.sort_order = down;
        }
        if (k > gIndex) {
          up++;
          v.sort_order = up;
        }
        new_sort_order.push({
          floor_id: v.id,
          sort_order: v.sort_order
        })
      });
      if (is_valid) {
        let data = {
          "floor_sort_order_list": new_sort_order,
          "updated_by": this.api.user_id
        }
        this.api.patchEpsData("deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + '/floors', data)
          .subscribe({next: (data: any) => {
          },
            error:(err) => {
            }});
      }
    }
  }

  getFloorMasterVal() {
    this.api.getEmsData('masters?master_name=floor_types&limit=10&exact_match=true')
      .subscribe((data: any) => {
        if(data && data.length > 0){
          // let master = data.find(w=> w.master_name == 'floor_types')
            let master = data[0];
            if(master){
              this.api.getEmsData('property_floor/masters/' + master?.master_id + '/values?limit=1000&status=1')
              .subscribe((val: any) => {
                let result = {};
                val.forEach((v) => {
                  result = {
                    id: v.master_value_id,
                    label: v.master_value_label[this.api.language],
                    name: v.master_value_name,
                    master_id: v.master_id,
                    status: v.status,
                    property_type_id: v.property_type_id
                  }
                  this.floor_type_list.push(result);
                });
              })
            }
          }
      })
  }

  backClicked() {
    //this.location.back();
    this.route.navigateByUrl('groups');
  }
  onCheckStatus(e) {
    this.selected_floor_id = null;
    if (e.checked) {
      this.selected_floor_id = e.source.value;
    }
  }
  onCheckImport(e) {
    if (e.checked) {
      this.select_import.push(e.source.value);
    } else {
      this.select_import.splice(this.select_import.indexOf(e.source.value), 1);
    }
  }

  selectFloorType(event, floor) {
    event.stopPropagation();
  }

  changeFloorType(floor, type) {
    if (!this.share.block0_obj.floor_access.PATCH) {
      this.msg.notify('You are not authorized to Change floor Type', 'warn');
      return;
    }
    // console.log(type);

    const url=`deadmin/${this.assignment_detail.assignment_name}/properties/${this.share.block0_obj.selected_property.id}/floors/${floor.id}`;
    let data = {
      "floor_type_name": type.name,
      "updated_by": this.api.user_id,
    }
    this.api.patchEpsData(url, data)
      .subscribe({next:(data: any) => {
        if(data==1){
          this.msg.notify("Change Success",'success');
          this.getFloorData();
        }
        else{
          this.msg.notify('Something Went Wrong','error')
        }
      },
        error:(err) => {
        }});
  }



  ngOnInit() {
    this.getFloorData();
    this.getFloorMasterVal();
    this.share.block0_obj.getAllowResource();
    setTimeout(() => {
      $(".mat-tab-header").css('display', 'none');
    }, 5);
    setTimeout(() => {
      // this.dHeight = (this.share.heightService - 66);
      // let e = $(this.element.nativeElement);
      // e.find('#DynamicBlock').css('height', (this.dHeight - 127));
      // e.find('#DynamicBlock_g').css('height', (this.dHeight - 41));
      // this.mheight = (this.dHeight - 0);
      // this.mBlock = (this.dHeight - 74);
    }, 10);

    this.selected_property = this.share.block0_obj.selected_property;

    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(1500))
      .subscribe(term => {
        this.searchText = term;
        this.getFloorData(false);
      });
    // //start sorting config
    // let thisObj = this;
    // setTimeout(()=>{
    //   let el = document.getElementById('floor_data_w');
    //   var sortable = new Sortable(el, {
    //    // dragClass: "sortable-drag",
    //     // Changed sorting within list
    //     onUpdate: function (e) {
    //       console.log(e);

    //       thisObj.sortWebFloor(e);
    //     }
    // });
    // },2000)

  }
  openDialog(type: string, info: any = {}): void {
    let dialogview;
    info.pObj = this;
    if (type == 'addFloor') {
      dialogview = AddFloorComponent
    }
    if (dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: '350px',
        panelClass: 'create-master-panel',
        data: info
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }
}


/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_post</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors2_post</li>
* </ol>
*
*/

@Component({
  selector: 'addFloor',
  templateUrl: './addFloor.component.html',
  styleUrls: ['./floor.component.css']
})
export class AddFloorComponent {

  floor_type_list: any;
  is_gis = false;
  floorForm: UntypedFormGroup;
  loader: boolean;

  constructor(public dialogRef: MatDialogRef<AddFloorComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder,
    private api: ApiserviceService, private share: ShareService, private notify: NotificationService, private fnc: CommonfunctionService) {
    this.floor_type_list = this.data.pObj.floor_type_list;
    this.floorForm = this.createForm();
    if (this.data.gis) this.is_gis = true;
  }

  restrictDummy(name) {
    if (name) {
      name = name.trim().toLowerCase();
      if (name == 'dummy') {
        return false;
      } else {
        return true;
      }
    }

  }
  createForm() {
    let group = this.fb.group({});
    if (this.floor_type_list.length > 0) {
      this.floor_type_list.forEach((v) => {
        group.addControl('input_!' + v.name, this.fb.control(''));
      });
    }
    return group;
  }
  addFloor(e) {
    let val = e.value, x, name = null, floor_count = [], floornum: any = 0;
    for (x in val) {
      name = x.split('_!')[1];

      if (val[x]) {
        floornum += val[x];
        floor_count.push({
          "count": val[x],
          "floor_type_name": name
        });
      }
    }
    if (floor_count.length > 0) {
      if (floornum > 20) {
        this.notify.notify('Maximum value is 20 for a single request', 'warn');
        return;
      }
      let body = {
        "added_by": this.api.user_id,
        "floor_type_counts": floor_count,
        "status": 1
      };
      this.api.postEpsData("deadmin/" + this.data.pObj.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + '/floors', body)
        .subscribe({next: (data: any) => {
          this.data.pObj.getFloorData();
          this.updateAttributeChange(this.share.block0_obj.selected_property, this.data.pObj.assignment_detail.assignment_name, data);
        },
          error: (err) => {
          }});
    }
    this.dialogRef.close();
  }

  //function to make newly created floors identified by QAQC
  updateAttributeChange(selected_property, assignment_name, floor_data) {
    if (!selected_property) {
      return;
    }
    if (selected_property.status == 4 || selected_property.status == 6) {
      let new_attr_info = { new_floor_ids: [] };
      if (selected_property.attribute_info) {
        new_attr_info = selected_property.attribute_info;
        if (new_attr_info && new_attr_info.new_floor_ids) {
          new_attr_info["new_floor_ids"] = [...new_attr_info.new_floor_ids, ...floor_data.floor_ids]
        } else {
          new_attr_info["new_floor_ids"] = floor_data.floor_ids;
        }
      } else {
        new_attr_info["new_floor_ids"] = floor_data.floor_ids;
      }
      let url = "deadmin/" + assignment_name + "/properties/" + selected_property.id;
      let body = {
        "updated_by": this.api.user_id
      }
      body["attribute_info"] = new_attr_info;
      this.api.patchEpsData(url, body).subscribe((data: any) => {
      })
    }
  }

  importMultiple() {
    let floor_detail = null, body = [];
    let import_floor = this.data.pObj.select_import;
    if (import_floor.length) {
      import_floor.forEach(v => {
        floor_detail = null;
        floor_detail = this.data.pObj.fnc.getArrayValue('gid', v, this.data.pObj.floorDataGis);
        if (floor_detail) {
          if(floor_detail.sort_order == 0){
            floor_detail.type = 'Ground'
          }
          if(floor_detail.sort_order < 0){
            floor_detail.type = 'Basement'
          }
          if(floor_detail.sort_order > 0){
            floor_detail.type = 'Typical_Foor'
          }
          body.push({
            "added_by": this.api.user_id,
            "floor_type_name": floor_detail.type,
            "floor_gis_uid": floor_detail.gid,
            "sort_order": floor_detail.sort_order,
            "status": 1
          });
        }
      });
    }
    if (body.length) {
      this.loader=true;
      this.api.postEpsData("deadmin/" + this.data.pObj.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + '/floor2', body)
        .subscribe((data: any) => {
          this.loader=false;
          this.data.pObj.getFloorData();
          this.data.pObj.select_import = [];
          this.closeDialog();
        }, err=>{
          this.loader=false;
          // this.notify.notify(err.error.message, 'error');

        });

    }
  }
  createFloorOnGis() {
    if (this.data.bulk) {
      this.importMultiple();
      return;
    }
    let selected_floor = this.data.pObj.selected_floor;
    if(selected_floor.sort_order == 0){
      selected_floor.type = 'Ground'
    }
    if(selected_floor.sort_order < 0){
      selected_floor.type = 'Basement'
    }
    if(selected_floor.sort_order > 0){
      selected_floor.type = 'Typical_Foor'
    }
    let body = {
      "added_by": this.api.user_id,
      "floor_type_name": selected_floor.type,
      "floor_gis_uid": selected_floor.gid,
      "sort_order": selected_floor.sort_order,
      "status": 1
    };
    this.loader=true;
    this.api.postEpsData("deadmin/" + this.data.pObj.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + '/floor', body)
      .subscribe({
        next: (data: any) => {
        this.loader=false;
        this.data.pObj.getFloorData();
        this.closeDialog();
      },
        error:(err) => {
        this.loader=false;
        this.notify.notify(err.error.message, 'error');
        }});

  }
  ngOnInit() {

  }
  closeDialog(): void {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'app-floor-map-dialog',
  templateUrl: './floor-map-web/floor-map-dialog.component.html',
  styleUrls: ['./floor-map-web/floor-map-dialog.css']
})

export class FloorMapWebComponent {
  mobile: boolean = false;
  @ViewChild('map') mapElement: any;
  @ViewChild('floorSwitcherContainer') floorSwitcherElement: any;
  selected_property: any;
  floor_name: any;
  map: Map;
  constructor(private share: ShareService, private route: Router, private http: HttpClient,
    public dialogRef: MatDialogRef<FloorMapWebComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiserviceService,
    private fnc: CommonfunctionService) {
    this.share.floorMapDialogObj = this;
    this.mobile = this.share.mobile;
    this.selected_property = this.share.block0_obj.selected_property;
  }


  close() {
    this.dialogRef.close();
  }
  ngOnInit() {

  }

  fullViewMap(event){
    this.dialogRef.close(event);
  }
}
