import { RouterModule } from '@angular/router';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http'
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { ServiceWorkerModule, SwPush, SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxLoadingModule, ngxLoadingAnimationTypes} from 'ngx-loading';
import { LoginComponent } from './main/login/login.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { AppComponent } from './app.component';
// import { MessagingService } from './services/messaging.service';
import { MainModule } from './main/main.module';
import { MainMenuService } from './main-menu.service';
import { MainBreadcrumbService } from './main/main-breadcrumb/main-breadcrumb.service';
import { ApiserviceService } from './services/apiservice.service';
import { AuthGuard } from './services/auth.guard';
import { CommonfunctionService } from './services/commonfunction.service';
import { NotificationService } from './services/notification.service';
import { ShareService } from './services/share.service';
// import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { EncryptDecryptService } from './encryptdecrypt.service';

//inactivity handling==================================================
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './state/reducers';
import { ApplicationEffects } from './state/application/effects';
import { ResetPasswordComponent } from './main/login/components/reset-password/reset-password.component';
import { OtpVerificationComponent } from './main/login/components/otp-verification/otp-verification.component';
//inactivity handling==================================================

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true,
//   swipeEasing : false
// };
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ResetPasswordComponent,
        OtpVerificationComponent
    ],
    imports: [
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([ApplicationEffects]),
        RouterModule,
        BrowserModule,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatCardModule,
        HttpClientModule,
        MainModule,
        MatBottomSheetModule,
        // PerfectScrollbarModule,
        FlexLayoutModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        ServiceWorkerModule,
        FormsModule, ReactiveFormsModule,
        HammerModule,
        MatFormFieldModule, MatInputModule,
        // DeviceDetectorModule.forRoot(),
        environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : [],
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour: 'rgba(0,0,0,0.3)',
            backdropBorderRadius: '4px',
            primaryColour: '#f69230',
            secondaryColour: '#16ceed',
            tertiaryColour: '#23cb6b',
            fullScreenBackdrop: false
        })
    ],
    providers: [
        MainMenuService,
        MainBreadcrumbService,
        ApiserviceService,
        AuthGuard,
        CommonfunctionService,
        NotificationService,
        EncryptDecryptService,
        ShareService
        // {
        //     provide: PERFECT_SCROLLBAR_CONFIG,
        //     useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        // }
    ],
    exports:[ ResetPasswordComponent,
      OtpVerificationComponent],
    bootstrap: [AppComponent],
})
export class AppModule { }
