<div *ngIf="!mobileview">
    <div fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
        <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
            <app-main-breadcrumb [isPropertyLevel]="isPropertySection()"></app-main-breadcrumb>
            <div fxLayout="row" fxLayoutAlign="end center"></div>
            <ng-container *ngIf="selected_property">
                <div fxLayout="row" class="addBar" fxLayoutAlign="end center" *ngIf="selected_property.property_id">
                    <div fxLayout="row" class="location" fxLayoutAlign="center">
                        <div (click)="setMapMarker()" class="circle">
                            <div title="map">
                                <mat-icon class="map" svgIcon="pin"></mat-icon>
                            </div>
                        </div>
                        <div class="property-details">
                            <div id="p-id">Property ID </div>
                            <div id="id-num">ID-{{selected_property.property_id}}</div>
                              <mat-icon  (click)="copyToClipboard(selected_property.property_id)" title="Copy Property ID" svgIcon="copyfm" class="icon copy-icon"></mat-icon>
                        </div>

                    </div>
                    <div class="address-line">
                        <p>{{selected_property.address}}</p>
                        <span *ngIf="selected_property.address.length > 65" class="tooltiptext">{{selected_property.address}}</span>
                    </div>
                </div>
            </ng-container>
        </div>


        <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b">
                <button [disabled]="!isPropertySection()"  class=" primary-btn" (click)="redirect()">
                    <mat-icon class="property-icon" svgIcon="add_property"></mat-icon>
                    <span *ngIf="!loader" >Add Property</span>
                    <span *ngIf="loader"> Loading... </span>
                </button>

                <button mat-button class="b-btn" [disabled]="gLoader" (click)="save()">
                    <mat-icon class="save-icon" svgIcon="save"></mat-icon>
                    <span *ngIf="!gLoader">Save</span>
                    <span *ngIf="gLoader">Saving...</span>
                </button>
            </div>
        </div>
    </div>
    <app-dynamic></app-dynamic>
</div>

<div *ngIf="mobileview">
    <app-block0></app-block0>
</div>
