<!-- WEB OPTIMIZED SIDENAV=========================================================== -->
<div *ngIf="!mobile">
    <div [ngClass]="{'sidebarnormal' : isNormal(), 'sidebarsmall' : isSmall()}">

        <mat-toolbar class="headtoolbar" color="primary">
          <div fxLayout="row" class="w-100" *ngIf="isSmall()" fxLayoutAlign="center center">
            <img class="hvr-grow cl" routerLink="/" src="assets/images/logo-mini-new.svg" width="32"/>
          </div>
          <div fxLayout="row" class="w-100" *ngIf="isNormal()" fxLayoutAlign="center center">
              <img class="hvr-grow cl" routerLink="/" src="assets/images/logo.svg"  width="170"/>
          </div>
        </mat-toolbar>

        <mat-nav-list color="primary">
            <ng-container *ngFor="let menus of mainMenu">
              <div title="{{menus.title}}" class="itemlist ctrans"  [ngClass]="{'active':getActive(menus)}" (click)="setActive(menus)">
                <mat-icon *ngIf="menus.icon" svgIcon="{{menus.icon}}" class="hvr-grow"></mat-icon>
                <div class="menu-text" *ngIf="isNormal()">{{menus.title}}</div>
              </div>
              <div *ngIf="getActive(menus) && isNormal()" class="sub-menu-web">
                <div class="search-bar" *ngIf="menus.title == 'My Assignments'"><input type="text" placeholder="Search" [(ngModel)]="filterTerm"></div>
                <div id="listCon" *ngFor="let submenu of menus.subMenu | searchFilter : 'search_param' : filterTerm" class="items-div" (click)="select(submenu); closeNav();" title="{{submenu.title}}_{{submenu.year}} ({{submenu.count}})">
                  <li class="items ctrans" [ngClass]="{'active':isActive(submenu)}"><p class="left-label"> {{submenu.title}}_{{submenu.year}} ({{submenu.count}})</p></li>
                </div>
              </div>
            </ng-container>
        </mat-nav-list>
      </div>
</div>
<!-- ================================================================================= -->

<!-- MOBILE OPTIMIZED SIDENAV========================================================= -->
<div *ngIf="mobile">
    <div class="sidebarnormal">
      <app-mobile-card></app-mobile-card>
        <mat-nav-list color="primary" style="max-height: 78vh; overflow: auto;">
          <div *ngFor="let menus of mainMenu">
            <ng-container>
              <div  class="itemlist" [ngClass]="{'active':getActive(menus)}" (click)="setActive(menus)">
                <mat-icon *ngIf="menus.icon" svgIcon="{{menus.icon}}"></mat-icon>
                <div class="menu-text" *ngIf="isNormal()">{{menus.title}}</div>
              </div>
              <div  class="sub-menu-web">
                <div class="search-bar" *ngIf="menus.title == 'My Assignments'"><input type="text" placeholder="Search" [(ngModel)]="filterTerm"></div>
                <div id="listCon" *ngFor="let submenu of menus.subMenu | searchFilter : 'search_param' : filterTerm" class="items-div" (click)="select(submenu); closeNav();" title="{{submenu.title}}_{{submenu.year}} ({{submenu.count}})">
                  <li class="items ctrans" [ngClass]="{'active':isActive(submenu)}"><p class="left-label"> {{submenu.title}}_{{submenu.year}} ({{submenu.count}})</p></li>
                </div>
              </div>
              <div class="sub-menu" *ngIf="getActive(menus)">
                <div id="listCon" *ngFor="let submenu of menus.subMenu" class="items-div" [ngClass]="{'active':isActive(submenu)}" (click)="select(submenu)" (click)="closeNav()">
                  <li class="items"><p class="left-label">{{submenu.title}}_{{submenu.year}}</p> <span>({{submenu.count}}) </span></li>
                </div>
              </div>
          </ng-container>
          </div>
        </mat-nav-list>
        <div class="logout" (click)="logout()">
          <span class="sign-btn">
            <img style="width: 16px;" src="../../../assets/icon/logout-light.svg" alt="">
              <strong class="txt">Logout</strong>
          </span>
        </div>
      </div>
</div>
<!-- ================================================================================= -->
