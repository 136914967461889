<div class="f-item">
    <div class="cent" (click)="navigate('property')"><a>
            <mat-icon class="icon" svgIcon="property"></mat-icon>
        </a></div>
    <div class="cent" (click)="navigate('floor')"><a>
            <mat-icon class="icon" svgIcon="floor"></mat-icon>
        </a></div>
    <div class="cent" (click)="navigate('unit')"><a>
            <mat-icon class="icon" svgIcon="unit"></mat-icon>
        </a></div>
    <div class="cent fnt16" [matMenuTriggerFor]="menu">
        <a class="rel">
            <mat-icon class="lang-icon">translate</mat-icon>
            <span class="abs-lang" *ngIf="selected_lang">
                {{selected_lang.language_code}}
            </span>
        </a>
    </div>
    <div class="cent" (click)="save()"><a>
            <mat-icon class="icon" svgIcon="save"></mat-icon>
        </a></div>
</div>
<mat-menu #menu="matMenu">
    <button class="cp" mat-menu-item *ngFor="let item of language_list" (click)="changeLanguage(item.language_id)" >{{item.name}}</button>
</mat-menu>