import { Component, Input, OnInit } from '@angular/core';
import { MainBreadcrumbService } from './main-breadcrumb.service';
import { inject } from '@angular/core/testing';
import { ShareService } from '../../services/share.service';
import { NotificationService } from '../../../../src/app/services/notification.service';
import { Clipboard } from "@angular/cdk/clipboard";

@Component({
  selector: 'app-main-breadcrumb',
  templateUrl: './main-breadcrumb.component.html',
  styleUrls: ['./main-breadcrumb.component.css']
})
export class MainBreadcrumbComponent implements OnInit {
  @Input() isPropertyLevel : any;
  count: number = 0;
  title: string = '';
  assignName: string = '';
  selectedFloorId: any;
  constructor(private _crumbs : MainBreadcrumbService, private share : ShareService,
    private clipboard: Clipboard,
    private notify: NotificationService) {

   }


  ngOnInit() {
  }

  getCrumbs() {
    return this._crumbs.crumbs;
  }

  getMobileLastCrumb() {
    let totalcrumb = this.getCrumbs().length;
    return this._crumbs.crumbs[totalcrumb-1];
  }

  getCount() {
    // if(this.share.block1_obj) this.share.header_obj.setProperty(this.share.block1_obj.selected_property);
    return this._crumbs.count;
  }

  getTitle() {
    return this._crumbs.title;
  }

  getassignName(){
    this.assignName = '';
    if(this.share.block0_obj){
      let s = this.share.block0_obj;
      if(s.assignment_detail){
        this.assignName = s.assignment_detail.assignment_label[s.api.language];
        if(this.assignName){
          return this.assignName;
        }else if(s.assignment_detail && s.assignment_detail.assignment_name){
          return s.assignment_detail.assignment_name;
        }
      }
    }else{
      setTimeout(() => {
        return this.assignName;
      }, 100);
    }
  }

  getFlooreName(){
    if(this.share.floor_obj){
      if(this.share.floor_obj.selected_floor){
        this.selectedFloorId = this.share.floor_obj.selected_floor.uid;
        return 'Floor Name : '+this.share.floor_obj.selected_floor.name;
      }
    }
  }

  getuserName(){
    let user = null;
    if(this.share.block0_obj){
      user = this.share.block0_obj.selectedUserName();
      return user;
    }else{
      return user;
    }

  }
  public copyToClipboard(id): void {
    const successful = this.clipboard.copy(id);
    if (successful) {
      this.notify.notify(
        `Floor uid ${id} copied`,
        "success"
      );
    } else {
      this.notify.notify(`Not copied, try again`, "warn");
    }
  }
}
