/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, Inject, ViewChildren, ElementRef, OnChanges, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, Validators, UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, FormArray } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { Observable } from 'rxjs';
import { Map } from '../../map_api/map';
import { LatLon } from '../../map_api/latlon';
import { ShareService } from '../../services/share.service';
import { ApiserviceService } from '../../services/apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import * as $ from 'jquery';
import { MatIconRegistry } from '@angular/material/icon';
import { debounceTime } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { map, startWith } from 'rxjs';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { environment } from '../../../environments/environment';
declare var $ : any;
export class State {
  constructor(public name: string) { }
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>gisapi_address_json_get</li>
 * <li>estapi_module_type_assignment_name_properties_get</li>
 * <li>gisapi_places_bulk_search_post</li>
 * <li>gisapi_place_location_post</li>
 * <li>gisapi_streets_json_post</li>
 * <li>estapi_module_type_assignment_name_properties_post</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_patch</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  map: Map;
  mobile: boolean = false;
  formDisplay: boolean = false;
  dHeight: number;
  gLat: number;
  gLon: number;
  building_name: string;
  house_no: string;
  parcel_no: string;
  lane_name: string;
  street_name: string;
  block: string;
  neighbourhood: string;
  address: string = '';
  building_gis_uid: string = null;
  address_gis_uid: string = null;
  building_uid: string = null;
  allProperties:Array<any> = [];
  block_uid: string;
  street_uid: string;
  parcel_uid: string;
  neigh_uid: string;
  lane_uid: string;
  city_uid: string = null;
  city_name: string = null;

  mp_uid: string = null;
  mp_name: string = null;
  proj_uid: string = null;
  proj_name: string = null;


  save: boolean = false;
  attr_id: number;
  select_suggested_street: boolean = false;
  streetObj: any = [];
  location_info: any;
  dialogShow: boolean = false;
  assignment_id: number;
  assignment_detail: any;
  loader: boolean = false;
  showSearchPane: boolean = false;
  existingData: any;
  nextLocation: any;
  existingAddress: any = [];
  multi: boolean = false;
  pro: boolean = true;
  add: boolean = false;
  loader1: boolean = false;
  notFound: boolean = false;
  manualEntry: boolean = false;
  showNavigation: boolean = false;
  searchField: UntypedFormControl;
  search_input: string = '';
  searchData: string;
  polygons: any = [];
  lt: any;
  ln: any;
  address_list: any = [];
  streetList: any = [];
  laneList: any = [];
  laneObj = [];
  invalidArea: boolean = false;
  selected_property: any;
  newuid: any = null;
  property_access: any;
  create_property = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  isInside: boolean = false;
  selectedPolygon: any;
  previousLocation: any = {
    lat: null,
    lon: null
  }
  public city_lat_lon = {
    lat: '',
    lon: ''
  };
  is_edited: boolean = false;
  @ViewChild('gmap') gmapElement: any;

  openForm() {
    this.formDisplay = true;
  }
  closeForm(flag: string) {
    if (flag == 'save') {
      this.formDisplay = false;
    } else {
      this.formDisplay = false;
    }
  }

  buildings: any = [];
  dropped: boolean = false;
  constructor(public dialog: MatDialog, private elementRef: ElementRef, private share: ShareService, private _zone: NgZone,
    private api: ApiserviceService, private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private fnc: CommonfunctionService, private notify: NotificationService, private bottomSheet: MatBottomSheet) {
    iconRegistry.addSvgIcon('pin', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Pin.svg'));
    this.mobile = this.share.mobile;
    this.api.setApiUrl();
    if (!this.share.block0_obj) {
      this.router.navigateByUrl('/');
      return;
    }
    this.previousLocation = this.share.block0_obj.previousLocation;
    this.attr_id = this.share.block0_obj.property_name_attr_id;
    this.property_access = this.share.block0_obj.property_access;
    this.selected_property = this.share.block0_obj.selected_property;
    this.assignment_id = this.share.block0_obj.assignment_id;
    this.assignment_detail = this.share.block0_obj.assignment_detail;
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(2500))
      .subscribe(term => {
        this.search_input = term;
        this.getAddlist();
      });
    this.share.block0_obj.getAllowResource();
    setTimeout(() => {
      this.property_access = this.share.block0_obj.property_access;
    }, 800);
    this.api.getUserRole(); // getting role assign to this user
  }
  isGeomAvailable() {
    if (!this.assignment_detail && !this.assignment_detail.geom) {
      return false;
    } else {
      return true;
    }
  }

  toggleNavigation() {
    this.showNavigation = !this.showNavigation;
    if (this.showNavigation) {
      if(this.map){
        this.map.removeAllMarkers();
      }
    }
  }
    navigateNext(location) {
    this.nextLocation = location;
    const bottomSheetRef = this.bottomSheet.open(BottomSheet,{data: location});
    bottomSheetRef.afterDismissed().subscribe(() => {
      this.nextLocation = null;
      this.showNavigation = false;
      if(this.map){
        this.map.removeAllMarkers();
      }
    });
  }

  selectPolygon() {
    if (this.assignment_detail.geom && this.assignment_detail.geom.length > 0) {
      let dialogRef = this.dialog.open(PolygonSelection, {
        width: '350px',
        panelClass: 'create-master-panel',
        data: { parent: this }//optional
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

  backToProperty() {
    this.router.navigateByUrl('property/' + this.share.block0_obj.assignment_id);
  }
  public markerDrop() {
    this.dropped = !this.dropped;
  }
  showPolygonSelection() {
    if (this.assignment_detail && this.assignment_detail.geom && this.assignment_detail.geom.length > 0) {
      let arr = JSON.parse(this.assignment_detail.geom)
      if (arr.length != 1) {
        if (!this.share.block0_obj.enablePatch) this.selectPolygon();
      } else {
        if (!this.share.block0_obj.enablePatch) {
          let poly = arr[0];
          let pg = new google.maps.Polygon({
            paths: this.getgeom(poly.geom),
            strokeColor: '#FF0000',
            strokeOpacity: 1,
            strokeWeight: 3
          });
          this.getBound(pg);
        }
      }
    }
  }

  checkIfInside(lt, ln, latlon = null) {
    let ll, contains: boolean = false;
    if (this.polygons.length > 0) {
      this.polygons.forEach(p => {
        if (!lt && !ln) {
          ll = latlon;
        } else {
          ll = new google.maps.LatLng(lt, ln);
        }
        if (google.maps.geometry.poly.containsLocation(ll, p)) {
          contains = true;
        }
      });
    }
    return contains;
  }

  selectedLocation(add) {
    this.gLat = +add.lat;
    this.gLon = +add.lon;
    let obj: any = new LatLon(+add.lat, +add.lon, 0);
    let p_name = 'New Property';
    if (this.share.block0_obj.enablePatch) {
      p_name = this.selected_property.property_id;
    }
    this.neighbourhood = (add.neigh_name)?add.neigh_name:'';
    this.block = (add.block_name)?add.block_name:'';
    this.lane_name = (add.lane_name)?add.lane_name:'';
    this.street_name = (add.street_name)?add.street_name:'';
    this.parcel_no = (add.parcel_no)?add.parcel_no:'';
    this.house_no = (add.house_no)?add.house_no:'';

    this.isInside = this.checkIfInside(this.gLat, this.gLon);
    if (this.isInside) {
      this.map.panTo(obj);
      //remove all markers
      this.map.removeAllMarkers();
      //add new marker with drag-end event
      this.map.addMarker(obj, {
        'title': 'My property',
        'info': '<div id="content">' +
          p_name +
          '</div>',
        'iconUrl': '../assets/icon/Pin.svg',
        'iconWidth': 50,
        'iconHeight': 50,
        'dragend': (e) => {
          this._zone.run(() => {
            this.gLat = e.latlon.getLat();
            this.gLon = e.latlon.getLon();
            this.getLocationInfo();
          });
        }
      });
      this.getLocationInfo();
      // this.searchData = add.address_text;
      this.showSearchPane = false;
    } else {
      this.validArea();
    }
  }
  getAddlist() {
    this.address_list = [];
    if ((this.share.block0_obj.enablePatch && this.property_access.PATCH) || (!this.share.block0_obj.enablePatch)) {
      if (!this.search_input) this.search_input = '';
      if (this.search_input.trim()) {
        this.showSearchPane = true;
        this.loader1 = true;
        let url = "address/json?categories=building,parcel&search=" + this.search_input + "&limit=200";
        this.api.getGesData(url)
          .subscribe(
            {next:(data: any) => {
              if(data?.length){
                this.address_list = data;
                this.notFound = false;
              }
              this.loader1 = false;
            }, error:(err) => {
              this.address_list = [];
              this.notFound = true;
              this.loader1 = false;
              // this.showSearchPane = false;
              if (err['status'] == 401) {
                this.notify.notify('You are not authorize for GIS', 'warn');
               }
            }});
      } else {
        this.search_input = '';
        this.address_list = [];
        this.showSearchPane = false;
      }
    }
  }
  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        //this.map.panTo({ 'lat': position.coords.latitude, 'lng': position.coords.longitude }, false);
        this.map.panTo(new LatLon(position.coords.latitude, position.coords.longitude, 0));
      }.bind(this));
    }
  }
  getBound(polygon) {
    var bounds = new google.maps.LatLngBounds();
    var paths = polygon.getPaths();
    var path;
    for (var i = 0; i < paths.getLength(); i++) {
      path = paths.getAt(i);
      for (var ii = 0; ii < path.getLength(); ii++) {
        bounds.extend(path.getAt(ii));
      }
    }
    this.map.map.fitBounds(bounds);
  }


  renderMap(lt = null, ln = null) {
    if (lt == null) {
      if (localStorage.getItem('city_detail')) {
        let city_detail = JSON.parse(localStorage.getItem('city_detail'));
        if (city_detail.lat) {
          lt = city_detail.lat;
          ln = city_detail.lon;
        } else {

        }
      }

    }
    let getZoomValue = (this.share.block0_obj && !this.share.block0_obj.enablePatch && lt != this.previousLocation.lat && ln != this.previousLocation.lon) ? 13 : 18;
    let options = {
      center: new LatLon(lt, ln, 0),
      zoom: getZoomValue,
      showZoomControl: this.mobile ? false : true,
      showScaleControl: true,
      showMapTypeControl: true,
      showFullScreenControl: true,
      element: this.gmapElement.nativeElement,
      api: 'googlemap'
    };

    this.map = new Map(options);

    let start;
    let geomArr = [];
    geomArr = JSON.parse(this.assignment_detail.geom);
    if (geomArr && geomArr.length > 0) {
      geomArr.forEach(geomdata => {
        if (geomdata.geom) {
          // draw boundary
          let poly = new google.maps.Polyline({
            path: this.getgeom(geomdata.geom),
            strokeColor: '#F3D91B',
            strokeOpacity: 1,
            strokeWeight: 3
          });
          poly.setMap(this.map.map);
          this.polygons.push(poly);
          // this.getBound(poly);

          //To show label on a polygon
          //   let info = new google.maps.InfoWindow();
          //   google.maps.event.addListener(poly, 'click',(e)=> {
          //     info.setContent(geomdata.label[this.api.language]);
          //     let latLng = e.latLng;
          //     info.setPosition(latLng);
          //     info.open(this.map.map);
          // });
        }
      });
    }

    this.map.addEventListener('click', (e) => {
      if(this.showNavigation){
        this.navigateNext(e.latlon);
        //remove all markers
        this.map.removeAllMarkers();
        //add new marker with drag-end event
        this.map.addMarker(e.latlon, {
          'title': 'My property',
          'iconUrl': '../assets/icon/Pin.svg',
          'iconWidth': 50,
          'iconHeight': 50,
          'dragend': (e) => {
            this._zone.run(() => {
              this.navigateNext(e.latlon);
            });
          }
        });
      }
    });

    //Get long-press event to map by finding time-gap between mousedown and mouseup
    this.map.addEventListener('mousedown', (e) => {
      if (this.showNavigation) return;
      start = new Date().getTime();
    });
    if ((this.share.block0_obj.enablePatch && this.property_access.PATCH) || (!this.share.block0_obj.enablePatch)) {

      this.map.addEventListener('mouseup', (e) => {
        if (this.showNavigation) return;
        var end = new Date().getTime();
        var tm = end - start;
        var longpress = (tm > 800 && this.map.getZoomLevel() > 15 && this.dropped) ? true : false; // only when user zoom the map
        let p_name = 'New Property';

        if (longpress) {
          this.dropped = false;
          if (this.share.block0_obj.enablePatch) {
            p_name = this.selected_property.property_id;
          }
          this.isInside = this.checkIfInside(null, null, e.latLng);
          this._zone.run(() => {
            this.gLat = e.latlon.getLat();
            this.gLon = e.latlon.getLon();
            //remove all markers
            this.map.removeAllMarkers();
            //add new marker with drag-end event
            this.map.addMarker(e.latlon, {
              'title': 'My property',
              'info': '<div id="content">' +
                p_name +
                '</div>',
              'iconUrl': '../assets/icon/Pin.svg',
              'iconWidth': 50,
              'iconHeight': 50,
              'dragend': (e) => {
                this._zone.run(() => {
                  this.gLat = e.latlon.getLat();
                  this.gLon = e.latlon.getLon();
                });
                this.isInside = this.checkIfInside(this.gLat, this.gLon);
                this.validArea();
                this.is_edited = false;
              }
            });
            this.validArea();
          });
        }
      });
    }
    // WMS layer
    // this.map.addLayer({
    //   'format': 'wms',
    //   'layerName': 'parcels',
    //   'name': 'parcels',
    //   'url': this.api.wms_url,
    //   'assignment': this.assignment_detail.assignment_id
    // });

    // this.map.addLayer({
    //   'format': 'wms',
    //   'layerName': 'properties',
    //   'name': 'properties',
    //   'url': this.api.wms_url,
    //   'assignment': this.assignment_detail.assignment_id
    // });

     // Geoserver
     //'assignment': this.assignment_detail.assignment_id,
     this.map.addGeoLayer({
      'format': 'wms',
      'layerName': 'parcels',
      'name': 'parcels',
      'url': environment.geoestater+'wms-layer',
      'viewparams':`assignment_no:${this.assignment_detail.assignment_id}`,
      'city_code': this.api.city_code,
      'city_id': this.api.city_id,
      'user_token': environment.user_token
    });
    this.map.addGeoLayer({
      'format': 'wms',
      'layerName': 'properties',
      'name': 'properties',
      'url': environment.geoestater+'wms-layer',
      'viewparams':`assignment_no:${this.assignment_detail.assignment_id};user:${this.api.user_id}`,
      'city_code': this.api.city_code,
      'city_id': this.api.city_id,
      'user_token': environment.user_token
    });


    if (lt != this.previousLocation.lat && ln != this.previousLocation.lon) {
      this.showPolygonSelection();
    }
  }

  validArea() {
    if (!this.isInside) {
      this.invalidArea = true;
      this.pro = true;
      this.add = false;
      this.openDialog('create-address');//to show unsupported area in same dialog
      return;
    } else {
      this.invalidArea = false;
      this.getLocationInfo();
    }
  }
  openEdit() {
    if ((this.share.block0_obj.enablePatch && this.property_access.PATCH) || (!this.share.block0_obj.enablePatch)) {
      if (!this.gLat && !this.gLon) {
        this.notify.notify('No location selected', 'warn');
        return;
      }
      this.pro = true;
      this.add = false;
      this.openDialog('create-address');
    }
  }

  openPropList() {
    if (!this.share.block0_obj.enablePatch) {
      this.openDialog('existing-property');
    }
  }

  getAllProperties(){
    let url = "properties?limit=1000&lat=" + this.gLat + "&lon=" + this.gLon + '&return_gis_properties=false';
    this.api.getEpsData(url)
      .subscribe(
        (data: any) => {
          this.allProperties = data;
        })
  }

  getPropertyList() {
    this.loader = true;
    //let url = "deadmin/properties?assignment_id=" + this.share.block0_obj.assignment_id + "&sort_by=added_date&sort_asc=false&limit=1000&offset=0&lat=" + this.gLat + "&lon=" + this.gLon + "&assigned_to="+ this.api.user_id;
    let url = "deadmin/" + this.assignment_detail.assignment_name + "/properties?sort_by=added_date&sort_asc=false&limit=1000&offset=0&lat=" + this.gLat + "&lon=" + this.gLon + '&return_gis_properties=false&get_latest_property=Y';
    this.api.getEpsData(url)
      .subscribe(
        {next: (res: any) => {
          this.loader = false;
          if(res.length) this.existingData = res;
          if (this.existingAddress.length == 1 && res.length > 0) {
            let data = this.existingAddress[0];
            let bname = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : '';
            //Append existing building name is the user is updating the property location
            this.building_name = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : data.building_name;
            this.parcel_no = data.parcel_no;
            this.parcel_uid = data.parcel_uid;
            this.house_no = data.house_no;
            this.lane_name = data.lane_name;
            this.block_uid = data.block_uid;
            this.block = data.block_name;
            this.street_name = data.street_name;
            this.neighbourhood = data.neigh_name;
            this.neigh_uid = data.neigh_uid;
            //Append existing building name at the end of address
            this.address = data.address_text + ', ' + bname;
            this.mp_uid = data.mp_uid;
            this.mp_name = data.mp_name;
            this.proj_uid = data.proj_uid;
            this.proj_name = data.proj_name;
            this.getbuilding();
          } else if (this.existingAddress.length > 1) {
            //Append existing building name in the address
            this.address = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : '';
            this.house_no = '';
            this.parcel_no = '';
            this.parcel_uid = '';
            this.lane_name = '';
            this.street_name = '';
            //Append existing building name is the user is updating the property location
            this.building_name = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : '';
            this.multi = true;
            this.getNeighInfo();
            this.pro = false;
            this.add = true;
            this.openDialog('create-address');
          }else{
            this.existingData = [];
            this.getbuilding()
          }
          // this.getbuilding();
        }, error:() => {
          this.loader = false;
          if (this.existingAddress.length > 1) {
            //Append existing building name in the address
            this.address = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : '';
            this.house_no = '';
            this.parcel_no = '';
            this.parcel_uid = '';
            this.lane_name = '';
            this.street_name = '';
            //Append existing building name is the user is updating the property location
            this.building_name = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : '';
            // this.block = '';
            // this.neighbourhood = '';
            this.multi = true;
            this.getNeighInfo();
            this.pro = false;
            this.add = true;
            this.openDialog('create-address');
          } else if (this.existingAddress.length == 1 && this.existingData.length == 0) {
            let data = this.existingAddress[0];
            let bname = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : '';
            //Append existing building name is the user is updating the property location
            this.parcel_no = data.parcel_no;
            this.parcel_uid = data.parcel_uid;
            this.house_no = data.house_no;
            this.lane_name = data.lane_name;
            this.block_uid = data.block_uid;
            this.block = data.block_name;
            this.building_name = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : data.building_name;
            this.street_name = data.street_name;
            this.neighbourhood = data.neigh_name;
            this.neigh_uid = data.neigh_uid;
            //Append existing building name at the end of address
            this.address = data.address_text + ', ' + bname;
            this.mp_uid = data.mp_uid;
            this.mp_name = data.mp_name;
            this.proj_uid = data.proj_uid;
            this.proj_name = data.proj_name;
            this.getbuilding();
          }
        }});
    this.existingData = [];
  }


  ///open popup property
  getbuilding() {

    let url = 'places/bulk/search', body = {
      "columns": [
        "key", "lat", "lon"
      ],
      "data": [
        ["location",
          this.gLat,
          this.gLon
        ]
      ]
    };
    this.api.postGmsData(url, body).subscribe(
      {next:(res: any) => {
      if (res.buildings && res.buildings.location) {
        this.buildings = res.buildings.location;
        let gData=[];
        let pData=[];
        this.buildings.forEach(e => {
          let res = null;
          if(this.allProperties.length){
            res =  this.allProperties.filter(ele => e.building_uid == ele.building_gis_uid)
          }
          if(res) res = res[0];
          if (res == null || res == undefined) {
            let data = {
              'building_uid': e.building_uid,
              'building_name': e.building_name,
              'no_of_floors': e.no_of_floors,
              'no_of_units': e.no_of_units,
              'address': ''
            }
            gData.push(data);

          }
        });

        console.log(this.existingData.length, this.existingData);

        if (this.existingAddress.length > 0 && (gData.length || this.existingData.length)) {
          this.openPropList();
        }
      }
    }, error:() => {

    }})
  }

  checkParcel() {
    let url = 'place/location', body = {
      location1: {
        lat: this.selected_property.lat,
        lon: this.selected_property.lon
      },
      location2: {
        lat: this.gLat,
        lon: this.gLon
      },
      scope: 'parcel'
    };
    this.api.postGmsData(url, body).subscribe({
      next: (res: any) => {
      if (res.match == true) {
        this.getLocationInfo(true);
      } else {
        this.loader = false;
        this.notify.notify("You can't move property outside of its parcel", 'warn');
      }
    }, error:() => {
      this.loader = false;
      this.notify.notify('Unable to check parcel', 'error');
    }});
  }

  getLocationInfo(recall = false) {
    this.getAllProperties();
    this.loader = true;
    if (this.share.block0_obj.enablePatch && recall == false) {
      this.checkParcel();
      return;
    }
    this.getSuggested();
    this.streetList = [];
    this.laneList = [];
    let url = "address/json?categories=parcel&lat=" + this.gLat + "&lon=" + this.gLon + "&return_geometry=true";
    this.api.getGesData(url)
      .subscribe({
        next: (data: any) => {
        this.existingAddress = data;
        if (data.length == 1) {
          data= data[0];
          this.address = data.address_text;
          this.location_info = data;
          this.loader = false;
          this.building_name = data.building_name;
          this.parcel_no = data.parcel_no;
          this.parcel_uid = data.parcel_uid;
          this.house_no = data.house_no;
          this.lane_name = data.lane_name;
          this.block_uid = data.block_uid;
          this.block = data.block_name;
          this.street_name = data.street_name;
          this.neighbourhood = data.neigh_name;
          this.neigh_uid = data.neigh_uid;
        }
        if (data.length > 1) {
          this.address = '';
          this.loader = false;
        }
        this.getPropertyList();
      },
        error:(err) => {
          if (err['status'] == 401) {
            this.notify.notify('You are not authorize for GIS', 'error');
          }
          this.house_no = '';
          this.parcel_no = '';
          this.parcel_uid = '';
          this.lane_name = '';
          this.street_name = '';
          //Append existing building name is the user is updating the property location
          this.building_name = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : '';
          this.location_info = null;
          this.multi = false;
          this.address = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : '';
          this.getNeighInfo();
          this.loader = false;
        }})
    // this.getLatLon();
    // this.getNeighInfo();
  }
  getNeighInfo() {
    let filtered = "address/json?categories=block&lat=" + this.gLat + "&lon=" + this.gLon + "&return_geometry=true";
    this.api.getGesData(filtered)
      .subscribe({
        next: (data: any) => {
        this.invalidArea = false;
        this.neighbourhood = data[0].neigh_name;
        this.block_uid = data[0].block_uid;
        this.block = data[0].block_name;
        this.neigh_uid = data[0].neigh_uid;
        this.loader = false;
        if (this.multi == false) {
          this.openEdit();
        }
      }, error:(err) => {
        if (err['status'] == 401) {
          this.notify.notify('You are not authorize for GIS', 'error');
         }
        this.neighbourhood = '';
        this.block = '';
        this.block_uid = '';
        this.neigh_uid = '';
        this.invalidArea = true;
        this.loader = false;
        if (this.multi == false) {
          this.openEdit();
        }
      }});
    this.loader = false;
  }
  getSuggested() {
    // for street
    let filtered = "streets/json?categories=street,major road,highway,roundabout&radius=200&lat=" + this.gLat + "&lon=" + this.gLon + "&return_geometry=false";
    this.api.getGpsData(filtered)
      .subscribe((data: any) => {
        data.forEach((v) => {
          this.streetObj.push(v);
          if (v.street_name) {
            if (this.streetList.indexOf(v.street_name) == -1) {
              this.streetList.push(v.street_name);
            }
          }
        });
      }, err=>{
        if (err['status'] == 401) {
          this.notify.notify('You are not authorize for GIS', 'error');
         }
      });

    // // for lane suggestion
    filtered = "streets/json?categories=lane&radius=200&lat=" + this.gLat + "&lon=" + this.gLon + "&return_geometry=false";
    this.api.getGpsData(filtered)
      .subscribe((data: any) => {
        data.forEach((v) => {
          this.laneObj.push(v);
          if (v.street_name) {
            if (this.laneList.indexOf(v.street_name) == -1) {
              this.laneList.push(v.street_name);
            }
          }
        })
      }, err=>{
        if (err['status'] == 401) {
          this.notify.notify('You are not authorize for GIS', 'error');
         }
      });
  }
  createProperty() {
    localStorage.removeItem('filter_data');
    this.street_uid = null;
    this.lane_uid = null;
    let streetData = this.fnc.getArrayValue('street_name', this.street_name, this.streetObj);
    let laneData = this.fnc.getArrayValue('street_name', this.lane_name, this.laneObj);
    if (streetData && this.street_name) {
      this.street_uid = streetData.street_uid;
    }
    if (laneData && this.lane_name) {
      this.lane_uid = laneData.street_uid;
    }
    if (!this.parcel_uid) {
      this.parcel_uid = null;
    }
    if (this.share.block0_obj.enablePatch) {
      this.updateProperty();
      this.share.block0_obj.enablePatch = false;
      return;
    }
    // if (!this.property_access.POST) {
    //   this.notify.notify('You are not authorized to create property', 'warn');
    //   return;
    // }

    if (!this.create_property.POST) {
      this.notify.notify('Only admin can create property', 'warn');
      return;
    }
    this.loader = true;
    let geom = 'POINT (' + this.gLon + ' ' + this.gLat + ')';
    let _building_name, _lane_name, _street_name, _block_name, _neigh_name, _property_name, _property_attr;
    _building_name = { [this.api.language.toString()]: this.building_name };
    _lane_name = { [this.api.language.toString()]: this.lane_name };
    _street_name = { [this.api.language.toString()]: this.street_name };
    _block_name = { [this.api.language.toString()]: this.block };
    _neigh_name = { [this.api.language.toString()]: this.neighbourhood };
    _property_attr = { [this.attr_id]: this.building_name };
    _property_name = { [this.api.language.toString()]: _property_attr };

    let data = {
      "added_by": this.api.user_id,
      "assigned_to": this.api.user_id,
      "assignment_id": this.assignment_id,
      "geom": geom,
      "lat": this.gLat,
      "lon": this.gLon,
      "own_address": {
        "building_name": _building_name,
        "building_uid": this.building_uid,
        "house_no": this.house_no,
        "parcel_no": this.parcel_no,
        "parcel_uid": this.parcel_uid,
        "lane_name": _lane_name,
        "street_name": _street_name,
        "street_uid": this.street_uid,
        "block_uid": this.block_uid,
        "block_name": _block_name,
        "neigh_name": _neigh_name,
        "neigh_uid": this.neigh_uid,
        "lane_uid": this.lane_uid,
        "city_uid": this.city_uid,
        "city_name": this.city_name,
        "is_edited": this.is_edited,
        "mp_uid": this.mp_uid,
        "mp_name": this.mp_name,
        "proj_uid": this.proj_uid,
        "proj_name": this.proj_name
      },
      "building_gis_uid": this.building_gis_uid,
      "address_gis_uid": this.address_gis_uid,
      "status": 1
    };
    if (this.building_name) {
      data['data'] = {
        0: {},
        [this.api.language.toString()]: _property_attr
      }
    }

    this.api.postEpsData('deadmin/' + this.assignment_detail.assignment_name + '/properties', data)
      .subscribe({
        next: (data: any) => {
        this.router.navigateByUrl('property/' + this.assignment_id);
        this.loader = false;
      },
        error:(err) => {
          if (err['status'] == 401) {
            this.notify.notify('You are not authorized to create property', 'error');
          }
          this.loader = false;
        }});
  }

  updateProperty() {
    if (!this.street_uid) this.street_uid = "-1";
    if (!this.lane_uid) this.lane_uid = "-1";
    if (!this.property_access.PATCH) {
      this.notify.notify("You are not authorized to update property.", 'warn');
      return;
    }
    this.loader = true;
    let geom = 'POINT (' + this.gLon + ' ' + this.gLat + ')';

    let _building_name, _lane_name, _street_name, _block_name, _neigh_name, _property_attr, _property_name;
    _building_name = { [this.api.language.toString()]: this.building_name };
    _lane_name = { [this.api.language.toString()]: this.lane_name };
    _street_name = { [this.api.language.toString()]: this.street_name };
    _block_name = { [this.api.language.toString()]: this.block };
    _neigh_name = { [this.api.language.toString()]: this.neighbourhood };
    _property_attr = { [this.attr_id]: this.building_name };
    _property_name = { [this.api.language.toString()]: _property_attr };
    let data;
    data = {
      "updated_by": this.api.user_id,
      "assignment_id": this.assignment_id,
      "geom": geom,
      "lat": this.gLat,
      "lon": this.gLon,
      "own_address": {
        "building_name": _building_name,
        "building_uid": this.building_uid,
        "house_no": this.house_no,
        "parcel_no": this.parcel_no,
        "parcel_uid": this.parcel_uid,
        "lane_name": _lane_name,
        "street_name": _street_name,
        "street_uid": this.street_uid,
        "block_uid": this.block_uid,
        "block_name": _block_name,
        "neigh_name": _neigh_name,
        "neigh_uid": this.neigh_uid,
        "lane_uid": this.lane_uid,
        "city_uid": this.city_uid,
        "city_name": this.city_name,
        "mp_uid": this.mp_uid,
        "mp_name": this.mp_name,
        "proj_uid": this.proj_uid,
        "proj_name": this.proj_name
      }
    }

    if (this.building_name) {
      data['data'] = {
        0: {},
        [this.api.language.toString()]: _property_attr
      }
    }

    this.api.patchEpsData('deadmin/' + this.assignment_detail.assignment_name + '/properties/' + this.selected_property.id, data)
      .subscribe({next: (data: any) => {
        this.router.navigateByUrl('property/' + this.assignment_id);
        this.loader = false;
      },
        error:(err) => {
          if (err['status'] == 401) {
            this.notify.notify('You are not authorized to update property', 'erro');
          }
          this.loader = false;
        }});
  }

  cancelProperty() {
    this.router.navigateByUrl('property/' + this.assignment_id);
  }
  openDialog(type: string, info: any = {}): void {
    let dialogview;
    info.pObj = this;
    // console.log(info.pObj);

    if (type == 'create-address') {
      dialogview = CreateAddressComponent
    } else if (type == 'existing-property') {
      dialogview = ExistingPropertyComponent
    }
    if (dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: '450px',
        panelClass: 'create-master-panel',
        data: info
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }
  setlocation() {
    if (this.share.block0_obj.property_detail.geom) {
      let point = this.share.block0_obj.property_detail.geom.replace('POINT', '').replace('(', '').replace(')', '').trim().split(' ');
      if (point.length) {
        this.gLon = point[0];
        this.gLat = point[1];
        let obj: any = new LatLon(this.gLat, +this.gLon, 0);
        this.getSuggested();
        this.renderMap(point[1], point[0]);
        setTimeout(() => {
          //remove all markers
          this.map.removeAllMarkers();

          //add new marker with drag-end event
          this.map.addMarker(obj, {
            'title': 'My property',
            'info': '<div id="content">' +
              this.selected_property.property_id +
              '</div>',
            'iconUrl': '../assets/icon/Pin.svg',
            'iconWidth': 50,
            'iconHeight': 50,
            'dragend': (e) => {
              this._zone.run(() => {
                this.gLat = e.latlon.getLat();
                this.gLon = e.latlon.getLon();
                if ((this.share.block0_obj.enablePatch && this.property_access.PATCH) || (!this.share.block0_obj.enablePatch)) {
                  this.isInside = this.checkIfInside(this.gLat, this.gLon);
                  this.validArea();
                  // this.getLocationInfo();
                }
              });
            }
          });

        }, 10);
        let detail = this.share.block0_obj.property_detail.own_address;
        if (detail) {
          this.manualEntry = true;
          this.building_name = (detail.building_name) ? detail.building_name : '';
          this.parcel_no = (detail.parcel_no) ? detail.parcel_no : '';
          this.parcel_uid = (detail.parcel_uid) ? detail.parcel_uid : '';
          this.house_no = (detail.house_no) ? detail.house_no : '';
          this.lane_name = (detail.lane_name) ? detail.lane_name : '';
          this.street_name = (detail.street_name) ? detail.street_name : '';
          this.block = (detail.block_name) ? detail.block_name : '';
          this.neighbourhood = (detail.neigh_name) ? detail.neigh_name : '';
          if (detail && detail.address_text) this.address = detail.address_text;
        }
      }
    }
  }
  getSavetext() {
    let saveText='Create Property';
    if (this.share.block0_obj.enablePatch) {
      saveText = 'Update Property';
    } else {
      saveText = 'Create Property';
    }
    return saveText;
  }
  getgeom(data) {
    if (data != null) {
      let str = data;
      let result = str.match(/\(\((.*)\)\)/);
      let str2 = result[1];
      let latlon = str2.split(',');

      let temp = [];
      latlon.forEach(el => {
        let s = el.split(' ')
        temp.push({ "lat": +s[1], "lng": +s[0] });
      });
      return temp;
    }
  }
  gotoPreviousLocation() {
    if (!this.share.block0_obj.enablePatch) {
      if (this.previousLocation.lat != null && this.previousLocation.lon != null) {
        this.renderMap(this.previousLocation.lat, this.previousLocation.lon);
      } else {
        this.notify.notify('No previously worked location found', 'warn');
      }
    }
  }
    // getting allow resources
    getAllowResourcesApi() {
      let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
      this.api.getUmsData(body)
        .subscribe((data: any) => {
            this.api.allowResources = data.data;
            this.create_property = this.fnc.checkResourceAccess('create_property', false);
        })
    }

  ngOnInit() {
    this.getAllowResourcesApi();

    setTimeout(() => {
      this.dHeight = (this.share.heightService);
      let e = $(this.elementRef.nativeElement);
      e.find('.D-height').css('height', this.dHeight + 50);
      }, 10);
  }
  ngAfterViewInit() {
    if (this.share.block0_obj && this.share.block0_obj.enablePatch == true) {
      this.setlocation();
    } else {
      this.renderMap();
    }

  }
  ngOnDestroy() {
    this.map = null;
  }

  is_Address() {
    if (this.address) {
      return true;
    } else {
      return false;
    }
  }

}

@Component({
  selector: 'create-address',
  templateUrl: './create-address.component.html',
  styleUrls: ['./map.component.css']
})

export class CreateAddressComponent {
  show_property: boolean = false;
  show_address: boolean = true;
  addressForm: UntypedFormGroup;
  address: string;
  existingAddress: any;
  laneField: string;
  streetField: string;
  selected: boolean = false;
  addresses: Array<{}> = [];
  streetdata: Observable<any[]>;
  lanedata: Observable<any[]>;
  lanes: State[] = [];
  streets: State[] = [];
  invalidArea: boolean = false;
  previousValue: any;
  constructor(public dialogRef: MatDialogRef<CreateAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder,
    private fnc: CommonfunctionService, private share: ShareService) {
    this.show_property = data.pObj.pro;
    this.show_address = data.pObj.add;
    this.data.pObj.loader = false;
    this.existingAddress = this.data.pObj.existingAddress;
    this.invalidArea = this.data.pObj.invalidArea;
    this.data.pObj.save = false;
    if(this.existingAddress.length>0){
      this.existingAddress.forEach((v) => {
        let ad = v.address_text;
        this.addresses.push({
          'address': ad,
          'info': v
        });
      });
    }
   if(this.data.pObj?.streetList.length>0){
    this.data.pObj.streetList.forEach((v) => {
      this.streets.push({
        'name': v
      });
    })
   }


    if (this.data.pObj.location_info || this.data.pObj.manualEntry == true) {
      this.addressForm = fb.group({
        'building_name': [this.data.pObj.building_name],
        'house_no': [this.data.pObj.house_no],
        'parcel_no': [this.data.pObj.parcel_no],
        'lane_name': [this.data.pObj.lane_name, null],
        'street_name': [this.data.pObj.street_name, null],
        'block': [{ value: this.data.pObj.block, disabled: true }],
        'neighbourhood': [{ value: this.data.pObj.neighbourhood, disabled: true }]
      })
      this.previousValue = this.addressForm.value;
    } else {
      this.addressForm = fb.group({
        'building_name': [this.data.pObj.building_name],
        'house_no': [this.data.pObj.house_no],
        'parcel_no': [this.data.pObj.parcel_no],
        'lane_name': [this.data.pObj.lane_name, null],
        'street_name': [this.data.pObj.street_name, null],
        'block': [{ value: this.data.pObj.block, disabled: true }],
        'neighbourhood': [{ value: this.data.pObj.neighbourhood, disabled: true }]
      })
      this.previousValue = this.addressForm.value;
      this.data.pObj.loader = false;
    }

    this.setStreetLaneSuggestion();
    if (!this.streets.length) {
      setTimeout(() => {
        this.setStreetLaneSuggestion();
      }, 1700);
    }
    if (!this.lanes.length) {
      setTimeout(() => {
        this.setStreetLaneSuggestion();
      }, 2500);
    }
  }


  setStreetLaneSuggestion() {
    this.streets = [];
    this.lanes = [];
    this.data.pObj.streetList.forEach((v) => {
      this.streets.push({
        'name': v
      });
    });
    this.data.pObj.laneList.forEach((v) => {
      this.lanes.push({
        'name': v
      });
    });
    if (this.addressForm) {
      this.lanedata = this.addressForm.get('lane_name').valueChanges
        .pipe(
          startWith(''),
          map((state: any) => state ? this.currentLane(state) : this.lanes.slice())
        );
      this.streetdata = this.addressForm.get('street_name').valueChanges
        .pipe(
          startWith(''),
          map((state: any) => state ? this.currentStreet(state) : this.streets.slice())
        );

    }
  }
  currentLane(name: string) {
    return this.lanes.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  currentStreet(name: string) {
    return this.streets.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  ngOninit() {

  }
  addOwn() {
    this.addressForm = this.fb.group({
      'building_name': ['', Validators.required],
      'house_no': ['', Validators.required],
      'parcel_no': ['', Validators.required],
      'lane_name': ['', null],
      'street_name': ['', null],
      'block': [{ value: this.data.pObj.block, disabled: true }],
      'neighbourhood': [{ value: this.data.pObj.neighbourhood, disabled: true }]
    });
    this.previousValue = this.addressForm.value;
    this.show_address = false;
    this.data.pObj.openEdit();
    this.closeDialog();
  }
  selectAddr(addr) {
    this.selected = true;
    this.data.pObj.save = true;
    let bname = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : '';
    this.data.pObj.building_name = (this.share.block0_obj.enablePatch) ? this.share.block0_obj.property_detail.own_address.building_name : addr.info.building_name;
    this.data.pObj.house_no = addr.info.house_no;
    this.data.pObj.street_name = addr.info.street_name;
    this.data.pObj.parcel_no = addr.info.parcel_no;
    this.data.pObj.lane_name = addr.info.lane_name;
    this.data.pObj.address = addr.address + ', ' + bname;
    // this.data.pObj.address = addr.address + ' ' + (this.share.block0_obj.enablePatch)? this.share.block0_obj.property_detail.own_address.building_name: '';
    this.data.pObj.block = addr.info.block_name;
    this.data.pObj.neighbourhood = addr.info.neigh_name;
    this.data.pObj.neigh_uid = addr.info.neigh_uid;
    this.data.pObj.parcel_uid = addr.info.parcel_uid;
    this.data.pObj.lane_uid = addr.info.lane_uid;
    this.data.pObj.city_uid = addr.info.city_uid;
    this.addressForm = this.fb.group({
      'building_name': [this.data.pObj.building_name],
      'house_no': [this.data.pObj.house_no],
      'parcel_no': [this.data.pObj.parcel_no],
      'lane_name': [this.data.pObj.lane_name, null],
      'street_name': [this.data.pObj.street_name, null],
      'block': [{ value: this.data.pObj.block, disabled: true }],
      'neighbourhood': [{ value: this.data.pObj.neighbourhood, disabled: true }]
    });
    this.data.pObj.location_info = addr.info;
    this.show_address = false;
    this.closeDialog();
    if ((this.data.pObj.existingData.length > 0 || this.data.pObj.buildings.length) && !this.share.block0_obj.enablePatch) {
      if (!this.data.pObj.openPropertyList) {
        this.data.pObj.openPropList();
      }
      // this.data.pObj.openDialog('existing-property');
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  saveDialog(event): void {
    this.data.pObj.save = true;

    if (this.data.pObj.neighbourhood && this.data.pObj.block) {
      let val = event.value;
      if (this.data.pObj.is_edited == false) {
        if (this.fnc.jsonCompare(val, this.previousValue)) {
          this.data.pObj.is_edited = false;
        } else {
          this.data.pObj.is_edited = true;
        }
      }
      this.data.pObj.building_name = val.building_name;
      this.data.pObj.house_no = val.house_no;
      this.data.pObj.parcel_no = val.parcel_no;
      this.data.pObj.lane_name = val.lane_name;
      this.data.pObj.street_name = val.street_name;
      this.address = '';

      if (this.data.pObj.neighbourhood) this.address += this.data.pObj.neighbourhood;
      if (this.data.pObj.block) this.address += ', Block ' + this.data.pObj.block;
      if (val.lane_name) this.address += ', ' + val.lane_name;
      if (val.street_name) this.address += ', ' + val.street_name;
      if (val.parcel_no) this.address += ', Parcel ' + val.parcel_no;
      if (val.house_no) this.address += ', House No ' + val.house_no;
      if (val.building_name) this.address += ', ' + val.building_name;
      this.data.pObj.address = this.address;
      this.dialogRef.close();
    } else {
    }
  }
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_assignment_name_properties_property_id_get</li>
 * <li>estapi_deadmin_notification_post</li>
 * </ol>
 *
 */

@Component({
  selector: 'existing-property',
  templateUrl: './existing-property.component.html',
  styleUrls: ['./map.component.css']
})

export class ExistingPropertyComponent {
  existingData: any=[];
  pData: Array<any> = [];
  gData: Array<any> = [];
  existingBuildings: number;
  showPropertyList: boolean = true;
  assignment_id: any;
  mobile: boolean
  permission: boolean = false;
  loader2: boolean = false;
  propertyId: any;
  propertyUID: any;
  assignment_detail: any;
  selected_gis_building: any;
  add_loader: boolean = false;
  public confirmProperty: boolean = false;
  constructor(public dialogRef: MatDialogRef<ExistingPropertyComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notify: NotificationService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private api: ApiserviceService, private share: ShareService, private router: Router, private fnc: CommonfunctionService) {
    iconRegistry.addSvgIcon('plus', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/plus.svg'));
    this.mobile = this.share.mobile;
    this.existingData = this.data.pObj.existingData;
    this.assignment_detail = this.data.pObj.assignment_detail;
    this.assignment_id = this.assignment_detail.assignment_id;

    if(this.existingData.length){
      this.existingData.forEach((v) => {

        let address = this.fnc.getAddressText(v.own_address), property_name = 'Property Name';
        if (v.data) {
          if (v.data['property_name']) property_name = v.data['property_name'];
        }
        v['address'] = address;
        v['property_name'] = property_name;
        v['attribute_info'] = v.attribute_info;
        v['status'] = v.status;
        this.pData.push(v);
      });
    }

    this.data.pObj.buildings.forEach(e => {
      let res = null;
      if(this.data.pObj.allProperties.length){
        res =  this.data.pObj.allProperties.filter(ele => e.building_uid == ele.building_gis_uid)
      }
      if(res) res = res[0];
      // res = this.fnc.getArrayValue('building_gis_uid', e.building_uid, this.data.pObj.allProperties);

      if (res == null || res == undefined) {
        let data = {
          'building_uid': e.building_uid,
          'building_name': e.building_name,
          'no_of_floors': e.no_of_floors,
          'no_of_units': e.no_of_units,
          'address': ''
        }
        this.gData.push(data);
      }
    });
    this.existingBuildings = this.pData.length;
    // Close pop up if not property found
    // setTimeout(()=>{
      if(this.existingBuildings == 0 && this.gData.length == 0){
        this.dialogRef.close();
      }
    // },100);

  }
  countProperty(type = 'estater') {
    let estater = 0, gis = 0;
    this.pData.forEach((v) => {
      if (v.property_uid) {
        estater++;
      }
    });
    if (type == 'estater') return estater;
    if (type == 'gis') {
      if (this.gData) {
        return this.gData.length;
      }
    }
  }
  selectedProp(val, gis = false) {//get assignment dependent information for selected property
    if(val?.status == '5'){
      this.notify.notify('You can not select approved property', 'warn')
      return;
    }
    if (gis) {
      this.selectExistingProperty(val);
      return;
    }
    this.permission = false;
    this.propertyId = val.property_id;
    this.propertyUID = val.property_uid;
    this.add_loader = true;
    let url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + val.property_id + "?assignment_id=" + this.assignment_id;
    this.api.getEpsData(url).subscribe(
      {
        next: (data: any) => {
      this.add_loader = false;
      if (data.assigned_to == this.api.user_id && data.property_uid) {
        this.selectExistingProperty(val);
      } else {
        this.permission = true;
      }
    }, error:() => {
      this.permission = true;
      this.add_loader = false;
    }})
  }

/**
 * This is function to assign property to self
 * @param event
 * @param property property is parameter
 */
  self_assign(event, property){
    event.stopPropagation();
    let body = [
      {
        assigned_to: this.api.user_id,
        assignment_id: this.assignment_detail.assignment_id,
        updated_by: this.api.user_id,
        property_id: property.property_id,
      },
    ];
    this.add_loader=true;
    this.api.patchEpsData(`deadmin/${this.assignment_detail.assignment_name}/copy-properties`, body).subscribe({next:(res:any)=>{
      this.add_loader=false;
      if(res?.length==0){
        this.notify.notify('Property assigned', 'success');
        this.pData.map(e=>{
          if(property.property_id == e.property_id){
            e['assigned_to']=this.api.user_id;
          }
        })
      }

    },error:() =>{
      this.add_loader=false;
    }})


  }


  selectExistingProperty(val) {
    if (val.property_uid) {
      let address = "";
      if (val.own_address) {
        address = this.fnc.getAddressText(val.own_address);
      }
      val = {
        id: val.property_id,
        property_id: val.property_uid,
        property_category: val.category,
        address: address,
        status: val.status,
        attribute_info: val.attribute_info
      }
      if (this.mobile) {
        this.data.pObj.share.block0_obj.openOptions(val, true);
      } else {
        this.assignment_id = this.share.block0_obj.assignment_id;
        if (this.fnc.getChildArray('quick_edit', true, this.share.block0_obj.attribute_custom_list).length) { // check for quick edit
          this.data.pObj.share.block0_obj.openOptions(val, false);
        } else {
          this.router.navigateByUrl('property/' + this.assignment_id);
          setTimeout(() => {
            this.data.pObj.share.block0_obj.openOptions(val, false);
          }, 1000);
        }

      }
      this.dialogRef.close();
    } else if (val.building_uid) {
      this.showPropertyList = false;
      this.selected_gis_building = val;
    }
  }
  // createGis(val){
  //   if(val.building_gis_uid) this.data.pObj.building_gis_uid = val.building_gis_uid;
  //   if(val.address_gis_uid) this.data.pObj.address_gis_uid = val.address_gis_uid;
  //   this.showPropertyList = false;
  // }

  /**
   * This is a function check user property
   * @param val
   * @returns boolean
   */
  checkMyProp(val) {
    if (this.api.user_id == val.assigned_to) {
      return true;
    } else {
      return false;
    }
  }

/**
 * This is a function check  Unallowcated property
 * @param val
 * @returns boolean
 */
  checkUnallowcated(val){
    if(val.assigned_to == "" || val.assigned_to == undefined || val.assigned_to == null || val.assigned_to == 0){
      return true;
    }
    return false;
  }
  sendNotification() {
    this.loader2 = true;
    let data = {
      "entity_id": this.propertyId,
      "entity_name": "property",
      "from_user_id": this.api.user_id,
      "message": "Requested for permission to update property - " + this.propertyUID + " of assignment: " + this.assignment_detail.assignment_name,
      "notification_type": "request_for_edit",
      "to_user_id": 1
    }
    this.api.postEmsData("deadmin/notification", data)
      .subscribe({next: (data: any) => {
        this.notify.notify('Notification to admin has been sent, kindly wait for approval. ', 'warn');
        this.loader2 = false;
        this.permission = false;
      },
        error:(err) => {
          if (err['status'] == 401) {
            this.notify.notify('You are not unauthorised', 'error');
          }
          this.permission = false;
          this.loader2 = false;
        }});

  }
  cancelNotifi() {
    this.permission = false;
    this.loader2 = false;
  }
  cancel() {
    this.showPropertyList = true;
  }

  confirmdialog() {
    this.confirmProperty = true;
  }
  cancleCreateProperty() {
    this.confirmProperty = false;
  }

  createProperty() {
    if (this.selected_gis_building) {
      let val = this.selected_gis_building;
      this.data.pObj.building_gis_uid = val.building_uid;
      if (val.address_gis_uid) this.data.pObj.address_gis_uid = val.address_gis_uid;
      if (val.own_address) {
        let addr = val.own_address;
        if (addr.block_name) this.data.pObj.block_name = addr.block_name;
        if (addr.block_uid) this.data.pObj.block_uid = addr.block_uid;
        if (addr.building_uid) this.data.pObj.building_uid = addr.building_uid;
        if (addr.city_name) this.data.pObj.city_name = addr.city_name;
        if (addr.city_uid) this.data.pObj.city_uid = addr.city_uid;
        if (addr.lane_name) this.data.pObj.lane_name = addr.lane_name;
        if (addr.lane_uid) this.data.pObj.lane_uid = addr.lane_uid;
        if (addr.neigh_name) this.data.pObj.neigh_name = addr.neigh_name;
        if (addr.neigh_uid) this.data.pObj.neigh_uid = addr.neigh_uid;
        if (addr.parcel_no) this.data.pObj.parcel_no = addr.parcel_no;
        if (addr.parcel_uid) this.data.pObj.parcel_uid = addr.parcel_uid;
      }
      if (val.data) {
        if (val.data['property_name']) this.data.pObj.building_name = val.data['property_name'];
      }
    }

    this.data.pObj.createProperty();
    this.dialogRef.close();
  }
  ngOninit() {
  }
}

@Component({
  selector: 'polygon-selection',
  templateUrl: './polygon-selection.html',
  styleUrls: ['./map.component.css']
})

export class PolygonSelection {
  polygons: any = [];
  polygonsList: any = [];
  selectedPolygon: any;
  constructor(public dialogRef: MatDialogRef<PolygonSelection>,
    @Inject(MAT_DIALOG_DATA) public data: any, public api: ApiserviceService, private fnc: CommonfunctionService) {
    this.selectedPolygon = this.data.parent.selectedPolygon;
    this.polygons = JSON.parse(this.data.parent.assignment_detail.geom);
    if (this.polygons.length) {
      this.polygons.forEach(e => {
        let val = {
          name: e.label[this.api.language],
          label: e.label,
          geom: e.geom
        }
        this.polygonsList.push(val);
      });
    }
  }
  getSelectedPoly(e) {
    this.data.parent.selectedPolygon = e.value;
    let poly = this.fnc.getArrayValue('name', e.value, this.polygonsList);
    let pg = new google.maps.Polygon({
      paths: this.data.parent.getgeom(poly.geom),
      strokeColor: '#FF0000',
      strokeOpacity: 1,
      strokeWeight: 3
    });
    this.data.parent.getBound(pg);
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  ngOninit() { }
}

@Component({
  selector: 'bottom-sheet',
  templateUrl: 'bottom-sheet.html',
  styleUrls: ['./map.component.css']
})
export class BottomSheet {
  location:any = {lat:'', lon:''};
  constructor(private bottomSheetRef: MatBottomSheetRef<BottomSheet>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    //console.log(data);
    if(data.lat && data.lon){
      this.location = data;
    }
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
