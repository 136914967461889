<div class="pos">

    <div *ngIf="confirmation" class="confirmation">
      <div>
        <p>Do you want to copy units to seleted floors?</p>
      </div>
      <div class="b-middle" fxLayout="row" fxLayoutAlign="center center">
        <button (click)="confirm('YES')" class="applybutton" mat-button>Yes</button>
        <button (click)="confirm('NO')" class="cancelbutton" mat-button>No</button>
      </div>
    </div>
    <div class="mobile-block" *ngIf="!confirmation">
        <div *ngIf="loader" class='loader-div'>
            <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
        </div>
        <div *ngIf="!loader && !floorData.length" class='loader-div-new'>
            <span class="alert">This Property doesn't have a different floor</span>
            <div class="cent">
                <span (click)="close()" class="butn">Close</span>
            </div>
        </div>
        <ng-container *ngFor="let data of floorData; let i = index;">
            <div *ngIf="data.uid" class="big-button-p" [ngClass]="{'active':isSelected(data.id)}" (click)="selectFloor(data.id)">
                <div id="part1">
                    <div>
                        <span id="serial">{{i+1}}</span>
                    </div>
                </div>
                <div id="part2">
                    <div>
                        <span>{{data.type}}</span>
                    </div>
                    <div id="part21">
                        <div>
                            <div class="indicator"></div>
                        </div>
                    </div>
                    <div id="part22">
                        <div id="boxes">
                            <div id="unit">Units-{{data.units}}</div>
                            <div id="parking">Parking-{{data.parking}}</div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="enableConfirm && !confirmation" class="center-btn">
      <div (click)="confirmation = true" class="round1">
          <div>
              <mat-icon id="tick" svgIcon="tick"></mat-icon>
          </div>
      </div>
  </div>
</div>
