<div class="dialog">
    <div>
        <mat-toolbar class="tool">Add Unit</mat-toolbar>
    </div>
    <form *ngIf="!gis" [formGroup]="addUnitForm" (ngSubmit)="addUnit(addUnitForm)">
        <div id="pad">
            <div class="field">
                <span>Unit Type</span>
                <mat-select (selectionChange)="getUnitMasterVal()" [(ngModel)]="property_type" [ngModelOptions]="{standalone: true}" class="form-select-md" placeholder="Unit type">
                    <mat-option class="cp" *ngFor="let property of property_type_list" value={{property.id}}>{{property.label}}</mat-option>
                </mat-select>
            </div>
            <ng-container *ngFor="let data of unit_type_list">
                <ng-container formArrayName="input_{{data.id}}">
                    <div class="field2" *ngFor="let num of getFieldCount(data.id); let kk = index">
                        <ng-container [formGroupName]="kk">
                            <span>{{data.label}}</span>
                            <input class="inp" formControlName="number_{{data.id}}" placeholder="No." type="number" min="0" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57">
                            <input class="inp" formControlName="area_{{data.id}}" placeholder="Area" type="number" min="0" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57">
                            <mat-select formControlName="unit_{{data.id}}" class="form-select-md" placeholder="Unit">
                                <mat-option style="min-width: 69px !important;padding: 9px !important;" class="cp" *ngFor="let unit of measure_unit_type" value="{{unit}}">{{unit}}</mat-option>
                            </mat-select>
                            <div class="cent">
                                <div (click)="addRow(data)" id="add-icon">
                                    <mat-icon>add</mat-icon>
                                </div>
                                <div (click)="removeRow(data)" id="add-icon">
                                    <mat-icon>remove</mat-icon>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="error">{{err_msg}}</div>
        <div class="action">
            <div>
                <button type="submit" [disabled]="!property_type || !unit_type_list.length" class="act" mat-button>Create</button>
            </div>
            <div>
                <button (click)="closeDialog()" type="button" class="act" mat-button>Cancel</button>
            </div>
        </div>
    </form>
    <div id="pad" *ngIf="gis">
        <div *ngIf="loader">
            <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
            </ngx-loading>
        </div>
        <div class="message">
            <span>Do you want to import selected the units in estater?</span>
        </div>
        <div class="action">
            <div>
                <button (click)="createGisUnit()" class="act" mat-button>Create</button>
            </div>
            <div>
                <button (click)="closeDialog()" type="button" class="act" mat-button>Cancel</button>
            </div>
        </div>
    </div>

</div>
