import { Component, OnInit, Output, EventEmitter, ElementRef , ChangeDetectorRef } from '@angular/core';
import { MediaObserver} from '@angular/flex-layout';
import {DomSanitizer} from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ApiserviceService } from '../../../services/apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { ShareService } from '../../../services/share.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
export interface subgrouplist {
  group_name : any,
  id : number,
  name : string,
  property_id : any,
  labels : any,
  description : any,
  status : boolean,
  parent_id : number,
  attributes_count: number
};

@Component({
  selector: 'app-block2',
  templateUrl: './block2.component.html',
  styleUrls: ['./block2.component.css']
})
export class Block2Component implements OnInit {
mobile:boolean = false;
sub_group: Array<subgrouplist>;
selected_group:any;
selected_sub_group:subgrouplist;
selected_property:any;
selected:number;
dHeight: number;

@Output() notify: EventEmitter<string> = new EventEmitter<string>();

  constructor(private media: MediaObserver, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,private share:ShareService, private route:Router,private api:ApiserviceService,
    private fnc: CommonfunctionService, private elementRef: ElementRef,  private changeDetectorRef: ChangeDetectorRef) {
    this.mobile = this.share.mobile;
    iconRegistry.addSvgIcon("check", sanitizer.bypassSecurityTrustResourceUrl("assets/icon/GreenCheck.svg"));
    if(!this.share.block1_obj){
      this.route.navigateByUrl('/');
      return;
    }
    this.share.block2_obj = this;
    //let group = this.fnc.getArrayValue('id',this.share.block1_obj.selected_group_id,this.share.block1_obj.group_custom_list);
    this.selected_group = this.share.block1_obj.selected_group;
    this.sub_group = this.selected_group.childrens;
    this.selected_property = this.share.block1_obj.selected_property;
   }

  openOptions(v,mobile:boolean=false):void{
    if(mobile == false){
      if(this.share.dynamic_obj.form){
        this.share.block3_obj.submitForm();
      }
    }
    this.selected_sub_group = v;
    this.selected = v.id;
    if(mobile){
      this.route.navigateByUrl('form');
    }else{
      this.notify.emit('form');
    }
  }
  isFilled(id){
    let status = false;
    let record = {};
    if(this.share.block0_obj.property_detail){
      record = this.share.block0_obj.property_detail.data;
    }
    let attributes = this.fnc.getChildArray('group_id',id,this.share.block0_obj.attribute_custom_list);
    if(attributes && record){
      attributes.forEach((v)=>{
        if(record[v.id]){
          status = true;
        }
      })
    }
    return status;
  }
  ngOnInit() {
    setTimeout(()=>{
      this.dHeight = this.share.heightService;
      let e = $(this.elementRef.nativeElement);
      e.find('#pList').css('height',this.dHeight);
      e.find('#DynamicBlock').css('height',(this.dHeight-50));
      this.mobile = this.api.ismobile;
    }, 10);

    if(this.share.block3_obj && this.share.block3_obj.selected_sub_group){
      this.selected = this.share.block3_obj.selected_sub_group.id;
    }

  }

  isActive(v){
    if(v == this.selected) return true;
  }

}
