<mat-toolbar class="headtoolbar">
    <div fxLayout="row" class="w-100" fxLayoutAlign="space-between center">
        <ul class="button-group"> 
            <li (click)="navigate('property')" routerLinkActive="selected"><button class="top-button btn ctrans"><mat-icon svgIcon="property"></mat-icon>Property</button></li>
            <li (click)="navigate('floor')" routerLinkActive="selected"><button class="top-button btn ctrans" [disabled]="getFloor()"><mat-icon svgIcon="floor"></mat-icon>Floor</button></li>
            <li (click)="navigate('unit')" routerLinkActive="selected"><button class="top-button btn ctrans" [disabled]="getunit()"><mat-icon svgIcon="unit"></mat-icon>Unit</button></li>
        </ul>
        <button mat-raised-button class="update-btn ctrans" (click)="openDialog()" *ngIf="finalize">
            <mat-icon class="fnt-sm">backup</mat-icon>
            <span class="fnt-sm-text">Submit property</span>
        </button>
    </div>
</mat-toolbar>