<div *ngIf="!mobile">
    <div id="DynamicBlock" class="block1">
        <div class="button-block">
            <ng-container *ngFor="let data of child_group_list">
                <div *ngIf="data.status" class="big-button-m" [ngClass]="{'remark-style':isRerorkFloorGroup(data)}">
                    <div (click)="openOptions(data,false)" class="cent-start"><span id="margin">{{data.group_name}}</span></div>
                    <!-- <div class="cent" id="check-box"><mat-checkbox value="{{data.group_name}}"></mat-checkbox></div> -->
                </div>
            </ng-container>
        </div>
    </div>
    </div>
    
    <div *ngIf="mobile">
            <app-mobile-toolbar [back]="true" [title]="'Floor Info | '+selected_group.group_name" [property_uid]="selected_property.property_id"
            [customClickAction]="true" [customObj]="this" [floor_name]="selected_floor.name"></app-mobile-toolbar>

    <div class="list-container-mobile" id="pList">
        <div class="block">
            <div class="button-block">
                <ng-container *ngFor="let data of child_group_list">
                <div *ngIf="data.status" class="big-button-m" [ngClass]="{'remark-style':isRerorkFloorGroup(data)}">
                    <div (click)="openOptions(data,true)" class="cent-start"><span id="margin">{{data.group_name}}</span></div>
                    <!-- <div class="cent" id="check-box"><mat-checkbox value="{{data.group_name}}"></mat-checkbox></div> -->
                </div>
            </ng-container>
            </div>
        </div>
    </div>
    </div>